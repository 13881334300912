<br /><br /><br /><br />
<div class="col-md-12 text-center">
  <div class="col-md-12 text-center row">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-center">
      <h2><strong> All Companies</strong></h2>
      <table class="table" style="width: 100%">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Company Name</th>
            <th>Email</th>

            <th>Status</th>
            <th>Details</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let data of companies; index as i">
            <td>{{ i + 1 }}</td>

            <td>{{ data.userName }}</td>
            <td>{{ data.email }}</td>
            <td>{{ data.Status === true ? "Approved" : "Rejected" }}</td>

            <td>
              <button
                type="button"
                class="btn btn-primary"
                [routerLink]="['/companyprofile']"
                [queryParams]="{ accid: data.uid }"
              >
                Details</button
              >&nbsp;<button
                type="button"
                class="btn btn-primary"
                [routerLink]="['/alljoblist']"
                [queryParams]="{ accid: data.uid }"
              >
                View Jobs Posted
              </button>
            </td>
            <td> <i style="cursor: pointer" class="fa fa-trash" (click)="deletecompany(data.uid)" ></i></td>
           
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
