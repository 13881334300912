import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd, Event, NavigationStart, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import {  Location } from '@angular/common';
import { LoginService } from './shared/Login/login.service';
// import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';
import { SwUpdate } from '@angular/service-worker';
import { UserService } from './shared/UserService/user.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CompanyInvitation } from './shared/VIewmodels/CompanyInvitation';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // showLoadingIndicator = true;

  accid: string;
  dropdown: any;
  isLogged: any;
  FirstName: any;
  public isLoggedIn: boolean;
  public loading: boolean = false;
  // private publishableKey: string = "pk_test_WjKTje8bZjNTewEvJDwbIDgU00ioPyppVQ";
  loadingpage: boolean = false;
  win = true;
  admin = false;
  uid: any;
  role: any;
  company = false;
  student = false;
  closeResult: string;
  constructor( private router: Router, private httpService:HttpClient,
     public location: Location, private SocialloginService: LoginService, private swUpdate: SwUpdate
  ,private userservice:UserService,private spinner:NgxSpinnerService,private modalService: NgbModal) {
    // this.spinner.show();
    if (localStorage.admin) {
      this.admin = true;

    }
    if (localStorage.company) {
      this.company = true;
    }
    if (localStorage.seeker) {
      this.student = true;
    }
   

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("OS") == "Windows") {
      this.win = false;
    }
    if (this.role == "2") {
      this.company = true;
    }
    else if (this.role == "3") {
      this.student = true;
    }
    else if (this.role == "1") {
      this.admin = true;
    }

    
    this.router.events.subscribe((routerEvent) => {
      let footer = document.getElementById("footerremove");
      let nav = document.getElementById("nav");
      
     // console.log(routerEvent.NavigationStart)
      console.log(routerEvent instanceof NavigationEnd)

      if (routerEvent instanceof NavigationStart) {
        // this.showLoadingIndicator = true;
      }
      if (routerEvent instanceof NavigationEnd) {
     if(routerEvent.url.includes("msview"))
      {
       if(footer != null)
        {
          footer.style.display = "none";
        }
        
        if(nav != null)
        {
          nav.style.display = "none";
        }

      }
      else
      {
        if(footer != null)
        {
          footer.style.display = "block";
        }
        
        if(nav != null)
        {
          nav.style.display = "block";
        }
      }
      }
    })
    this.FirstName = localStorage.getItem("UserName");
    if (this.FirstName) {
      this.isLogged = true;
    }
    else {
      this.isLogged = false;
    }
  }
  institution = false;
  ngOnInit() {

    if (environment.production) {
      if (location.protocol === 'http:') {
        if(window.location.href.includes('www.'))
        window.location.href = location.href.replace('http://www.', 'https://');
        else
        window.location.href = location.href.replace('http', 'https');
      }
    }



    this.userservice.getinsti(this.uid).subscribe((data:any)=>{
      if(data){
        this.institution = true;
      }
      
    })
    if(this.swUpdate.isEnabled){
      this.swUpdate.available.subscribe(()=>{
        window.location.reload();
      })
    }
    AOS.init();
if (this.role == "2") {
  this.company = true;
}
else if (this.role == "3") {
  this.student = true;
}
else if (this.role == "1") {
  this.admin = true;
}
    $("#abt").click(function () {
      $('html, body').animate({
        scrollTop: $("#aboutus").offset().top
      }, 2000);
    });
    $(document).ready(function(){
      $('.menu-toggle').click(function(){
        $('nav').toggleClass('active');
      })
      $('ul li').click(function(){
        $(this).siblings().removeClass('active');
        $(this).toggleClass('active');
      })
    });

    setTimeout(() => {
      this.dropdown = document.getElementById("dropdown-menu");
      if (this.dropdown) {
        this.dropdown.style.display = "none";
      }

      this.dropdown1 = document.getElementById("profile-menu");
      if (this.dropdown1) {
        this.dropdown1.style.display = "none";
      }
    }, 10);



    this.loadingpage;
    if (localStorage.getItem("loadingpage") == "true") {
      this.loading = true;
      if (this.isLoggedIn == true) {
        this.loading = false;
      }
    }
   
  
    var ua = window.navigator.userAgent;
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    if (version) {
      var body = document.getElementsByTagName('body')[0];
      body.classList.add('ie-background');

    }

  }
  deviceInfo: any;
  
  togglem(){
    var nav = document.getElementById("remove");
    if(nav){
      nav.click();
    }
  }
  removeFooter() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (titlee === 'signup' || titlee === 'nucleoicons') {
      return false;
    }
    else {
      return true;
    }
  }
  togglemenu() {
    this.dropdown = document.getElementById("dropdown-menu");
    if (this.dropdown.style.display == "block") {
      this.dropdown.style.display = "none";
    }
    else {
      this.dropdown.style.display = "block";
    }
  }
  dropdown1: any;
  toggleprofile() {
    this.dropdown1 = document.getElementById("profile-menu");
    if (this.dropdown1.style.display == "block") {
      this.dropdown1.style.display = "none";
    }
    else {
      this.dropdown1.style.display = "block";
    }
  }
  dropdown2: any;
  toggleCompany() {
    this.dropdown2 = document.getElementById("Company-menu");
    if (this.dropdown2.style.display == "block") {
      this.dropdown2.style.display = "none";
    }
    else {
      this.dropdown2.style.display = "block";
    }
  }
  dropdown3: any;
  toggleStudent() {
    this.dropdown3 = document.getElementById("Student-menu");
    if (this.dropdown3.style.display == "block") {
      this.dropdown3.style.display = "none";
    }
    else {
      this.dropdown3.style.display = "block";
    }
  }
  plans() {
    this.router.navigate(['/payuplans', this.uid]);
}

itsUserorCompany(a) {
 
   localStorage.setItem("regtype", a);
   this.router.navigate(['/newsignup']);

 }

  logoutadmin() {
    localStorage.clear();
    localStorage.loadingpage = "true";
    this.router.navigate(['/newhome']);
  }
  logout() {
    this.dropdown1 = document.getElementById("profile-menu");
    if (this.dropdown1.style.display == "block") {
      this.dropdown1.style.display = "none";
    }
    else {
      this.dropdown1.style.display = "block";
    }
    localStorage.clear();
    // var landingUrl = "http://" + window.location.host + "/newhome";
    // //var landingUrl = "http://" + window.location.host + "/home";
    // window.location.href = landingUrl;
    localStorage.loadingpage = "true";
    var landingUrl = "https://jobs-zone.com/";
    //var landingUrl = "http://" + window.location.host + "/home";
    window.location.href = landingUrl;

  }
  downpath: any;
  seleteddownload: any;
  DownloadFile() {
    this.SocialloginService.downloadFile().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
    });

  }
  DownloadFilePortable() {
    this.SocialloginService.downloadFileportable().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
    });

  }



  Modalpopup(Sharecontent) {
   
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     // this.m = this.msg;
    });
  }


  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  SendInvitation(type) {
    this.spinner.show();
    var uid = localStorage.getItem("uid");
    const inputRequest: CompanyInvitation = {
      CompanyEmail : this.accid,
      InstitutionId : uid,
      type :type
    }
    this.userservice.CompanyInvitation(inputRequest).subscribe((data: any) => {
  if(data == "AI"){
        alert("Already Sent Invitation");
        
      }
      else if(data == "NU"){
        alert("We are sorry "+this.accid+" is not using Jobs-Zone!!");
      
      }
      else if(data == "IS"){
        alert("Invitation Sent successfully");
      }
      else if(data == "SWW"){
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid ="";
    });
  }
Excel:any;
handleFileInput2(files:File) {
  this.Excel = files[0];
}
  SendInvitation1()
  {
    if(this.Excel != null )
      {
  
        var uid = localStorage.getItem("userId");
      const frmData = new FormData();
      frmData.append("insid", uid);
  
      frmData.append("fileUpload", this.Excel);
  
      
    
      this.httpService.post('https://blockchainmatrimony.com/jobzoneapi/api/InviteStudents/InviteStudents/', frmData).subscribe(
          data => {
        
            //call flask api just like face recognition
            if(data == "1")
            {
          alert("Invited Successfully");
            }
           else{
            alert("Something went wrong. Please try again..!! ");
           }
          },
          (err: HttpErrorResponse) => {
            alert("Something went wrong. Please try again..!! ")
            console.log(err.message);    // Show error, if any.
          });
    
        }
        else
        {
          alert("Select Excel File");
        }
  }


}
