<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<h2 style="text-align: center">Report of applicant who have attended your test</h2>
<br />
<div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div class="table-responsive">
            <div style="overflow-x:visible;height: 300px;overflow-y: visible">
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th>Applicant Name</th>
                        <th>Contact Email</th>
                        <th>MCQ Marks</th>
                        <th>Coding Result</th>
                        <th>Contact</th>
                        <th>Video</th>
                    </tr>
                    <tr *ngFor="let aa of alldata;let i = index" style="font-size:16px;font-family: sans-serif">
                        <td> {{ (i+1) }}</td>
                        <td> {{ aa.name }}</td>
                        <td> {{ aa.email }}</td>
                        <td> {{ aa.marksmcq }}</td>
                        <td *ngIf="aa.markscode == 0"> Failed</td>
                        <td *ngIf="aa.markscode == 1">Passed</td>
                        <td *ngIf="aa.markscode == 2">No output</td>
                        <td *ngIf="aa.markscode == 3">No coding round</td>
                        <td> <button type="button" class="btn btn-primary" (click)="addFriend(aa.id, content3)" style="width: 100px;">
                                Contact
                            </button> <br> <button style="width: 100px;margin-top: 10px;" type="button" class="btn btn-primary" (click)="viewUserProfile(aa.id,aa.name,aa.email)">
                                Profile
                            </button><br>
                            <button style="width: 100px;margin-top: 10px;" type="button" class="btn btn-primary" (click)="certificateGeneration(aa.id,aa.candidateName,aa.mail,aa.parentName,aa.mobnum,aa.adhaar,aa.address)">
                                Certificate
                            </button>
                        </td>
                        <td> <button type="button" class="btn btn-primary" (click)="viewvideo(aa.video,videocontent)">View</button> </td>
                    </tr>
                </table>
            </div>
            <br />
            <!-- <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls><br /><br /> -->
        </div>
    </div>
</div>
<br /><br />

<!-- notification alert -->
<!-- <div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <i class="material-icons" style="color: white">close</i>
      </button>
            <span style="color: white;margin-top: 6px;">
        <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div> -->

<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white;">
        <div>&nbsp;</div>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->
    <div class="modal-body" style="width: 883px; background-color: white;">
        <!-- <video autoplay #video controls id="video" onerror="error(event)" class="video">
            <source id="video"  type='video/x-matroska; codecs="avc1, opus"'>
        </video> -->
        <video width="560" height="420" controls="controls">
            <source id="source" src="{{openvideo}}" type="video/webm">
        </video>
    </div>
</ng-template>

<ng-template #content3 let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h2 style="font-family: 'Times New Roman', Times, serif;text-align: center;color: mediumslateblue;">
            Continue Chat here
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="col-md-12">
                <div class="col-md-12">
                    <div class="form-horizontal">
                        <div class="">
                            <h2 style="text-align: center;"></h2>
                        </div>
                        <div class="col-md-12" style="margin-bottom: 50px;max-width: 100%;background-color: white;">
                            <div class="form-group">
                                <label class="control-label">chat message:</label>
                                <input type="text" id="messa" name="messa" class="form-control" placeholder="Enter the message" [(ngModel)]="messa" />
                            </div>
                            <div class="form-group">
                                <input type="Submit" class="btn btn-primary btn-round" style="background-color: grey; color: white;" (click)="SaveUserChat()" (click)="d('Cross click')" value="Send" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>