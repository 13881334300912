<div class="row" style="margin-top: 4%;">
    <div class="col-lg-12">
        <div class="box">
            <!--mail inbox start-->
            <div class="mail-box">
                <aside class="sm-side">
                  
                    <div class="inbox-body">
                        <a class="btn btn-compose" data-toggle="modal" (click)="composemail()" data-original-title=""
                            title="">
                            Compose
                        </a>


                    </div>

                    <ul class="inbox-nav inbox-divider">
                        <li class="active">
                            <a (click)="inbox()"><i class="fa fa-inbox"></i> Inbox </a>
                        </li>
                        <li>
                            <a (click)="sentitems()"><i class="fa fa-envelope-o"></i> Sent Mail</a>
                        </li>

                    </ul>


                </aside>
                <aside class="lg-side">
                    <app-inbox-mails *ngIf="query == 'inbox'"></app-inbox-mails>
                    <app-sent-mails *ngIf="query == 'sent'"></app-sent-mails>
                    <app-composemail *ngIf="query == 'compose'"></app-composemail>
                    <app-read-mail *ngIf="query == 'read'"></app-read-mail>
                </aside>
            </div>
        </div>
    </div>
</div>