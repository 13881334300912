import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Jobzonestudent_Rolemaster } from 'app/shared/VIewmodels/PlaytypeJobPortalVM';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-approle',
  templateUrl: './approle.component.html',
  styleUrls: ['./approle.component.scss']
})
export class ApproleComponent implements OnInit {
  plantype: string;
  amount: string;
  planlist: any;
  palnid: any;
  plaid: any;

  constructor(private masterservices:MasterService , private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      document.getElementById("save").style.display = "block";
      document.getElementById("update").style.display = "none";
    }, 500);
    this.PlansList();
    this.rolename = "";
  }
  ResetData(){
    this.rolename = "";
  }
  role:any;
  rolename:any;
  PlansList() {
    this.masterservices.getjobzonerole().subscribe((data:any)=>{
      this.role = data;
    })
  }
  SavePlanType(form: NgForm) {
    const inputRequest: Jobzonestudent_Rolemaster = {
      Id: 0,
      Role:this.rolename
    }
    this.masterservices.postjobzonerole(inputRequest).subscribe((data: any) => {
      if (data == null) {
        alert("Added Successfully");
        this.router.navigate(['/approlemaster']);
        window.location.reload();

      }
      else {
        alert("Something went Wrong");
        this.router.navigate(['/approlemaster']);
        window.location.reload();
      }
    });
  }
  instiid:any;
  editplantype(data: any) {
    document.getElementById("save").style.display = "none";
    document.getElementById("update").style.display = "block";

    this.instiid = data.id;
    this.rolename = data.role;
  }

  UpdatePlanType(form: NgForm) {
    document.getElementById("save").style.display = "block";
    document.getElementById("update").style.display = "none";

    const inputRequest1: Jobzonestudent_Rolemaster = {
      Id: this.instiid,
      Role: this.rolename,
    }
    this.masterservices.Updaterole(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          alert("Role Name Updated Succesfully");
          this.PlansList();
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }
  deleteplantype(data: { id: any; }) {
    this.plaid = data.id;
    this.masterservices.deleterole(this.plaid).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Role Deleted Succesfully");
          this.PlansList();
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }

}
