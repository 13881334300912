<div class="inbox-head">
    <h3>Sent Mails</h3>

</div>
<div class="inbox-body">

    <table class="table table-inbox table-hover">
        <tbody>
            <tr class="unread" id="{{ret.Id}}" (click)="ReadMessage(ret)" style="cursor: pointer;" *ngFor="let ret of sentList">

                <!-- <td class="inbox-small-cells">
                                        <input type="checkbox" class="mail-checkbox">
                                    </td> -->

                <td class="view-message  dont-show" >
                    {{ret.To}}
                </td>

                <td class="view-message " >
                    {{ret.Subject}}
                </td>
                <td class="view-message " >
                    {{ret.date}}
                </td>


            </tr>

        </tbody>
    </table>
</div>