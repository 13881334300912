<app-nav-bar></app-nav-bar>
<br> <br /> <br /> <br /> <br />
<div class="col-md-12 row">
    <div class="col-md-2">
        <app-mailcomposee></app-mailcomposee>
    </div>
    <div class="col-md-10">
        <div class="inbox-head">
            <div class="col-md-8">
               
                <br />
            </div>

        </div>
        <table class="table table-hover">
            <thead>
              
            </thead>
            <tbody style="background: #ecf0f1">
                <tr class="unread" id="{{ ret.Id }}" *ngFor="let ret of deleteList" style="cursor: pointer">
                    <td >
                        <input type="checkbox" style="height: 16px;" class="mail-checkbox" (click)="toggleSelect(ret)" >
                    </td>

                    <td class="view-message dont-show" (click)="ReadMessage(ret)">
                        {{ ret.fromName }}
                    </td>

                    <td class="view-message" (click)="ReadMessage(ret)">
                        {{ ret.subject }}
                    </td>
                    <td class="view-message" (click)="ReadMessage(ret)">
                        {{ ret.date }}
                    </td>
                    <td (click)="restore(ret)">
                        <img src="assets/img/restore.png" data-toggle="tooltip" title="Restore" alt="" width="25px" />
                    </td>
                    <td (click)="singleDelete(ret)">
                        <img src="assets/img/permanentdel.png"  data-toggle="tooltip" title="permanently delete" alt="" width="25px" />
                    </td>
                </tr>
            </tbody>
        </table>
        <br /><br />
      
        <br /><br />
        <br /><br />
    </div>
</div>