import { Component, OnInit } from '@angular/core';
import { CourseService } from '../services/course.service';
import { ChapterService } from '../services/chapter.service';
import { QuestionService } from '../services/question.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { questionVM } from 'app/shared/VIewmodels/questionvm';
import { NgForm } from '@angular/forms';
import { onlyid } from 'app/shared/VIewmodels/onlyid';
import { optionsnewvm } from 'app/shared/VIewmodels/optionsnewvm';

@Component({
  selector: 'app-addquestions',
  templateUrl: './addquestions.component.html',
  styleUrls: ['./addquestions.component.scss']
})
export class AddquestionsComponent implements OnInit {

  courseId: any;
  CourseId: any;
  Courses: any;
  Question: any;
  ChapterId: any;
  Questions: any;
  ID: any;
  blkid:any;
  constructor(private route:ActivatedRoute,private Courseservice: CourseService, private ChapterService: ChapterService,private questionservice:QuestionService,private router:Router) {
    this.route.params.subscribe((data:any)=>{
this.blkid = data['id'];
    })
   }
  userChapter: questionVM;
  showSave: boolean;
  showUpdate: boolean;
  cityName: string;
  cityDesc: string;
  Cities: any;
  cityid: number;
  Chapterid: number;
  ChapterName: string;
  ChapterDesc: string;
  bid: number;
  Chapters: any;
  p: number = 1
  public dismissalert = false;
  notify: string
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
   // this.GetChapters();
    this.getQuestions();
    this.getQuestions1();
this.userid=localStorage.userId;
  }
userid;
  getQuestions()
  {
    const id :onlyid = {
      id:this.blkid
    }
    this.questionservice.getAllquestions(id).subscribe((response: any) => {
      this.Questions = response;
      console.log(this.Questions)

    });
  }
 
  GetChapters() {
    
    this.ChapterService.getAllChapteres().subscribe((response: any) => {
      this.Chapters = response;
      

    });
  }
  addClick() {
    this.showSave = true;
    this.showUpdate = false;
    this.CourseId =null;
    this.Question ="";
    this.ChapterName = "";
    this.Chapterid = null;
  }
  //edit Chapter
  editChapter(data: any) {
    this.showSave = false;
    this.showUpdate = true;
    this.ID = data.id;
  //  this.ChapterName = data.Chapter;
   this.Question = data.question;
  }
  onSelectCity(data: any) {
    this.GetChapters1(Number(this.CourseId));

    this.CourseId = data
  }


  GetChapters1(id) {
    
    this.ChapterService.getcourseChapteres(id).subscribe((response: any) => {
      this.Chapters = response;
      

    });
  }

  SaveChapter(form: NgForm) {
    const inputRequest: questionVM = {
      ID: 0,
     Question :  form.controls["Question"].value,
      Chapter:null,
      QuestionBankName :  this.blkid,
  
    }
    

    this.questionservice.savequestion(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Question Saved Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions();
          
        }else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions();
          
        }
      }
    );
  }
  UpdateChapter(form: NgForm) {
    
    const inputRequest1: questionVM = {
      ID: this.ID,
     Question :  form.controls["Question"].value,
     Chapter:null,
      QuestionBankName :  this.blkid,
  
    }
    
    this.questionservice.UpdatequestionRow(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          this.notify = "Question Updated Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions();
          
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions();
          

        }
      }
    );
  }
  deleteChapter(data: any) {
    this.bid = data.id;
  }
  deleteChapterRow() {
    
    this.questionservice.deletequestiondata(this.bid).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Question Deleted Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions();
          

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions();
          
        }
      });
  }
  option1:any;
  option2:any;
  option3:any;
  option4:any;
  questionid:any;
  answer:any;
  options(data){
    this.questionid= data.id;
    this.questionservice.getoptions(this.questionid).subscribe((dat:any)=>{
      this.option1 = "";
      this.option2 = "";
      this.option3 = "";
      this.option4 = "";
      this.isChecked = false;
      this.isCheckedName = "";
      if(dat[0]){
        this.option1 = dat[0].options;
        if(dat[0].isEnabled == true){
          this.isCheckedName = 1;
          this.isChecked = true;
        }
      }
      if(dat[1]){
        this.option2 = dat[1].options;
        if(dat[1].isEnabled == true){
          this.isCheckedName = 2;
          this.isChecked = true;

        }
      }
      if(dat[2]){
        this.option3 = dat[2].options;
        if(dat[2].isEnabled == true){
          this.isCheckedName = 3;
          this.isChecked = true;

        }
      }
      if(dat[3]){
        this.option4 = dat[3].options;
        if(dat[3].isEnabled == true){
          this.isCheckedName = 4;
          this.isChecked = true;

        }
      }
    })
  }

  onSelectChapter(data)
  {
    this.ChapterId = data;
  }
  isChecked ;
  isCheckedName;
  checkboxData = [1,2,3,4];
  onChange(e){       
    this.isChecked = !this.isChecked;
    this.isCheckedName = e.target.name;
  }
  saveoptions(form:NgForm){
    const optionsvalues:optionsnewvm={
id:this.questionid,
option1:form.controls['option1'].value,
option2:form.controls['option2'].value,
option3:form.controls['option3'].value,
option4:form.controls['option4'].value,
answer:this.isCheckedName
    }
    if(this.isCheckedName == ""||this.isCheckedName == undefined){
      alert("You have not selected answer please select answer");
    }
    else{
      this.questionservice.postoptions(optionsvalues).subscribe((data:any)=>{
        if(data == 1){
          this.notify = "Options added Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
        else if(data == 2){
          this.notify = "Options updated Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
        else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
      })
    }
  }
input:any;
output:any;
  saveoptions11(form:NgForm){
    const optionsvalues:optionsnewvm={
id:this.codequestion,
option1:this.input,
option2:this.output,
answer:null,
option3:null,
option4:null
    }

    
      this.questionservice.postcodeoptions(optionsvalues).subscribe((data:any)=>{
        if(data == 1){
          this.notify = "Input and output added Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
        else if(data == 2){
          this.notify = "Input and output updated Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
        else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
      })
    }
  
  codequestion:any;
 
  options1(data){
    this.codequestion= data.id;
    this.questionservice.getcodeanswers(this.codequestion).subscribe((dat:any)=>{
      this.input = "";
      this.output = "";
      
      if(dat){
        this.input = dat.input;
       this.output = dat.output;
      }
    
    })
  }
  link1;
  generatestreamlink(){
    this.link1 = "https://jobs-zone.com/videoreceiver/"+this.userid+","+this.Questions[0].courseId;
  }
  SaveChapter1(form: NgForm) {
    const inputRequest: questionVM = {
      ID: 0,
     Question :  form.controls["Question1"].value,
      Chapter:null,
      QuestionBankName :  this.blkid,
  
    }
    

    this.questionservice.savecodequestion(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Question Saved Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions1();
          
        }else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions1();
          
        }
      }
    );
  }

  UpdateChapter1(form: NgForm) {
    
    const inputRequest1: questionVM = {
      ID: this.ID,
     Question :  form.controls["Question1"].value,
     Chapter:null,
      QuestionBankName :  this.blkid,
  
    }
    
    this.questionservice.UpdatecodequestionRow(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          this.notify = "Question Updated Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions1();
          
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions1();
          

        }
      }
    );
  }
  deleteChapter1(data: any) {
    this.bid = data.id;
    
  }
  deleteChapterRow1() {
    
    this.questionservice.deletecodequestiondata(this.bid).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Question Deleted Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions1();
          

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getQuestions1();
          
        }
      });
  }

Questions1:any;
removeAdd=false;
  getQuestions1()
  {
    const id :onlyid = {
      id:this.blkid
    }
    this.questionservice.getAllcodequestions(id).subscribe((response: any) => {
      this.Questions1 = response;
      if(this.Questions1.length>0){
        this.removeAdd=true;
      }
      else{
        this.removeAdd = false;
      }
      console.log(this.Questions1)

    });
  }
 
Question1:any;
  addClick1() {
    this.showSave = true;
    this.showUpdate = false;
    this.CourseId =null;
    this.Question1 ="";
    this.ChapterName = "";
    this.Chapterid = null;
  }
  //edit Chapter
  editChapter1(data: any) {
    this.showSave = false;
    this.showUpdate = true;
    this.ID = data.id;
  //  this.ChapterName = data.Chapter;
   this.Question1 = data.question;
  }
link;
  generatetestlink(){
    this.link = "https://jobs-zone.com/test/"+this.Questions[0].courseId;
  }
}
