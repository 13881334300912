import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { UserService } from 'app/shared/UserService/user.service';
import { MasterService } from 'app/shared/MasrerService/master.service';

@Component({
  selector: 'app-shared-employer',
  templateUrl: './shared-employer.component.html',
  styleUrls: ['./shared-employer.component.scss']
})
export class SharedEmployerComponent implements OnInit {
  userid: any;
  constructor(private registrationService: RegistrationService, private userser: UserService,private service:MasterService) {
    this.userid = localStorage.getItem("uid");
    window.scrollTo(0,0);

  }
  roles:any;
  ngOnInit(): void {
    this.getdetails();
    this.service.getrole().subscribe((data:any)=>{
      this.roles = data;
    });
    setTimeout(() => {
      var coll = document.getElementsByClassName("collapsible");
      var i;

      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
          this.classList.toggle("active");
          var content = this.nextElementSibling;
          if (content.style.maxHeight) {
            content.style.maxHeight = null;
          } else {
            content.style.maxHeight = content.scrollHeight + "px";
          }
        });
      }

    }, 5000);
  }
joblist:any;
jobroles:any;

  getdetails() {
    this.registrationService.getSharedDetails(this.userid).subscribe((data: any) => {
      this.joblist=data;
      this.joblist.forEach(element => {
        this.userser.getCerti(element['applicantId']).subscribe((respose: any) => {

          element.certificates = respose;
          this.userser.getUserDetails(element.certificates[0]['userID']).subscribe((response:any)=>{
            element.userdata = response;
            console.log(this.joblist);
          });
          this.userser.getjobdetails(element.jobBlkId).subscribe((responses:any)=>{
            element.jobdata = responses;
            
          
              if(element.jobdata['jobRole']!=null){
                element.jobdata['jobRole']=this.roles[element.jobdata['jobRole']].role
                
              }
           
          
            console.log(this.joblist);

          })

        });
      });
     
    })
  }
  download(value){
    window.open(value);
  }
}
