<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>

<app-nav-bar></app-nav-bar>

<h2 style="text-align: center;">User List</h2>
<div>&nbsp;</div>
<div class="row col-sm-12">
    <div class="col-sm-1"></div>
    <div class="col-sm-2">
        <button class="btn btn-primary" style="width: 8vw;" (click)="addUser(adduser)">
            Add
        </button>
    </div>
    <div class="col-sm-9"></div>
</div>
<br />
<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
        <div>
            <div class="table-responsive" style="overflow-x: visible; height: 300px;">
                <table class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>UserId</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Designation</th>
                        <th>Department</th>
                        <th>Location</th>
                        <th>Created On</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                    <tr *ngFor="
              let aa of UserList
                | paginate: { itemsPerPage: 5, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{ aa.userId }}</td>
                        <td>{{ aa.userName }}</td>
                        <td>{{ aa.email }}</td>
                        <td>{{GetRole(aa.role)}}</td>
                        <td>{{GetDesignation(aa.designation)}}</td>
                        <td>{{GetDepartment(aa.department)}}</td>
                        <td>{{ aa.location }}</td>
                        <td>{{ aa.createdOn | date: "short" }}</td>

                        <td>
                            <button class="btn btn-success" (click)="EditUser(aa, updateuser)">
                                Edit
                            </button>
                        </td>
                        <td>
                            <button class="btn btn-danger" (click)="deleteUser(aa, deleteuser)">
                                Delete
                            </button>
                        </td>
                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event" style="float: right;"></pagination-controls>
                <br /><br />
                <br /><br />
            </div>
        </div>
    </div>
</div>
<br /><br />



<ng-template #adduser let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add User</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">User Name:</h5>
        <input type="text" id="fullname" [(ngModel)]="fullname" class="form-control" name="dp" (change)="Validateusername()" /><br/>
        <h6 *ngIf="validusername" style="color: red;">UserName already taken, Try with other UserName</h6>

        <h5 class="modal-title" id="modal-basic-title">Email:</h5>
        <input type="text" id="email" [(ngModel)]="email" class="form-control" name="dp" (change)="Validatemail()" />
        <h5 *ngIf="validmail" style="color: red;">Email already exist!!</h5>
        <br />
        <select (change)="OnselectUserRole($event.target.value)" class="form-control">
            <option value="">Select Role</option>
            <option *ngFor="let do of RoleList" value={{do.id}}>
                {{do.roleName}}
            </option>
        </select><br />
        <select (change)="OnselectUserDepartment($event.target.value)" class="form-control">
            <option value="">Select Department</option>
            <option *ngFor="let do of DepartmentList" value={{do.id}}>
                {{do.departmentName}}
            </option>
        </select><br />
        <select (change)="OnselectUserDesignation($event.target.value)" class="form-control">
            <option value="">Select Designation</option>
            <option *ngFor="let do of DesignationList" value={{do.id}}>
                {{do.designationName}}
            </option>
        </select><br />

        <h5 class="modal-title" id="modal-basic-title">Location:</h5>
        <input type="text" id="location" [(ngModel)]="location" class="form-control" name="dp" /><br />
    </div>
    <br />
    <div class="modal-footer">
        <button *ngIf="validmail" type="button" class="btn btn-outline-dark" style="color: black; cursor: pointer;">
            Save
        </button>
        <button *ngIf="!validmail" type="button" class="btn btn-outline-dark" (click)="SaveUser()" (click)="d('Cross click')" style="color: black; cursor: pointer;">
        Save
    </button>
    </div>
    <br />
</ng-template>
<ng-template #updateuser let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update User</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Full Name:</h5>
        <input type="text" id="editfullname" [(ngModel)]="editfullname" class="form-control" name="dp" /><br/>
        <select (change)="OnselectUserRole($event.target.value)" class="form-control">
            <option value="">Select Role</option>
            <option *ngFor="let do of RoleList" value={{do.id}} [selected]="do.id == editrole">
                {{do.roleName}}
            </option>
        </select><br />
        <select (change)="OnselectUserDepartment($event.target.value)" class="form-control">
            <option value="">Select Department</option>
            <option *ngFor="let do of DepartmentList" value={{do.id}} [selected]="do.id == editdepartment">
                {{do.departmentName}}
            </option>
        </select><br />
        <select (change)="OnselectUserDesignation($event.target.value)" class="form-control">
            <option value="">Select Designation</option>
            <option *ngFor="let do of DesignationList" value={{do.id}} [selected]="do.id == editdesignation">
                {{do.designationName}}
            </option>
        </select><br />

        <h5 class="modal-title" id="modal-basic-title">Location:</h5>
        <input type="text" id="editlocation" [(ngModel)]="editlocation" class="form-control" name="dp" /><br />

    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="UpdateUser()" (click)="d('Cross click')" style="color: black; cursor: pointer;">
            Update
        </button>
    </div>
    <br />
</ng-template>
<ng-template #deleteuser let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete User</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="DeleteUserRow()" (click)="d('Cross click')" style="color: black; cursor: pointer;">
            Ok
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="color: black; cursor: pointer;">
            Cancel
        </button>
    </div>
    <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
                <i class="material-icons" style="color: white;">close</i>
            </button>
            <span style="color: white; margin-top: 6px;">
                <b style="text-transform: capitalize; font-size: 14px;">
                    {{ notify }}</b></span>
        </div>
    </div>
</div>