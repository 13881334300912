


<!------------hero--------->
<header>
  <br>
    <div class="view"
      style="background-image: url('../assets/img/dream.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;height: 74vh;">
  
   
      <div class="mask rgba-gradient d-flex justify-content-center align-items-center">
  
        <div class="container">
  
          <div class="row mt-5">
  
    
            <div class="col-md-12 mb-5 mt-md-0 mt-5 white-text text-center text-md-center">
              <br><br><br><br><br>          <br><br><br><br><br><br><br>
              <h1 class="h1-responsive font-weight-bold wow fadeInLeft" data-wow-delay="0.3s" style="color: rgb(0, 0, 0);">Online HR Portal! </h1>
              <hr class="hr-light wow fadeInLeft" data-wow-delay="0.3s">
              <h3 class="mb-3 wow fadeInLeft" data-wow-delay="0.3s" style="color: rgb(0, 0, 0);">An Interactive Digital Platform for Job Seekers and Companies and Educational Institutions</h3>
              <!-- <a class="btn btn-outline-white wow fadeInLeft" data-wow-delay="0.3s">Learn more</a> -->
            </div>
         
            <!-- <div class="col-md-6 col-xl-5 mb-4">
    
              <div class="card1 wow fadeInRight" data-wow-delay="0.3s">
                <div class="card-body">
      
                  <div class="text-center">
                    <h3 class="white-text">
                      <i class="fa fa-user white-text"></i> Register:
                    </h3>
                    <hr class="hr-light">
                  </div>
         
                  
                  <div class="md-form">
                    <i class="fa fa-envelope prefix white-text active"></i>  <label for="form2" class="active"> Email or Phone</label>
                    <input type="email" id="form2" class="white-text form-control">
                  
                  </div>  <br>
                  <div class="md-form">
                    <i class="fa fa-lock prefix white-text active"></i>  <label for="form4">Password</label>
                    <input type="password" id="form4" class="white-text form-control">
                    <br> 
                    <label for="form4">Forgot password?</label>
                  </div>
                  <div class="text-center mt-4">
                    <button class="btn btn-indigo">Get in</button>
                    <hr class="hr-light mb-3 mt-4">
                    <label for="form2" class="active">Sign up</label>
                  </div>
                </div>
              </div>
              <br><br>
            </div> -->
      
          </div>
  
        </div>
  
      </div>
   
    </div>
  
  </header>
  <!------------steps-------->
  <div class="row">
    <div class="col-md-12" >
      <nav>
        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
            aria-controls="nav-home" aria-selected="true">Companies</a>
          <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
            aria-controls="nav-profile" aria-selected="false">Applicants</a>
          <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab"
            aria-controls="nav-contact" aria-selected="false">Placement Officer</a>
          <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab"
            aria-controls="nav-about" aria-selected="false">PRMIA Advantages</a>
        </div>
      </nav>
      <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
         <p> Corporate Registrations.</p> 
          <p>Corporate Payments for Successful Hiring </p>
            <p>Corporate Payments for Outsourced HR Management .</p>
             
               
          
        </div>
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <p>Application Registration Fee from the Applicants </p>
            <p>Placement Charges </p>
              <p>Applicant Payments for Additional Services like Training ,  Testing , Profiling , Counselling etc.,</p>
          
        </div>
        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
          <p>Internship & Training  </p>
            <p>Placement Fees </p>
              <p>Charges receivable for using our technology platform  </p>
          
        </div>
        <div class="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
        
          <p>PRM Benefits through SRICHID:</p> 
          <p>(i) High paying jobs/ Global career</p>      
  
          <p>(ii) Job placement through SRICHID Jobs-Zone</p>  
  
          <p>(iii) Special discount for Indians Rs.20,000 Students Rs.50,000  only for limited period</p>        
  
          <p>(iv) Flexible Online exams, quarterly testing windows</p>   
        </div>
      </div>
  
    </div>
  </div>
  <!---------features------------>
  <section class="testimonial-section1"  style="height: 20vh;background-color: rgb(240, 240, 240);padding-top: 10%;">
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-2">
       <div class="page-404">
        <div class="outer">
            <div class="middle">
                <div class="inner">
                    <!--BEGIN CONTENT-->
                    <div class="inner-circle"><i class="">     <img
                      src="/assets/img/hand.svg" width="40" height="40"
                       style="align-items: center;"
                    /></i><span>Job<br>Application</span></div>
                
                    <!--END CONTENT-->
                </div>
            </div>
        </div>
       </div>
      </div>
      <div class="col-md-2">
        <div class="page-404">
         <div class="outer">
             <div class="middle">
                 <div class="inner">
                     <!--BEGIN CONTENT-->
                     <div class="inner-circle"><i class=""> <img
                      src="/assets/img/file1.svg" width="40" height="40"
                      style=" align-items: center;"
                    /></i><span>Job<br>Posting</span></div>
                 
                     <!--END CONTENT-->
                 </div>
             </div>
         </div>
        </div>
       </div>
       <div class="col-md-2">
        <div class="page-404">
         <div class="outer">
             <div class="middle">
                 <div class="inner">
                     <!--BEGIN CONTENT-->
                     <div class="inner-circle"><i class=""><img
                      src="/assets/img/security.svg" width="40" height="40"
                      style="align-items: center;"
                    /></i><span>Identity Authentication</span></div>
                 
                     <!--END CONTENT-->
                 </div>
             </div>
         </div>
        </div>
       </div>
       <div class="col-md-2">
        <div class="page-404">
         <div class="outer">
             <div class="middle">
                 <div class="inner">
                     <!--BEGIN CONTENT-->
                     <div class="inner-circle"><i class=""> <img
                      src="/assets/img/profile.svg" width="40" height="40"
                       style="align-items: center;"
                    /></i><span>Background Verification</span></div>
                 
                     <!--END CONTENT-->
                 </div>
             </div>
         </div>
        </div>
       </div>
    </div>
    </section>
    <section  style="height: 50vh;background-color: rgb(240, 240, 240);padding-top: 6%;">
      <div class="col-md-12 row">
        <div class="col-md-2"></div>
        <div class="col-md-2">
         <div class="page-404">
          <div class="outer">
              <div class="middle">
                  <div class="inner">
                      <!--BEGIN CONTENT-->
                      <div class="inner-circle"><i class="">
                        <img
                        src="/assets/img/gottilla.svg" width="40" height="40"
                         style="align-items: center;"
                      />
                      </i><span>Online Recruitment</span></div>
                  
                      <!--END CONTENT-->
                  </div>
              </div>
          </div>
         </div>
        </div>
        <div class="col-md-2">
          <div class="page-404">
           <div class="outer">
               <div class="middle">
                   <div class="inner">
                       <!--BEGIN CONTENT-->
                       <div class="inner-circle"><i class=""> <img
                        src="/assets/img/school.svg" width="40" height="40"
                         style="align-items: center;"
                      /></i><span>Online<br>Training</span></div>
                   
                       <!--END CONTENT-->
                   </div>
               </div>
           </div>
          </div>
         </div>
         <div class="col-md-2">
          <div class="page-404">
           <div class="outer">
               <div class="middle">
                   <div class="inner">
                       <!--BEGIN CONTENT-->
                       <div class="inner-circle"><i class="">
                        <img
                        src="/assets/img/computer.svg" width="40" height="40"
                         style="align-items: center;"
                      />
                       </i><span>Online<br>Testing</span></div>
                   
                       <!--END CONTENT-->
                   </div>
               </div>
           </div>
          </div>
         </div>
         <div class="col-md-2">
          <div class="page-404">
           <div class="outer">
               <div class="middle">
                   <div class="inner">
                       <!--BEGIN CONTENT-->
                       <div class="inner-circle"><i class="">
                        <img
                        src="/assets/img/computer1.svg" width="40" height="40"
                         style="align-items: center;"
                      />
                       </i><span>Online<br>Chatting</span></div>
                   
                       <!--END CONTENT-->
                   </div>
               </div>
           </div>
          </div>
         </div>
      </div>
      </section>
  <!-- -------spiral--------->
  <!-- <section class="testimonial-section1"></section> -->
  <!------plans--------------->
  <section class="testimonial-section">
  <section id="pricing" class="our_pricing section-padding">
    <div class="container">
      <div class="row">
        <div class="section-title text-center">
          <h1 class="section-title-white">Pricing plan</h1>
          <p class="section-title-white" style="color: white;">Blockchain Security <br> Artificial Intelligence for talent identification and job matching and Multi-Service Delivery Platform with Online Training, Internship Placement and Digital Identity Management.</p>
        </div>
        <div class="col-xs-12 col-sm-4 col-lg-4">
          <div class="pricingTable pricingTable-2">
            <div class="pricingTable-header">
              <h3 class="title">Profiling 
                Students / Professionals Applicants
                </h3>
              <h5 class="price-month">Per Month</h5>
              <h1 class="price-value">Free <span class="value-bg">Free</span></h1>
            </div>
            <ul class="pricing-content">
              <li>Create profile</li>
              <li>Matched jobs</li>
              <li>Chat </li>
              <li>File System</li>
            </ul>
           
          </div>
        </div><!-- END COL  -->
        <div class="col-xs-12 col-sm-4 col-lg-4">
          <div class="pricingTable pricingTabletop pricingTable-2">
            <div class="pricingTable-header">
              <h3 class="title">Organizations</h3>
              <h5 class="price-month">Per Month</h5>
              <h1 class="price-value">1000 <span class="value-bg">₹1000</span></h1>
            </div>
            <ul class="pricing-content">
              <li>Job Postings </li>
              <li>Get Candidates</li>
              <li>Online Test</li>
              <li>Hire Candidates Virtually </li>
              <li></li>
            </ul>
       
          </div>
        </div><!-- END COL  -->
        <div class="col-xs-12 col-sm-4 col-lg-4">
          <div class="pricingTable pricingTable-2">
            <div class="pricingTable-header">
              <h3 class="title">Placement Officer
              </h3>
              <h5 class="price-month">Per Month</h5>
              <h1 class="price-value">500 <span class="value-bg">₹500</span></h1>
            </div>
            <ul class="pricing-content">
              <li>College Details </li>
              <li>Dashboard</li>
              <li>Invite companies for internship, training and job.</li>
              <li>File System</li>
            </ul>
            <!-- <a  [routerLink]="['/newsignup']" class="btn btn-lg btn-price-bg">Sign Up</a> -->
          </div>
        </div><!-- END COL  -->
      </div>
      <!--END  ROW  -->
    </div><!-- END CONTAINER  -->
  </section>
  </section>
  
