import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DesignationService } from 'app/shared/designation.service';
import { DepartmentService } from 'app/shared/department.service';
import { RoleService } from 'app/shared/role.service';
import { Designation } from 'app/shared/VIewmodels/designation';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent implements OnInit {
  closeResult: any;
  DesignationName: any;
  designationname: any;
  DesignationDesc: any;
  designationdesc: any;
  public dismissalert = false;
  notify: string;
  DesignationList: any;
  p: number = 1;
  editdesignationName: any;
  editdesc: any;
  editdesignationid: any;
  udesignationname: any;
  udesignationdesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  RoleList: any=[];
  DepartmentList: any=[];
  selectedrole: any;
  selecteddepartment: any;
  editrole: any;
  editdepid: any;
  AllDepartmentList: any=[];
  constructor( private modalService: NgbModal,private spinner: NgxSpinnerService,private designationService: DesignationService,
    private roleService:RoleService,private departmentService:DepartmentService) { }

  ngOnInit(): void {
    this.Designations();
    this.Roles();
    this.Departments();
    this.DesignationName = "";
    this.DesignationDesc = "";
  }
  Roles(){
    this.spinner.show();
    this.roleService.getAllRoles().subscribe((Data: any) => {
      this.RoleList = Data;
      this.spinner.hide();
    });
  }
  Departments() {
    this.spinner.show();
    this.departmentService.getAllDepartments().subscribe((Data: any) => {
      this.AllDepartmentList = Data;
      this.spinner.hide();
    });
  }
  onSelectedRole(id){
    this.selectedrole= id;
    this.spinner.show();
    this.departmentService.getParticularDepart(this.selectedrole).subscribe((Data: any) => {
      this.DepartmentList = Data;
      this.spinner.hide();
    });
  }
  onSelectedDepartment(id){
    this.selecteddepartment=id
  }
  Designations() {
    this.spinner.show();
    this.designationService.getAllDesignations().subscribe((Data: any) => {
      this.DesignationList = Data;
      this.spinner.hide();
    });
  }
  addDesignation(addesignation) {
    this.modalService.open(addesignation).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditDesignation(updatedata, updatedesignation) {
    this.editdesignationName = updatedata.designationName;
    this.editdesignationid = updatedata.id;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.editrole=updatedata.roleId;
    this.editdepid=updatedata.departmentId
    this.modalService.open(updatedesignation).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteDesignation(deletedata, adddesignation) {
    this.DeleteName = deletedata.designationName;
    this.deleteid = deletedata.id;
    this.modalService.open(adddesignation).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  SaveDesignation() {
    const inputRequest: Designation = {
      Id: 0,
      DesignationName: this.designationname,
      DesignationDesc: this.designationdesc,
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
      RoleId:this.selectedrole,
      DepartmentId:this.selecteddepartment
    };
    this.spinner.show();
    this.designationService.saveDesignation(inputRequest)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Designation added Successfully";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Designations();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Designations();
        }
      });
  }

  UpdateDesignation() {
    if(this.selectedrole==null || this.selectedrole==""){
      this.selectedrole=this.editrole
    }
    if(this.selecteddepartment==null || this.selecteddepartment==""){
      this.selecteddepartment=this.editdepid
    }
    const inputRequest1: Designation = {
      Id: this.editdesignationid,
      DesignationName: this.editdesignationName,
      DesignationDesc: this.editdesc,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
      DepartmentId:this.selecteddepartment,
      RoleId: this.selectedrole
    };
    this.spinner.show();
    this.designationService
      .UpdateDesignation(inputRequest1)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Designation Updated Succesfully";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Designations();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Designations();
          this.spinner.hide();
        }
      });
  }
  DeleteDesignationRow() {
    this.spinner.show();
    this.designationService
      .deleteDesignation(this.deleteid)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Designation Deleted Succesfully";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Designations();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Designations();
          this.spinner.hide();
        }
      });
  }
  closealert(){
    this.dismissalert=false;
  }
  GetDepartment(deptid) {
    for (let i = 0; i < this.AllDepartmentList.length; i++) {
      if (deptid == this.AllDepartmentList[i].id) {
        return this.AllDepartmentList[i].departmentName;
      }
    }
  }
  GetRole(rid) {
    for (let i = 0; i < this.RoleList.length; i++) {
      if (rid == this.RoleList[i].id) {
        return this.RoleList[i].roleName;
      }
    }
  }
}
