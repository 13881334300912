import { Component, OnInit } from "@angular/core";
import { RegistrationService } from "app/shared/JobPortalServices/registration.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Friend } from "app/shared/VIewmodels/Friend";
import { ChatDetail } from "app/shared/VIewmodels/ChatDetail";
import { IndividualChatDetails } from "app/shared/VIewmodels/IndividualChatDetail";
import { UserId } from "app/shared/VIewmodels/UserId";
import { NgForm } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalServiceService } from "app/shared/Modalpopup/modal-service.service";
import { UserService } from "app/shared/UserService/user.service";
import { MailService } from "app/shared/MailService/mail.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-company-match-list",
  templateUrl: "./company-match-list.component.html",
  styleUrls: ["./company-match-list.component.scss"],
})
export class CompanyMatchListComponent implements OnInit {
  isLoggedIn: boolean;
  accountid: string;
  slist: any;
  blkid: any;
  joblist: any=[];
  friends: boolean=false;
  friend: any;
  mailto:any = []

  expands: boolean = true;
  compress: boolean = false;
  className: string;
  chatdetail: ChatDetail;
  Users: any = [];
  userid: any;
  fullName: string;
  touserid: any;
  chatopen: boolean;
  IndividualChatDetail: any;
  uid: boolean;
  chattoid: any;
  chatvar: any;
  chattouserid: any;
  chaatto: any;
  Touser:any = [];
  TicketsList: any;
  ToUsersForMail: any;
  mailsubject:any;
 
  message:any;
  aaaaa: ChatDetail;
  IndividualChatRecivedDetails: any;
  IndividualChatSentDetails: any=[];
  UserImage: any;
  BMid: string;
  touser: any;
  currentuser: string;
  data: any;
  chtnm: any;
  cnnTableData: any = [];
  closeResult: string;
  accid: any;
  m: any;
  Friends: any;
  selectedSelected: any = [];
  UserID: any;
  frmDataa: FormData;
  SelectedFiles: any = [];
  selectedFriend: any;
  CurrentFiles: any;
  selected: any;
  remove: boolean;
  foldernamelist: any;
  allFolders1: any;
  allFolders: any;
  morefiles: boolean;

  constructor(
    private registrationService: RegistrationService,
    private route: Router,
    private router: ActivatedRoute,
    private modalService1: ModalServiceService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private userservice: UserService,
    private mailservice: MailService,
    private httpService: HttpClient

  ) {
    window.scrollTo(0,0);
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem("userId");
      this.accountid = localStorage.getItem("uid");
    }
    this.router.params.subscribe((params) => {
      if (params["id"]) {
        this.blkid = params["id"];
      }
    });
  }

  ngOnInit() {
    // setTimeout(() => {
    //   var coll = document.getElementsByClassName("collapsible");
    //   var i;
  
    //   for (i = 0; i < coll.length; i++) {
    //     coll[i].addEventListener("click", function () {
    //       this.classList.toggle("active");
    //       var content = this.nextElementSibling;
    //       if (content.style.maxHeight) {
    //         content.style.maxHeight = null;
    //       } else {
    //         content.style.maxHeight = content.scrollHeight + "px";
    //       }
    //     });
    //   }
  
    // }, 5000);
   
    this.getlistofseekers();
    this.GetUsers();
    this.loadChatDetail();
    this.GetDetails();
   
  }
  getlistofseekers() {
    this.registrationService
      .GetMatchList(this.blkid)
      .subscribe((response: any) => {
        this.joblist = response;
        console.log(this.joblist)
      });
  }
  OnSelectUser(id) {
    this.touserid = id;
    this.userservice.GetToUser(this.touserid).subscribe((respose: any) => {
      this.touser = respose;
    });
  }
  messa: any;
  SaveUserChat() {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: this.currentuser,
      ChatTo: null,
      ChatToName: null,
      ChatMessage: this.messa,
      IsEnabled: true,
      ChatFromUId: this.userid,
      ChatToUserUId: this.touser,
    };
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Message Sent Successfully");
      }
    });
  }


  GetUsers() {
    const inputRequest: UserId = {
      userid: this.userid,
    };
    this.userservice
      .GetAllUsersExceptMe(inputRequest)
      .subscribe((respose: any) => {
        var Users1 = respose.friendsList;
        for (let i = 0; i < Users1.length; i++) {
          if (Users1[i]["friendId"] == this.userid) {
            Users1[i]["friendId"] = Users1[i]["userId"];
          }
          this.Users.push(Users1[i]);
        }
      });
  }
  cancelmail(){
    this.route.navigate(['jobmail']);

  }
  onChangeDocumnets(event: any) {
    this.frmDataa = new FormData();
    let files = [].slice.call(event.target.files);
    for (var i = 0; i < files.length; i++) {
      this.SelectedFiles.push(files[i]);
      this.frmDataa.append("Docs", files[i]);
    }
  }
  viewUserProfile(id){
    localStorage.profileUserName = id.name;
    localStorage.profileEmail = id.email;
    this.route.navigate(['/profileview',id.toid]);
  }

  emailstu(id){
  localStorage.profileUserName = id.name;
  localStorage.profileEmail = id.email;
  this.mailto=id.email;
  console.log(this.mailto)
    this.route.navigate(['/jobsent',id.toid])
  }
  GetCurrentUserImage() {
    this.userservice
      .getCurrentUSerImage(this.userid)
      .subscribe((respose: any) => {
        this.UserImage = respose;
      });
  }

  loadChatDetail() {
    this.userservice
      .GetChatforParticularUser(this.userid)
      .subscribe((chat: any) => {
        this.chatdetail = chat;
      });
  }

  OpenChatWindow(chat) {
    this.chatvar = chat;
    this.chattouserid = chat.chatFrom;
    this.chaatto = chat.userID;
    this.chatopen = true;
    const inputRequest: IndividualChatDetails = {
      Chatid: chat.id,
      ChatFrom: chat.chatFrom,
      ChatTo: chat.userID,
    };
    this.userservice
      .GetIndividualChatRecivedDetails(inputRequest)
      .subscribe((data: any) => {
        // this.IndividualChatRecivedDetails = data.recivedchatdetails;
        this.IndividualChatSentDetails = data;
        console.log(this.IndividualChatSentDetails);
        for (let i = 0; i < this.IndividualChatSentDetails.length; i++) {
          if (
            this.IndividualChatSentDetails[i].chatFromName != this.currentuser
          ) {
            console.log(this.IndividualChatSentDetails);
            this.chtnm = this.IndividualChatSentDetails[i].chatFromName;
            break;
          }
        }

        if (this.userid == chat.chatTo) {
          this.uid = true;
        }
      });
  }
  goto() {
    this.route.navigate(["chat"]);
  }
  SendIndividualMessage(form: NgForm) {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: "",
      ChatTo: this.touser,
      ChatToName: "",
      ChatMessage: form.controls["messa"].value,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.chattouserid,
    };
    this.aaaaa = inputRequest;
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.OpenChatWindow(this.chatvar);
        (<HTMLInputElement>(
          document.getElementById("individualchatmessage")
        )).value = "";
      }
    });
  }

  CloseChatWindow() {
    this.chatopen = false;
  }
  GetDetails() {
    this.userservice.friendlist(this.userid).subscribe((respose: any) => {
      this.Friends = respose;
      this.spinner.hide();
    });
  }
  friendid: any;
  addFriend(id, addfriendcontent) {
    this.touser = id;
    // this.OnSelectUser(id);
    this.modalService.open(addfriendcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.accid;
      }
    );
  }
  savefrienddetails() {
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
    };
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");
        this.GetDetails();
      } else if (data == "alreadyfriends") {
        alert("You are already friends!!");
        this.GetDetails();
      } else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using jobs-zone!!");
        this.GetDetails();
      } else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");
        this.GetDetails();
      } else if (data == "1") {
        alert("Friend Request Sent successfully");
        this.GetDetails();
      } else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
    });
  }
  acceptrequest(f) {
    this.userservice.AcceptReq(f).subscribe((data: any) => {
      alert("Accepted Successfully");
      // this.route.navigate(['/allfolders']);
      this.GetDetails();
    });
  }

  ViewDocument(bb, pdfcontent) {
    this.modalService.open(pdfcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = "yvtfcfu";
      }
    );
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  sendmail(){

    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var det = {
      //TicketId: this.selectedticketid,
    FromId: this.accountid,
    //MultiToUser: this.Touser,
    Jobzonedoc:this.SelectedFiles,
      ToUser: this.mailto,
      Subject: this.mailsubject,
      Message: this.message,
      //FromuserGUID: this.GUID
    }
    //this.frmDataa.append("Drive", this.selectedSelected[i]);
    this.frmDataa.append("MessageData", JSON.stringify(det));
    this.frmDataa.append("Internal", "Internal");
    this.spinner.show()
    this.httpService.post("http://localhost:8451/" + 'api/JobMailSystem/PostSaveNewMailforJob/', this.frmDataa).subscribe(
      data => {
        this.spinner.hide();
        this.selectedFriend= this.selectedSelected;
        this.route.navigate(['jobsent'])
        alert("Mail Sent Successfully");
        
        const audit = {
          TransactionName:"Mail",
          TransactionType:"Send",
          TransactionDesc:"Compose Mail",
          
          }
          //this.auditortransactionservice.AuditorTransactionDetails(audit);
          this.Touser="";

        this.mailsubject = "";
        this.message = "";
        window.location.reload();
        // this.route.navigate(['sentmails']);
      
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });

  }
  
  

  

  onClick(data) {
    this.CurrentFiles = data;
    if (data != null) {
      for (var i = 0; i < this.selectedSelected.length; i++) {
        if (this.selectedSelected[i].Id == data.Id) {
          var index = this.selected.indexOf(data);
          this.selectedSelected.splice(index, 1);
          this.remove = true;
        }
      }
      this.selectedSelected.push(data);
    }
  
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  selectfile(data) {
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        this.morefiles = false;
        document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
          document.getElementById(data.Id.toString()).style.backgroundColor = "";
        }
        else {
          this.selectedSelected.push(data);
          document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
        }
      }
      if (this.selectedSelected.length == 0) {
        this.morefiles = false;
      }
    }
    if (this.selectedSelected.length <= 1) {
      this.morefiles = false;
    }
    else if (this.selectedSelected.length >= 2) {
      this.morefiles = true;  
    }
  }
  GetAllFolders() {
    this.spinner.show()
    this.userservice.getfolders(this.accountid).subscribe((respose: any) => {
      this.allFolders = respose;
      // console.log("user", this.allFolders)
    });
  }
  GetAllFolders1() {
    this.userservice.getfolders(this.accountid).subscribe((respose: any) => {
      this.allFolders1 = respose;
      // console.log("user", this.allFolders);
      this.spinner.hide();
    });
  }
  GetPFolderlist(id){
    // this.spinner.show()
    this.mailservice.getpfoldernames(id).subscribe((respose: any) => {
      // this.spinner.hide();
      this.foldernamelist = respose;  
      console.log(this.foldernamelist);
    });
  }
  
}