import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { style } from '@angular/animations';
import { UserService } from 'app/shared/UserService/user.service';
import { QuestionService } from '../services/question.service';
declare var Peer:any;
import * as $ from 'jquery';
@Component({
  selector: 'app-videoreceiver',
  templateUrl: './videoreceiver.component.html',
  styleUrls: ['./videoreceiver.component.scss']
})
export class VideoreceiverComponent implements OnInit {
  @ViewChild('myvideo') myvideo: any;
userid;
  peer:any;
  anotherid;
  mypeerid;
  refid;
  hostid;
  qid;
    constructor(private route:ActivatedRoute,private sevice:QuestionService,private router:Router) {
      this.userid=0;
      window.onbeforeunload = function(e) {
        return 'Dialog text here.';
      };
      this.route.params.subscribe((data:any)=>{
this.refid=data['id'];
this.hostid=this.refid.split(',')[0];
this.qid=this.refid.split(',')[1];
      });
      if(localStorage.userId!=this.hostid){
        this.router.navigate(['/newsignin']);
        return;
      }
     }

  ngOnInit(): void {
    
    let parent;
    setTimeout(() => {
       parent = document.getElementById("videos");

    }, 5000);
    this.peer = new Peer();
    this.peer.on('open', function(id) {
      this.peer.id=id
      console.log('My peer ID is: ' + id);
    });
    setTimeout(() => {
      this.mypeerid = this.peer.id;
      console.log("here");
      if(this.mypeerid==null||this.mypeerid==undefined){
        location.reload();
      }
      else{
        this.sevice.storepeerid(this.hostid,this.qid,this.mypeerid).subscribe((data:any)=>{
          console.log(data);
        });
      }
    },3000);
    
    this.peer.on('connection', function(conn) {
  conn.on('data', function(data){
    // Will print 'hi!'
    console.log(data);
  });
});

var n = <any>navigator;
    
    n.getUserMedia =  ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia );
    
    this.peer.on('call', function(call) {
      this.userid=this.userid+1;
          let video= document.createElement("video");
          video.setAttribute("style","width:200px;height:200px");
          parent.appendChild(video);

      n.webkitGetUserMedia({video: true, audio: true},function(stream) {
        call.answer(stream);
        call.on('stream', function(remotestream){
        
          console.log("adding stream");
          
          video.srcObject=remotestream;
          video.play();
          console.log(remotestream)
          //document.getElementById("videos").appendChild(document.createElement("video").srcObject=remotestream) ;
       
       
        })
      }, function(err) {
        console.log('Failed to get stream other', err);
      })
    })
  }
  addstream(){
  console.log("adding stream");
  document.getElementById("videos").appendChild(document.createElement("video").srcObject=localStorage.userid) ;
  

  }
  connect(){
    var conn = this.peer.connect(this.anotherid);
  conn.on('open', function(){
  conn.send('Message from that id');
  });
  }
  
  // videoconnect(){
  //   let video = this.myvideo.nativeElement;
  //   var localvar = this.peer;
  //   var fname = this.anotherid;
    
  //   // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    
  //   var n = <any>navigator;
    
  //    n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );
    
  //    n.getUserMedia({video: true, audio: true}, function(stream) {
  //     var call = localvar.call(fname, stream);
  //     call.on('stream', function(remotestream) {
  //       video.srcObject = remotestream;
  //       video.play();
  //     })
  //   }, function(err){
  //     console.log('Failed to get stream', err);
  //   })
    
    
  // }
}

