<br><br>

<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
            <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                <h2 id="heading">My Dream Job Details!</h2>
                <!-- <p>Fill all form field to go to next step</p> -->
                <form id="msform" #JobPost="ngForm" ngNativeValidate>
                    <!-- progressbar -->
                    <ul id="progressbar">
                        <li class="active" id="account"><strong>Job Details</strong></li>
                        <li id="personal"><strong>Photo</strong></li>
                        <li id="payment"><strong>Signature</strong></li>
                        <li id="confirm"><strong>Finish</strong></li>
                    </ul>
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div> <br>
                    <!-- fieldsets -->
                 
                    <fieldset>
                        <div class="form-card">
                            <div class="row">
                                <div class="col-7">
                                    <!-- <h2 class="fs-title">Job Profile Details:</h2> -->
                                </div>
                                <div class="col-5">
                                    <h2 class="steps">1/4</h2>
                                </div>
                            </div>
                            <!-- <label class="fieldlabels">Email</label> <input type="email" name="email" required/> <label class="fieldlabels">Username: </label> <input type="text" name="uname"  required/> <label class="fieldlabels">Password</label>                            <input type="password" name="pwd" required/> <label class="fieldlabels">Confirm Password</label> <input type="password" name="cpwd"  required/> -->
                             <!-- +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
                             <div class="col-md-12 col-lg-12 row">
                                <div class="col-md-6 col-lg-6">
                                    <div class="col-md-12 col-lg12 inputbox">
                                        <span id="no"></span> <input type="text" name="fullname" id="fullname" required placeholder="Enter Full Name Please Include Mr. or Mrs. Before the Name" [(ngModel)]="fullname" required>
                                        <!-- <label>Designation</label> -->
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox">
                                        <span id="no"></span> <input type="text" name="parentname" id="parentname" required placeholder="Father/ Mother Name" [(ngModel)]="parentname" required>
                                        <!-- <label>Designation</label> -->
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox">
                                        <span id="no"></span> <input type="text" name="mobilnumber" id="mobilnumber" required placeholder="Mobile Number" [(ngModel)]="mobilnumber" required>
                                        <!-- <label>Designation</label> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="col-md-12 col-lg12 inputbox">
                                        <span id="no"></span> <input type="text" name="MailID" id="mailid" required placeholder="Mail ID" [(ngModel)]="mailid" required>
                                        <!-- <label>Designation</label> -->
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox">
                                        <span id="no"></span> <input type="text" name="aadhaarnumber" id="aadhaarnumber" required placeholder="Aadhaar Number" [(ngModel)]="aadhaarnumber" required>
                                        <!-- <label>Designation</label> -->
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox">
                                        <span id="no"></span> <input type="text" name="fulladdress" id="fulladdress" required placeholder="Enter the Complete Address with Area Pin" [(ngModel)]="fulladdress" required>
                                        <!-- <label>Designation</label> -->
                                    </div>
                                </div>
                            </div>
                            <!-- +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->

                            <div class="col-md-12 col-lg-12 row">

                                <div class="col-md-6 col-lg-6">
                                    <div class="col-md-12 col-lg12 inputbox">
                                        <span id="no">1.&nbsp;</span> <input type="text" name="designation"
                                            id="designation" required placeholder="Designation"
                                            [(ngModel)]="designation" required>
                                        <!-- <label>Designation</label> -->
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox" style="display: inline;">
                              
                                        <span id="no">2.&nbsp;</span>
                                        <select class='select-option' id="role"
                                            style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-bottom: 5px;text-decoration: none;"
                                            (change)='onchangerole($event)' required>
                                            <option value="" selected disabled hidden style="color: #312f5356;">Role
                                            </option>
                                            <option class='option' *ngFor='let role of roles' [value]="role.id">
                                                {{role.role}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox" *ngIf="othersrole" >
                                        <span id="no">2.&nbsp;</span> <input type="text" name="otherrole"
                                            id="otherrole" required placeholder="Enter Role"
                                            [(ngModel)]="otherrole" required>
                                        <!-- <label>Designation</label> -->
                                    </div>
                              
                                    <br /><br />
                                    <div class="col-md-12 col-lg12 inputbox" style="display: flex;">
                                        <span id="no">3.&nbsp;</span>
                                        <span style="width: 100%">
                                            <ngx-select-dropdown id="industry" [config]="config" [options]="industries"
                                                [multiple]="false" (change)="onchangeindustry($event)">
                                            </ngx-select-dropdown>
                                        </span>
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox" *ngIf="otherindustry" >
                                        <span id="no">3.&nbsp;</span> <input type="text" name="otherindustries"
                                            id="otherindustries" required placeholder="Enter Industry"
                                            [(ngModel)]="otherindustries" required>
                                
                                    </div>
                                    <!-- <div class="col-md-12 col-lg12 inputbox" style="display: inline;">
                                        
                                        <br><br>
                                        <span id="no">3.&nbsp;</span>
                                        <select class='select-option' id="industry" style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-bottom: 5px;text-decoration: none;" (change)='onchangeindustry($event)' required>
                                            <option value="" selected disabled hidden style="color: #312f5356;">Industry</option>
                                        <option class='option'  *ngFor='let role of industries' [value]="role.id">{{role.indrustType}}</option>
                                        </select>

                                    </div> -->
                                    <div class="col-md-12 col-lg12">
                                        <br><br>
                                        <div style="width: 30%;float: left;padding: 20px 0;"><span id="no">4.&nbsp;
                                            </span> Location: </div>
                                        <div style="width: 70%;float: right;" id="locinput">
                                            <label for="loc1">(1)</label> <input type="text" id="loc1" name="loc1"
                                                required [(ngModel)]="loc1" required><label for="loc2">(2)</label>
                                            <input type="text" name="loc2" [(ngModel)]="loc2"> <label
                                                for="loc3">(3)</label> <input type="text" [(ngModel)]="loc3" name="loc3"
                                                required>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <br>
                                    <div class="col-md-12 col-lg-12">
                                        <div class="inputbox">
                                            <span id="no">5.&nbsp;</span>
                                            <select class='select-option' id="salary"
                                                style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-bottom: 5px;text-decoration: none;"
                                                (change)='onchangesalary($event)' required>
                                                <option value="" selected disabled hidden style="color: #312f5356;">
                                                    Salary Range Per Annum</option>
                                                <option class='option' *ngFor='let role of salaries' [value]="role.id">
                                                    {{role.salary}}</option>
                                            </select>


                                        </div>
                                    </div>

                                    <div class="col-md-12 col-lg-12">
                                        <br>
                                        <div class="inputbox">
                                            <span id="no">6.&nbsp;</span>
                                            <select class='select-option' id="hq"
                                                style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-bottom: 5px;text-decoration: none;"
                                                (change)='onchangequalify($event.target.value)' required>
                                                <option value="" selected disabled hidden style="color: #312f5356;">
                                                    Highest Qualification</option>
                                                <option class='option' *ngFor='let role of HQlist' [value]="role.id">
                                                    {{role.highestQualification}}</option>
                                            </select>


                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox" *ngIf="Otherqualification" >
                                        <span id="no">6.&nbsp;</span> <input type="text" name="otherqualification"
                                            id="otherqualification" required placeholder="Enter Highest Qualification"
                                            [(ngModel)]="otherqualification" required>
                                
                                    </div>

                                    <div class="col-md-12 col-lg-12 inputbox" style="display: inline;">
                                        <br>

                                        <span id="no">7.&nbsp;</span>
                                        <select class='select-option' id="perks"
                                            style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-top: 10px;text-decoration: none;"
                                            (change)='onchangeperk($event)' required>
                                            <option value="" selected disabled hidden style="color: #312f5356;">Levels
                                            </option>
                                            <option class='option' *ngFor='let role of perks' [value]="role.id">
                                                {{role.perks}}</option>
                                        </select>

                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox" style="display: inline;">
                                        <!-- <label for="role">Role: &nbsp;</label> -->
                                        <br><br>
                                        <span id="no">8.&nbsp;</span>
                                        <select class='select-option' id="growth"
                                            style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-top: 5px;text-decoration: none;"
                                            (change)='onchangegrowth($event)' required>
                                            <option value="" selected disabled hidden style="color: #312f5356;">Growth
                                            </option>
                                            <option class='option' *ngFor='let role of growths' [value]="role.id">
                                                {{role.growth1}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 col-lg12 inputbox" style="display: inline;">
                                        <!-- <label for="role">Role: &nbsp;</label> -->
                                        <br><br>
                                        <span id="no">9.&nbsp;</span>
                                        <select class='select-option' id="gender"
                                            style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-top: 5px;text-decoration: none;"
                                            (change)='onchangegender($event)' required>
                                            <option value="" selected disabled hidden style="color: #312f5356;">Gender
                                            </option>
                                            <option class='option' *ngFor='let role of genders' [value]="role.id">
                                                {{role.gender1}}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div> <input type="button" (click)="onSubmit()" name="next" class="next action-button"
                            value="Next" />
                    </fieldset>
                
                    <fieldset>
                        <div class="form-card">
                            <div class="row">
                                <div class="col-8">
                                    <h2 class="fs-title"></h2>
                                    <p style="color: grey;font-size: small;font-weight: 100;">* Please upload and also
                                        take a snapshot to complete this step. And make sure your face is clearly
                                        visible.</p>
                                </div>

                                <div class="col-4">
                                    <h2 class="steps">2/4</h2>
                                </div>
                            </div>
                            <br> <br>
                            <div class="container fileUploadWrapper">


                                <!-- <div class="col-md-12">
                                    <div class="fileupload" appDragDropFileUpload (click)="fileField.click()"
                                        (fileDropped)="onDrop($event)">
                                        <span class="ddinfo" style="display: flex;justify-content: center;">Click on the
                                            cloud to upload</span>

                                    </div>
                                    <span *ngIf="uimg">{{uimg.name}}</span>
                                    <input type="file" style="display: none;" name="avatars" #fileField
                                        (change)="handleFileInput1($event.target.files)">
                                </div> -->


                                <ul style="list-style:none;">
                                    <br />
                                    <span class="ddinfo" style="display: flex;justify-content: center;">
                                        Live Camera</span>
                                    <div style="text-align:center">
                                        <div>
                                            <p>{{seconds}}</p>
                                            <webcam [trigger]="triggerObservable"
                                                (imageCapture)="handleImage($event)"></webcam>
                                            <br />
                                            <button id="snapshotBtn" (click)="triggerSnapshot();">Take A
                                                Snapshot</button>
                                        </div>
                                    </div>
                                    <div class="snapshot" *ngIf="webcamImage">
                                        <h2>Nice one!</h2>
                                        <img [src]="webcamImage.imageAsDataUrl" />
                                    </div>


                                </ul>
                            </div>
                            <hr>
                            <br><br>
                            <div class="col-md-12 col-lg-12 ">
                           
                            </div>
                            <div class="col-md-12 col-lg-12 "
                                style="padding: 10% 10%;display:flex;justify-content:center;">
                               
                                <label for="file">Upload Pancard Image</label>
                                <br><br>
                                <input type="file" id="file" (change)="handleFileInput3($event)">


                            </div>
                        </div>
                        <!-- <input (click)="verifyimage()" type="button" name="next" class="next action-button"
                            value="Next" /> <input type="button" name="previous" class="previous action-button-previous"
                            value="Previous" /> -->
                            <input (click)="verifyimage()" id="verifynext" type="button" name="next" class="action-button" value="Next" />

                            <input type="button" name="next"
                            class="next" style=" background-color: green;    
                            width: 100px;                       
                            font-weight: bold;
                            color: white;
                            border: 0 none;
                            border-radius: 0px;
                            cursor: pointer;
                            padding: 10px 5px;
                            margin: 10px 5px 10px 0px;
                            float: right;" value="Skip" />
                            <input type="button" id="nextbtn" name="next"
                            class="next" style="display: none;" />
                            <input type="button" name="previous" class="previous action-button-previous" value="Previous" />

                    </fieldset>
                    <fieldset>
                        <div class="form-card" id="signf">
                            <div class="row" id="sign">
                                <div class="col-7">
                                    <p style="color: grey;font-size: small;font-weight: 100;">* Please sign and also
                                        upload your signature signed on white paper to complete this step.</p>

                                </div>
                                <div class="col-5">
                                    <h2 class="steps">3/4</h2>
                                </div>
                            </div>
                            <br> <br>
                            <div class="container fileUploadWrapper">


                                <div class="col-md-12">
                                    <div class="fileupload" appDragDropFileUpload (click)="fileField1.click()"
                                        (fileDropped)="onDrop($event)">
                                        <span class="ddinfo" style="display: flex;justify-content: center;">Click on the
                                            cloud to upload your signature</span>

                                    </div>
                                    <span *ngIf="sgimg">{{sgimg.name}}</span>
                                    <input type="file" style="display: none;" name="avatars" #fileField1
                                        (change)="handleFileInput2($event.target.files)">
                                </div>


                            </div>
                            <br>
                            <hr>
                            <br>
                            <!-- <label for="file">Upload Pancard Image</label><br><br>
                            <input type="file" id="file" (change)="handleFileInput4($event.target.files)"> -->
                           <br><br><br>
                            <p style="color: red;">Note : Scan Your Pan card using <b>Adobe Scan</b></p>
                   

                        </div> 
                        <input  (click)="verifysignature()" id="verifynext" type="button" name="next" class="next action-button" value="Next" />

                        <!-- <input type="button" (click)="verifysignature()" name="next"
                            class="action-button" value="Submit" /> -->
                          
                            <input type="button" id="nextbtn1" name="next"
                            class="next" style="display: none;" />
                            <input type="button" name="previous"
                            class="previous action-button-previous" value="Previous" />

                            <input type="button" name="next"
                            class="next" style=" background-color: green;    
                            width: 100px;                       
                            font-weight: bold;
                            color: white;
                            border: 0 none;
                            border-radius: 0px;
                            cursor: pointer;
                            padding: 10px 5px;
                            margin: 10px 5px 10px 0px;
                            float: right;" value="Skip" />
                    </fieldset>
                 
                    <fieldset>
                        <div class="form-card">
                            <div class="row">
                                <div class="col-7">
                                    <h2 class="fs-title"></h2>
                                </div>
                                <div class="col-5">
                                    <h2 class="steps">4/4</h2>
                                </div>
                            </div> <br><br>
                            <h2 class="purple-text text-center"><strong>SUCCESS !</strong></h2> <br>
                            <div class="row justify-content-center">
                                <div class="col-3"> <img src="https://i.imgur.com/GwStPmg.png" class="fit-image"> </div>
                            </div> <br><br>
                            <div class="row justify-content-center">
                                <div class="col-7 text-center">
                                    <h5 class="purple-text text-center">You Have Successfully Completed Your Profile.
                                    </h5>
                                </div>
                            </div>
                            <div class="col-md-12 text-center">
                                <input type="button" name="next" (click)="reload()" [routerLink]="['/appprofile']"
                                    style=" cursor: pointer;
                                padding: 10px 5px; font-weight: bold;
                                margin: 10px 0px 10px 5px;width:  max-content;color: #fff;border: none;background-color: transparent;text-decoration: none;outline: none;background-color: #1e5ee5;"
                                    value="View Matching Jobs" />
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>

<button style="display:none;" id="openpopup" data-toggle="modal" data-target="#ImageCropPopUp"></button>


<div class="modal fade bd-example-modal-lg" id="ImageCropPopUp"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="margin: auto;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Crop your Signature</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div style="height: 500px;">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="4 / 4" format="png" (imageCropped)="imageCropped($event)"></image-cropper>
                </div>
                <br>
                <div style="display: flex;justify-content: center;">
                <img [src]="croppedImage">
            </div>
            <br>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btnprop waves-light next" data-dismiss="modal" mdbWavesEffectstyle="background: blue;">Done</button>

            </div>
        </div>
    </div>
</div>