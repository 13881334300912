import { Directive, HostListener, HostBinding, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDragDropFileUpload]'
})
export class DragDropFileUploadDirective {
  @Output() fileDropped = new EventEmitter();
  @HostBinding('style.background-color') private background = '#ffffff';
  constructor() { }
  @HostListener('dragover', ['$event']) dragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#e2eefd';
  }

  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#ffffff'
  }

  // Drop Event
  @HostListener('drop', ['$event']) public drop(event) {
   
    event.preventDefault();
    event.stopPropagation();
    this.background = '#ffffff';
    const files = event.dataTransfer.files;
   
    if (files.length > 0) {
      this.fileDropped.emit(files)
    }
  }
}
