import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/UserService/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfo } from 'app/shared/VIewmodels/UserInfo';
import { FolderviewService } from 'app/shared/FlderViewService/folderview.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private usersService:UserService,private folderviewservice:FolderviewService, private router:Router,private spinner:NgxSpinnerService) {
    this.spinner.show();
    window.scrollTo(0,0);

    if(localStorage.admin != "admin"){
      this.router.navigate(['/newlogin']);
    }
    if (localStorage.loadingpage == "true") {
      localStorage.loadingpage = "false";
      location.reload();
    }
   }

  ngOnInit() {

    // var nav = document.getElementById("nav");
    // if(nav){
    //   nav.style.display = "none";
    // }

  }
  uid:any;
  
  sum=0;
 
  p:any=[];
}
