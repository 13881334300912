<div class="col-md-12 row">
    
    <div class="col-md-2">
        <br><br>
        <br><br>
        <br><br>
        <br><br>
        <br>
    
        <div>
            <button class="btn btn-primary btn-lg" id='videosrecord' (click)="startRecording1()">Start Recording</button>
        </div>
        <br>
        <div>
            <button class="btn btn-primary btn-lg" id='videosrecord' (click)="stopRecording1()">Stop Recording</button>
        </div>
        <br>
        <div>
            <button class="btn btn-primary btn-lg" id='videosrecord' (click)="Recordings()">Watch Recordings</button>
        </div>
    
    </div>
    <div class="row1">
        <div class="col-xs-12 text-right">
            <div style="text-align:center">
                <video class="video" #video autoplay controls muted></video>
                
                <span class="m-1" controls #recordedVideo></span>
                <br>
                <!-- <button class="btn btn-primary btn-lg" id='videosrecord' (click)="startRecording1()">Start
                    Recording</button>
                <button class="btn btn-primary btn-lg" id='videosrecord' (click)="stopRecording1()">Stop Recording</button>
                <button class="btn btn-primary btn-lg" id='videosrecord' (click)="Recordings()">Stop Recording</button> -->
    
            </div>
        </div>
    </div>