<br><br><br><br><br>
<div class="col-md-12 text-right">
    <video #onlymyvideo style="width: 200px;height: 200px;" muted playsinline></video>

</div>
<br>
<div class="col-md-12 text-center row">
    <div class="col-md-4 text-center"></div>
    <div class="col-md-8 text-center">
        <div style="width: 400px; height: 300px;" id="remote-video"></div>
    </div>
</div>

<div class="wrapper" id="videos">

</div>
<br><br><br><br><br><br><br><br><br><br>