<div class="col-md-12">

    &nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>


<h2 style="text-align: center">Options List</h2>
<div class="row col-sm-12">
    <div class="col-sm-10"></div>
    <div class="col-sm-2">
        <button style="background-color: green" class="btn btn-success" data-toggle="modal" data-target="#basicExample" mdbWavesEffect (click)="addClick()">
      Add
    </button>
    </div>
</div>
<br />
<div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div class="table-responsive">
            <div style="overflow-x:visible;height: 300px;overflow-y: visible">
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th>Course</th>

                        <th>Chapter</th>
                        <th>Question </th>
                        <th>Option </th>
                        <th>IsAnswer </th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    <tr *ngFor="let aa of Options;let i = index" style="font-size:16px;font-family: sans-serif">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>
                        <td>{{aa.CourseName}}</td>
                        <td>{{aa.ChapterName}}</td>
                        <td>{{aa.Question}}</td>
                        <td>{{aa.option}}</td>
                        <td>{{aa.IsAnswer}}</td>
                        <td>
                            <button style="background-color:blue " class="btn btn-success" data-toggle="modal" data-target="#basicExample" mdbWavesEffect (click)="editChapter(aa)">
                Edit
              </button>

                        </td>
                        <td>
                            <button class="btn btn-danger" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect (click)="deleteChapter(aa)">Delete</button>

                        </td>
                    </tr>
                </table>
            </div>
            <br />
            <!-- <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls><br /><br /> -->
        </div>
    </div>
</div>
<br /><br />



<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <i class="material-icons" style="color: white">close</i>
      </button>
            <span style="color: white;margin-top: 6px;">
        <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>