import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { UserService } from 'app/shared/UserService/user.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ChatDetail } from 'app/shared/VIewmodels/ChatDetail';

@Component({
  selector: 'app-profileviewusers',
  templateUrl: './profileviewusers.component.html',
  styleUrls: ['./profileviewusers.component.scss']
})
export class ProfileviewusersComponent implements OnInit {
  userDetails: any;
  username: any;
  email: any;
  role: any;
  salaryrange: any;
  perks: any;
  industry: any;
  growth: any;
  loc1: any;
  loc2: any;
  loc3: any;
  b: any[] = [1, 2, 8, 5];

  className: string;

  Users: any = [];
  userid: any;
  isLoggedIn: boolean;
  fullName: string;
  touserid: any;
  chatopen: boolean;
  IndividualChatDetail: any;
  uid: boolean;
  chattoid: any;
  chatvar: any;
  chattouserid: any;
  chaatto: any;
  IndividualChatRecivedDetails: any;
  IndividualChatSentDetails: any;
  UserImage: any;
  BMid: string;
  touser: any;
  currentuser: string;
  data: any;
  chtnm: any;
  cnnTableData: any = [];
  closeResult: string;
  accid: any;
  m: any;
  Friends: any;
  accountid: string;
id:any;
  ResumeFile: any;
  pdfUrl: any;
  constructor(
    private userService: RegistrationService,
    private userser: UserService,
   private router:ActivatedRoute,
   private modalService: NgbModal,
   
  ) {
    this.userid = localStorage.getItem("userId");
this.router.params.subscribe((data:any)=>{
  if(data){
    this.id = data['id'];
  }
})
   window.scrollTo(0,0);
  }
  image: any;
  ngOnInit(): void {
    this.username = localStorage.profileUserName;
    this.email = localStorage.profileEmail;
    this.userService.getImage(this.id).subscribe((data: any) => {
      this.image = data;
      this.image = "data:image/png;base64," + this.image;
      console.log("userimg ",this.image);
    });
    this.userService.getprofileforcompany(this.id).subscribe((data: any) => {
      this.userDetails = data;
      this.userService.getrole(this.userDetails?.role).subscribe((data: any) => {
        this.role = data;
      });
      this.userService
        .getsalary(this.userDetails?.salarymin)
        .subscribe((data: any) => {
          this.salaryrange = data;
        });
      this.userService
        .getperks(this.userDetails?.perks)
        .subscribe((data: any) => {
          this.perks = data;
        });
      this.userService
        .getindustry(this.userDetails?.industry)
        .subscribe((data: any) => {
          this.industry = data;
        });
      this.userService
        .getgrowth(this.userDetails?.growth)
        .subscribe((data: any) => {
          this.growth = data;
        });
      this.userService
        .geteducation(this.userDetails?.salarymax)
        .subscribe((data: any) => {
          this.education = data;
        });
      console.log(this.userDetails);
    });
    this.getExp();
    this.getbio();
    this.getUserImage();
    this.getEdu();
    this.getSkills();

    setTimeout(() => {
      var footer = document.getElementById("footer");
      if (footer) {
        footer.style.display = "block";
      }
    }, 1000);
  }
  getbio() {
    this.userser.getbio(this.id).subscribe((respose: any) => {
      this.bio = respose;
      console.log(respose);
    });
  }
  education: any;
  getmarks() {
    this.userser.getMarks(this.id).subscribe((respose: any) => {
      this.marks = respose;
      console.log(respose);
    });
  }

  imgp = false;
  usrimage: any;
 
  getUserImage() {
    this.userser.getUserImage(this.id).subscribe((respose: any) => {
      if (respose != "0") {
        this.imgp = true;
        this.usrimage = respose;
      } else {
        this.imgp = false;
      }
    });
  }

  edrad = false;
  Cname: any;
  Course: any;
  date: any;

  Edudet: any;

  getEdu() {
    this.userser.getEduDet(this.id).subscribe((respose: any) => {
      this.Edudet = respose;
      console.log(respose);
    });
  }
  Skilldet: any;
  getSkills() {
    this.userser.getSkillDet(this.id).subscribe((respose: any) => {
      this.Skilldet = respose;
      console.log(respose);
    });
  }
  requestingfor: any;
  edid: any;
 





  Skill: any;

  skid = null;






  fileToUploaddoc: FileList;
  Certi: any;
  marks: any;

  ubio: any;
  bio: any;
  base64: string;
 
  // ---------------------------------------------------
 

  messa: any;
  SaveUserChat() {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: this.currentuser,
      ChatTo: null,
      ChatToName: null,
      ChatMessage: this.messa,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.touser,
    };
    this.userser.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Message Sent Successfully");
      }
    });
  }



  addFriend( addfriendcontent) {
    this.touser = this.id;
    // this.OnSelectUser(id);
    this.modalService.open(addfriendcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.accid;
      }
    );
  }
 
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

 
 


  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

 

  Edudet1: any;

  edrad1: boolean;
  Cname1: string;
  Course1: string;

  edid1: any;




  getExp() {
    this.userser.getExpDet(this.id).subscribe((respose: any) => {
      this.Edudet1 = respose;
      console.log(respose);
    });
  }


  GetResume() {
    this.userser.getresumefile(this.id).subscribe((respose: any) => {
      if(respose != "0")
      {
        this.ResumeFile = respose;
        this.pdfUrl = this.ResumeFile.file;
      }
     else
     {
      this.pdfUrl = null;
     }
   
    });
  }


}