<!-- <br /><br />

<div class="whole-page">
    <br /><br /><br /><br /><br />
    <div class="col-md-12 text-center" style="font-weight: 700;font-size: 22px;color: whitesmoke;" *ngIf="joblist == undefined || joblist == null|| joblist == ''">No Jobs have been posted yet please post a job !</div>
    <div class="col-md-12 text-center" *ngIf="joblist.length > 0" style="font-weight: 700;font-size: 22px;color: whitesmoke;">Jobs posted by you</div>
    <br>
    <div *ngFor="let job of joblist">
        <button class="collapsible">{{job.jobRole}}</button>
        <div class="whole-content">
            <div class="content">
                <label>KeySkils :</label>
                <span style="font-size: 15px;"><b>&nbsp;{{ job.keySkils }}</b></span
        ><br />
        <label>JobDescription :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.jobDescription }}</b></span
        ><br />
        <label>JobposterName :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.jobposterName }}</b></span
        ><br />
        <label>PayScale :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.payScale }}</b></span
        ><br />
        <label>MinimumExp :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.minimumExp }}</b></span
        ><br />
      </div>
      <div class="row col-md-12"></div>
      <div class="row col-md-12">
        <a
          (click)="getlistofseekers(job)"
          style="cursor: pointer;"
          class="btn btn-primary btn-rounded btn-xs btn-header"
          >Get Match List</a
        >
      </div>
      <br /><br />
    </div>
  </div>
</div> -->

<!-- <div class="whole-page">
    <br /><br /><br /><br />
    <div class="col-md-12 text-center" style="font-weight: 700; font-size: 22px; color: whitesmoke;" *ngIf="joblist == undefined || joblist == null || joblist == ''">
        No Jobs have been posted yet please post a job !
    </div>
    <div class="col-md-12 text-center" *ngIf="joblist.length > 0" style="font-weight: 700; font-size: 22px; color: whitesmoke;">
        Jobs posted by you
    </div>
    <main>
        <section class="container register-form">
            <div style="display: flex;" class="col-md-12 row">
                <div class="complete-form col-md-6" *ngFor="let job of joblist">
                    <div class="form">
                        <div class="row text-center justify-content-center">
                            <div class="col-lg-12">
                                <div class="col-lg-12">
                                    <br />
                                    <div class="note">
                                        <p style="font-size: x-large;">{{ job.jobRole }} <i style="cursor: pointer;" class="fa fa-pencil" [routerLink]="['/jobposting',job.blockId]"></i> &nbsp; <i style="cursor: pointer;" class="fa fa-trash" (click)="delete(job.blockId)"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-content">
                            <div class="container">
                                <div class="row justify-content-center mt--200"></div>
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="fname" style="font-size: 15px; color: #8a061c;">Job Description :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="
                          font-size: 15px;
                          color: #8a061c;
                          word-break: break-all;
                        "
                        >{{ job.jobDescription }}</label
                      >
                    </div>
                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Key Skills :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.keySkils }}</label
                      >
                    </div>

                   
                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Payscale :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.payScale }}</label
                      >
                    </div>
                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Experience :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.minimumExp }} - {{ job.maximumExp }} years</label
                      >
                    </div>
                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Qualification :</label
                      >
                    </div>
                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.highestQualification }}
                      </label>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="fname" style="font-size: 15px; color: #8a061c;">Location :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.jobLocation }}</label
                      >
                    </div>

                    <div class="col-md-12">&nbsp;</div>

                    <div
                      class="col-md-10"
                      style="text-align: center; padding-left: 10%;"
                    >
                      <button
                        type="submit"
                        class="btnSubmit"
                        [routerLink]="['/seekerlist', job.blockId]"
                      >
                        Get Match List
                      </button> &nbsp;  
                       <button
                      type="submit"
                      class="btnSubmit"
                      [routerLink]="['/addquestions', job.blockId]"
                    >
                      Host Contest
                    </button>
                    &nbsp; 
                    <button
                    type="submit"
                    class="btnSubmit"
                    [routerLink]="['/report', job.blockId]"
                  >
                    Fetch Results
                  </button>
                    </div>
                    <div class="col-md-1">&nbsp;</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
 -->

<br /><br /><br />
<h1 class="col-md-12 text-center" id="title">Job-Zone</h1>
<div class="col-md-12">
  <div class="wrapper">
    <div class="cards_wrap">
      <div class="card_item">
        <div class="card_inner">
          <!-- <div class="card_top">
                       <img src="data:{{contest.FileType}};base64,{{contest.FilePoster}}" alt="{{contest.FileName}}" /> -->
          <!-- </div>  -->
          <div class="card_bottom">
            <div class="card_category">
              <strong> Companies</strong>
            </div>
            <div class="card_info">
              <!-- <p class="title">{{contest.jobRole}}</p>
                        <p class="card_info1" style="color: grey;"><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; {{contest.jobLocation}}</p>
                        <p class="card_info1" style="font-size: 14px;">{{contest.jobDescription}}</p>
                        <p class="card_info1" style="font-size: 14px;">
                            {{contest.minimumExp}} - {{contest.maximumExp}} yr/s <br>
                        </p> -->
              <p class="title">
                Total Companies: &nbsp; <strong>{{ compcount }}</strong>
              </p>
              <br />
              <p class="card_info1">
                <button [routerLink]="['/allcompanylist']">
                  View All Companies
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card_item">
        <div class="card_inner">
          <div class="card_bottom">
            <div class="card_category">
              <strong> Applicants</strong>
            </div>
            <div class="card_info">
              <p class="title">
                Total Applicants: &nbsp; <strong>{{ appcount }}</strong>
              </p>
              <br />
              <p class="card_info1">
                <button [routerLink]="['/allstudentlist']">
                  View All Applicants
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="basicExample"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="margin-top: 20%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div></div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          style="cursor: pointer"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
