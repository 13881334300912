<div class="col-md-12">

    &nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>


<h2 style="text-align: center">Question List</h2>
<div class="row col-sm-12">
    <div class="col-sm-10"></div>
    <div class="col-sm-2">
        <button style="background-color: green" class="btn btn-success" data-toggle="modal" data-target="#basicExample" mdbWavesEffect (click)="addClick()">
      Add
    </button>
    </div>
</div>




<br />
<div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div class="table-responsive">
            <div style="overflow-x:visible;height: 300px;overflow-y: visible">
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>

                        <th>Question </th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Options</th>
                    </tr>
                    <tr *ngFor="let aa of Questions;let i = index" style="font-size:16px;font-family: sans-serif">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>

                        <td>{{aa.question}}</td>

                        <td>
                            <button style="background-color:blue " class="btn btn-success" data-toggle="modal" data-target="#basicExample" mdbWavesEffect (click)="editChapter(aa)">
                Edit
              </button>

                        </td>
                        <td>
                            <button class="btn btn-danger" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect (click)="deleteChapter(aa)">Delete</button>

                        </td>
                        <td>
                            <button class="btn btn-danger" data-toggle="modal" data-target="#options" mdbWavesEffect (click)="options(aa)">Add/Edit options</button>

                        </td>
                    </tr>
                </table>

            </div>
            <br />
            <!-- <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls><br /><br /> -->
        </div>
    </div>
</div>
<br><br>

<h2 style="text-align: center">Coding Round Question List</h2>
<div class="row col-sm-12">
    <div class="col-sm-10"></div>
    <div class="col-sm-2">
        <button style="background-color: green" *ngIf="!removeAdd" class="btn btn-success" data-toggle="modal" data-target="#basicExample1" mdbWavesEffect (click)="addClick1()">
      Add
    </button>
    </div>
</div>




<br />
<div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div class="table-responsive">
            <div style="overflow-x:visible;height: 300px;overflow-y: visible">
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>

                        <th>Question </th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Output</th>
                    </tr>
                    <tr *ngFor="let aa of Questions1;let i = index" style="font-size:16px;font-family: sans-serif">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>

                        <td>{{aa.question}}</td>

                        <td>
                            <button style="background-color:blue " class="btn btn-success" data-toggle="modal" data-target="#basicExample1" mdbWavesEffect (click)="editChapter1(aa)">
                Edit
              </button>

                        </td>
                        <td>
                            <button class="btn btn-danger" data-toggle="modal" data-target="#deleteExample1" mdbWavesEffect (click)="deleteChapter1(aa)">Delete</button>

                        </td>
                        <td>
                            <button class="btn btn-danger" data-toggle="modal" data-target="#options1" mdbWavesEffect (click)="options1(aa)">Add/Edit Output</button>

                        </td>
                    </tr>
                </table>

            </div>
            <br />
            <!-- <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls><br /><br /> -->
        </div>
    </div>
</div>
<br /><br />
<h2 style="text-align: center"> <button class="btn btn-primary" data-toggle="modal" data-target="#link1" mdbWavesEffect (click)="generatetestlink()">Get Test Link</button> </h2>
<h2 style="text-align: center"> <button class="btn btn-primary" data-toggle="modal" data-target="#link11" mdbWavesEffect (click)="generatestreamlink()">Get Stream Link</button> </h2>

<div class="modal modal-angular fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Add Question</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body text-center">
                <form #ChapterForm="ngForm" (ngSubmit)="SaveChapter(ChapterForm)">
                    <div class="col-md-12 row">
                        <div class="form-horizontal container">


                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Question :</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="Question" name="Question" placeholder="Enter Question" [(ngModel)]="Question" />
                                </div>
                            </div><br />
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal" mdbWavesEffect>Close</button>
                <button *ngIf="showSave" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect (click)="SaveChapter(ChapterForm)">Save</button>
                <button *ngIf="showUpdate" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" (click)="UpdateChapter(ChapterForm)">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="modal modal-angular fade" id="deleteExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Delete Question</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
            </div>
            <div class="modal-body text-center">
                <h3 style="text-align: center">Are you sure to delete..??</h3>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" data-dismiss="modal" mdbWavesEffect>Close</button>
                <button type="submit" data-dismiss="modal" style="background-color: red" class="btn btn-danger" (click)="deleteChapterRow()" mdbWavesEffect>Delete</button>
            </div>
        </div>
    </div>
</div>

<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;z-index: 10000;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <i class="material-icons" style="color: white">close</i>
      </button>
            <span style="color: white;margin-top: 6px;">
        <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>


<div class="modal modal-angular fade" id="options" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Options</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body text-center">
                <form #ChapterForm1="ngForm" (ngSubmit)="saveoptions(ChapterForm1)">
                    <div class="col-md-12 row">
                        <div class="form-horizontal container">


                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Option 1 :</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="option1" name="option1" [(ngModel)]="option1" />

                                </div>

                            </div><br />
                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Option 2 :</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="option2" name="option2" [(ngModel)]="option2" />
                                </div>
                            </div><br />
                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Option 3 :</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="option3" name="option3" [(ngModel)]="option3" />
                                </div>
                            </div><br />
                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Option 4 :</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="option4" name="option4" [(ngModel)]="option4" />
                                </div>
                            </div><br />

                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Select Answer:</label>
                                <div class="col-md-8">
                                    <div *ngFor="let name of checkboxData">
                                        <input type="checkbox" [checked]='(name == isCheckedName)' [name]="name" (change)="onChange($event)" [disabled]="isChecked && (isCheckedName!=name)"> {{'Option ' + name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal" mdbWavesEffect>Close</button>
                <button data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect (click)="saveoptions(ChapterForm1)">Save</button>
            </div>
        </div>
    </div>
</div>


<div class="modal modal-angular fade" id="basicExample1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Add Question</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body text-center">
                <form #ChapterForm11="ngForm" (ngSubmit)="SaveChapter1(ChapterForm11)">
                    <div class="col-md-12 row">
                        <div class="form-horizontal container">


                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Question :</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="Question1" name="Question1" placeholder="Enter Question" [(ngModel)]="Question1" />
                                </div>
                            </div><br />
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal" mdbWavesEffect>Close</button>
                <button *ngIf="showSave" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect (click)="SaveChapter1(ChapterForm11)">Save</button>
                <button *ngIf="showUpdate" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" (click)="UpdateChapter1(ChapterForm11)">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="modal modal-angular fade" id="deleteExample1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Delete Question</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
            </div>
            <div class="modal-body text-center">
                <h3 style="text-align: center">Are you sure to delete..??</h3>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" data-dismiss="modal" mdbWavesEffect>Close</button>
                <button type="submit" data-dismiss="modal" style="background-color: red" class="btn btn-danger" (click)="deleteChapterRow1()" mdbWavesEffect>Delete</button>
            </div>
        </div>
    </div>
</div>




<div class="modal modal-angular fade" id="options1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Options</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body text-center">
                <form #ChapterForm111="ngForm" (ngSubmit)="saveoptions11(ChapterForm111)">
                    <div class="col-md-12 row">
                        <div class="form-horizontal container">
                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Input :</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" name="input" id="input" cols="30" rows="10" [(ngModel)]="input">
                                </div>

                            </div><br />
                            <div class="col-md-12 row">
                                <label class="col-md-4" style="color:black;padding-top: 13px">Output :</label>
                                <div class="col-md-8">
                                    <textarea name="output" id="output" cols="30" rows="10" [(ngModel)]="output"></textarea>
                                </div>

                            </div><br />
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal" mdbWavesEffect>Close</button>
                <button data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect (click)="saveoptions11(ChapterForm1)">Save</button>
            </div>
        </div>
    </div>
</div>



<div class="modal modal-angular fade" id="link1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Share Link</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
            </div>
            <div class="modal-body text-center">
                <h6 style="text-align: center">Copy the below provided link to share it with the candidates</h6>

                <span>{{link}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" data-dismiss="modal" mdbWavesEffect>Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal modal-angular fade" id="link1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Delete Question</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
            </div>
            <div class="modal-body text-center">
                <h6 style="text-align: center">Copy the below provided link to stream but be online before the test starts.</h6>

                <span>{{link}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" data-dismiss="modal" mdbWavesEffect>Close</button>
            </div>
        </div>
    </div>
</div>