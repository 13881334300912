<div class="col-md-12">

    &nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>


<h2 style="text-align: center">Video Recordings</h2>

<br />
<div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div class="table-responsive">
            <div style="overflow-x:visible;height: 300px;overflow-y: visible">
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th>Recorded Date</th>
                        <th>Video</th>
                        <th>Delete</th>
                    </tr>
                    <tr *ngFor="let aa of alldatas ;let i = index" style="font-size:16px;font-family: sans-serif">
                        <td> {{ (i+1) }}</td>
                        <td> {{ aa.createdOn }}</td>
                        <td>
                             <button type="button" class="btn btn-primary" (click)="viewvideo(aa.fileBlobLink,videocontent)">View</button>
                        </td>
                        <td>
                            <button class="btn btn-danger signin-submit _demo" (click)="deletevideo(aa)" type="submit" >
                                <div class="">Delete</div>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <br />
            <!-- <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls><br /><br /> -->
        </div>
    </div>
</div>
<br /><br />



<!-- notification alert -->
<!-- <div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <i class="material-icons" style="color: white">close</i>
      </button>
            <span style="color: white;margin-top: 6px;">
        <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div> -->




<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white;">
        <div>&nbsp;</div>


        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <!-- <video autoplay #video controls id="video" onerror="error(event)" class="video">
            <source id="video"  type='video/x-matroska; codecs="avc1, opus"'>

        </video> -->
        <video width="560" height="420" autoplay controls unmuted >
            <source id="source" src="{{openvideo}}" type="video/webm">
            </video>
    </div>
</ng-template>

<ng-template #content3 let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h2 style="
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    color: mediumslateblue;
  ">
            Continue Chat here
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
  <span aria-hidden="true">&times;</span>
</button>
    </div>
    <div class="modal-body">
        <form>
            <div class="col-md-12">
                <div class="col-md-12">
                    <div class="form-horizontal">
                        <div class="">
                            <h2 style="text-align: center;"></h2>
                        </div>
                        <div class="col-md-12" style="
            margin-bottom: 50px;
            max-width: 100%;
            background-color: white;
          ">
                            <div class="form-group">
                                <label class="control-label">chat message:</label>
                                <input type="text" id="messa" name="messa" class="form-control" placeholder="Enter the message" [(ngModel)]="messa" />
                            </div>
                            <div class="form-group">
                                <input type="Submit" class="btn btn-primary btn-round" style="background-color: grey; color: white;" (click)="SaveUserChat()" (click)="d('Cross click')" value="Send" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>