
<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div   class="modal-dialog" role="document">

        <div class="modal-content" id="myOverlay" style="width:100%;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">New Message</h5>
                <img  *ngIf="expands" src="assets/img/expand.png" (click)="expand()" alt="" width="18px" style="margin-left: 74%;"/>
                <img  *ngIf="compress" src="assets/img/compres.png" (click)="compres()" alt="" width="26px" style="margin-left: 86%;"/>

                <button type="button" class="close" (click)="cancelmail()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="inbox-body">

                    <div class="col-md-12">


                        <div class="col-md-12" id="divstyle">
                            <label id="labeldiv">To</label>
                            <div id="aboveTagInp">
                                <!-- <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                                    [separatorKeyCodes]="[13,188,186,32]">
                                    <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                                    <!-- <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                                        <ng-template let-item="item" let-index="index">
                                            {{ item.email }}
                                        </ng-template>
                                    </tag-input-dropdown> -->
                                <!-- </tag-input> --> 
                                <input type="text" class="form-control" placeholder="To User" name="ToUser" [(ngModel)]="ToUser" style="font-size: 20px;" autofocus>
                        <br />
                            </div>

                        </div> <br />

                        <input type="text" class="form-control" placeholder="Subject" name="mailsubject" [(ngModel)]="mailsubject" style="font-size: 20px;" autofocus>
                        <br />

                        <textarea type="text" class="form-control" placeholder="Message" name="message" [(ngModel)]="message"  style="font-size: 20px;" rows="4" cols="50"> </textarea>
                        <br /> <br />
                        <label id="actionfunctions">
                            <!-- <p data-toggle="modal" data-target="#UploadModalPopup">
                              <img src="./assets/img/ellipse182.png"
            style="cursor: pointer;" /> &nbsp; Add Attachment From Blocdrive
                            </p> -->
                          </label><br /> <br />
                        <label>Attach file</label>
                        <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)"> <br /> <br />
                        <!-- <div style="text-align: right;">
                            <button type="submit" id="SendBtn" class="btn btn-primary" (click)="sendmail()">SEND</button></div><br /> <br />
                    -->

                    <div *ngIf="friends">
                        <a  (click)="savefrienddetails()"><u style="font-size: 22px;
                            color: blue;">Send Friend Request</u></a>
    
                    </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="cancelmail()" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="sendmail()" [routerLink]="['/jobsent']">Send</button>
            </div>
        </div>
    </div>
</div>
<div>
  

    <button class="btn btn-compose" data-target="#exampleModal1" data-toggle="modal" (click)="composemail()" data-original-title="" title="">
        Compose
    </button>
    <ul class="inbox-nav inbox-divider">
        <li>
            <a (click)="inbox()"><img src="assets/img/inbox.png" alt="" width="18px"><span>&nbsp;&nbsp;Inbox</span> </a>
        </li>
        <li>
            <a (click)="sentitems()"><i class="fa fa-paper-plane" aria-hidden="true"></i>Sent Mail</a>
        </li>

        <li>
            <a (click)="archiveitems()"><img src="assets/img/download.png" alt="" width="18px"><span>&nbsp;&nbsp;Archive</span></a>
        </li>
        <li>
            <a (click)="deleteitems()"><i class=" fa fa-trash-o" style="font-size: x-large;"></i>Trash</a>
        </li>
        <li>
            <a class="modal-title w-100" id="folderText" *ngIf="install" style="color:blue;font-size: 14px;"><b><u>Install Extensions for External Mail</u></b><br>
            </a>
        </li>
        <li>
            <a class="modal-title w-100" id="folderText" *ngIf="install" style="background-color: white; color: #1a0dab; font-weight: bold;" >
                <span style="color:#4285F4">G</span>
                <span style="color:#DB4437">o</span>
                <span style="color:#F4B400">o</span>
                <span style="color:#4285F4">g</span>
                <span style="color:#0F9D58">l</span>
                <span style="color:#DB4437">e</span>
            </a>
        </li>
        <li>
            <a class="modal-title w-100" id="folderText" *ngIf="install" style="background-color: white; color: #1a0dab; font-weight: bold;" >
                Outlook</a>
        </li>
        <li>
            <a class="modal-title w-100" id="folderText" *ngIf="install" style="background-color: white; color:#720e9e; font-weight: bold;">
                Yahoo</a>
        </li>

    </ul>
</div>


<!-- Upload from computer modal -->
<div class="modal modal-angular fade" id="UploadModalPopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content" id="choosepopup">
            <button type="button" class="close" id="closeinto" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" style="float: right;">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Attach File</p>
            </div>
            <br />
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <!-- <div id="chosefromcom">
            <br /> <br />
            <img src="./assets/img/Choosefrom.png" style="cursor: pointer;" />
            <br />
            <label id="chosefromcom">
              <p class="temp-upload-icon" for="excel-upload">
                UPLOAD FROM COMPUTER</p>
              <input #fileInput (click)="fileInput.value = null"
type="file" id='excel-upload' style="display: none"
                (change)="onChangeDocumnets($event)" multiple="true" />
                <input type="file" name="avatars" multiple=true
(change)="onChangeDocumnets($event)"> <br /> <br />

            </label>
            <br />
          </div> -->
                </div>
                <div class="col-md-2"></div>
            </div>

            <br />
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitmaindiv" id="chosefromcom">
                        <br /> <br />
                        <img data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitmaindiv" src="./assets/img/Choosefrom.png" style="cursor: pointer;" />
                        <br />
                        <p class="temp-upload-icon" data-dismiss="modal" style="margin-top: 1%;" data-toggle="modal" data-target="#choosefromhaspitmaindiv">
                            UPLOAD FROM BLOCDRIVE
                        </p>
                        <br />
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 row">
                    <div class="col-md-5"></div>
                    <div style="margin-left: -6%;">
                        <button type="button" id="testt" class="close" data-dismiss="modal" aria-label="Close"></button>
                        <button type="submit" id="CloseBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button><br>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- choosefrom main haspit modal -->
<div class="modal modal-angular fade" id="choosefromhaspitmaindiv" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
          id="closeinto">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Attach From Haspit</p>
            </div>
            <div class="modal-body" style="margin-top: -6%;">
                <div *ngIf="folderdisaply">
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <p id="homediv">Home</p>
                            <p id="namediv">Name</p>
                            <table class="table table-hover" id="tabledivision">
                                <tbody class="items">
                                    <tr id="trdiv">
                                        <td style="cursor: pointer;" data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitacc">
                                            <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;margin-top: -1%;" />
                                            <span id="spandivv">My Personal Files</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- <tbody class="items">
                  <tr id="trdiv">
                    <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
                      data-target="#choosefromshared">
                      <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
                      <span id="spandivv">Shared with Others</span>
                    </td>
                  </tr>
                </tbody> -->
                                <!-- <tbody class="items">
                  <tr id="trdiv">
                    <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
                      data-target="#choosefromdeletd">
                      <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
                      <span id="spandivv">Deleted Files</span>
                    </td>
                  </tr>
                </tbody> -->
                            </table>
                            <br />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <button type="submit" id="uploadBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">UPLOAD</button>
                    <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button>
                </div>

            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>

<!-- choosefromhaspit My personal files modal -->

<div class="modal modal-angular fade" id="choosefromhaspitacc" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
          id="closeinto">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Upload From Blocdrive</p>
            </div>
            <div class="modal-body">

                <div *ngIf="folderdisaply">
                    <div class="col-md-12 row" id="popupcurrentpagename">
                        <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal" data-target="#choosefromhaspitmaindiv">Home</p>
                        <span (click)="GetAllFolders()" id="Breadcrumbone" style="cursor: pointer;"> &nbsp; <img
              width="30px" height="30px"
src="./assets/img/folder.png" />&nbsp; My Personal Files</span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">
              &nbsp; <img width="30px" height="30px"
src="./assets/img/folder.png" />&nbsp;
              <span (click)="GetDetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                        </span>
                        <!-- <span id="Breadcrumbone" style="cursor: pointer;">
&nbsp; <img src="./assets/img/Vector122.png" />
              &nbsp;{{currentfoldername}}</span> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files</span></p>               -->
                            <table class="table table-hover table-responsive" id="popuptable">
                                <thead>
                                    <tr id="theadstyle">
                                        <th style="padding-left:6%">Name</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody class="items" *ngFor="let folderview of allFolders">
                                    <tr id="trdiv">
                                        <td (click)="GetDetails(folderview)" style="cursor: pointer;" id="foldernamedisplay1">

                                            <div class="col-md-12 row">
                                                <div class="col-md-1">
                                                    <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                                    {{folderview.folderName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle">{{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>
                                <tbody class="items" *ngFor="let ret of allFoldersList">
                                    <tr id="hoverlink" id="{{ret.Id}}" style="height: 60px;">
                                        <td style="cursor: pointer;" *ngIf="ret.DocumentType=='image/jpeg'||ret.DocumentType=='image/png'||ret.DocumentType=='png'
||ret.DocumentType=='jpeg'||ret.DocumentType=='jpg'

||ret.DocumentType=='PNG'" id="foldernamedisplay1">

                                            <div class="col-md-12 row" (click)="selectfile(ret)">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle">{{ret.date | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
                <div *ngIf="subfolderdisaply">
                    <div class="col-md-12 row" id="popupcurrentpagename">
                        <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal" data-target="#choosefromhaspitmaindiv">Home</p>
                        <span (click)="GetMypersonalfolders()" id="Breadcrumb" style="cursor: pointer;"> &nbsp; <img
                src="./assets/img/Vector122.png" />&nbsp; My Personal
Files</span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">
              &nbsp; <img src="./assets/img/Vector122.png" />&nbsp;
              <span (click)="GetSubfolderdetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                        </span>
                        <span id="Breadcrumbone" style="cursor: pointer;"> &nbsp;
<img width="30px" height="30px" src="./assets/img/folder.png" />
              &nbsp;{{currentfoldername}}</span>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files > {{currentfoldername}}</span></p>
-->
                            <table class="table table-hover table-responsive" *ngIf="allSubFolders" id="popuptable">
                                <thead>
                                    <tr id="theadstyle">
                                        <th style="padding-left:6%">Name</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody class="items" *ngFor="let folderview of
allSubFolders.retData">
                                    <tr id="trdiv">
                                        <td *ngIf="folderview.folderName" style="cursor:
pointer;" (click)="GetSubfolderdetails(folderview)" id="foldernamedisplay1">

                                            <div class="col-md-12 row">
                                                <div class="col-md-1">
                                                    <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay3" style="cursor: pointer;">
                                                    {{folderview.folderName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="!folderview.folderName" id="foldernamedisplay1">
                                            <div class="col-md-12 row">
                                                <input type="checkbox" (click)="onClick(folderview)" style="
                        height: 16px;
                        width: 16px;
                        /* margin-top: 28px; */
                        cursor: pointer;
                      " />
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>

                                                <div class="col-md-10" id="datadisplay3" style="cursor: pointer;" (click)="onClick(folderview)">
                                                    {{folderview.documentName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle" *ngIf="folderview.folderName">
                                            {{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                        <td id="datestyle" *ngIf="!folderview.folderName">{{folderview.date | date: 'MM/dd/yy, hh:mm a'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="margin-right: 10%;">
                <button type="button" id="closetwo" class="close" data-dismiss="modal" aria-label="Close"></button>
                <button type="submit" id="Uploadbtnn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal" (click)="uploadmypersonalfiles()">UPLOAD</button>
                <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button>
            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>
<br /> <br /> <br />