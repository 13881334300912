<ngx-spinner></ngx-spinner> <br /><br /><br /><br />
<div *ngIf="!edit">
    <div class="col-md-12 row">
        <div class="col-md-3">&nbsp;</div>
        <div class="col-md-4">
            <b style="font-size: 22px; color: black;">Account Details</b>
        </div>
        <div class="col-md-2 text-right" (click)="editchange(CompanyRegistration)">
            <button type="submit" class="btn btn-primary">
        <i class="fa fa-pencil"></i> Edit
      </button>
        </div>
        <!--  -->
    </div>
    <div class="col-md-12">&nbsp;</div>
    <div class="col-md-12">&nbsp;</div>

    <div class="col-md-12 row">
        <div class="col-md-3">&nbsp;</div>
        <div class="col-md-6 row" style="
        background-image: linear-gradient(to right, #ff5722, #ff9800);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      ">
            <!-- <div class="col-md-12 text-center"> <input type="file" id="filePicker" (change)="handleFileSelect($event)"></div> -->
            <div class="table-responsive">
                <table class="table table-hover">
                    <tbody style="color: white;">
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Company Name
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.companyName
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Year of Establishment
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.established
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                No. of Employees
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.numberofEmployees
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                No. of Branches
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.numberofBranches
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Registration ID
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.registrationId
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                GST
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{ alldata.gst }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                PAN
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{ alldata.pan }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Industry Type
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.industry
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Headquarter
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.headquarters
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Website
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.webSite
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Official Email
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.officialEmail
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Registered Address
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{ alldata.regaddone }}
                  <span *ngIf="alldata.regaddtwo"
                    >, {{ alldata.regaddtwo }}</span
                  >
                  <span *ngIf="alldata.regaddthree"
                    >, {{ alldata.regaddthree }}</span
                  >, <br />
                  near {{ alldata.regaddlanmark }} <br />
                  {{ alldata.regaddcity }}, {{ alldata.regaddstate }}, <br />{{
                    alldata.regaddcountry
                  }}
                  - {{ alldata.regaddpincode }}
                </b>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="col-md-12 row">
                    <div class="col-md-8">
                        <b style="font-size: 20px; color: white;">Contact Person Details</b>
                    </div>
                    <!--  -->
                </div>
                <div class="col-md-12">&nbsp;</div>
                <table class="table table-hover">
                    <tbody style="color: white;">
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Name
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.contactPersonName
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Email
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.contactPersonEmail
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Phone
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  alldata.contactPersonPhone
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Address
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{ alldata.contactaddone }}
                  <span *ngIf="alldata.contactaddtwo"
                    >, {{ alldata.contactaddtwo }}</span
                  >
                  <span *ngIf="alldata.contactaddthree"
                    >, {{ alldata.contactaddthree }}</span
                  >, <br />
                  near {{ alldata.contactaddlanmark }} <br />
                  {{ alldata.contactaddcity }}, {{ alldata.contactaddstate }},
                  <br />{{ alldata.contactaddcountry }} -
                  {{ alldata.contactaddpincode }}
                </b>
                            </td>
                        </tr>

                        <tr>
                            <td style="color: white; font-weight: 500;">
                                ********
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <button class="btn btn-primary" (click)="ViewDocument('nothing', pdfcontent1)" style="
                    float: right;
                    padding: 6px;
                    text-transform: capitalize;
                  ">
                  Change Password
                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- profile edits -->

<main *ngIf="edit">
    <section class="container register-form">
        <div class="form">
            <div class="row text-center justify-content-center">
                <div class="col-lg-12">
                    <br />
                    <div class="note">
                        <p style="font-size: x-large;">Company Details</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-content">
            <!-- SVG separator -->
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w2.org/2000/svg"
      >
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div> -->

            <div class="container">
                <div class="row">
                    <div class="col-md-2">&nbsp;</div>
                    <div class="col-md-8"></div>
                    <button type="button" (click)="editchangeback()" class="btn btn-primary">
            <i class="fa fa-window-close"></i> Cancel
          </button>
                </div>
                <div class="row justify-content-center mt--200"></div>
                <form #CompanyRegistration="ngForm" (ngSubmit)="OnSubmitCompanyRegistration(CompanyRegistration)">
                    <div>&nbsp;</div>

                    <div class="row">
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="fname" style="font-size: 15px; color: #8a061c;">Company Name</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  disabled
                  type="text"
                  [(ngModel)]="cname"
                  id="cname"
                  name="cname"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="fname" style="font-size: 15px; color: #8a061c;"
                >Year of Establishment</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <!-- <input
                  class="form-control"
                  #estyear
                  type="year"
                  [ngModel]
                  id="estyear"
                  name="estyear"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                /> -->
                <select
                  name="estyear"
                  id="estyear"
                  class="form-control"
                  [(ngModel)]="estyear"
                >
                  <option value="1990">1990</option>
                  <option value="1991">1991</option>
                  <option value="1992">1992</option>
                  <option value="1993">1993</option>
                  <option value="1994">1994</option>
                  <option value="1995">1995</option>
                  <option value="1996">1996</option>
                  <option value="1997">1997</option>
                  <option value="1998">1998</option>
                  <option value="1999">1999</option>
                  <option value="2000">2000</option>
                  <option value="2001">2001</option>
                  <option value="2002">2002</option>
                  <option value="2003">2003</option>
                  <option value="2004">2004</option>
                  <option value="2005">2005</option>
                  <option value="2006">2006</option>
                  <option value="2007">2007</option>
                  <option value="2008">2008</option>
                  <option value="2009">2009</option>
                  <option value="2010">2010</option>
                  <option value="2011">2011</option>
                  <option value="2012">2012</option>
                  <option value="2013">2013</option>
                  <option value="2014">2014</option>
                  <option value="2015">2015</option>
                  <option value="2016">2016</option>
                  <option value="2017">2017</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2020">2021</option>
                  <option value="2020">2022</option>
                </select>
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="fname" style="font-size: 15px; color: #8a061c;"
                >No of Employees</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <!-- <input
                  class="form-control"
                  #numofemp
                  type="number"
                  [ngModel]
                  id="numofemp"
                  name="numofemp"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                /> -->
                <select
                  name="numofemp"
                  id="numofemp"
                  class="form-control"
                  [(ngModel)]="numofemp"
                >
                  <option value="0 - 10">0 - 10</option>
                  <option value="11 - 50">11 - 50</option>
                  <option value="51 - 100">51 - 100</option>
                  <option value="101 - 500">101 - 500</option>
                  <option value="501 - 1000">501 - 1000</option>
                  <option value="1001 - 10000">1001 - 10000</option>
                  <option value="10000+">10000+</option>
                </select>
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >No of Branches
              </label>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <!-- <input class="form-control" #numofbra type="text" [ngModel] id="number" name="numofbra" (focus)="focus2 = true" (blur)="focus2 = false" /> -->
                                <select name="numofbra" id="numofbra" class="form-control" [(ngModel)]="numofbra">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="10+">10+</option>
                </select>
                            </div>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="secondemail" style="font-size: 15px; color: #8a061c;">Registration Number
              </label>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" disabled type="text" [(ngModel)]="regId" id="regId" name="regId" (focus)="focus2 = true" (blur)="focus2 = false" />
                            </div>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="secondemail" style="font-size: 15px; color: #8a061c;">GST Number
              </label>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" disabled type="text" [(ngModel)]="gst" id="gst" name="gst" (focus)="focus2 = true" (blur)="validategst(CompanyRegistration)" />
                            </div>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="secondemail" style="font-size: 15px; color: #8a061c;">PAN
              </label>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" disabled type="text" [(ngModel)]="pan" id="pan" name="pan" (focus)="focus2 = true" (blur)="focus2 = false" />
                            </div>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="secondemail" style="font-size: 15px; color: #8a061c;">Head Quarter</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [(ngModel)]="headquarters"
                  id="headquarters"
                  name="headquarters"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <!-- <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Registration Address</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Registration Address"
                  #RegAddress
                  type="RegAddress"
                  [ngModel]
                  id="RegAddress"
                  name="RegAddress"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div> -->
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="addressone" style="font-size: 15px; color: #8a061c;">
                Website</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [(ngModel)]="Website"
                  id="Website"
                  name="Website"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="landmark" style="font-size: 15px; color: #8a061c;"
                >Email</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="email"
                  [(ngModel)]="email"
                  id="email"
                  name="email"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="addressone" style="font-size: 15px; color: #8a061c;"
                >Contact Person Name</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [(ngModel)]="contpersonname"
                  id="contpersonname"
                  name="contpersonname"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <!-- <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="landmark" style="font-size: 15px; color: #8a061c;"
                >Contact Person Address</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Contact Person Address"
                  #contactperAdd
                  type="text"
                  [ngModel]
                  id="contactperAdd"
                  name="contactperAdd"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  style="text-transform: capitalize;"
                />
              </div>
            </div> -->
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="city" style="font-size: 15px; color: #8a061c;"
                >Contact Person Email</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="email"
                  [(ngModel)]="contactperemail"
                  id="contactperemail"
                  name="contactperemail"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>

            <!-- <div class="col-md-6">
              <label for="state" style="font-size: 15px;color: #8a061c">State</label>
                            <select (change)="onSelectState($event.target.value)" class="form-control">
                <option value="">Select State</option>
                <option *ngFor="let do of States" value={{do.id}}>
                  {{do.stateName}}
                </option>
              </select>
                        </div>
                        <div class="col-md-6">
                            <label for="state" style="font-size: 15px;color: #8a061c">Country</label>
                            <select (change)="onSelectCountry($event.target.value)" class="form-control">
                <option value="">Select Country</option>
                <option *ngFor="let do of Countries" value={{do.countryId}}>
                  {{do.countryName}}
                </option>
              </select>
                        </div> -->
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="state" style="font-size: 15px; color: #8a061c;">Contact Person Phone</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="number"
                  [(ngModel)]="contactpersonphone"
                  id="contactpersonphone"
                  name="contactpersonphone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >Industry Type</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <select
                  name="industry"
                  id="industry"
                  (change)="onSelectIndrust($event.target.value)"
                  class="form-control"
                  [(ngModel)]="industry"
                >
                  <option value="" hidden></option>
                  <option
                    *ngFor="let do of Industry"
                    value="{{ do.id }}"
                    [selected]="do.id === industry"
                  >
                    {{ do.indrustType }}
                  </option>
                </select>
              </div>
            </div>

            <!-- ---------------------------------------- -->
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-6">
              <h3>Company Address</h3>
            </div>
            <div class="col-md-6">
              <div class="form-group"></div>
            </div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                No./Block</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="alone"
                  [(ngModel)]="alone"
                  id="alone"
                  name="alone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Street/Cross/Main</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="altwo"
                  [(ngModel)]="altwo"
                  id="altwo"
                  name="altwo"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Area/Extension</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="althree"
                  [(ngModel)]="althree"
                  id="althree"
                  name="althree"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Landmark</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="landmark"
                  [(ngModel)]="landmark"
                  id="landmark"
                  name="landmark"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Pincode</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="number"
                  [(ngModel)]="pincode"
                  id="pincode"
                  name="pincode"
                  (focus)="focus2 = true"
                  (blur)="getalldata(CompanyRegistration)"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                District</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="city"
                  [(ngModel)]="city"
                  id="city"
                  name="city"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >State</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="state"
                  [(ngModel)]="state"
                  id="state"
                  name="state"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>

            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Country</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="Country"
                  [(ngModel)]="Country"
                  id="Country"
                  name="Country"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-6">
              <h3>Contact Person Address</h3>
            </div>
            <div class="col-md-6">
              <div class="form-group"></div>
            </div>

            <div class="col-md-12">&nbsp;</div>

            <!-- <div class="col-md-6">
              <input class="form-control"  (change)="contactadd($event,CompanyRegistration)" type="checkbox"   [ngModel] /> -->
            <!-- </div> -->

            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                No./Block</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="contalone"
                  id="contalone"
                  name="contalone"
                  [(ngModel)]="contalone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Street/Cross/Main</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="conaltwo"
                  id="conaltwo"
                  name="conaltwo"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  [(ngModel)]="conaltwo"
                />
              </div>
            </div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Area/Extension</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="conalthree"
                  id="conalthree"
                  name="conalthree"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  [(ngModel)]="conalthree"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Landmark</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="contlandmark"
                  id="contlandmark"
                  name="contlandmark"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  [(ngModel)]="contlandmark"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Pincode</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="number"
                  id="contpincode"
                  name="contpincode"
                  (focus)="focus2 = true"
                  (blur)="getalldataposter(CompanyRegistration)"
                  [(ngModel)]="contpincode"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                District</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="city"
                  id="contcity"
                  name="contcity"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  [(ngModel)]="contcity"
                />
              </div>
            </div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >State</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="constate"
                  id="constate"
                  name="constate"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  [(ngModel)]="constate"
                />
              </div>
            </div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Country</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="contCountry"
                  id="contCountry"
                  name="contCountry"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  [(ngModel)]="contCountry"
                />
              </div>
            </div>

            <!-- ------------------------------- -->
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-10" style="text-align: center;">
              <button type="submit" class="btnSubmit">
                Save
              </button>
            </div>
            <div class="col-md-1">&nbsp;</div>
          </div>
        </form>
      </div>
    </div>
  </section>
</main>

<!-- profile edits end -->
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>

<ng-template #pdfcontent let-d="dismiss" let-c="dismiss">
  <div class="modal-header" style="width: 100%; background-color: white;">
    <div>&nbsp;</div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="width: 100%; background-color: white;">
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <input type="text" id="Name" placeholder="Name" />
        <br /><br />
        <div class="col-md-12 row">
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <button
              style="
                background-color: orange;
                float: right;
                padding: 6px;
                text-transform: capitalize;
                color: black;
                border: solid 1px;
              "
            >
              Save
            </button>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <button
              style="
                background-color: green;
                float: right;
                padding: 6px;
                text-transform: capitalize;
                color: black;
                border: solid 1px;
              "
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pdfcontent1 let-d="dismiss" let-c="dismiss">
  <div class="modal-header" style="width: 100%; background-color: white;">
    <div>&nbsp;</div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="width: 100%; background-color: white;">
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <form #forms="ngForm" (ngSubmit)="ChangePassword(forms)">
          <input
            type="password"
            class="form-control"
            name="passwordone"
            id="Password"
            placeholder="New Password"
            ngModel
          />
          <br /><br />
          <input
            type="password"
            class="form-control"
            name="passwordtwo"
            id="ConfirmPassword"
            placeholder="Confirm Password"
            ngModel
          />
          <br /><br />
          <div class="col-md-12 row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <button
                type="submit"
                style="
                  background-color: orange;
                  float: right;
                  padding: 6px;
                  text-transform: capitalize;
                  color: black;
                  border: solid 1px;
                "
              >
                Save
              </button>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <button
                style="
                  background-color: green;
                  float: right;
                  padding: 6px;
                  text-transform: capitalize;
                  color: black;
                  border: solid 1px;
                "
              >
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<style>
  ::placeholder {
    color: black;
    opacity: 0.7;
  }

  .form-control {
    border-radius: 5px;
  }

  .input {
    background-color: white;
  }
</style>

<style>
  .dropzone {
    min-height: 400px;
    min-width: 400px;
    display: table;
    width: 100%;
    background-color: #eee;
    border: dotted 1px #aaa;
  }

  .text-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .centered {
    font-family: sans-serif;
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
  }
</style>

<style>
  .modal-backdrop {
    display: none;
  }

  .modal-open .modal {
    width: 300px;
    margin: 0 auto;
  }

  .modal-backdrop {
    display: none !important;
  }

  .modal-open .modal {
    width: 300px;
    margin: 0 auto;
  }
</style>