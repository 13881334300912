import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddNewFolder } from '../VIewmodels/NewFolder';
import { Signup } from '../VIewmodels/Signup';
import { Friend } from '../VIewmodels/Friend';
import { Share } from '../VIewmodels/Share';
import { Search } from '../VIewmodels/Search';
import { PayU } from '../VIewmodels/Payment';
import { addresslist } from '../VIewmodels/addresslist';
import { SavepaymentDetails } from '../VIewmodels/SavePaymentDet';
import { Plan } from '../VIewmodels/plan';
import { ShareFileViaMailVM } from '../VIewmodels/ShareFileViaMailVM';
import { ImportFromMailVW } from '../VIewmodels/ImportFromMailVM';
import { EmailMessageVM } from '../VIewmodels/EmailMessageVM';
import { OtpViewModel } from '../VIewmodels/OtpViewModel';
import { MobileViewModel } from '../VIewmodels/MobileViewModel';
import { ChangePassword } from '../VIewmodels/ChangePassword';
import { ContactForm } from '../VIewmodels/ContactForm';
import { Delete } from '../VIewmodels/Delete';
import { SupportVM } from '../VIewmodels/SupportVM';
import { DownloadFolderVM } from '../VIewmodels/DownloadFolderVM';
import { paymentVM } from '../VIewmodels/paymentVM';
import { PayVM } from '../VIewmodels/PayVM';
import { RestoredataVM } from '../VIewmodels/RestoredataVM';
import { UserId } from '../VIewmodels/UserId';
import { IndividualChatDetails } from '../VIewmodels/IndividualChatDetail';
import { ShareMulti } from '../VIewmodels/ShareMulti';
import { FolderShareToUers } from '../VIewmodels/FolderShareToUers';
import { MoveMultiFolder } from '../VIewmodels/MoveMultiFolder';
import { MultiFolderShareVM } from '../VIewmodels/MultiFolderShareVM';
import { DeleteMulit } from '../VIewmodels/DeleteMulit';
import { ShareOnlyFileVM } from '../VIewmodels/ShareOnlyFileVM';
import { Email } from '../VIewmodels/Email';
import { CompanyInvitation } from '../VIewmodels/CompanyInvitation';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  //readonly rooturl1 = "http://localhost:19200";
  readonly rooturl1 = 'http://localhost:19200';

  //readonly url = "https://bsafeapi.azurewebsites.net";
  //  readonly url = "https://bsafeapi.azurewebsites.net";
  // readonly url = "https://blockchainmatrimony.com/jobzoneapi/";
  // readonly url = "https://blockchainmatrimony.com/jobzoneapi";
  //readonly planurl ="https://bsafeapi.azurewebsites.net";
  readonly planurl = 'https://blockchainmatrimony.com/jobzoneapi';
  // readonly url = "https://blockchainmatrimony.com/jobzoneapi";
  readonly url = 'https://blockchainmatrimony.com/jobzoneapi';
  //readonly cmsurl = "http://www.srichid.in/bscmsapi"
  readonly cmsurl = 'http://localhost:19210';
  getCurrentUSerImage: any;

  constructor(private http: HttpClient) {}
  getUserpassword(id) {
    return this.http.post(this.url + '/api/Account/GetPassword', id);
  }
  userAuthentication(userName, password) {
    var data =
      'username=' + userName + '&password=' + password + '&grant_type=password';
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded;','No-Auth': 'True',});
    return this.http.post(this.url + '/token', data, { headers: reqHeader });
  }

  // getprovincelist(){
  //   return this.http.get(this.rooturl1 + '/api/AuRoleMaster/GetProvinceList' );
  // }
  // selectMenubyUserRoleDetails(usrole) {
  //   return this.http.get(this.rooturl1 + '/api/MenuMaster/GetMenubyUserRole/' + usrole);
  // }
  // selectMenubyMenuId(usrole) {
  //   return this.http.get(this.rooturl1 + '/api/MenuMaster/GetMenubyUserRole/' + usrole);
  // }
  getreport(id) {
    return this.http.get(this.url + '/api/Account/report/' + id);
  }

  getpysicalvideoreport(id) {
    return this.http.get(this.url + '/api/videorecord/physicalvideoreport/' + id);
  }
  deleterecordedvideo(id: number) {
    return this.http.get(this.url + '/api/videorecord/Deleterecordedvideo/' + id);
  }


  afterlogin(id) {
    return this.http.get(this.url + '/api/Account/whereToGo/' + id);
  }

  getUser() {
    return this.http.get(this.url + '/api/AuManagement/GetA_U_Management');
  }
  getinsti(id) {
    return this.http.get(this.url + '/api/Account/Getinstitution/' + id);
  }
  shareall(data: any) {
    return this.http.post(this.url + '/api/ShareFileFolder/shareall', data);
  }
  getUserDetails(id) {
    return this.http.get(
      this.url + '/api/AuManagement/GetA_U_Management/' + id
    );
  }
  getjobdetails(id) {
    return this.http.get(this.url + '/api/Account/getjobdetails/' + id);
  }
  getfolders(id) {
    return this.http.get(this.url + '/api/FolderMasters/GetallFolder/' + id);
  }
  getfolderssearch(id) {
    return this.http.post(this.url + '/api/FolderMasters/Searchdata', id);
  }
  getsubfolders(id) {
    return this.http.get(this.url + '/api/UploadDocument/Getdocument/' + id);
  }
  getbackdata(pid) {
    return this.http.get(
      this.url + '/api/UploadDocument/GetBackdocument/' + pid
    );
  }

  UpdateFolder(data: AddNewFolder) {
    return this.http.post(
      this.url + '/api/FolderMasters/PostUpdateContent',
      data
    );
  }

  addNewFolder(data: AddNewFolder) {
    return this.http.post(
      this.url + '/api/FolderMasters/PostFolderMaster',
      data
    );
  }

  deletefolder(aa) {
    return this.http.post(
      this.url + '/api/FolderMasters/PostDeleteContent',
      aa
    );
  }
  // getUserforBiometrics() {
  //   return this.http.get(this.rooturl1 + "api/AuManagement");
  // }
  // sendBiometrics(bio) {
  //   return this.http.post(this.rooturl1 + "/api/Biometrics/PostBiometricsAuthentication", bio);
  // }
  postFile(fileupload: File, fid: string, uid: any, uname: string) {
    const url = '  http: //localhost:8541/api//UploadDocument/DocumentUpload';
    //const url = "  http: //localhost:8541/api//UploadDocument/DocumentUpload";
    const formData: FormData = new FormData();
    formData.append('Image', fileupload, fileupload.name);
    formData.append('FolderId', fid);
    return this.http.post(url, formData);
  }

  postdocuments(files: File, uid: any, uname: string) {
    //const url = "  http: //localhost:8541/api//UploadDocument/NewDocumentUpload";
    const url =
      '  http: //localhost:8541/api//UploadDocument/NewDocumentUpload';
    // data: {analyticals.co/bcsdmsapi
    //   files: files;
    // }
    const formData: FormData = new FormData();
    formData.append('Image', files);
    formData.append('userId', uid);
    formData.append('username', uname);
    return this.http.post(url, formData);
  }

  saveDetails(data: Signup) {
    return this.http.post(this.url + '/api/account/register', data);
  }
  GetFriendRequests(id){
    return this.http.get(this.url + '/api/FriendLists/GetFriendRequestList/' + id);
  }
  SaveFrienddetails(data: Friend) {
    return this.http.post(this.url + '/api/FriendLists/PostFriendList', data);
  }
  friendlist(id) {
    return this.http.get(this.url + '/api/FriendLists/GetFriendList/' + id);
  }

  DeleteFiles(data: Delete) {
    return this.http.post(
      this.url + '/api/FolderMasters/PostDeleteFiles',
      data
    );
  }
  sharefileviamail(data: ShareFileViaMailVM) {
    return this.http.post(
      this.url + '/api/ShareFilesViaMail/ShareFilesThroughMail',
      data
    );
  }
  importfilefrommail(data: ImportFromMailVW) {
    return this.http.post(
      this.url + '/api/ShareFilesViaMail/ImportFilesFromMail',
      data
    );
  }

  // GetAllTransactions(id) {
  //   return this.http.get(this.url + "/api/Contract/GetTransactions/" + id);
  // }

  SearchFiles(data: Search) {
    return this.http.post(this.url + '/api/Search/PostSearch', data);
  }
  AcceptReq(f) {
    return this.http.post(this.url + '/api/FriendLists/AcceptRequest', f);
  }

  getpath(id) {
    return this.http.get(this.url + '/api/Path/getpath/' + id);
  }
  updateretailstores(id) {
    return this.http.get(
      this.url + 'api/ReturnedOrders/Updateretailstores/' + id
    );
  }
  getPayuPlans() {
    return this.http.get(
      this.planurl + '/api/PlansTypeMasters/GetPlansTypeMasters'
    );
  }

  getUserImage(uid) {
    return this.http.get(this.url + '/api/Account/GetUserPic/' + uid);
  }

  deleteedu(id) {
    return this.http.get(this.url + '/api/Account/deleteedu/' + id);
  }

  deleteskl(id) {
    return this.http.get(this.url + '/api/Account/deleteSkl/' + id);
  }

  deletedoc(id) {
    return this.http.delete(this.url + '/api/Account/deletecertirskl/' + id);
  }

  getCerti(uid) {
    return this.http.get(this.url + '/api/Account/GetCert/' + uid);
  }

  getbio(uid) {
    return this.http.get(this.url + '/api/Account/Getbio/' + uid);
  }

  getMarks(uid) {
    return this.http.get(this.url + '/api/Account/Getmarks/' + uid);
  }

  getEduDet(uid) {
    return this.http.get(this.url + '/api/Account/GetEdu/' + uid);
  }

  getSkillDet(uid) {
    return this.http.get(this.url + '/api/Account/GetSkills/' + uid);
  }

  getUsername(uid) {
    return this.http.get(this.url + '/api/Account/Getusername/' + uid);
  }

  saveoreditskill(data) {
    return this.http.post(this.url + '/api/Account/AddUpdateskill', data);
  }
  saveoreditedu(data) {
    return this.http.post(this.url + '/api/Account/AddUpdateedu', data);
  }

  savebio(data) {
    return this.http.post(this.url + '/api/Account/Addbio', data);
  }

  SaveOrderDetails(aaa: SavepaymentDetails) {
    return this.http.post(this.planurl + '/api/Payments/GetOrderPayments', aaa);
  }

  getorderpayment(mm: addresslist) {
    return this.http.post(
      this.planurl + '/api/Payments/GetOrderDetailsforPayment',
      mm
    );
  }

  getpaymentstatus(aa: PayU) {
    return this.http.post(this.planurl + '/api/Payments/GetPaymentStatus', aa);
  }

  UpdatePlan(id) {
    return this.http.put(
      this.planurl + '/api/PlansTypeMasters/PutPlansTypeMaster/',
      id
    );
  }
  DeletePlan(id) {
    return this.http.delete(
      this.planurl + '/api/PlansTypeMasters/DeletePlansTypeMaster/' + id
    );
  }
  SavePlan(data: Plan) {
    return this.http.post(
      this.planurl + '/api/PlansTypeMasters/PostPlansTypeMaster',
      data
    );
  }
  PlantList() {
    return this.http.get(
      this.planurl + '/api/PlansTypeMasters/GetPlansTypeMasters'
    );
  }
  getAllCustomers() {
    return this.http.get(this.url + '/api/AuManagement/GetA_U_Management');
  }

  // GetCustomerTransaction() {
  //   return this.http.get(this.url + "/api/Contract/GetAllTransactions");
  // }
  getProfileDetails(data) {
    return this.http.get(
      this.url + '/api/AuManagement/GetA_U_Management/' + data
    );
  }
  // autocall() {
  //   return this.http.get(this.url + '/api/ShareFilesViaMail/GetCheckChainAsync/');
  // }
  // UploadEmailFiles(mail: EmailMessageVM) {
  //   return this.http.post(this.url + "/api/ShareFilesViaMail/TestUpload", mail);

  // }

  postVerifyOTP(data: OtpViewModel) {
    return this.http.post(this.url + '/api/Account/OTPValidation', data);
  }

  verifyEmail(num: MobileViewModel) {
    return this.http.post(this.url + '/api/Signup/MobileValidation', num);
  }

  postchangePassword(pwd: ChangePassword) {
    return this.http.put(this.url + '/api/Signup/UpdateForgotPassword', pwd);
  }

  VerifyOTP(pwd: MobileViewModel) {
    return this.http.post(
      this.url + '/api/Signup/ForgotPswdOTPValidation',
      pwd
    );
  }

  postchangePasswordAfterLogin(pwd: ChangePassword) {
    return this.http.post(this.url + '/api/Signup/PostChangePassword', pwd);
  }

  getfoldersbydate(uid) {
    return this.http.get(
      this.url + '/api/FolderMasters/GetallFoldersByDate/' + uid
    );
  }

  getfoldersbyname(uid) {
    return this.http.get(
      this.url + '/api/FolderMasters/GetallFolderByName/' + uid
    );
  }

  getsubfoldersbydate(id) {
    return this.http.get(
      this.url + '/api/UploadDocument/GetdocumentbyDate/' + id
    );
  }

  getsubfoldersbyname(id) {
    return this.http.get(
      this.url + '/api/UploadDocument/GetdocumentbyName/' + id
    );
  }

  getCategories() {
    return this.http.get(this.url + '/api/AuRoleMaster/GetCategories');
  }

  savecontactform(user: ContactForm) {
    return this.http.post(this.url + '/api/AuRoleMaster/PostContactForm', user);
  }
  SaveSupportRequest(data: SupportVM) {
    return this.http.post(
      this.url + '/api/BSSuppoerRequest/PostCustomerRequest',
      data
    );
  }
  downloadFolder(data: DownloadFolderVM) {
    return this.http.post(
      this.url + '/api/DownloadFolderorFile/PostDownloadFolder',
      data
    );
  }
  postBuyPlan(data: paymentVM) {
    return this.http.post(this.url + '/api/StripePayment/PostBuyPlan', data);
  }

  getMonthList() {
    return this.http.get(this.url + '/api/AuRoleMaster/GetMonthList');
  }

  getYearList() {
    return this.http.get(this.url + '/api/AuRoleMaster/GetYearList');
  }
  GetPlanAmount(data: PayVM) {
    return this.http.post(this.url + '/api/StripePayment/GetplanAmount', data);
  }

  getallpaymentlist(id) {
    return this.http.get(this.url + '/api/AuRoleMaster/GetPaymentList/' + id);
  }

  getBillIngormation(id) {
    return this.http.get(
      this.url + '/api/StripePayment/GetBillInformation/' + id
    );
  }

  Getalldeletetrns(id) {
    return this.http.get(
      this.url + '/api/FolderMasters/GetAllDeletedData/' + id
    );
  }
  PostRestoredata(data: RestoredataVM) {
    return this.http.post(
      this.url + '/api/FolderMasters/PostRestoredata',
      data
    );
  }

  //Chat
  GetToUser(UserId) {
    return this.http.get(this.url + '/api/UserChat/GetTouserDetails/' + UserId);
  }

  saveuserChat(ChatDetail) {
    return this.http.post(
      this.url + '/api/ChatContract/PostUserChat',ChatDetail);
  }

  GetAllUsersExceptMe(data: UserId) {
    return this.http.post(this.url + '/api/UserChat/GetAllUserExceptMe', data);
  }

  // getCurrentUSerImage(id) {
  //   return this.http.get(this.url + "/api/UploadImage/" + id);
  // }

  GetChatforParticularUser(id) {
    return this.http.get(this.url + '/api/ChatContract/GetChatmsg/' + id);
  }

  GetIndividualChatRecivedDetails(individual: IndividualChatDetails) {
    return this.http.post(
      this.url + '/api/ChatContract/RecivedIndividualChatDetails/',
      individual
    );
  }

  SharemultiFiles(data: ShareMulti) {
    return this.http.post(this.url + '/api/Mulit/MultiShareFiles', data);
  }
  MoveMultiFileFolders(data: MoveMultiFolder) {
    return this.http.post(
      this.url + '/api/Mulit/PostMoveMultiFolderOrDocument',
      data
    );
  }
  addExsistingMainFolderAndShare(data: FolderShareToUers) {
    return this.http.post(
      this.url + '/api/ShareFileFolder/PostExsistingMainFolderShareTOUsers',
      data
    );
  }
  getAllSharingfolders(id) {
    return this.http.get(
      this.url + '/api/ShareFileFolder/GetallSharingFolder/' + id
    );
  }
  addMultigMainFolderAndShare(data: MultiFolderShareVM) {
    return this.http.post(
      this.url + '/api/ShareFileFolder/PostMultyMainFolderShareTOUsers',
      data
    );
  }
  shareExsistingSubFolder(data: FolderShareToUers) {
    return this.http.post(
      this.url + '/api/ShareFileFolder/PostExsistingSubFolderShareTOUsers',
      data
    );
  }

  DeleteMore(data: DeleteMulit) {
    return this.http.post(this.url + '/api/Mulit/PostDeleteMore', data);
  }

  shareOnlyFile(data: ShareOnlyFileVM) {
    return this.http.post(
      this.url + '/api/ShareFileFolder/PostSharingFileOnly',
      data
    );
  }

  LoginVerification(data: OtpViewModel) {
    return this.http.post(this.url + '/api/Account/LoginVerification', data);
  }

  refer(data: any) {
    return this.http.post(this.url + '/api/refer/postrefer', data);
  }

  redeemdata(data: any) {
    return this.http.get(this.url + '/api/refer/getStorage/' + data);
  }
  redeemcash(data: any) {
    return this.http.post(this.url + '/api/refer/getAmount', data);
  }

  getblocks(data: any) {
    return this.http.get(this.url + '/api/refer/getblocks/' + data);
  }

  getAllUsers() {
    return this.http.get(this.url + '/api/refer/getAllUsers');
  }
  getrefers() {
    return this.http.get(this.url + '/api/refer/getrefers');
  }
  updateProfile(dat: any) {
    return this.http.post(this.url + '/api/Account/uploadProfilePic', dat);
  }
  getFileCount(id) {
    return this.http.get(
      this.url + '/api/UploadDocument/GetFilesUploaded/' + id
    );
  }

  updateProfilepic(dat: any) {
    return this.http.post(this.url + '/api/Account/uploadProfilephoto', dat);
  }

  sendotp(id) {
    const idd: Email = {
      Email: id,
    };
    return this.http.post(this.url + '/api/Account/forgotpassword/', idd);
  }

  otpvalid(dat) {
    return this.http.post(this.url + '/api/Account/resetPasswordvalid', dat);
  }

  sharedetails(data) {
    return this.http.post(this.url + '/api/Account/Share', data);
  }

  CompanyInvitation(data: CompanyInvitation) {
    return this.http.post(
      this.url + '/api/InstitutionRegistration/PostInviteCompany',
      data
    );
  }
  saveoreditexp(data) {
    return this.http.post(this.url + '/api/Account/AddUpdateexp', data);
  }
  getExpDet(uid) {
    return this.http.get(this.url + '/api/Account/GetExp/' + uid);
  }
  deleteexp(id) {
    return this.http.get(this.url + '/api/Account/deleteexp/' + id);
  }

  getcompanyprofile(id) {
    return this.http.get(
      this.url + '/api/CompanyRegistration/GetallDataCompany/' + id
    );
  }
  delete(id) {
    return this.http.get(this.url + '/api/CompanyRegistration/Jobdelete/' + id);
  }

  // -------------new--------------

  GetallUsersApplicant() {
    return this.http.get(this.url + '/api/Account/GetallUsersApplicant');
  }
  GetallcompApplicant() {
    return this.http.get(this.url + '/api/Account/GetallcompApplicant');
  }
  deletecom(id) {
    return this.http.get(this.url + '/api/CompanyRegistration/Companydelete/' + id);
  }
  GetCompStatus(id) {
    return this.http.get(
      this.url + '/api/Account/GetCompanyStatus?compid=' + id
    );
  }
  getresumefile(id) {
    return this.http.get(this.url + '/api/UploadDocument/Getresume/' + id);

  }
  getskillgroupDetails() {
    return this.http.get(this.url + '/api/JobSkillGroups/GetallSkillGroups');
  }

  getallskils() {
    return this.http.get(this.url + '/api/JobSkillGroups/GetSkillist');
  }
}
