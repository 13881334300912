import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/shared/UserService/user.service';
import { ChatDetail } from 'app/shared/VIewmodels/ChatDetail';

@Component({
  selector: 'app-recordedphysicalvideos',
  templateUrl: './recordedphysicalvideos.component.html',
  styleUrls: ['./recordedphysicalvideos.component.scss']
})
export class RecordedphysicalvideosComponent implements OnInit {
  jobid;
  alldata : any=[];
  userid;
  videoid: any;
  alldatas: any;
    constructor(private router:Router,private service:UserService,private route:ActivatedRoute,private modalService:NgbModal) {
      this.route.params.subscribe((data:any)=>{
        this.jobid = data['id'];
        console.log(this.jobid);
      });
      this.userid = localStorage.getItem("userId");
  
    }
  
    ngOnInit(): void {
  this.getphysicalvideoreport();
    }

    ngAfterViewInit(){
      this.video= this.video.nativeElement;
  
    }

  getphysicalvideoreport(){
    this.service.getpysicalvideoreport(this.userid).subscribe((data:any)=>{
      this.alldatas = data;
      console.log(this.alldata);
    });
  }

  openvideo;
  @ViewChild('video') video: any;
  viewvideo(videos,openmodal){
  
  
  this.openvideo = videos;
  
    this.modalService.open(openmodal).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );}
  friendid: any;
  touser;
  closeResult;
  m;
  addFriend(id, addfriendcontent) {
    this.touser = id;
    // this.OnSelectUser(id);
    this.modalService.open(addfriendcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  messa: any;
  SaveUserChat() {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: null,
      ChatTo: null,
      ChatToName: null,
      ChatMessage: this.messa,
      IsEnabled: true,
      ChatFromUId: null,
      ChatToUserUId: this.touser,
    };
    this.service.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Message Sent Successfully");
      }
    });
  }
  viewUserProfile(id,name,email){
    localStorage.profileUserName = name;
    localStorage.profileEmail = email;
    this.router.navigate(['/profileview',id]);
  }

  deletevideo(data: { id: any; }) {
    this.videoid = data.id;
    this.service.deleterecordedvideo(this.videoid).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Video Deleted Succesfully");
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }
  }
  