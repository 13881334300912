<br /><br /><br /><br />
<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-8">
        <div class="img" style="
        background-image: linear-gradient(
            150deg,
            rgba(63, 174, 255, 0.3) 15%,
            rgba(63, 174, 255, 0.3) 70%,
            rgba(63, 174, 255, 0.3) 94%
          ),
          url(../assets/img/blockc.jpg);
        height: 350px;
        background-size: cover;
      "></div>

        <div class="card social-prof">
            <div class="card-body">
                <div class="wrapper" style="padding-left: 10%;">
                    <img [src]="image" alt="" class="user-profile" style="
              width: 150px;
              height: 150px;
              border-radius: 50%;
              margin-bottom: 20px;
              border: 5px solid #fff;
            " />
                    <div class="col-md-12 row">
                        <div class="col-md-9">
                            <h3>
                                <div class="top-section-profile-name">{{ username }}</div>
                            </h3>
                            <br />
                            <p>
                                {{ email }}
                            </p>
                            <p>
                                {{ role?.role }}
                            </p>
                            <p>{{ bio }}</p>
                        </div>
                        <div class="col-md-3">
                            <button type="button" class="btn btn-primary"
                                (click)="addFriend( content3)">Contact</button>
                        </div>

                        <!-- ----------------- -->
                    </div>
                </div>

                <div class="col-md-12 row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6">
                        <hr class="new4" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ------------------------------------------ -->

    <!-- ----------------------------------chat----------------------------- -->

</div>
<!-- ------------------------------------education-------------------------------- -->
<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-8">
        <div class="card social-timeline-card">
            <div class="card-body">
                <h4 class="card-title">
                    Qualification
                </h4>
                <br />
                <!-- <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2">
              <img
                class="rounded-circle"
                width="45"
                src="/assets/img/college.png"
                alt=""
              />
            </div>
           <div class="ml-2">
              <div class="h5 m-0 text-black">
                {{ education.highestQualification }}
              </div>
            </div> -->
                <!-- </div>
        </div>  -->

                <div class="bottom-section-details" style="z-index: 2;">
                    <div class="bottom-section-right">
                        <div *ngFor="let a of Edudet">
                            <div class="col-md-12 row" style="margin-top: -20px;">
                                <div class="mr-2">
                                    <img class="rounded-circle" width="45" src="/assets/img/college.png" alt="" />
                                </div>
                                <div class="col-md-6" style="padding-left: 8px;">
                                    <span style="font-size: 15px;"><b>{{ a.college }}</b></span><br />
                                    <span style="font-size: 15px;">{{ a.course }}</span><br />
                                    <span>{{ a.date }}</span>
                                </div>
                                <div class="col-md-3">&nbsp;</div>
                                <div class="col-md-1">
                                    <!-- <div>
                    <img
                      data-toggle="modal"
                      data-target="#Addskill"
                      (click)="editpp(a)"
                      src="/assets/img/edit.png"
                      width="30vw"
                      height="30vw"
                      style="z-index: 10;"
                    />
                  </div> -->
                                </div>
                                <div class="col-md-1">
                                    <!-- <div>
                    <img
                      src="/assets/img/delt.png"
                      (click)="deleteedu(a)"
                      width="30vw"
                      height="30vw"
                      style="z-index: 10;"
                    />
                  </div> -->
                                </div>
                            </div>
                            <br /><br />
                        </div>
                        <span style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
              ">
                            <!-- <button
                data-toggle="modal"
                data-target="#exampleModal1"
                style="
                  border-radius: 10px;
                  background-color: blue;
                  color: white;
                  padding: 5px 10px 5px 10px;
                "
                (click)="addedct()"
              >
                &nbsp;&nbsp;Add
              </button> -->
                        </span>
                    </div>
                    <br /><br /><br /><br />
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card social-timeline-card">
          <div class="card-body" style="height: auto">
            <br /><br />
            <div style="color: #ff5722">Preferred Role</div>
            <div class="bottom-section-right">{{ role?.role }}</div>
            <br />
            <div class="bottom-section-details">
              <div class="bottom-section-right" style="color: #ff5722">
                Preferred Salary Range
              </div>
              <div class="bottom-section-right">{{ salaryrange?.salary }}</div>
            </div>
    
            <br />
            <div class="bottom-section-details">
              <div class="bottom-section-right" style="color: #ff5722">
                Preferred Industry
              </div>
              <div class="bottom-section-right">{{ industry?.indrustType }}</div>
            </div>
            <br />
            <div class="bottom-section-details">
              <div class="bottom-section-right" style="color: #ff5722">
                Preferred Growth
              </div>
              <div class="bottom-section-right">{{ growth?.growth1 }}</div>
            </div>
            <br />
          </div>
        </div>
      </div>
</div>
<!-- -----------------------------------------Skills-------------------------------------------------------------------- -->

<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-8">
        <div class="card social-timeline-card">
            <div class="card-body">
                <h4 class="card-title">
                    Skills
                </h4>
                <br />
                <div class="bottom-section-details" style="z-index: 2;">
                    <div class="bottom-section-right">
                        <div *ngFor="let a of Skilldet">
                            <div class="col-md-12 row" style="margin-top: -20px;">
                                <div class="col-md-6" style="padding-left: 8px;">
                                    <span style="width: max-content;"> {{ a.skill1 }}</span>
                                </div>
                                <div class="col-md-4">&nbsp;</div>
                                <div class="col-md-1">
                                    <!-- <div>
                    <img
                      data-toggle="modal"
                      data-target="#Addskill"
                      (click)="editskl(a)"
                      src="/assets/img/edit.png"
                      width="30vw"
                      height="30vw"
                      style="z-index: 10;"
                    />
                  </div> -->
                                </div>
                                <div class="col-md-1">
                                    <!-- <div>
                    <img
                      src="/assets/img/delt.png"
                      (click)="deleteskl(a)"
                      width="30vw"
                      height="30vw"
                      style="z-index: 10;"
                    />
                  </div> -->
                                </div>
                            </div>
                            <br /><br />
                        </div>
                        <span style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
              ">
                            <!-- <button
                data-toggle="modal"
                data-target="#Addskill"
                style="
                  border-radius: 10px;
                  background-color: blue;
                  color: white;
                  padding: 5px 10px 5px 10px;
                "
                (click)="addskl()"
              >
                &nbsp;&nbsp;Add
              </button> -->
                        </span>
                    </div>
                    <br /><br /><br /><br />
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card social-timeline-card">
            <div class="card-body" style="height: auto">

                <h3 style="    text-align: center;">Resume </h3>

                <br><br>

                <div style="    text-align: center;">
                    <button data-toggle="modal" data-target="#resume1" (click)="GetResume()" style="
                                                                        border-radius: 10px;
                                                                        background-color: #ff5722;
                                                                        color: white;
                                                                        padding: 5px 30px 5px 10px;
                                                                        z-index: 10;
                                                                        width: 120px;
                                                                      ">
                        &nbsp;&nbsp;&nbsp;View
                    </button>
                </div><br /><br />

            </div>
        </div>

    </div>
</div>

<!-- -----------------------------------------Experience-------------------------------------------------------------------- -->


<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-8">
        <div class="card social-timeline-card">
            <div class="card-body">
                <h4 class="card-title" style="padding-left: 10px">Experience</h4>
                <br />
                <div class="bottom-section-details" style="z-index: 2">
                    <div class="bottom-section-right">
                        <div *ngFor="let b of Edudet1">
                            <div class="col-md-12 row" style="margin-top: -20px">
                                <div class="mr-2">
                                    <img class="rounded-circle" width="45" src="./assets/img/org.png" alt="" />
                                </div>
                                <div class="col-md-6" style="padding-left: 10px">
                                    <p style="font-size: 20px">Organization:<span
                                            style="font-size: 20px">&nbsp;{{ b.oragnisation }}</span></p>

                                    <p style="font-size: 20px">Designation: <span
                                            style="font-size: 18px">&nbsp;{{ b.designation }}</span></p>
                                    <p style="font-size: 20px">Current annual salary:<span
                                            style="font-size: 18px">&nbsp;{{ b?.salary }}</span></p>

                                    <p style="font-size: 20px">Present city: <span
                                            style="font-size: 18px">&nbsp;{{ b.city}}</span></p>

                                    <p style="font-size: 20px">Notice period: <span
                                            style="font-size: 18px">&nbsp;{{ b.period }}&nbsp;Days</span></p>

                                    <p style="font-size: 20px">Date: <span
                                            style="font-size: 18px">&nbsp;{{ b.date }}</span></p>

                                </div>

                            </div>
                            <br /><br />
                        </div>
                        <span style="
                  display: flex;
                  justify-content: flex-end;
                  padding-right: 20px;
                ">


                        </span>
                    </div>
                    <br /><br /><br /><br />
                </div>
            </div>
        </div>
    </div>
</div>



<ng-template #content3 let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h2 style="
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    color: mediumslateblue;
  ">
            Continue Chat here
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="col-md-12">
                <div class="col-md-12">
                    <div class="form-horizontal">
                        <div class="">
                            <h2 style="text-align: center;"></h2>
                        </div>
                        <div class="col-md-12" style="
            margin-bottom: 50px;
            max-width: 100%;
            background-color: white;
          ">
                            <div class="form-group">
                                <label class="control-label">chat message:</label>
                                <input type="text" id="messa" name="messa" class="form-control"
                                    placeholder="Enter the message" [(ngModel)]="messa" />
                            </div>
                            <div class="form-group">
                                <input type="Submit" class="btn btn-primary btn-round"
                                    style="background-color: grey; color: white;" (click)="SaveUserChat()"
                                    (click)="d('Cross click')" value="Send" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- ---resume popup -->

<div class="modal fade" id="resume1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="
  width: 758px;
  max-width: 750px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Resume View</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container fileUploadWrapper">
                    <div class="col-md-12">
                        <ul style="list-style: none">
                            <div class="modal-body">

                                <div *ngIf="pdfUrl">
                                <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 95vh; height: 70vh;">
                                </ngx-doc-viewer>
                            </div>
                            <div *ngIf="!pdfUrl">
                            <h2> Resume is not Uploaded</h2>   
                            </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal"
                    style="cursor: pointer">
                    Cancel
                </button>


            </div>
        </div>
    </div>
</div>