import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/UserService/user.service';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { JobzoneShare } from 'app/shared/VIewmodels/sharedetails';

@Component({
  selector: 'app-trainingview',
  templateUrl: './trainingview.component.html',
  styleUrls: ['./trainingview.component.scss']
})
export class TrainingviewComponent implements OnInit {

  isLoggedIn: boolean;
  userid: string;
  accountid: string;
  jonlist: any;

  CompanyName:any;
  constructor(private userser:UserService,private registrationService: RegistrationService,) {
    if (localStorage.loadingpage == "true") {
      localStorage.loadingpage = "false";
      location.reload();
    }
    window.scrollTo(0,0);

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem("userId");
      this.accountid = localStorage.getItem("uid")
    }
   }


   ngOnInit(): void {
  
   setTimeout(() => {
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }
   }, 5000);
   
    this.GetJobMatchList();
    this.getcertificates();
  }
  Certi:any;
  getcertificates()
{
  var uid = localStorage.getItem("userId");
  this.userser.getCerti(uid).subscribe((respose: any) => {

this.Certi = respose;
console.log(respose);

  });
}
  GetJobMatchList() {
    this.registrationService.getTrainingforins(this.accountid).subscribe((response: any) => {
      if(response == 1){
        this.jonlist = null;
      }
      else{
      this.jonlist = response;
      }
      console.log(this.jonlist);
    });
  }

  selectedDetails(job){
    this.CompanyName = job;
    console.log(this.CompanyName);
  }
 

  shareDetails(){
    const inputr:JobzoneShare={
      Id:null,
      ApplicantId:this.userid,
      CompanyId:this.CompanyName.companyId,
      JobBlkId:this.CompanyName.blockId
    }
    this.userser.sharedetails(inputr).subscribe((data:any)=>{
      if(data==1){
        alert("Shared your details successfully");
      }
    })
  }
}