import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Friend } from '../VIewmodels/Friend';
import { TransactionVM } from '../VIewmodels/TransactionVM';

@Injectable({
  providedIn: 'root'
})
export class FriendsServiceService {
  readonly url = 'https://blockchainmatrimony.com/jobzoneapi/api';
  ipAddress: any;
  constructor(private http: HttpClient) { }

  SaveFrienddetails(data: Friend) {
    return this.http.post(this.url + "/FriendLists/PostFriendList", data);
  }

  GetFriendRequests(id){
    return this.http.get(this.url + '/FriendLists/GetFriendRequestList/' + id);

  }
  GetRecievedFriendRequest(id){
    return this.http.get(this.url + '/FriendLists/Getrecievedfriendrequrest/' + id);

  }

  GetSentFriendRequest(id){
    return this.http.get(this.url + '/FriendLists/Getsentfriendrequrest/' + id);

  }

  AcceptFriendequest(data){
    return this.http.post(this.url +"/FriendLists/AcceptRequest",data);

  } 
  AuditorTransactionDetails(data){
  
    let uid = localStorage.getItem("userId");
    let roleid = localStorage.getItem("role");
    let GUID = localStorage.getItem("GUID");
    let MailId=localStorage.getItem("MailId");
    let UserFullName=localStorage.getItem("UserFullName");
    let EnterPriseId  =localStorage.getItem("EnterPriseId");
    
      var one:TransactionVM={
        Id:0,
        CustomerId:uid,
        EnterpriseId:EnterPriseId,
        GuId:GUID,
        UserName:UserFullName,
        MailId:MailId,
        ContentName:"",
        BrowserName: "Chrome",
        GeoLocation:"",
        IPAddress: this.ipAddress,
        TransactionName:data.TransactionName,
        TransactionType:data.TransactionType,
        TransactionDesc:data.TransactionDesc,
        TransactionDate:null,
       
            
     }
      //  this.saveauditordetails(one).subscribe(
      //    (response:any)=>{
      //     // var one=response;
          
      //    }
      //  )
    }

}
