<app-nav-bar></app-nav-bar>
<br /><br /> <br /> <br /> <br />
<div class="col-md-12 row">
    <div class="col-md-2">
        <app-mailcomposee></app-mailcomposee>
    </div>
    <div class="col-md-10">
        <aside class="lg-side">
            <div class="inbox-head">
                <div class="col-md-8">
                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Here " />
                    <br />
                </div>

            </div>
            <div class="inbox-body">
                <table class="table table-hover">
                    <tbody style="background: #ecf0f1">
                        <tr class="unread" id="{{ ret.Id }}" style="cursor: pointer" *ngFor="let ret of sentList;index as i">

                            <td >
                                <input type="checkbox" style="height: 16px;" class="mail-checkbox" (click)="toggleSelect(ret)" >
                            </td>
                            <td class="view-message dont-show" (click)="ReadMessage(ret)">
                                {{ ret.toName }}
                            </td>

                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.subject }}
                            </td>
                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.date }}
                            </td>
                            <td (click)="singlearchive(ret)">
                                <img src="assets/img/download.png" alt="" width="18px" />
                            </td>
                            <td (click)="singleDelete(ret)">
                                <i class="fa fa-trash-o fa-lg"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br /><br />
             
                <br /><br />
                <br /><br />
            </div>
        </aside>
    </div>
</div>