import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'angularx-social-login';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MessageService } from 'app/shared/MessageService';
import { UserService } from 'app/shared/UserService/user.service';
import { Signup } from 'app/shared/VIewmodels/Signup';
import { NgxSpinnerService } from 'ngx-spinner';
var authorizationData;

@Component({
  selector: 'app-gotojobposting',
  templateUrl: './gotojobposting.component.html',
  styleUrls: ['./gotojobposting.component.scss'],
})
export class GotojobpostingComponent implements OnInit {
  email: any;
  password: any;
  isLoginError: any;
  rdurl: any;
  returnurl;
  search;
  accid;
  curid;
  statusurl;
  constructor(
    private userservice: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private userService: UserService,
    private http: HttpClient,
    private registrationService: RegistrationService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe((id: any) => {
      this.returnurl = id.redirect;

      if (id?.search) {
        this.search = id.search;
      }
      if (id?.curid) {
        this.curid = id?.curid;
      }
      if (id?.accid) {
        this.accid = id?.accid;
      }
      if (id?.status) {
        this.statusurl = id?.status;
      }
      if (localStorage.uid === id?.curid) {
        this.accid = id?.accid;
        console.log(this.accid);
        this.getup(localStorage.userId);
      } else if (id?.curid) {
        this.accid = id?.accid;
      } else {
        this.getup(localStorage.userId);
      }
    });
  }
  getup(id) {
    const usid: Signup = {
      Id: null,
      Branch: null,
      BranchName: null,
      ConfirmPassword: null,
      Email: null,
      FirstName: null,
      Institution: null,
      LastName: null,
      Password: null,
      PhoneNumber: null,
      PlanId: id,
      Question: null,
      SubRoleForApplicant: null,
      USN: null,
      UserName: null,
    };
    this.userService.getUserpassword(usid).subscribe((data: any) => {
      this.email = data.email;
      this.password = data.confirmPassword;
      this.OnSubmit();
    });
  }
  ngOnInit(): void {
    var adata = localStorage.getItem('authdata');
    authorizationData = JSON.parse(adata);

    let url = window.location.href;
  }

  role: any;

  OnSubmit() {
    localStorage.setItem('loadingpage', 'true');
    // this.loading = true;
    if (this.email == null || this.email == '') {
      alert('Please enter Your Email');
    } else if (this.password == null || this.password == '') {
      alert('Please enter Your password');
    }
    // else if (this.email == "admin" && this.password == "Srichid@123") {
    //   localStorage.loadingpage = "true";
    //   localStorage.admin = "admin";
    //   this.router.navigate(["/newhome"]);
    // }
    else {
      this.spinner.show();
      if (this.email == 'admin' && this.password == 'Srichid@123') {
        localStorage.loadingpage = 'true';
        localStorage.admin = 'admin';
        //this.router.navigate(["/newhome"]);
      }
      this.userService.userAuthentication(this.email, this.password).subscribe(
        (data: any) => {
          var firstName = data.userName;
          var movies = [
            data.access_token,
            this.email,
            data.roleName,
            data.refresh_token,
            true,
            data.userUid,
            firstName[0],
            data.username,
            data.nameofRole,
            data.departmentname,
            data.userId,
            data.uid,
            data.area,
            data.IsMember,
          ];
          console.log(data);
          localStorage.setItem('IsLoggedIn', 'true');
          localStorage.setItem('userToken', data.access_token);
          localStorage.setItem('authorizationData', data);
          localStorage.setItem('Token_type', data.token_type);
          localStorage.setItem('UserName', data.fullName);
          //localStorage.setItem("this.password",password);
          localStorage.setItem('role', data.roleName);
          localStorage.setItem('userId', data.userId);
          localStorage.setItem('uid', data.uid);
          localStorage.setItem('MailId', data.mailId);
          //localStorage.setItem("area",data.area);
          //localStorage.setItem("isMember",data.IsMember);
          //localStorage.setItem("gst",data.gst);
          localStorage.setItem('authdata', JSON.stringify(movies));
          localStorage.storage = data.storage;

          // send message to subscribers via observable subject
          this.messageService.sendMessage('true');
          this.messageService.sendName(data.userName);
          if (data.roleName == '2') {
            localStorage.company = 'company';
            if (this.returnurl) {
              this.spinner.hide();
              if (
                this.returnurl != 'appjobseek' &&
                this.returnurl != 'alljoblist'
              ) {
                if (
                  this.returnurl == 'companyprofile' &&
                  this.statusurl == 'edit'
                ) {
                  var a = document.getElementById('clicknowcompanycorrect');
                  if (a) {
                    a.click();
                    return;
                  }
                }
                this.router.navigate(['/' + this.returnurl]);
                return;
              } else {
                this.router.navigate(['/']);
                return;
              }
            }
          } else if (data.roleName == '3') {
            localStorage.seeker = 'seeker';
          }
          if (this.returnurl) {
            this.spinner.hide();

            if (data.roleName == '3') {
              if (
                this.returnurl == 'jobposting' ||
                this.returnurl == 'companyprofile' ||
                this.returnurl == 'alljoblist'
              ) {
                this.router.navigate(['/']);
                return;
              }
              //console.log(this.returnurl);
              if (this.search) {
                // let navigationExtras: NavigationExtras = {
                //   queryParams: { 'search': this.search }
                // };
                var node = document.getElementById('clicknow');
                if (node) {
                  node.click();
                }
                // this.router.navigate(['/'+this.returnurl,{ queryParams: { search: this.search } }])
                return;
              }
              this.router.navigate(['/' + this.returnurl]);
              return;
            } else if (
              data.roleName == '2' &&
              this.returnurl == 'companyprofile' &&
              (this.statusurl != undefined || this.statusurl != '') &&
              this.returnurl != 'alljoblist'
            ) {
              if (
                this.returnurl == 'companyprofile' &&
                this.statusurl == 'edit'
              ) {
                var a = document.getElementById('clicknowcompanycorrect');
                if (a) {
                  a.click();
                  return;
                }
              }
              this.router.navigate(['/' + this.returnurl]);
              return;
            } else if (data.roleName == '4') {
            } else {
              if (this.curid) {
                var node = document.getElementById('clicknowcompany');
                if (node) {
                  node.click();
                  return;
                }
              }
              if (this.returnurl == 'studentchat') {
                this.router.navigate(['/']);
                return;
              } else {
                if (this.returnurl == 'alljoblist') {
                  var node = document.getElementById('clickalljoblist');
                  if (node) {
                    node.click();
                    return;
                  }
                } else {
                  this.router.navigate(['/']);
                }
              }
            }
          }
          //var landingUrl = "http://" + window.location.host + "/home";
          // var landingUrl = "http://" + window.location.host + "/newhome";
          // window.location.href = landingUrl;
          if (this.rdurl != null) {
            if (this.rdurl == 'I') {
              //   this.router.navigate(['/jobregforins']);
              this.router.navigate(['/internshippost']);
            } else if (this.rdurl == 'J') {
              this.router.navigate(['/jobregforins']);
            } else {
              this.router.navigate(['/trainingpost']);
            }
          } else {
            this.role = data.roleName;
            this.userService.afterlogin(data.uid).subscribe((response: any) => {
              if (response == 'registration') {
                this.router.navigate(['/companyRegistration']);
              } else if (response == 'payu') {
                var uidc = localStorage.getItem('uid');

                this.userService
                  .getcompanyprofile(uidc)
                  .subscribe((data: any) => {
                    console.log(data);
                    var uid = localStorage.getItem('userId');

                    this.router.navigate([
                      '/payuplans/' + uid + ',' + data[0].numberofEmployees,
                    ]);
                  });
              } else if (response == 'newhome') {
                this.router.navigate(['/newhome']);
              } else if (response == 'newprofile') {
                this.router.navigate(['/newprofile']);
              } else if (response == 'profile') {
                this.router.navigate(['/appprofile']);
              } else if (response == 'dashboard') {
                this.router.navigate(['/report']);
              } else {
                this.router.navigate(['/eregistration']);
              }
            });
            //  if(this.role==2)
            //  {
            //    //var landingUrl = "http://" + window.location.host + "/home";
            //    // var landingUrl = "http://" + window.location.host + "/newhome";
            //    // window.location.href = landingUrl;

            //    this.router.navigate(['/newhome']);
            //    //this.getmenu(this.role);
            //  }else if(this.role==3){
            //    // var landingUrl = "http://" + window.location.host + "/newhome";
            //    // //var landingUrl = "http://" + window.location.host + "/home";
            //    // window.location.href = landingUrl;
            //    this.router.navigate(['/appprofile']);
            //  }
            //  else{
            //    this.router.navigate(['/report']);
            //  }
          }
          this.spinner.hide();

          // location.reload();
        },
        (err: HttpErrorResponse) => {
          this.isLoginError = true;
          alert('Invalid credentials');
          this.spinner.hide();
        }
      );
    }
  }
  // signInWithGoogle(): void {
  //   this.authService
  //     .signIn(GoogleLoginProvider.PROVIDER_ID)
  //     .then((socialusers) => {
  //       console.log(socialusers);
  //       let name = socialusers.name.split(" ");
  //       let fname = name[0];
  //       let lname = name[1];
  //       let email = socialusers.email;
  //       let actk = socialusers.authToken;
  //       this.savefromsocio(fname, lname, email, actk);

  //       //this.router.navigate(['/home']);

  //       //this.Savesresponse(socialusers);
  //     });
  // }

  // signInWithFB(): void {
  //   this.authService
  //     .signIn(FacebookLoginProvider.PROVIDER_ID)
  //     .then((socialusers) => {
  //       console.log(socialusers);
  //       let name = socialusers.name.split(" ");
  //       let fname = name[0];
  //       let lname = name[1];
  //       let email = socialusers.email;
  //       let actk = socialusers.authToken;
  //       this.savefromsocio(fname, lname, email, actk);

  //       //this.router.navigate(['/home']);

  //       //this.Savesresponse(socialusers);
  //     });
  // }
  // savefromsocio(fname, lname, email, actk) {
  //   const inputRequest: OtpViewModel = {
  //     Id: 0,
  //     Email: email,
  //     Otp: "1",
  //     FirstName: fname,
  //     LastName: lname,
  //     UserName: null,
  //     PhoneNumber: null,
  //     Password: null,
  //     ConfirmPassword: null,
  //     ProvinceId: null,
  //     PlanId: null,
  //     AccessToken: actk,
  //   };
  //   this.userService.LoginVerification(inputRequest).subscribe((data: any) => {
  //     if (data != "1") {
  //       var firstName = data.userName;
  //       var movies = [
  //         data.access_token,
  //         this.email,
  //         data.roleName,
  //         data.refresh_token,
  //         true,
  //         data.userUid,
  //         firstName[0],
  //         data.username,
  //         data.nameofRole,
  //         data.departmentname,
  //         data.userId,
  //         data.uid,
  //         data.area,
  //         data.IsMember,
  //       ];

  //       localStorage.setItem("IsLoggedIn", "true");
  //       localStorage.setItem("userToken", data.access_token);
  //       localStorage.setItem("authorizationData", data);
  //       localStorage.setItem("Token_type", data.token_type);
  //       localStorage.setItem("UserName", data.fullName);
  //       //localStorage.setItem("this.password",password);
  //       localStorage.setItem("role", data.roleName);
  //       localStorage.setItem("userId", data.userId);
  //       localStorage.setItem("uid", data.uid);
  //       localStorage.setItem("MailId", data.mailId);
  //       //localStorage.setItem("area",data.area);
  //       //localStorage.setItem("isMember",data.IsMember);
  //       //localStorage.setItem("gst",data.gst);
  //       localStorage.setItem("authdata", JSON.stringify(movies));
  //       localStorage.storage = data.storage;

  //       // send message to subscribers via observable subject
  //       this.messageService.sendMessage("true");
  //       this.messageService.sendName(data.userName);
  //       if (data.roleName == "2") {
  //         localStorage.company = "company";
  //       } else if (data.roleName == "3") {
  //         localStorage.seeker = "seeker";
  //       }
  //       //var landingUrl = "http://" + window.location.host + "/home";
  //       // var landingUrl = "http://" + window.location.host + "/newhome";
  //       // window.location.href = landingUrl;
  //       this.router.navigate(["/newhome"]);

  //       this.role = data.roleName;
  //       if (this.role == 2) {
  //         //var landingUrl = "http://" + window.location.host + "/home";
  //         // var landingUrl = "http://" + window.location.host + "/newhome";
  //         // window.location.href = landingUrl;
  //         this.router.navigate(["/newhome"]);
  //         //this.getmenu(this.role);
  //       } else {
  //         // var landingUrl = "http://" + window.location.host + "/newhome";
  //         // //var landingUrl = "http://" + window.location.host + "/home";
  //         // window.location.href = landingUrl;
  //         this.router.navigate(["/newhome"]);
  //       }
  //       this.spinner.hide();

  //       // location.reload();
  //     } else {
  //       this.router.navigate(["/newregister"]);
  //     }
  //   });
  // }
  // signInWithLI(): void {
  //   window.open(
  //     "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81fsghpn98dxwx&redirect_uri=https://blockchainsafe.in/newlogin&state=gfvtfvgfv&scope=r_liteprofile%20r_emailaddress%20w_member_social",
  //     "_self"
  //   );
  // }
}
