import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { UserService } from 'app/shared/UserService/user.service';
import { education } from 'app/shared/VIewmodels/Education';
import { skills } from 'app/shared/VIewmodels/Skills';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-profilepagenew',
  templateUrl: './profilepagenew.component.html',
  styleUrls: ['./profilepagenew.component.scss']
})
export class ProfilepagenewComponent implements OnInit {
  userDetails: any;
  username: any;
  email:any;
  role:any;
  salaryrange:any;
  perks:any;
  industry:any;
  growth:any;
  loc1:any;
  loc2:any;
  loc3:any;
  constructor(private userService: RegistrationService,private userser:UserService,private httpService:HttpClient) {
    this.username = localStorage.getItem("UserName");
    this.email = localStorage.getItem("MailId");
    window.scrollTo(0,0);

  }

  ngOnInit(): void {



    var id = localStorage.getItem("uid");
    this.userService.getprofile(id).subscribe((data: any) => {
      this.userDetails = data;
      this.userService.getrole(this.userDetails.role).subscribe((data:any)=>{
        this.role = data;
      })
      this.userService.getsalary(this.userDetails.salarymin).subscribe((data:any)=>{
        this.salaryrange = data;
      })
      this.userService.getperks(this.userDetails.perks).subscribe((data:any)=>{
        this.perks = data;
      })
      this.userService.getindustry(this.userDetails.industry).subscribe((data:any)=>{
        this.industry = data;
      })
      this.userService.getgrowth(this.userDetails.growth).subscribe((data:any)=>{
        this.growth = data;
      })
      this.userService.geteducation(this.userDetails.salarymax).subscribe((data:any)=>{
        this.education = data;
      })
      console.log(this.userDetails);
    })
  this.getUserImage();
    this.getEdu();
    this.getSkills();
    this.getcertificates();
    // this.getmarks();
 
  }
  education:any;
  getmarks()
{
  var uid = localStorage.getItem("userId");
  this.userser.getMarks(uid).subscribe((respose: any) => {

this.marks = respose;
console.log(respose);

  });
}
  
imgp=false;
usrimage:any;
getcertificates()
{
  var uid = localStorage.getItem("userId");
  this.userser.getCerti(uid).subscribe((respose: any) => {

this.Certi = respose;
console.log("Hello");
console.log(respose)

  });
}
  getUserImage()
  {
    var uid = localStorage.getItem("userId");
    this.userser.getUserImage(uid).subscribe((respose: any) => {
if(respose!= "0")
{
this.imgp = true;
this.usrimage = respose;
}
else
{
  this.imgp = false;
}
    });
  }

  edrad=false;
  Cname:any;
  Course:any;
  date:any;
  addedct()
  {
    this.edrad = true;
    this.Cname = "";
    this.Course = "";
     this.date = "";
  }
Edudet:any;

  getEdu()
  {
    var uid = localStorage.getItem("userId");
    this.userser.getEduDet(uid).subscribe((respose: any) => {

this.Edudet = respose;
console.log(respose);

    });
  }
Skilldet:any;
  getSkills()
  {
    var uid = localStorage.getItem("userId");
    this.userser.getSkillDet(uid).subscribe((respose: any) => {

this.Skilldet = respose;
console.log(respose);


    });
  }
  requestingfor:any;
edid:any;
  editpp(a)
{
  this.edrad = false;
  this.Cname = a.college;
 this.Course = a.course;
  this.date = a.date;
  this.edid = a.id;
}


deleteedu(data)
{
  this.userser.deleteedu(data.id).subscribe((respose: any) => {
if(respose= "1")
{
alert("deleted successfully");
location.reload();
}
else
{
  alert("Something went wrong. Please try again..!! ");
  location.reload();
}
  });
}

deleteskl(data)
{
  this.userser.deleteskl(data.id).subscribe((respose: any) => {
if(respose= "1")
{
alert("deleted successfully");
location.reload();
}
else
{
  alert("Something went wrong. Please try again..!! ");
  location.reload();
}
  });
}
accountid = localStorage.getItem("uid");
AddEdu()
{
  
  var uid = localStorage.getItem("userId");
  const inp: education =
  {
Id:0,
College:this.Cname,
Course:this.Course,
date:this.date,
UserId:Number(uid),
UID : this.accountid
  };
  
  this.userser.saveoreditedu(inp).subscribe((respose: any) => {
   if(respose == "1")
   {
     alert("Education Details Added Successfully");
     location.reload();
   }
   else
   {
    alert("Something went wrong. Please try again..!! ");
    location.reload();
   }
      });
}

updatedu()
{


  var uid = localStorage.getItem("userId");
  const inp:education=
  {
Id:this.edid,
College:this.Cname,
Course:this.Course,
date:this.date,
UserId:Number(uid),
UID:this.accountid
  };
  
  this.userser.saveoreditedu(inp).subscribe((respose: any) => {
   if(respose == "1")
   {
     alert("Education Details Updated Successfully");
     location.reload();
   }
   else
   {
    alert("Something went wrong. Please try again..!! ");
    location.reload();
   }
      });
}
Skill :any;
addskl()
{
  this.edrad = true;
   this.Skill = "";
}
skid = null;
editskl(a)
{
  this.edrad = false;
  this.Skill = a.skill1;
  this.skid = a.id;
}



Addskill()
{
  var uid = localStorage.getItem("userId");
  const inp:skills=
  {
Id:0,
UID:this.accountid,
UserId:Number(uid),
Skill1:this.Skill
  };
  
  this.userser.saveoreditskill(inp).subscribe((respose: any) => {
   if(respose == "1")
   {
     alert("Skill Details Added Successfully");
     location.reload();
   }
   else
   {
    alert("Something went wrong. Please try again..!! ");
    location.reload();
   }
      });
}

updateskill()
{
  var uid = localStorage.getItem("userId");
  const inp:skills=
  {
Id:this.skid,
UID:this.accountid,
UserId:Number(uid),
Skill1:this.Skill
  };
  
  this.userser.saveoreditskill(inp).subscribe((respose: any) => {
   if(respose == "1")
   {
     alert("Skill Details Updated Successfully");
     location.reload();
   }
   else
   {
    alert("Something went wrong. Please try again..!! ");
    location.reload();
   }
      });
}



deletedoc(data)
{
  this.userser.deletedoc(data.blockId).subscribe((respose: any) => {
if(respose= "1")
{
alert("deleted successfully");
location.reload();
}
else
{
  alert("Something went wrong. Please try again..!! ");
  location.reload();
}
  });
}




fileToUploaddoc: FileList;
Certi: any;
marks: any;

ubio: any;
bio: any;
base64: string;
uploaddocs(flname)
{
  var uid = localStorage.getItem("userId");
  
  const frmData = new FormData();
  for(let i=0;i<this.fileToUploaddoc.length;i++)
  {
    frmData.append("fileUpload", this.fileToUploaddoc[i]);
  }
 
  frmData.append("userId", uid);

  frmData.append("foldername", flname);


  



  this.httpService.post('https://blockchainmatrimony.com/jobzoneapi/api/Account/UploadCertiandMkc/', frmData).subscribe(
      data => {
        // SHOW A MESSAGE RECEIVED FROM THE WEB API.
      
        if (data == "1") {
          //document.getElementById("cancelUpload").click();
      
          alert("File Uploaded Successfully");
          location.reload();
         // this.();

        }
        else {
          alert("Something went wrong. Please try again..!! ");
          location.reload();
      
        }
      },
      (err: HttpErrorResponse) => {
        alert("Something went wrong. Please try again..!! ")
        console.log(err.message);    // Show error, if any.
      });



}
fileToUpload
handleFileInput(files: FileList) {
  this.fileToUpload = files.item(0);
}

handleFileInput1(files: FileList) {
this.fileToUploaddoc = files;
}
}
