<br><br>





<!-- ----profiles------------------ -->

<!-- Section: Testimonials v.3 -->
<div class="container">
    <section class="team-section text-center my-5">

        <!-- Section heading -->
        <h2 class="h1-responsive font-weight-bold my-5">Management Team</h2>
        <!-- Section description -->

        <!-- <p class="dark-grey-text w-responsive mx-auto mb-5">The Value Creator through Value Addition</p> -->
        <br>
        <!--Grid row-->
        <div class="row text-center">

            <!--Grid column-->
            <div class="col-md-4 mb-md-0 mb-5">

                <div class="testimonial">
                    <!--Avatar-->
                    <div class="avatar mx-auto">
                        <img src="assets/img/sogala.png" style="    width: 31%;" class="rounded-circle z-depth-1 img-fluid">
                    </div>
                    <!--Content-->
                    <h4 class="font-weight-bold dark-grey-text mt-4">Dr Satchidananda S Sogala
                    </h4>
                    <h6 class="font-weight-bold blue-text my-3">Founder & CEO<br><br></h6>
                    <p class="font-weight-normal dark-grey-text">
                        Founder & CEO, Srichid Technologies Pvt Ltd.<br>Founder & CEO, Srichid Blockchain Services<br>Former Regional Director, Reserve Bank of India<br>Former Head, IBM Risk Consulting India<br>Former Professor & Research Director – CBIT
                        –IIIT, Bangalore</p>
                    <!--Review-->
                    <!-- <div class="orange-text">
                        <i class="fas fa-star"> </i>
                        <i class="fas fa-star"> </i>
                        <i class="fas fa-star"> </i>
                        <i class="fas fa-star"> </i>
                        <i class="fas fa-star-half-alt"> </i>
                    </div> -->
                </div>

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-4 mb-md-0 mb-5">

                <div class="testimonial">
                    <!--Avatar-->
                    <div class="avatar mx-auto">
                        <img src="assets/img/c.png" style="    width: 40%;" class="rounded-circle z-depth-1 img-fluid">
                    </div>
                    <!--Content-->
                    <h4 class="font-weight-bold dark-grey-text mt-4"> Sandeep Bhat
                    </h4>
                    <h6 class="font-weight-bold blue-text my-3"> C.A, C.P.A (Aus)<br><br>Director Strategy</h6>
                    <p class="font-weight-normal dark-grey-text">
                        <!-- <i class="fas fa-quote-left pr-2"></i> -->
                        35+ Years experience in financial services<br>Ex-CFO, Aditya Birla Private Equity<br>Ex-COO & ED, UBS India Limited<br>Ex-COO, CLSA India Ltd.<br>Ex-CFO & D, ABN AMRO Equities Ltd</p>


                </div>

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-4">

                <div class="testimonial">
                    <!--Avatar-->
                    <div class="avatar mx-auto">
                        <img src="assets/img/a.png" class="rounded-circle z-depth-1 img-fluid">
                    </div>
                    <!--Content-->
                    <h4 class="font-weight-bold dark-grey-text mt-4">Mr. Siddharth Arur
                    </h4>
                    <h6 class="font-weight-bold blue-text my-3"> BEPM – (U-C) Berkeley,<br><br> Director Buisness Development</h6>
                    <p class="font-weight-normal dark-grey-text">
                        IIM, Calcutta, Alumnus<br>17+ Years in Impact Investing<br>Women’s World Banking<br>ING, Standard Chartered & DWM <br>Visiting Faculty – NMIMS / S.P. </p>


                </div>

            </div>
            <!--Grid column-->

        </div>
        <!--Grid row-->

    </section>
</div>
<!-- Section: Testimonials v.3 -->

<br><br><br><br>

<app-footer></app-footer>