<br /><br />

<main>
    <section class="container register-form">
        <div class="form">
            <div class="row text-center justify-content-center">
                <div class="col-lg-12">
                    <br />
                    <div class="note">
                        <p style="font-size: x-large;">Institution Details</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-content">
            <!-- SVG separator -->
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w2.org/2000/svg"
      >
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div> -->
            <div class="container">
                <div class="row justify-content-center mt--200"></div>
                <form #InstituteRegistration="ngForm" (ngSubmit)="OnSubmitInstituteRegistration(InstituteRegistration)">
                    <div>&nbsp;</div>

                    <div class="row">
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="fname" style="font-size: 15px; color: #8a061c;">Institute Name</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
             
                  #cname
                  type="text"
                  [ngModel]
                  id="cname"
                  name="cname"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="fname" style="font-size: 15px; color: #8a061c;"
                >Established Year</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #estyear
                  type="year"
                  [ngModel]
                  id="estyear"
                  name="estyear"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="fname" style="font-size: 15px; color: #8a061c;"
                >Number of Employess</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #numofemp
                  type="number"
                  [ngModel]
                  id="numofemp"
                  name="numofemp"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Number of Branches
              </label>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" #numofbra type="text" [ngModel] id="number" name="numofbra" (focus)="focus2 = true" (blur)="focus2 = false" />
                            </div>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="secondemail" style="font-size: 15px; color: #8a061c;">Registration Id
              </label>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" #regId type="number" [ngModel] id="regId" name="regId" (focus)="focus2 = true" (blur)="focus2 = false" />
                            </div>
                        </div>



                        <!-- <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Registration Address</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Registration Address"
                  #RegAddress
                  type="RegAddress"
                  [ngModel]
                  id="RegAddress"
                  name="RegAddress"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div> -->
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="addressone" style="font-size: 15px; color: #8a061c;">
                Website</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #Website
                  type="text"
                  [ngModel]
                  id="Website"
                  name="Website"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="landmark" style="font-size: 15px; color: #8a061c;"
                >Email</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #email
                  type="email"
                  [ngModel]
                  id="email"
                  name="email"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="addressone" style="font-size: 15px; color: #8a061c;"
                >Contact Person Name</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #contpersonname
                  type="text"
                  [ngModel]
                  id="contpersonname"
                  name="contpersonname"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <!-- <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="landmark" style="font-size: 15px; color: #8a061c;"
                >Contact Person Address</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Contact Person Address"
                  #contactperAdd
                  type="text"
                  [ngModel]
                  id="contactperAdd"
                  name="contactperAdd"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  style="text-transform: capitalize;"
                />
              </div>
            </div> -->
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="city" style="font-size: 15px; color: #8a061c;"
                >Contact Person Email</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #contactperemail
                  type="email"
                  [ngModel]
                  id="contactperemail"
                  name="contactperemail"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>

            <!-- <div class="col-md-6">
              <label for="state" style="font-size: 15px;color: #8a061c">State</label>
                            <select (change)="onSelectState($event.target.value)" class="form-control">
                <option value="">Select State</option>
                <option *ngFor="let do of States" value={{do.id}}>
                  {{do.stateName}}
                </option>
              </select>
                        </div>
                        <div class="col-md-6">
                            <label for="state" style="font-size: 15px;color: #8a061c">Country</label>
                            <select (change)="onSelectCountry($event.target.value)" class="form-control">
                <option value="">Select Country</option>
                <option *ngFor="let do of Countries" value={{do.countryId}}>
                  {{do.countryName}}
                </option>
              </select>
                        </div> -->
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="state" style="font-size: 15px; color: #8a061c;">Contact Person Phone</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #contactpersonphone
                  type="number"
                  [ngModel]
                  id="contactpersonphone"
                  name="contactpersonphone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >Industry Type</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <select
                  (change)="onSelectIndrust($event.target.value)"
                  class="form-control"
                >
                  <option value="">Select Industry</option>
                  <option
                    *ngFor="let do of Industry"
                    value="{{ do.id }}"
                  >
                    {{ do.indrustType }}
                  </option>
                </select>
              </div>
            </div>
            <!-- ---------------------------------------- -->

            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-6">
              <h3>Institute Address</h3>
            </div>
            <div class="col-md-6">
              <div class="form-group"></div>
            </div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Address Line One</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #alone
                  type="alone"
                  [ngModel]
                  id="alone"
                  name="alone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Address Line Two</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #altwo
                  type="altwo"
                  [ngModel]
                  id="altwo"
                  name="altwo"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Address Line Three</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #althree
                  type="althree"
                  [ngModel]
                  id="althree"
                  name="althree"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                City</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #city
                  type="city"
                  [ngModel]
                  id="city"
                  name="city"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;">
                Land Mark</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #landmark
                  type="landmark"
                  [ngModel]
                  id="landmark"
                  name="landmark"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >State</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #state
                  type="state"
                  [ngModel]
                  id="state"
                  name="state"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Pin code</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #pincode
                  type="number"
                  [ngModel]
                  id="pincode"
                  name="pincode"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Country</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #Country
                  type="Country"
                  [ngModel]
                  id="Country"
                  name="Country"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
     
            <!-- ------------------------------- -->
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-10" style="text-align: center;">
              <button type="submit" class="btnSubmit">
                Get In!
              </button>
            </div>
            <div class="col-md-1">&nbsp;</div>
          </div>
        </form>
      </div>
    </div>
  </section>
</main>