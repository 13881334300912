import { Component, OnInit } from '@angular/core';
import { EmailValidation } from 'app/shared/VIewmodels/emailvalidation';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { CountryService } from 'app/shared/country.service';
import { EnterpriseService } from 'app/shared/enterprise.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { EnterPriseRegister } from 'app/shared/VIewmodels/enterpriseregister';
@Component({
  selector: 'app-placementprofile',
  templateUrl: './placementprofile.component.html',
  styleUrls: ['./placementprofile.component.scss']
})
export class PlacementprofileComponent implements OnInit {
  CountryList: any;
  EnterpriseForm = new FormGroup({
    cname: new FormControl(),
    noemp: new FormControl(),
    fname: new FormControl(),
    sname: new FormControl(),
    Surname: new FormControl(),
    email: new FormControl(),
    staddress: new FormControl(),
    staddress2: new FormControl(),
    city: new FormControl(),
    Country: new FormControl(),
    pincode: new FormControl(),
    state: new FormControl(),
    phnno: new FormControl(),
    username: new FormControl(),
    password: new FormControl(),
    cpassword: new FormControl(),
  });
  noemp: any;
  sname: any;
  countryid: any;
  submitted = false;
  validtrue = false;
  comname: any;
  noofemp: any;
  fname: any;
  cname: any;
  surname: any;
  Surname: any;
  email: any;
  staddress: any;
  staddress2: any;
  city: any;
  pincode: any;
  state: any;
  phnno: any;
  cpassword: any;
  username: any;
  checkemail: string;
  checkphone: string;
  checkpincode: string;
  password: any;
  files1: any;
  SelectedFiles: any;
  validfile: boolean;
  DateTime: Date;
  alldata: any;
  firstdata: string;
  seconddata: string;
  credentials: string;
  public dismissalert = false;
  notify: string;
  validateemail: any;
  mailresult: any;
  validmail: boolean = false;
  validateusername: any;
  usernameresult: any;
  validusername: boolean;
  dispinfo: any;
  get f() {
    return this.EnterpriseForm.controls;
  }
  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private countryService: CountryService,
    private enterpriseservice: EnterpriseService,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private httpService: HttpClient
  ) {
    if (localStorage.loadingpage == "true") {
      localStorage.loadingpage = "false";
      location.reload();
    }
    this.EnterpriseForm = formBuilder.group({
      cname: ["", Validators.required],
      noemp: ["", Validators.required],
      fname: ["", Validators.required],
      sname: ["", Validators.required],
      Surname: new FormControl(),
      email: ["", [Validators.required, Validators.email]],
      staddress: ["", Validators.required],
      staddress2: ["", Validators.required],
      city: ["", Validators.required],
      pincode: ["", Validators.required],
      Country: ["", Validators.required],
      state: ["", Validators.required],
      phnno: [
        "",
        [
          Validators.required,
          Validators.min(6000000000),
          Validators.max(9999999999),
          Validators.pattern(/^[6-9]\d{9}$/),
        ],
      ],
      username: ["", Validators.required],
      password: ["", Validators.required],
      cpassword: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/
          ),
        ],
      ],
    });
  }
  id: any;
  onchangeinstitution(value) {
    this.comname = value.target.value;
  }

  ngOnInit(): void {
    this.id = localStorage.getItem("uid");
    $(document).ready(function () {
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;

      $(".next").click(function () {
        var value = localStorage.getItem("validateval");
        if (value == "true") {
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          //Add Class Active
          $("#progressbar li")
            .eq($("fieldset").index(next_fs))
            .addClass("active");

          //show the next fieldset
          next_fs.show();
          //hide the current fieldset with style
          current_fs.animate(
            { opacity: 0 },
            {
              step: function (now) {
                // for making fielset appear animation
                opacity = 1 - now;

                current_fs.css({
                  display: "none",
                  position: "relative",
                });
                next_fs.css({ opacity: opacity });
              },
              duration: 600,
            }
          );
          localStorage.setItem("validateval", "false");
        }
      });

      $(".previous").click(function () {
        localStorage.setItem("validateval", "true");

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //Remove class active
        $("#progressbar li")
          .eq($("fieldset").index(current_fs))
          .removeClass("active");

        //show the previous fieldset
        previous_fs.show();

        //hide the current fieldset with style
        current_fs.animate(
          { opacity: 0 },
          {
            step: function (now) {
              // for making fielset appear animation
              opacity = 1 - now;

              current_fs.css({
                display: "none",
                position: "relative",
              });
              previous_fs.css({ opacity: opacity });
            },
            duration: 600,
          }
        );
      });

      $(".radio-group .radio").click(function () {
        $(this).parent().find(".radio").removeClass("selected");
        $(this).addClass("selected");
      });

      $(".submit").click(function () {
        return false;
      });
    });
    this.Countries();
    this.GetDetails();
  }
  Countries() {
    this.spinner.show();
    this.countryService.getAllCountries().subscribe((Data: any) => {
      this.CountryList = Data;
      this.spinner.hide();
    });
  }
  onSelectCountry(id) {
    this.countryid = id;
  }
  Validatemail() {
    this.validateemail = this.EnterpriseForm.controls["email"].value;
    this.spinner.show();
    const data: EmailValidation = {
      Email: this.validateemail,
    };
    this.enterpriseservice.validateemail(data).subscribe((Data: any) => {
      this.mailresult = Data;
      if (this.mailresult == "exist") {
        localStorage.setItem("validateval", "false");
        this.validmail = true;
      } else {
        localStorage.setItem("validateval", "true");
        this.validmail = false;
      }
      this.spinner.hide();
    });
  }
  Validateusername() {
    this.validateusername = this.EnterpriseForm.controls["username"].value;
    this.spinner.show();
    const data: EmailValidation = {
      Email: this.validateusername,
    };
    this.enterpriseservice.validateusername(data).subscribe((Data: any) => {
      this.usernameresult = Data;
      if (this.usernameresult == "exist") {
        this.validusername = true;
      } else {
        this.validusername = false;
      }
      this.spinner.hide();
    });
  }
  Validateone() {
    this.submitted = true;
    this.comname = this.comname;
    this.email = this.EnterpriseForm.controls["email"].value;
    this.Country = this.EnterpriseForm.controls["Country"].value;
    this.city = this.EnterpriseForm.controls["city"].value;
    this.state = this.EnterpriseForm.controls["state"].value;
    this.pincode = this.EnterpriseForm.controls["pincode"].value;
    this.phnno = this.EnterpriseForm.controls["phnno"].value;
    if (
      this.EnterpriseForm.invalid &&
      (this.comname == undefined || this.email == "")
    ) {
      localStorage.setItem("validateval", "false");
      return;
    } else {
      localStorage.setItem("validateval", "true");
    }
  }
  Validatethree() {
    this.submitted = true;
    this.staddress = this.EnterpriseForm.controls["staddress"].value;
    this.staddress2 = this.EnterpriseForm.controls["staddress2"].value;
    this.city = this.EnterpriseForm.controls["city"].value;
    this.pincode = this.EnterpriseForm.controls["pincode"].value;
    this.state = this.EnterpriseForm.controls["state"].value;
    this.phnno = this.EnterpriseForm.controls["phnno"].value;
    this.checkphone = this.EnterpriseForm.controls["phnno"].status;
    this.checkpincode = this.EnterpriseForm.controls["pincode"].status;

    if (
      this.EnterpriseForm.invalid &&
      (this.staddress == undefined ||
        this.staddress2 == undefined ||
        this.city == undefined ||
        this.pincode == undefined ||
        this.state == undefined ||
        this.phnno == undefined ||
        this.staddress == "" ||
        this.staddress2 == "" ||
        this.city == "" ||
        this.pincode == "" ||
        this.state == "" ||
        this.phnno == undefined ||
        this.checkphone == "INVALID" ||
        this.checkpincode == "INVALID")
    ) {
      localStorage.setItem("validateval", "false");
      return;
    } else {
      localStorage.setItem("validateval", "true");
    }
  }
  Validatefour() {
    this.submitted = true;
    this.username = this.EnterpriseForm.controls["username"].value;
    this.cpassword = this.EnterpriseForm.controls["cpassword"].value;
    this.password = this.EnterpriseForm.controls["password"].value;

    if (
      this.EnterpriseForm.invalid &&
      (this.password == undefined ||
        this.password == "" ||
        this.username == undefined ||
        this.cpassword == undefined ||
        this.username == "" ||
        this.cpassword == "")
    ) {
      localStorage.setItem("validateval", "false");
      return;
    } else if (this.cpassword != this.password) {
      alert("Password do not match!!");
      localStorage.setItem("validateval", "false");
    } else {
      localStorage.setItem("validateval", "true");
    }
  }

  getalldata() {
    var pin = this.EnterpriseForm.controls["pincode"].value;
    this.http
      .get("https://api.postalpincode.in/pincode/" + pin)
      .subscribe((data: any) => {
        console.log(data);
        if (data[0].Message == "No records found") {
          alert("Invalid Pincode");
        } else {
          this.EnterpriseForm.controls["state"].setValue(
            data[0].PostOffice[0].State
          );
          this.EnterpriseForm.controls["Country"].setValue(
            data[0].PostOffice[0].Country
          );
          this.EnterpriseForm.controls["city"].setValue(
            data[0].PostOffice[0].District
          );
        }
      });
  }
  Country: any;
  RegisterUser() {
    var value = localStorage.getItem("validateval");
    if (value == "true") {
      const frmData = new FormData();
      this.spinner.show();
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(
        this.DateTime,
        "dd-MM-yyyy hh:mm:ss a"
      );
      const inputRequest: EnterPriseRegister = {
        Id: 0,
        EnterPriseID: null,
        CompanyName: this.comname,
        NoOfEmployees: this.noofemp,
        Country: this.Country,
        FirstName: this.fname,
        Surname: this.surname,
        Email: this.email,
        Logo: this.SelectedFiles,
        StreetaddressLineOne: this.staddress,
        StreetaddressLineTwo: this.staddress2,
        City: this.city,
        Pincode: this.pincode,
        State: this.state,
        PhoneNumber: this.phnno,
        Username: this.username,
        Password: this.password,
        date: latest_date,
      };
      this.firstdata =
        this.comname +
        "," +
        this.city +
        "," +
        this.Country +
        "," +
        this.state +
        "," +
        this.pincode +
        "," +
        this.email +
        "," +
        this.id +
        "," +
        this.phnno;
      this.seconddata =
        this.staddress +
        "," +
        this.staddress2 +
        "," +
        this.city +
        "," +
        this.pincode +
        "," +
        this.state +
        "," +
        this.phnno;
      this.credentials =
        this.username + "," + this.password + "," + latest_date;
      frmData.append("firstdata", this.firstdata);
      frmData.append("seconddata", this.seconddata);
      frmData.append("credentials", this.credentials);
      frmData.append("logo", this.SelectedFiles);
      this.httpService
        .post(
          "https://blockchainmatrimony.com/jobzoneapi/api/EnterPriseRegistration/PostEnterPriseRegistration/",
          frmData
        )
        .subscribe((data) => {
          this.spinner.hide();
        });
    } else {
      return;
    }
  }
  onDrop(event: any) {
    var fileslist = "";
    var files = event;

    this.files1 = files;
    if (
      this.files1.length == 1 &&
      (this.files1[0].type == "image/png" ||
        this.files1[0].type == "image/jpg" ||
        this.files1[0].type == "image/jpeg")
    ) {
      for (let k = 0; k < this.files1.length; k++) {
        if (this.files1[k].type == "") {
          alert("Please Upload only Files");
          this.files1 = "";
        }
        fileslist = this.files1[k].name + ",";
      }

      this.SelectedFiles = fileslist;
      localStorage.setItem("validateval", "true");
      this.validfile = true;
    } else {
      localStorage.setItem("validateval", "false");
      this.validfile = false;
    }
  }
  onChange(event: any) {
    var fileslist = "";
    this.files1 = [].slice.call(event.target.files);
    console.log(this.files1);
    fileslist = this.files1[0];

    this.SelectedFiles = fileslist;
    localStorage.setItem("validateval", "true");
  }
  GetDetails() {
    this.enterpriseservice.GetallDetails(this.id).subscribe((response: any) => {
      this.alldata = response;
      if(this.alldata[0].allRegDetails.companyName==3){
        this.alldata[0].allRegDetails.companyName="Srichid Academy - Global Risk Hub";
      }
      this.dispinfo = this.alldata[0].allRegDetails;
      console.log(this.alldata[0]);
    });
  }
}