import { Component, OnInit } from '@angular/core';
import { ChangePassword } from 'app/shared/VIewmodels/ChangePassword';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/shared/UserService/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MobileViewModel } from 'app/shared/VIewmodels/MobileViewModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.scss']
})
export class ForgotpwdComponent implements OnInit {
  email:any;
  otp:any;
  openotp=false;
  beforeotp=true;
  afterotp=false;
p1:any;
p2:any;
  constructor(private spinner:NgxSpinnerService,private userService:UserService,private router:Router) {
    window.scrollTo(0,0);

   }

  ngOnInit(): void {
  }
  OnSubmit(){
    if(this.openotp){
      this.otpvalid();
    }
    else{
    localStorage.setItem('loadingpage',"true");
   // this.loading = true;
   if(this.email == null || this.email == ""){
     
     alert("Please enter Your Email");
   }
  
  else{
    this.spinner.show();

    this.userService.sendotp(this.email).subscribe((data : any)=>
    {
    if(data=="0"){
      alert("We did not find the provided Email within jobs-zone! Please register if you are a new user");

      this.email = "";
    }
    else if(data == "1"){
      alert("OTP has been sent to your provided Email please enter it below.");
      this.openotp=true;
    }
    this.spinner.hide();
 
   }, 
   (err : HttpErrorResponse)=>{
    alert("Something went wrong!!! Please try again later");
    this.email="";
    this.spinner.hide();
   }
   
)

}
    }
}
otpvalid(){
  const a: MobileViewModel = {
    Id:null,
    OTPExpiryTime:null,
    OTPStartingTime:null,
    Email:this.email,
    UserId:null,
    OTP:this.otp,
    Attempts:null
  }
  this.userService.otpvalid(a).subscribe((data:any)=>{
    if(data=="Expired"){
      alert("OTP Expired! Please retry.");
      location.reload();
    }
    else{
       if(data>0){
         localStorage.setItem("otpuid",data);
         alert("OTP validated reset your password now!");
        //  this.router.navigate(['/reset']);
        this.beforeotp=false;
        this.afterotp=true;
       }
       else if(data==0){
         alert("Please enter correct OTP");
         this.otp="";
       }
    }
  })
}
changepassword(){
  if(this.p1 != this.p2){
    alert("Passwords do not match");
  }
  else if(this.p1 == "" || this.p2 == undefined || this.p2 == "" || this.p1 == undefined){
    alert("Please enter your password");
  }
  else if(this.p1 == this.p2){
    var uid=+localStorage.getItem("otpuid");
    const inputRequest:ChangePassword={
      CustomerId:uid,
      Email:"",
      Password:this.p1,
      ConfirmPassword:this.p1,
    }
    this.userService.postchangePasswordAfterLogin(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Your Password Was Successfully Changed");
          this.router.navigate(['/newsignin']);
        }else{
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        } 
      });
  }
}
}
