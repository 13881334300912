<div class="whole-page">
    <br /><br /><br /><br /><br />
    <div class="col-md-12 text-center" style="font-weight: 500;font-size: 20px;" *ngIf="joblist == undefined || joblist == null|| joblist == ''">No Applicants yet!</div>
    <div class="col-md-12 text-center" style="font-weight: 500;font-size: 20px;" *ngIf="joblist.length > 0">Candidate lists</div>
    <br>
    <div *ngFor="let job of joblist">


        <button class="collapsible">{{ job.userdata.userName }}</button>
        <div class="whole-content">
            <div class="content">
                <label>Name :</label>
                <span style="font-size: 15px;"><b>&nbsp;{{ job.userdata.userName  }}</b></span
        ><br />
        
       

        <label>Email :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.userdata.email }}</b></span
        ><br />
       
        <label>Documents shared : </label>
        <span style="font-size: 15px;" *ngFor="let certi of job.certificates"
          ><a (click)="download(certi.file)">&nbsp;{{ certi.documentName }}</a> <br></span
        ><br />

        <br>
        <label>Applied for the job role: </label>
        <span style="font-size: 15px;"
          ><b *ngIf="job.jobdata.jobRole">&nbsp;{{ job.jobdata.jobRole }}</b> <br></span
        ><br />
      </div>
      <div class="row col-md-12"></div>
     
      <br /><br />
    </div>
  </div>
</div>