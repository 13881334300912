import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FriendsServiceService } from 'app/shared/Friendrequest/friends-service.service';
import { Friend } from 'app/shared/VIewmodels/Friend';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-friendrequest',
  templateUrl: './friendrequest.component.html',
  styleUrls: ['./friendrequest.component.scss']
})
export class FriendrequestComponent implements OnInit {
  userid: string;
  uid: string;
  GUID: string;

  p: number = 1;
  q: number = 1;
  r: number = 1;
  closeResult: string;
  accid: string;
  Friends: any;
  SentFriendRequests: any;
  RecievedFriendRequests: any;
  UserID: string;

  constructor(private friendservice: FriendsServiceService,private spinner: NgxSpinnerService, 
    private modalService: NgbModal) { 
    this.GUID = localStorage.getItem("GUID");
    this.userid = localStorage.getItem("userId");
    this.uid = localStorage.getItem("userId");
    this.UserID=localStorage.getItem("uid");
  }

  ngOnInit(): void {
    this.GetFriendRequests();
    this.GetRecievedFriendRequest();
    this.GetSentFriendRequest();
  }

  async GetFriendRequests() {
    //this.spinner.show();
    this.friendservice.GetFriendRequests(this.UserID).subscribe((data: any) => {
      // this.RecievedFriendRequests = data.incomingRequest;
      // this.SentFriendRequests = data.sentRequest;
      this.Friends = data;
      this.spinner.hide();
    })
  }
  async GetRecievedFriendRequest() {
    //this.spinner.show();
    this.friendservice.GetRecievedFriendRequest(this.UserID).subscribe((data: any) => {
      this.RecievedFriendRequests = data;
      console.log(this.RecievedFriendRequests)
      this.spinner.hide();
    })
  }
  async GetSentFriendRequest() {
    //this.spinner.show();
    this.friendservice.GetSentFriendRequest(this.UserID).subscribe((data: any) => {
      this.SentFriendRequests = data;
      this.spinner.hide();
    })
  }

  Accept(data) {
    var info = {
      FriendId: data.friendId,
      UserId: this.UserID,
      Id:data.id
    }
    //this.spinner.show();
    this.friendservice.AcceptFriendequest(info).subscribe((data: any) => {
      if(data=="Successfull"){
        alert("Friend Request Accepted Successfully")
        window.location.reload();
        // const audit = {
        //   TransactionName:"Friend Request",
        //   TransactionType:"accept",
        //   TransactionDesc:"Friend Request",

        //   }
          //this.auditortransactionservice.AuditorTransactionDetails(audit);
      }
      this.GetFriendRequests();
      this.spinner.hide();
    })
  }
  AddFriend(addfriend) {
    this.modalService.open(addfriend).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  savefrienddetails() {
    ////this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.UserID,
      Status: 4,
      IsEnabled: true,
      

    }
    this.friendservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using jobzone!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "1") {
        alert("Friend Request Sent Successfully!");
        window.location.reload();
        // const audit = {
        //   TransactionName:"Friend Request",
        //   TransactionType:"Send",
        //   TransactionDesc:"Friend Request",

        //   }
         // this.registrationService.AuditorTransactionDetails(audit);
      }
      else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";
      this.GetFriendRequests();
      this.GetRecievedFriendRequest();
      this.GetSentFriendRequest();
    });
  }
}

