<div class="inbox-head">

</div>
<div class="inbox-body" >
    <div class="col-md-10" style=" padding: 30px 140px 50px 140px;">
        <div class="col-lg-12">
            <div class="col-lg-12 row">
                <div>
                        <p><b>From:</b> {{From}}</p>
                    <p><b>To:</b> {{To}}</p>
                </div>
                <div class="col-lg-6">
                    <span>
                        <p style="float: right;">{{date}}</p>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-lg-12 row">
            <div class="col-lg-6">
                <p><b>Sub:</b> {{Subject}}</p>
            </div>
            <div class="col-lg-12">
                <h5>{{BodyText}}</h5><br>

            </div>

            <br /> <br />
            <div class="col-md-12 row">

                <div class="col-md-3"></div>
                <div class="col-md-3" style="text-align: end;">
                    <br /> <br />
                    <!-- (click)="sendmail()" -->
                    <button type="submit" id="SendBtn" class="btn btn-info"
                        (click)="replay()">Reply</button>
                    <br /> <br />
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>


    </div>
    <hr />
</div>


<div class="col-md-12 row" *ngIf="replayenable">
    <div class="col-md-4">
    </div>

    <div class="col-md-8" style="background-color: white;    padding: 30px 140px 50px 0px;">

        <label> To User</label>
        <div class="col-lg-12 row" style="cursor: pointer;">
            {{ From }}<br />
        </div>
        <br />
        <label>Message :</label>

        <textarea type="text" class="form-control" name="message" [(ngModel)]="message" rows="4" cols="50"> </textarea>
        <br /> <br />


        <div style="text-align: center;">

            <button type="submit" id="SendBtn" style="text-align: left;" class="btn btn-success"
                (click)="sendmail()">SEND</button>
        </div>
    </div>


</div>



<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.documentName }}</h6>
        <h6 style="float: right;">
            Date &nbsp;: &nbsp;{{date | date: "short"}}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{imgdisplay.doclink}}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="imgdisplay.doclink" target="blank" style="cursor: pointer;"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>
<!-- PDF VIEW -->
<ng-template #pdftesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
        <h6 style="float: left;">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
        <br /><br />

        <!-- <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button> </a> -->

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #newmember let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Add Frineds</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="font-size: 16px;">
        <div>
            <div id="aboveTagInp">
                <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test"
                    [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                    [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                    [separatorKeyCodes]="[13,188,186,32]">
                    <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
                        [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'uid'"
                        [autocompleteItems]="frndslist">
                        <ng-template let-item="item" let-index="index">
                            {{ item.email }}
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input>
            </div>
        </div>
        <br />
    </div>
    <br />
    <div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark btn-primary" (click)="forword()"
            style="color: black; color: white;padding: 5px;margin-left: 10px;">
            Forword
        </button>
    </div>
    <br />
</ng-template>



<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white;">
        <div>&nbsp;</div>
        <h6 style="float: left;">
            Date &nbsp;: &nbsp;{{ videodate | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <video controls #videoPlayer style="height: 350px; width: 400px;">
            <source src="{{ videoplay }}" type="video/mp4" />
        </video>
    </div>
</ng-template>
<ng-template #audiocontent let-d="dismiss" let-c="dismiss">
    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <audio controls #videoPlayer style="height: 350px; width: 400px;">
            <source src="{{ audioplay }}" type="video/mp4" />
        </audio>
    </div>
</ng-template>