import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideorecordService {

  readonly url = 'https://blockchainmatrimony.com/jobzoneapi/api';
  constructor(private http: HttpClient) {}

  saverecord(data: any) {
    return this.http.post(this.url + '/videorecord/savevideorecord/',data);}
    getreport(id:any){
      return this.http.get(this.url + '/videorecord/report1/'+id);}
}
