import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  // readonly rooturl = "https://blockchainmatrimony.com/jobzoneapi/api/"
  readonly rooturl = 'https://blockchainmatrimony.com/jobzoneapi/api/';

  constructor(private http: HttpClient) {}

  getAllCourse() {
    return this.http.get(this.rooturl + 'CourseMasters/GetalltblCourseMasters');
  }

  saveCoursedata(data: any) {
    return this.http.post(
      this.rooturl + 'CourseMasters/PosttblCourseMaster',
      data
    );
  }
  UpdateCourseRow(data: any) {
    return this.http.put(
      this.rooturl + 'CourseMasters/PuttblCourseMaster',
      data
    );
  }
  deleteCoursedata(id: any) {
    return this.http.delete(
      this.rooturl + 'CourseMasters/DeletetblCourseMaster/' + id
    );
  }
  deletemat(id: any) {
    return this.http.delete(
      this.rooturl + 'UploadDocuments/DeleteUploadDocument/' + id
    );
  }
}
