import { Component, OnInit } from '@angular/core';
import { VCEvent } from 'app/shared/VIewmodels/VCEvent';
import { CodeService } from 'app/newcomponents/mcq/services/code.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hostevent',
  templateUrl: './hostevent.component.html',
  styleUrls: ['./hostevent.component.scss']
})
export class HosteventComponent implements OnInit {
count=1;
userid;
  constructor(private service:CodeService,private router:Router) {
    this.userid=localStorage.userId;
  }

  ngOnInit(): void {
  }
add(){

  var emailtext=(<HTMLInputElement>document.getElementById("inputs").lastChild).value;
  if(emailtext=="" || !emailtext.includes('@')){
    alert("Please type in the email of invitee to continue");
  }
  else{
  var inputs=document.getElementById("inputs");
  var node=document.createElement("input");
  node.setAttribute("name","email"+(++this.count).toString());
  node.setAttribute("id","email"+(this.count).toString());
  node.setAttribute("class","form-control");
  node.setAttribute("placeholder","Please type the invitee email");

  node.setAttribute("type","email");
  inputs.append(document.createElement('br'));
  inputs.append(document.createElement('br'));

  inputs.append(node);
  }
}
submit(){
  var totalAdded=1;
  var isgroup=false;
  if(this.count>1){
    isgroup=true;
  }
  for(var i=0;i<this.count;i++){
    var j=i+1;
    var emailcount="email"+j;
    var emailtext=(<HTMLInputElement>document.getElementById(emailcount)).value;
    console.log(emailtext);
    if(emailtext!="" && emailtext.includes('@')){
    const data:VCEvent={
      ClientID:emailtext,
      Finished:false,
      HostID:this.userid,
      Id:null,
      OnDate:null,
      isGroup:isgroup
    }
this.service.getusermailid(data.ClientID).subscribe((response:any)=>{
  if(response!=null){
    data.ClientID=response;
    this.service.saveHostId(data).subscribe((data:any)=>{
      totalAdded+=1;
  });
  }
  else{
    alert(data.ClientID+" is not using Jobs-zone");
  }
  var timerid=setInterval(() => {
    if(totalAdded==this.count){
      alert("Invitation sent successfully");
      
      clearInterval(timerid);
      this.router.navigate(['/hostjoin']);
    }
}, 100);
})}
else{
  totalAdded+=1;

}
  }
 
}
}
