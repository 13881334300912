import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MailboxVM } from '../VIewmodels/MailboxVM';
import { ShareIdShow } from '../VIewmodels/shareidshow';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  restoredeletemessages: any;


  permanentlydeletemessages: any;
  constructor(private http:HttpClient) { }
  readonly url = "https://blockchainmatrimony.com/jobzoneapi";


  // getInboxlist(email) {
  //   return this.http.get(this.url + "/Hmail/GetMails/"+email);
  // }

  getInboxlist(cid) {
    return this.http.post(this.url + "/api/MailSystem/postGetAllMails" , cid);
  }

  getInboxlistforjob(cid) {
    return this.http.post(this.url + "/api/JobMailSystem/postGetAllMailsforjob" , cid);
  }

  postupdatemailread(cid) {
    return this.http.post(this.url + "/api/MailSystem/postupdatemailread", cid);
  }
  postupdatemailreadforjob(cid) {
    return this.http.post(this.url + "/api/JobMailSystem/postupdatemailreadforjob", cid);
  }


  postaddnewebers(id:any) {
    return this.http.post(this.url + "/api/MailSystem/PostAddNewMembers" , id);
  }

  postaddnewebersforjob(id:any) {
    return this.http.post(this.url + "/api/JobMailSystem/PostAddNewMembersforjob" , id);
  }

  getSentlistHmail(email) {
    return this.http.get(this.url + "/Hmail/GetSentMails/"+email);
  }


  postmakeitsentArchive(data: any) {
    return this.http.post(this.url + '/api/MailSystem/MultiarchivesentMessages', data);
  }

  sendOrReplyMail(data) {
    return this.http.post(this.url + "/Hmail/PostSendorReply",data);
  }
  

  getUsers(cid) {
    return this.http.get(this.url + "/api/FriendList/GetFriendList/" + cid);
  }

  getmailstatus(id){
    return this.http.get(this.url + "/api/Gateway/EnableMailStatus/" + id);
  }
  postaddnewebersmail(id:any) {
    return this.http.post(this.url + "/api/MailSystem/PostAddNewMembers" , id);
  }
  getmaildetails(id){
    return this.http.post(this.url + "/api/MailSystem/PostGetMailDetails", id);
  }
  getmaildetailsforjob(id){
    return this.http.post(this.url + "/api/JobMailSystem/PostGetMailDetailsforjob", id);
  }
  getdeletelist(cid) {
    return this.http.post(this.url + "/api/MailSystem/postGetAllDeletedMails", cid);
  }
  getdeletelistforjob(cid) {
    return this.http.post(this.url + "/api/JobMailSystem/postGetAllDeletedMails", cid);
  }
  getsentlist(cid) {
    return this.http.post(this.url + "/api/MailSystem/PostGetAllSentMails" , cid);
  }

  getsentlistforjob(cid) {
    return this.http.post(this.url + "/api/JobMailSystem/PostGetAllSentMailsforjob" , cid);
  }

  getarchivelist(cid) {
    return this.http.post(this.url + "/api/MailSystem/postGetAllArchiveMails", cid);
  }

  postmakeitArchive(data: any) {
    return this.http.post(this.url + '/api/MailSystem/MultiarchiveMessages', data);
  }
  postmakeitArchiveforjob(data: any) {
    return this.http.post(this.url + '/api/JobMailSystem/PostMakeAllArchiveMails', data);
  }
  
  
  
  multideletemessages(det: MailboxVM) {
    return this.http.post(this.url+ "/api/MailSystem/MultiDeleteMessages", det);
  }
  multideletemessagesforjob(det: MailboxVM) {
    return this.http.post(this.url+ "/api/JobMailSystem/MultiDeleteMessages", det);
  }
  multideletemessagessent(det: MailboxVM) {
    return this.http.post(this.url+ "/api/MailSystem/MultiDeletesentMessages", det);
  }
  multideletemessagessentforjob(det: MailboxVM) {
    return this.http.post(this.url+ "/api/JobMailSystem/MultiDeletesentMessages", det);
  }

  multideletesentmessages(det: MailboxVM) {
    return this.http.post(this.url+ "/api/MailSystem/MultiDeletesentMessages", det);
  }
  GetInboxMessages(cid) {
    return this.http.get(this.url + "/api/UploadFile/GetInboxMessages/" + cid);    
  }
  getParticularUsers(rid){
    return this.http.get(this.url + "/api/UploadFile/GetParticularUsers/" + rid);
  }
  getUsersformail(cid){
    return this.http.get(this.url + "/api/UploadFile/GetUsersforMail/" + cid);
  }
  deletedmail(cid) {
    return this.http.get(this.url + "/api/JobMailSystem/DeleteSelectedMail/" + cid);
  }
  getUserTickets(cid){
    return this.http.get(this.url + "/api/JobMailSystem/GetUserTickets/" + cid);
  }
  getdeletedmails(cid) {
    return this.http.get(this.url + "/api/JobMailSystem/GetDeletedItems/" + cid);
  }
  getsentmails(cid) {
    return this.http.get(this.url + "/api/UploadFile/GetSentItems/" + cid);
  }

  GetStatusList(rid){
    return this.http.get(this.url + "/api/MailBox/GetStatusList/" + rid);
  }
  getParticularUsersforEmp(ticketid){
    return this.http.get(this.url + "/api/MailBox/GetParticularUsersforEMP/" + ticketid);
  }
  saveAcceptTasks(id){
    return this.http.post(this.url +"/api/CustomerRequests/PostAcceptTasks/", id);
  }


   //haspit

   getsubfolders(ids: ShareIdShow) {
    return this.http.post(this.url + "/api/UploadDocument/Posttakedocument", ids);
  }
  getpfoldernames(id) {
    return this.http.get(this.url + "/api/FolderMasters/GetFoldernames/" + id);
  }
  getfolders(id) {
    return this.http.get(this.url + "/api/FolderMasters/GetallFolderone/" + id);
  }
}
