import { Component, OnInit, ViewChild } from '@angular/core';
import { QuestionService } from '../services/question.service';
import { CodeService } from '../services/code.service';
import {  pythoncode, onlyid } from 'app/shared/VIewmodels/onlyid';
import { Router, ActivatedRoute } from '@angular/router';
declare var Peer:any;
@Component({
  selector: 'app-codemirror',
  templateUrl: './codemirror.component.html',
  styleUrls: ['./codemirror.component.scss']
})
export class CodemirrorComponent implements OnInit {
content;
quizid:any;
userid:any;
//video peerjs
@ViewChild('myvideo') myvideo: any;
  
peer;
anotherid;
mypeerid;
//end here
  constructor(private codeService:CodeService,private router:Router,private route:ActivatedRoute,private questionservice:QuestionService) {
    this.route.params.subscribe((data:any)=>{
      this.quizid=data['id'];
    });
    this.userid = localStorage.userId;
    localStorage.myid = "";
   }
question:any;
input:any;
  ngOnInit(): void {
    //video peer
  
    
    

    //end here
    const id:onlyid={
      id:this.quizid
    }
    this.questionservice.getcodequestion(id).subscribe((data:any)=>{
      
      this.question = data[0];
      console.log(this.question);
    })
  }
fetch(){
  this
  const codeString:pythoncode={
    id:0,
    code:this.content,
    quizid:this.question.id,
    stdid:this.userid
  }
  this.codeService.savecode(codeString).subscribe((response:any)=>{
      if(response == 1){
        alert("Your submission is saved ! Thank you for participating in this test");
        this.router.navigate(['/']);
      }

      
  })
}
connect(){
  var conn = this.peer.connect(this.anotherid);
conn.on('open', function(){
conn.send('Message from that id');
});
}

videoconnect(){
  let video = this.myvideo.nativeElement;
  var localvar = this.peer;
  var fname = this.anotherid;
  
  // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
  
  var n = <any>navigator;
  
   n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );
  
   n.getUserMedia({video: true, audio: true}, function(stream) {
    var call = localvar.call(fname, stream);
    // call.on('stream', function(remotestream) {
    //   // video.srcObject = remotestream;
    //   // video.play();
    // })
  }, function(err){
    console.log('Failed to get stream', err);
  })
  
  
}
}