<!-- Example split danger button -->

<body>
    <div class="col-md-12 row" style="margin-top: 10%;margin-bottom: 10%;">
        <div class="col-md-5"></div>
        <div class="col-md-3">
            <div class="dropdown">
                <button class="dropbtn">Register as an</button>
                <div class="dropdown-content">
                    <a href="companyRegistration">Company</a>
                    <a href="eregistration">Placemnnt Officer</a>
                    <a href="newprofile">Applicants</a>
                </div>
            </div>
        </div>
    </div>
</body>