<br />
<br />
<br /><br />
<br />
<br />


<div class="col-md-12 row">

    <div class="col-md-12">&nbsp;</div>
    <div class="col-md-2">
        <div style="width: 100%;">
            <div style="width: 50%;float: left;">
                <i *ngIf="mobile" class="fa fa-bars" (click)="treeviewba()" style="margin-left: 10%;"> </i>
            </div>
        </div>
    </div>

    <div class="col-md-12 row">
        <div class="col-md-2" style="overflow: auto;border-right: solid black 1px;padding-left: 35px;" id="treeview">


            <div *ngIf="ppopup">
                <div *ngFor="let a of allFolders1" id="{{a.folderID}}">

                    <span><i class="fa fa-chevron-right" style="cursor: pointer;"  (click)="getfolders1(a)"  id="{{a.id}}" ></i> <img width="30px" height="30px" src="./assets/img/folder.png"  (click)="GetSubfolder(a)">
                  <span  (click)="GetSubfolder(a)" style="cursor: pointer;">{{a.folderName}}</span></span>

                </div>
            </div>



        </div>
        <div>&nbsp;</div>
        <div class="col-md-9" style="display: flex;justify-content: center;  ">
            <table class="table table-responsive table-borded" style="overflow-x: unset;width: auto;">
                <thead class="text-warning">
                    <th>Name</th>
                    <th>Modified</th>
                    <th>Members</th>
                    <th>Setting</th>
                </thead>
                <tbody *ngFor="let folderview of allFolders">
                    <tr style="font-size: 20px">
                        <!-- <td> <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px;width:16px;">
                      </td> -->
                        <td><i class="fa fa-folder" style="cursor: pointer;"></i> &nbsp;&nbsp;
                            <b (click)="GetSubfolder(folderview)" style="cursor: pointer;">{{folderview.folderName}}</b>
                        </td>
                        <td>{{folderview.createdOn | date:'short'}}</td>
                        <td>{{folderview.totalMembers}} &nbsp;members</td>
                        <td *ngIf="!multi">
                            <div ngbDropdown class="dropdown" placement="bottom-left">
                                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i
                                    class="fa fa-gear" aria-hidden="true" style="cursor: pointer;"></i></a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                    <a class="dropdown-item" (click)="Delete(folderview)">Delete</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngFor="let ret of SharedFiles">
                    <tr>
                        <input type="checkbox" *ngIf="shareitem" (click)="toggle(ret, 'doc')" style="height: 16px;width:16px;margin-top: 28px;">
                        <input type="checkbox" *ngIf="deleteitem" (click)="toggleDelete(ret, 'doc')" style="height: 16px;width:16px;margin-top: 28px;">
                        <td *ngIf="ret.documentType=='image/jpeg'||ret.documentType=='png'|| ret.documentType =='image/png'" (click)="ViewDoc(ret,content)" data-toggle="modal" data-target="#Viewimage">
                            <img src="{{ret.file}}" style="width:50px;height:40px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/pdf'||ret.documentType=='pdf'" (click)="ViewDocument(ret,pdftesting)" data-toggle="modal" data-target="#ViewPdf">
                            <img src="assets/img/pdficon.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || ret.documentType=='xlsx'" (click)="ViewxlsDoc(ret,xlscontent)" data-toggle="modal" data-target="#Viewxls">
                            <img src="assets/img/xls.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/vnd.openxmlformats-officedocument.presentationml.presentation' || ret.documentType=='ppt' ||ret.documentType=='pptx'" (click)="ViewPpt(ret,pptcontent)" data-toggle="modal" data-target="#ViewPpt">
                            <img src="assets/img/ppticon2.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>
                        <td *ngIf="ret.documentType=='video/mp4' || ret.documentType=='mp4'" (click)="ViewVideo(ret,videocontent)" data-toggle="modal" data-target="#Viewvideo">
                            <img src="assets/img/video.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || ret.documentType=='doc' || ret.documentType=='docx'" (click)="ViewDocumentword(ret,pdftesting)" data-toggle="modal" data-target="#Viewword">
                            <img src="assets/img/docx.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/x-zip-compressed' ||ret.documentType=='zip' " (click)="Viewzip(ret)" data-toggle="modal" data-target="#Viewzip">
                            <img src="assets/img/zip.jpg" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>
                        <td *ngIf="ret.documentType=='text/plain' || ret.documentType=='txt'" (click)="ViewDocumenttext(ret,textfileview)" data-toggle="modal" data-target="#Viewtxt">
                            <img src="assets/img/txt.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>
                        <td [hidden]="ret.documentType == 'text/plain' || ret.documentType == 'txt' || ret.documentType == 'application/x-zip-compressed' || ret.documentType == 'zip' || ret.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||ret.documentType == 'doc' || ret.documentType == 'docx' ||
                    ret.documentType =='video/mp4'|| ret.documentType =='mp4' || ret.documentType =='application/vnd.openxmlformats-officedocument.presentationml.presentation'|| ret.documentType =='ppt'|| ret.documentType =='pptx'
                    || ret.documentType =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || ret.documentType =='xlsx' || ret.documentType =='application/pdf' || ret.documentType =='image/jpeg'|| ret.documentType =='png'|| ret.documentType =='image/png'||ret.documentType=='pdf'"
                            (click)="Viewanyfile(ret)" data-toggle="modal" data-target="#Viewanyfile">
                            <img src="assets/img/anyf1.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
            &nbsp;&nbsp;{{ret.documentName}}
          </span>
                        </td>

                        <td> {{ret.date | date:'short'}}</td>
                        <td>{{ret.documentType}}</td>
                        <td>
                            <div ngbDropdown class="dropdown" placement="bottom-left">
                                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i
                                    class="fa fa-gear" aria-hidden="true" style="cursor: pointer;"></i></a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                    <a class="dropdown-item" (click)="Delete(folderview)">Delete</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="col-md-1"></div>

    </div>
</div>



<!-- IMAGE VIEW -->
<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center;cursor: pointer;"> {{zzz.documentName}}</h6>
        <h6 style="float:left;">Date &nbsp;: &nbsp;{{zzz.date | date:'short'}}</h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <img src="{{zzz.file}}" class="img-responsive" alt="" style="height:300px;width:400px ">

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="zzz.file"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button>
          </a>
            </div>
        </div>
    </div>
</ng-template>

<!-- PDF VIEW -->
<ng-template #pdftesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width:883px;background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center"> {{zzz.documentName}}</h6>
        <h6 style="float:left;">Date &nbsp;: &nbsp;{{zzz.date | date:'short'}}</h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" style="width:883px;background-color: white;">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width:100%;height:70vh;"></ngx-doc-viewer>
        <br /><br />

        <!-- <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button> </a> -->

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button>
          </a>
            </div>
        </div>
    </div>
</ng-template>

<!-- TEXT FILE VIEW -->
<ng-template #textfileview let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width:883px;background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center"> {{zzz.documentName}}</h6>
        <h6 style="float:left;">Date &nbsp;: &nbsp;{{zzz.date | date:'short'}}</h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="width:883px;background-color: white;">
        <ngx-doc-viewer [url]="txtUrl" viewer="google" style="width:100%;height:70vh;"></ngx-doc-viewer>
        <br /><br />

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="txtUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button>
          </a>
            </div>
        </div>
    </div>
</ng-template>

<!-- DOCX VIEW -->
<ng-template #pdfcontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width:883px;background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center"> {{zzz.documentName}}</h6>
        <h6 style="float:left;">Date &nbsp;: &nbsp;{{zzz.date | date:'short'}}</h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="width:883px;background-color: white;">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width:100%;height:70vh;"></ngx-doc-viewer>
        <br /><br />

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button>
          </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #xlscontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width:883px;background-color: white;">
        <div>&nbsp;</div>
        <h6 style="float:left;">Date &nbsp;: &nbsp;{{zzz.date | date:'short'}}</h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" style="width:883px;background-color: white;" *ngIf="pdfUrl">
        <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQr43mmLOdZoy-EgkXcF_bahfs-uxtcvOXhEbnLtm-K2ubQjw8rGnv-z-9v8YL3SidA35WCpsmXaMnk/pubhtml?widget=true&amp;headers=false"></iframe>
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-7">
                <a href="{{pdfUrl.DocPath}}" download="{{Docname}}"><button class="btn btn-primary"><i
                class="fa fa-download"></i>Download</button> </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #pptcontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width:883px;background-color: white;">
        <div>&nbsp;</div>
        <h6 style="float:left;">Date &nbsp;: &nbsp;{{zzz.date | date:'short'}}</h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- <object type="application/pdf" width="1000" height="800" data="{{pdfUrl.DocPath}}"></object> -->

    <div class="modal-body" style="width:883px;background-color: white;">
        <pdf-viewer [src]="pdfUrl.PdfPath" [render-text]="true" style="display: contents;">
        </pdf-viewer>
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-7">
                <a href="{{pdfUrl.DocPath}}" download="{{Docname}}"><button class="btn btn-primary"><i
                class="fa fa-download"></i>Download</button> </a>
            </div>
        </div>
    </div>

</ng-template>


<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width:883px;background-color: white;">
        <div>&nbsp;</div>
        <h6 style="float:left;">Date &nbsp;: &nbsp;{{videodate | date:'short'}}</h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width:883px;background-color: white;">
        <video controls #videoPlayer style=" height:350px; width:400px">
        <source src="{{videoplay}}" type="video/mp4" />
      </video>
    </div>
</ng-template>