<br /><br />
<div id="parent">
  <div class="container">
    <div class="row">
      <div class="col-md-10 offset=md-1">
        <div class="row">
          <div class="col-md-5 register-left">
            <div style="padding-top: 40px">
              <h1>Job-Zone</h1>
              <h2>Welcome</h2>
              <p>Login and get placed !</p>
              <img
                src="assets/img/se.svg"
                alt=""
                height="350px"
                width="350px"
                style="padding-bottom: 60px"
                data-aos="fade-right"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1000"
              />
              <br />
            </div>
            <!-- <button type="button" class="btn btn-primary">About Us</button> -->
          </div>
          <div class="col-md-7 register-right">
            <h2>
              <!-- <span> <i (click)="signInWithFB()" class="fa fa-facebook-square" aria-hidden="true" title="sign in with facebook"></i> </span> &nbsp;&nbsp;
                            <span> <i (click)="signInWithLI()" class="fa fa-linkedin-square" aria-hidden="true" title="sign in with linkedin"></i> </span>&nbsp;&nbsp;
                            <span> <i (click)="signInWithGoogle()" class="fa fa-google" aria-hidden="true" title="sign in with gmail"></i> </span>
                          -->
              <!-- <span> <i  class="fa fa-facebook-square" aria-hidden="true" title="sign up with facebook"></i> </span> &nbsp;&nbsp;
                            <span> <i  class="fa fa-linkedin-square" aria-hidden="true" title="sign up with linkedin"></i> </span>&nbsp;&nbsp;
                            <span> <i  class="fa fa-google" aria-hidden="true" title="sign up with gmail"></i> </span> -->
            </h2>
            <hr />
            <!-- <h2 *ngIf="company">
                            Register as an <span style="color: #1e5ee5;">Employer</span>
                        </h2>
                        <h2 *ngIf="!company">
                            Register as an <span style="color: #1e5ee5;">Applicant</span>
                        </h2> -->
            <h2>Welcome</h2>
            <div class="register-form">
              <!-- <div class="form-group">
                                <input type="text" name="name" class="form-control" placeholder="Name" [(ngModel)]="name">
                            </div> -->
              <div class="form-group">
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="Email or Phone"
                  [(ngModel)]="email"
                />
              </div>
              <!-- <div class="form-group" *ngIf="onlyphone">
                                <input type="number" name="phone" class="form-control" placeholder="Phone" [(ngModel)]="phone">
                            </div> -->
              <!-- <div (click)="changetophone()" *ngIf="!onlyphone" style="color: #1e5ee5;cursor: pointer;">Sign up with phone number</div>
                            <div (click)="changetophone()" *ngIf="onlyphone" style="color: #1e5ee5;cursor: pointer;">Sign up with Email</div>
                            <br> -->
              <div class="form-group">
                <input
                  type="password"
                  name="password"
                  class="form-control"
                  placeholder="Password"
                  [(ngModel)]="password"
                />
              </div>
              <hr />
              <div>
                <!-- <p> Don't Have an Account? <a [routerLink]="['/newsignup']" style="color: #1e5ee5;"> Register Here</a></p> -->
              </div>
              <div>
                <p>
                  <a [routerLink]="['/forgotpassword']" style="color: #1e5ee5"
                    >Forgot Password</a
                  >
                </p>
              </div>
              <hr />
              <!-- <div *ngIf="otp && !onlyphone">
                                <input type="number" class="form-control" id="otp" name="otp" placeholder="Verify Email" [(ngModel)]="otp" />
                            </div>
                            <div *ngIf="otp && onlyphone">
                                <input type="number" class="form-control" id="otp" name="otpp" placeholder="Verify Phone number" [(ngModel)]="otpp" />
                            </div> -->
              <!-- <div>
                                <br> Click Sign Up to accept and agree to the <a [routerLink]="['/termsofservice']">Terms and Conditions</a> and <a [routerLink]="['/privacy']">Privacy Policy</a>

                            </div> -->
              <button
                type="button"
                class="btn btn-primary"
                (click)="OnSubmit()"
              >
                Get In
              </button>
            </div>
            <span style="margin-left: 30px"
              ><a [routerLink]="['/newsignup']">Sign up</a></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<a
  [routerLink]="['/appjobseek']"
  id="clicknow"
  [queryParams]="{ search: search }"
  style="display: none"
></a>
<a
  [routerLink]="['/companyprofile']"
  id="clicknowcompany"
  [queryParams]="{ accid: accid }"
  style="display: none"
></a>
<a
  [routerLink]="['/alljoblist']"
  [queryParams]="{ accid: accid }"
  id="clickalljoblist"
  style="display: none"
></a>
<a
  [routerLink]="['/companyprofile']"
  id="clicknowcompanycorrect"
  [queryParams]="{ status: 'edit' }"
  style="display: none"
></a>
