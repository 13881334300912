import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { streamstore } from './storestream';

@Injectable({
  providedIn: 'root',
})
export class CodeService {
  // private readonly url="https://blockchainmatrimony.com/jobzoneapi/api/";
  readonly url = 'https://blockchainmatrimony.com/jobzoneapi/api/';

  constructor(private http: HttpClient) {}
  savecode(data) {
    return this.http.post(this.url + 'code/saveCode/', data);
  }
  savewrittencode(data) {
    return this.http.post(this.url + 'code/savewrittenCode/', data);
  }

  saveHostId(data) {
    return this.http.post(this.url + 'VideoCall/ScheduleCall', data);
  }
  getusermailid(email) {
    return this.http.post(this.url + 'VideoCall/getUserId', email);
  }
  storePeer(hostid, myid, peerid) {
    const data: streamstore = {
      peerid: peerid,
      qid: hostid,
      uid: myid,
    };
    return this.http.post(this.url + 'VideoCall/storePeerClient', data);
  }
  storePeerHost(hostid, myid, peerid) {
    const data: streamstore = {
      peerid: peerid,
      qid: hostid,
      uid: myid,
    };
    return this.http.post(this.url + 'VideoCall/storePeerHost', data);
  }
}
