<br><br>
<div id="parent">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset=md-1">
                <div class="row">
                    <div class="col-md-3"></div>
                    <!-- <div class="col-md-5 register-left">
                        <div style="padding-top: 40px;">
                            <h1>Jobs-zone</h1>
                            <h2></h2>
                            <p>Don't remember your passwords! <br> Not to worry we will get it back to you. <br> Reset your password in one step. <br> Verify your Email and Reset your password.</p>
                            <img src="assets/img/forgot.svg" alt="" height="350px" width="350px" style="padding-bottom:60px;" data-aos="zoom-in" data-aos-anchor="#example-anchor" data-aos-offset="500" data-aos-duration="1000">
                            <br>
                        </div>
                      
                    </div> -->
                    <div class="col-md-6 register-right">
                       
                        <h2>Welcome</h2>
                        <hr>
                 
                                 
                                                          <p style="text-align: center;">Verify your Email and Reset your password.</p>

                     
                     
                        <div class="register-form">
                            <!-- <div class="form-group">
                                <input type="text" name="name" class="form-control" placeholder="Name" [(ngModel)]="name">
                            </div> -->
                            <div *ngIf="beforeotp">
                                <div class="form-group">
                                    <input type="text" name="email" class="form-control" placeholder="Email or Phone" [(ngModel)]="email">
                                </div>
                                <!-- <div class="form-group" *ngIf="onlyphone">
                                <input type="number" name="phone" class="form-control" placeholder="Phone" [(ngModel)]="phone">
                            </div> -->
                                <!-- <div (click)="changetophone()" *ngIf="!onlyphone" style="color: #1e5ee5;cursor: pointer;">Sign up with phone number</div>
                            <div (click)="changetophone()" *ngIf="onlyphone" style="color: #1e5ee5;cursor: pointer;">Sign up with Email</div>
                            <br> -->

                                <div class="form-group" *ngIf="openotp">
                                    <hr>
                                    <input type="number" name="otp" class="form-control" placeholder="Enter OTP" [(ngModel)]="otp">
                                </div>

                            </div>
                            <div *ngIf="afterotp">
                                <div class="form-group">
                                    <input type="password" class="form-control" id="p1" name="p1" placeholder="Password" [(ngModel)]="p1" />
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" id="p2" name="p2" placeholder="Confirm Password" [(ngModel)]="p2" />
                                </div>
                            </div>

                            <!-- <div *ngIf="beforeotp">
                                <hr>
                                <p> Don't Have an Account? <a [routerLink]="['/newsignup']" style="color: #1e5ee5;"> Register Here</a></p>
                            </div> -->

                            <hr>
                            <!-- <div *ngIf="otp && !onlyphone">
                                <input type="number" class="form-control" id="otp" name="otp" placeholder="Verify Email" [(ngModel)]="otp" />
                            </div>
                            <div *ngIf="otp && onlyphone">
                                <input type="number" class="form-control" id="otp" name="otpp" placeholder="Verify Phone number" [(ngModel)]="otpp" />
                            </div> -->
                            <!-- <div>
                                <br> Click Sign Up to accept and agree to the <a [routerLink]="['/termsofservice']">Terms and Conditions</a> and <a [routerLink]="['/privacy']">Privacy Policy</a>

                            </div> -->



                            <button type="button" *ngIf="beforeotp" class="btn btn-primary" (click)="OnSubmit()">Submit</button>
                            <button type="button" *ngIf="afterotp" class="btn btn-primary" (click)="changepassword()">Reset Password</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>