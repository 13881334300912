import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OptionService {
  // readonly rooturl = "https://blockchainmatrimony.com/jobzoneapi/api/"

  readonly rooturl = 'https://blockchainmatrimony.com/jobzoneapi/api/';

  constructor(private http: HttpClient) {}

  getAlloptions() {
    return this.http.get(this.rooturl + 'OptionMaster/GettblOptionMaster');
  }

  getcourseoptions(id) {
    return this.http.get(this.rooturl + 'OptionMaster/GetCourseoption/' + id);
  }

  saveoption(data: any) {
    return this.http.post(
      this.rooturl + 'OptionMaster/PosttbloptionBankMaster',
      data
    );
  }

  UpdateoptionRow(data: any) {
    return this.http.put(
      this.rooturl + 'OptionMaster/PuttblOptionMaster',
      data
    );
  }

  deleteoptiondata(id: any) {
    return this.http.delete(
      this.rooturl + 'OptionMaster/DeletetblOptionMaster/' + id
    );
  }
}
