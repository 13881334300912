<app-nav-bar></app-nav-bar>
<br/><br/><br/><br/><br/>
<div>&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-8"></div>
    <div class="col-md-4">
        <button class="btn btn-primary" (click)="AddFriend(addfriend)">Add Friend</button>
    </div>
</div>
<div class="container register">
    <div>
        <div class="row col-sm-12">
            <div class="col-sm-4">&nbsp;</div>
            <div class="col-sm-4">
                <h2 style="text-align: center; color: rgb(0, 0, 0)">Recieved Requests</h2>
            </div>
            <br />
            <br />
            <br />
        </div>
        <br />
        <div class="row col-sm-12">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead class="thead-dark">
                        <tr style="text-align: center;">
                            <th></th>
                            <th>From</th>
                            <th>Email/Mobile Number</th>
                            <th>Date</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tr style="text-align: center;" *ngFor="
              let inbox of RecievedFriendRequests"
               >
                        <td>
                            <i class="fas fa-user"></i>
                        </td>
                        <td>

                            {{ inbox.friendName }}
                        </td>
                        <td>
                            {{
                            inbox.email
                            }}
                        </td>
                        <td>

                            {{ inbox.date|date }}
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="Accept(inbox)">Accept</button>
                        </td>
                    </tr>
                </table>
                <br />
                
            </div>
        </div>
    </div>
</div>
<br />
<div class="container register">
    <div>
        <div class="row col-sm-12">
            <div class="col-sm-4">&nbsp;</div>
            <div class="col-sm-4">
                <h2 style="text-align: center; color: rgb(0, 0, 0)">Sent Requests</h2>
            </div>
            <br />
            <br />
            <br />
        </div>
        <br />
        <div class="row col-sm-12">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead class="thead-dark">
                        <tr style="text-align: center;">
                            <th></th>
                            <th>To</th>
                            <th>Email/Mobile Number</th>
                            <th>Date</th>

                        </tr>
                    </thead>
                    <tr style="text-align: center;" *ngFor="
              let inbox of SentFriendRequests"
                
              
            >
                        <td>
                            <i class="fas fa-user"></i>
                        </td>
                        <td>

                            {{ inbox.friendName }}
                        </td>
                        <td>
                            {{
                            inbox.email
                            }}
                        </td>
                        <td>

                            {{ inbox.date |date }}
                        </td>

                    </tr>
                </table>
                <br />
               
            </div>
        </div>
    </div>
</div>
<br />
<div class="container register">
    <div>
        <div class="row col-sm-12">
            <div class="col-sm-4">&nbsp;</div>
            <div class="col-sm-4">
                <h2 style="text-align: center; color: rgb(0, 0, 0)"> Friends</h2>
            </div>
            <br />
            <br />
            <br />
        </div>
        <br />
        <div class="row col-sm-12">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead class="thead-dark">
                        <tr style="text-align: center;">
                            <th></th>
                            <th>From</th>
                            <th>Email/Mobile Number</th>


                        </tr>
                    </thead>
                    <tr style="text-align: center;" *ngFor="
              let inbox of Friends"
               >
                        <td>
                            <i class="fas fa-user"></i>
                        </td>
                        <td>

                            {{ inbox.friendName }}
                        </td>
                        <td>
                            {{
                            inbox.email
                            }}
                        </td>


                    </tr>
                </table>
                <br />
               
            </div>
        </div>
    </div>
</div>
<ng-template #addfriend let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Add Friends</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="font-size: 16px;">
        <div>

            <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email/Mobile"
                name="dp" />
        </div>
        <br />
    </div>
    <br />
    <div>
        
    </div>
   
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
            style="color: black; color: white;padding: 5px;margin-left: 10px;">
            Send Request
        </button>
    </div>
    <br />
</ng-template>
<br /><br />