<br>
<div class="col-md-12 text-right">
    <video #onlymyvideo style="width: 200px;height: 200px;" muted playsinline></video>

</div>
<div style="width: 400px; height: 300px; " id="remote-video"></div>
<div class="col-md-12 text-center">
    <video #myvideo playsinline></video>
</div>
<br><br><br><br><br>
<br><br><br><br><br>