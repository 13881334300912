<br><br>
<br><br>
<br><br>
<h1 style="text-align: center;">Issue Certificate </h1>
<div style="width: 50%" class="col-lg-2">
    <div class="dropdown-cover field-cover   has-input">
        <span class="sub-label-true">
            <span class="main-label">
                <span style="color:black"><h2>Select the type of Certificate</h2></span>
        </span>
        <span class="sub-label">
            </span>
        </span>
        <div>
            <select [(ngModel)]="crttype" class="form-control" data-is-required="true" name="gender">
                <option value="">Select An Option</option>
                <option value="AgreementofService" class=" ">Agreement of Service</option>
                <option value="NDA" class=" ">NDA</option>
                <option value="OfferLetter" class=" ">Offer Letter</option>
            </select>
        </div>
    </div>
</div>
<br>
<br>







<div class="col-lg-12 row">
    <div class="col-lg-1"></div>
    <div style="width: 25%" class="col-lg-2">
        <div class="dropdown-cover field-cover   has-input">
            <span class="sub-label-true">
            <span class="main-label">
                <span style="color:black">Working From Date</span>
            </span>
            <span class="sub-label">
            </span>
            </span>
            <div>
                <input class="form-control" type="text" [(ngModel)]="timeduration" id="noOfPlates" name="noOfPlates"><br>
            </div>
        </div>
    </div>
    <div style="width: 25%" class="col-lg-2">
        <div class="dropdown-cover field-cover   has-input">
            <span class="sub-label-true">
            <span class="main-label">
                <span style="color:black">Working Last Date</span>
            </span>
            <span class="sub-label">
            </span>
            </span>
            <div>
                <input class="form-control" type="text" [(ngModel)]="lastdate" id="noOfPlates" name="noOfPlates"><br>
            </div>
        </div>
    </div>
    <div style="width: 50%" class="col-lg-2">
        <div class="dropdown-cover field-cover   has-input">
            <span class="sub-label-true">
            <span class="main-label">
                <span style="color:black">Probation Period Salary</span>
            </span>
            <span class="sub-label">
            </span>
            </span>
            <div>
                <input class="form-control" type="number" [(ngModel)]="sal" id="noOfPlates" name="noOfPlates"><br>
            </div>
        </div>
    </div>
</div>


<div class="col-lg-12 row">
    <div class="col-lg-6"></div>
    <div style="width: 50%" class="col-lg-2">
        <div class="dropdown-cover field-cover   has-input">
            <span class="sub-label-true">
            <span class="main-label">
                <span style="color:black"></span>
            </span>
            <span class="sub-label">
            </span>
            </span>
            <div><br><br>
                <button class="btn btn-primary" type="button" id="submit" name="submit" (click)="save()">SUBMIT</button><br>
            </div>
        </div>
    </div>
</div>
<br>
<div *ngIf="crttype == 'AgreementofService'">
    <div class="col-md-12 row">
        <div class="col-md-2">
            &nbsp;
        </div>
        <div id="printsectionAgreementofService" class="col-md-8 table-bordered" style="background-color:white;border-color:forestgreen;border-width:2px">
            <div>&nbsp;</div>
            <b>AGREEMENT OF SERVICE</b> <br />

            <p>
                THIS DEED of Agreement of Service is made at Bangalore on this {{TodayDate}}<br> BETWEEN
            </p>

            <p>
                {{alldata.companyName}} a Company incorporated and Registered under the Companies Act,<br> 1956, having its Registered Office at <span *ngIf="alldata.regaddtwo">{{ alldata.regaddone }}</span><span *ngIf="alldata.regaddtwo">, {{ alldata.regaddtwo }}</span>, which expression shall mean and<br> include unless repugnant to the context
                their successors and assigns), of the FIRST PART.<br> AND
                <br> {{candidateName}} Son of {{parentName}}, resident of <br> {{address}}, bearing Aadhar No {{adhaar}}<br> (hereinafter called “the Employee”, which expression shall mean and include<br> unless repugnant to the context his/her heirs,
                successors, legal representatives’ executors, administrators,<br> and assignees) of the SECOND PART.<br>
                <br>

            </p>

            <p>WHEREAS:<br></p>
            <p>
                1. The Company is engaged in the business of IT Solutions and Services, Business and has extensive dealings with various Clients and parties in this behalf, requiring Executives who are well versed in handling business, technical and commercial
                IT work and assignments for productively interacting with the Clients and prospective Customers. {{ alldata.companyName}} is an employer at will.
                <br>
            </p>

            <p>
                2. Before offering formal appointment, the Company imparts to the prospective Employee, considered suitable and selected for such assignment, such basic training and know-how as is necessary for the Employee to discharge his/her duties, involving investment
                of time and money on the part of the Company.
                <br>
            </p>

            <p>
                3. The Company also seeks to safeguard and protect their commercial and business data concerning their present Clients and Business Associates and good will in the market by suitably advising the prospective Employee and obtaining Undertakings from the
                Employee that for a period specified in the agreement, he/she shall not join or collaborate or associate or assist or deal with in any manner whatsoever directly or otherwise or provide consultancy in any manner to any competitor including
                Firm or Company or act on their behalf with or without consideration or return or gratis or by way of favor, so that the current business deals and terms are not taken advantage of by any business rival / competitor or firm or Company
                which is similarly engaged in the business as that of the Employer. Further, the Employee shall not engage himself /herself in similar business as that of the Company or use contact and goodwill of the Company for his/her personal gain
                and benefit.<br>
                <br>
            </p>

            <p>
                4. On being approached by {{candidateName}}, the Company has appointed {{candidateName}} as Software Engineer upon certain terms and conditions incorporated in this Agreement and on the acceptance of his/her representations that he/she will abide by the
                Negative Covenant, as detailed hereinafter, once his/her contract of service stands terminated for whatsoever reason.
                <br>
            </p>

            <p>
                <br> NOW THIS AGREEMENT WITNESSETH:<br>
            </p>
            <p>
                1. {{candidateName}} is appointed as Full Time Staff in the Company with effect from {{timeduration}} to {{lastdate}} Software Engineer in the Company.<br>

            </p>
            <p>
                2. His/her appointment is subject to his/her unconditional acceptance of the following terms:<br>
            </p>
            <p>
                3. Initially He/she will be on a probationary period for SIX months with effect from{{timeduration}}. During the prod of probation, she will be paid consolidatedpay of Rs{{sal}} After six months, his/her performance is reviewed and if his/her work is satisfactory,
                then He/She will be confirmed; otherwise, he/she will be terminated from the post.<br>

            </p>
            <p>
                4. After his/her confirmation, his/her salary will be fixed solely according to his/her performance which will include variable performance incentive component as well.<br>

            </p>
            <p>
                5. He/She has to assist the other employees in the discharge of their official duties as and when required.<br>

            </p>
            <p>
                6. He/She has to perform software development, testing, hosting, Business Development, Training and Any other duties assigned by Company from time to time.<br>
            </p>
            <p>
                7. In the process of his/her work in the Company, she will be the responsible for the maintaining safety security and chain of custody of all the documents / objects received for various types of work in the Company.<br>
            </p>
            <p>
                8. He/She will be responsible for maintaining the official secrecy confidentiality and propriety in the course of discharge of his/her assigned duties and responsibilities and She shall always uphold the dignity of the office of{{ alldata.companyName}} and
                the company and his/her conduct shall always be above reproach and free from moral turpitude.<br>

            </p>
            <p>
                9. He/She will also take oath of allegiance to follow the rules and regulations of the Company in full measure and to maintain strict confidentiality and non-disclosure of any internal information of {{ alldata.companyName}} or it activities at any time
                in future and any circumstances.
                <br>
            </p>
            <p>
                10. She will deal with the individuals and institutions seeking various types of services offered by {{ alldata.companyName}} Technologies in a truly professional manner keeping in view the public concern, care, compassion, competence and correctness
                in the delivery of the services.<br>

            </p>
            <p>
                11. Breach of any one of the conditions above will render his/her liable for the termination of his/her employment without notice.<br>

            </p>
            <p>
                12. The Company has appointed {{candidateName}} as Software Engineer, for the duties/work assigned to her/his from time to time, for a period of THIRTY MONTHS commencing from {{timeduration}} and ending on {{lastdate}}. The Employee’s place of posting
                at present will be in Bangalore. However, he/she shall be liable to be transferred to any other Department or Branch Office in India or abroad either in existence or that may be set up at a future date as per the requirements of the Company.<br>

            </p>
            <p>
                13. In terms of the Agreement the Company shall pay the Employee salary with increments subject to performance, conduct and other relevant parameters. The grant of salary and an increment will be at the sole discretion of the Company.<br>

            </p>
            <p>
                14. The Employee shall, in the discharge of his/her duties, conform to and comply with the Staff Regulations of the Company and all the other general rules and regulations of the Company and shall not do or cause to be done anything against or contrary
                to the interests of the Company. The Employee will follow all instructions to upgrade his/her skills and keep abreast with the latest techniques and procedure in the said Information Technology Field and the special activity he/she or
                she/he is engaged or entrusted with by the Employer or his/her Representative, as the case may be and any failure to do so will be regarded as inadequacy in performance.<br>

            </p>
            <p>
                15. The Employee shall, during the said term, engage herself / himself efficiently and to the best of his/her ability and shall devote his/her whole time and attention to the concerned Department and faithfully carry out duties/work as assigned to his/her
                and shall obey and comply with orders and directions given to his/her by the Management of the Company and the Staff superior to his/her, and shall honestly, diligently and faithfully serve the Company and use his/her utmost endeavor to
                promote the business interests of the Company.<br>

            </p>
            <p>
                16. The Employee further acknowledge, accept and agree that it is also legitimate and fair for Company to safeguard and protect its commercial and business data, goodwill in the market, information and interests by obtaining undertakings from Executive
                that for the period specified in this Agreement Executive shall not join, collaborate, associate, assist or deal or provide consultancy in any manner, whatsoever whether directly or indirectly, to any competitor of Company engaged in similar
                business nor shall the Executive engage himself/herself in a similar business as that of the Company or use his/her contact for his/her own personal gain and profit.<br>

            </p>
            <p>
                17. The Employee shall not at any time divulge to any person whosoever and shall under no circumstance permit the circulation or publication or disclosure of any trade secret or any information concerning the activities or decisions of the Employer or
                of its dealings, transactions, or affairs which may come to his/her knowledge while in the services of the Company. If at any time during his/her employment, the Employee is found guilty of willful breach or negligence of the terms of
                his/her assignment or dereliction of duty and/or instructions given to his/her from time to time by the Company, the Company may without prejudice to any other action as may be called for, without any notice or payment in lieu of any notice,
                put an end to and determine the employment of the Employee. However, the Company shall so intimate to the Employee concerned in this regard. In such circumstances it will be deemed that the Employee himself/herself so brought about such
                a situation by his/her conduct compelling the Company to put an end to his/her services and the Employee shall continue to be liable for all losses/damages as stipulated hereinafter.<br>
            </p>
            <p>
                18. The Employee shall not leave or abandon the service of the Company until the completion of the agreed period of service and without handing over the charge together with the data, software’s and the computer systems in his/her use and custody to the
                Company and without obtaining the acknowledgement thereof. Before completion of the agreed period of service, the Employee is bound to give three months prior notice in writing or tender the last three month’s drawn salary in lieu thereof
                before leaving the services of the Company.
                <br>
            </p>
            <p>
                19. In case of any breach of this Covenant, the following conditions shall be attracted immediately, namely: - <br>
            </p>
            <p>
                (a). The Company will tender in writing the specific grounds that amount to breach of Contract on the part of the Employee concerned. The Employee will be granted three days’ time to reply to the same in writing. If the reply is found unsatisfactory by
                the Company, the Employee will be required to tender three months’ salary in lieu of notice. The “salary” means pay along with all allowances, paid to an Employee while on duty. The said payment of three months’ salary may be adjusted
                by the Company against dues payable to the Employee if the said amount is so available.

            </p>
            <p>
                (b). That the parties hereby agree that the minimum payment on account of damages for the uncompleted service for whatever reason shall be Rupees Five Lakhs. The Employee affirms that it shall not be open to him/her to plead that the amount of compensation
                is excessive or that it tantamount to penalty or that it is otherwise not recoverable according to law.
            </p>
            <p>
                (c). The Company, without prejudice to their rights, may consider waiving of making the claim in case of serious illness, injury, physical disability or such personal and compelling circumstances as may be reasonable to warrant the course of action decided
                upon the Employee.<br>
            </p>


            <p>
                20. The Employee hereby agrees and undertakes: -<br>

            </p>
            <p>
                20.1 To serve the Company or any of its holding, subsidiary, associate or affiliate organizations/entities to which he/she may be transferred for a minimum period of THIRTY MONTHS from the date of appointment, excluding unauthorized absence, leave without
                pay and study leave, if any.

            </p>
            <p>
                20.2 To obey and abide by all the orders, instructions, rules, staff regulations, service conditions and standing orders of the Company and of its authorized officers and representatives, issued from time to time.

            </p>
            <p>
                20.4 Not to take up employment or consultancy services either full time or part time in whatsoever manner with any other person, Firm or Company during the said period of THIRTY MONTHS.
                <br>

            </p>
            <p>
                20.5 To serve the Company loyally and treat all information, data, business plans, business affairs, contacts and connections of Company in strict confidence and to use them exclusively for the benefit and profit of the Company.
                <br>

            </p>
            <p>
                20.6 Not to share or sell the goodwill / It Solutions / Intellectual Properties of the Company for his/her own benefit or of anybody else or anybody corporate, which has been built over a period of 11 years involving considerable expenditure on the part
                of the Company during the subsistence of this agreement or for a period of Six months after it’s termination or otherwise.

            </p>
            <p>

                20.7 Not to take up any whole time or part time employment or consultancy of any nature or assignment, whether for remuneration or otherwise, with any other person, any client and /or competitor of Company in order to protect company’s goodwill from the
                date he/she leaves the services of the company (even if he/she leaves the services of the company after paying liabilities to company and fulfilling all other conditions as stipulated in this agreement), or from the date of discharge or
                termination from the services of the company, as the case may be.

            </p>
            <p>
                20.8 The Employee agrees to the noncompete obligation of not joining or serving directly or indirectly any Company or organization during their tenure and not joining or serving directly or indirectly any client of the Company for a period of three years
                from the cessation of their service in the Company.

            </p>
            <p>
                20.9 The Employee agrees voluntarily to deposit their Original Certificates and marks Cards with the Company during their tenure which should be returned to his/her after the completion of the service period of 30 months or his/her cessation of service.
                <br>

            </p>
            <p>
                21. The claim for compensation and liquidated damages for any breach and all other matters, actions, disputes, claims, differences or controversies between the parties hereto arising out of or in connection with or in relation to this Agreement, whether
                in contract, tort, or otherwise shall be settled and resolved by an Arbitrator to be conducted at Bangalore, in accordance with the Arbitration & Conciliation Act, 1996, by a Sole Arbitrator appointed by the Company. The decision of the
                Sole Arbitrator shall be final and binding on both the parties.

            </p>
            <p>

                22. In the event any provision of this Agreement is found to be invalid, unlawful or unenforceable to any extent, such term, condition and provision will, to that extent, be severed from the remaining terms, conditions and provisions which will continue
                to be valid to the fullest extent permitted by law.

            </p>
            <p>
                23. This Agreement will not be superseded by any other contract or terms and conditions of service governing the Employee’s services in the Company including those contained in the Appointment Letter, confirmation letter or any other letter or communication
                issued or that may be issued by the Company to the Employee.<br>

            </p>

            <p>
                24. Both the parties herein agree and covenant to that on expiry of the 30 months period commencing from {{timeduration}} to {{lastdate}}, as per the covenants hereinabove contained, this agreement with all the terms and conditions as also covenants and
                obligations as contained herein, would continue to govern both the parties for further period until it is terminated.<br>

            </p>

            <p>
                IN WITNESS WHERE OF the parties hereto have set their hands to this Agreement on the day and year first<br> hereinabove mentioned.<br>
            </p>


            <br><br><br><br> For {{ alldata.companyName}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Employee<br> {{ alldata.contactPersonName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            _______________
            <br> CEO
            <br> Witness: ____________ <br> Witness: ____________<br>


        </div>

    </div>
</div>
<div *ngIf="crttype == 'NDA'">
    <div class="col-md-12 row">
        <div class="col-md-2">
            &nbsp;
        </div>
        <br><br>
        <div id="printsectionNDA" class="col-md-8 table-bordered" style="background-color:white;border-color:forestgreen;border-width:2px">
            <br><br>
            <p>
                disagreement DATE: {{TodayDate}} NDA#:2 DISCLOSING PARTY: STPL RECEIVING PARTY: {{candidateName}}<br><br><br> NON-DISCLOSURE AGREEMENT<br><br><br>
                <p><br> This Non-Disclosure Agreement (“Agreement”) is entered into and made effective as of the date set forth above, by and between {{candidateName}}, Employee {{ alldata.companyName}} Ltd and its divisions, and {{ alldata.companyName}}
                    Limited, as identified below.<br>
                </p>
                <p>THE PARTIES AGREE AS FOLLOWS:<br> </p>
                <p>
                    • Confidential Information. The confidential proprietary and trade secret information of the disclosing party (“Confidential Information”) to be disclosed here under is (i) information in tangible from that bears a “confidential”, “proprietary”, “secret”,
                    or similar legend, and (ii) discussions relating to that information whether those discussions occur prior to, concurrent with, or following disclosure of the information. The disclosing party shall make reasonable efforts to mark
                    its confidential information, shall nevertheless be protected hereunder as Confidential Information, if the receiving party knew, or should have reasonably known under the circumstances, that the information was confidential and had
                    been communicated to it in confidence.</p>




                <p>
                    • Obligations of Receiving Party. The receiving party will maintain the confidentiality of the Confidential Information of the disclosing part with at least the same degree of care that it uses to protect its own confidential and proprietary information,
                    but no less than a reasonable degree of care under the circumstances. The receiving party will not disclose any of the disclosing party’s Confidential Information to any employees or to any third parties except to the receiving; party’s
                    employees, parent company and majority-owned subsidiaries who have a need to know and who agree to abide by nondisclosure terms at least as comprehensive as those set forth herein; provided that the receiving party will be liable for
                    breach by any such entity. For the purpose of this Agreement, the term “employees” shall include independent contractors of each party. The receiving party will not make any copies of the Confidential Information received from the
                    disclosing party except as necessary for its employees, parent company and majority-owned subsidiaries with a need to know. Any copies which are made will be identified as belonging to the disclosing party and marked; “confidential”,
                    “proprietary” or with a similar legend.
                    <br>
                </p>

                <p>
                    • Termination of Obligation of Confidentiality. The confidentiality obligations set forth in this Agreement shall bind the parties for a period of five (5) years from the date of disclosure of Confidential Information, unless any of the exceptions set
                    forth in Section 4 below occurs<br>
                </p>
                <p>
                    • Exceptions to the Obligation of Confidentiality. The receiving party will not be liable for the disclosure of any Confidential Information which is:<br>

                </p>
                <p>
                    • Generally made available publicly or to third parties by the disclosing party without restriction on disclosure;<br>
                </p>
                <p>
                    • Rightfully received from a third party without any obligation of confidentiality;<br>
                </p>
               
                <p>
                    • Rightfully known to the receiving party without any limitation on disclosure prior to its receipt from the disclosing party;<br>
                </p>
                <p>
                    • Independently developed by employees of the receiving party;<br>or
                </p>
                <p>
                    • Required to be disclosed in accordance with applicable laws, regulations, court, judicial or other government order, provided that the receiving party shall give the disclosing party reasonable notice prior to such disclosure and shall comply with any
                    applicable protective order.<br>
                </p>
                <p>
                    • Title. Title or the right to possess Confidential Information as between the parties will remain in the disclosing party.
                    <br>
                </p>
                <p>
                    • No Obligation of Disclosure; Termination. Neither party has any obligation to disclose Confidential Information to the other. Either party may terminate this Agreement at any time without cause upon written notice to the other party; provided that each
                    party’s obligations with respect to Confidential Information disclosed during the term of this Agreement will survive any such termination. Either party may, at any time: (a) cease giving Confidential Information to the other party
                    without any liability, and/or (b) request in writing the return or destruction of all or part of its Confidential Information previously disclosed, and all copies thereof, and the receiving party will promptly comply with such request
                    and certify in writing its compliance.<br>

                </p>
                <p>
                    • <u>General.</u><br>

                </p>
                <p>
                    • This Agreement is neither intended to nor will it be construed as creating a joint venture, partnership or other form of business association between the parties, nor an obligation to buy or sell products using or incorporating the Confidential Information.<br>

                </p>
                <p>
                    • Both parties understand and acknowledge that no license under any patents, copyrights, trademarks, or mask works is granted to or conferred upon either party in this Agreement or by the disclosure of any Confidential Information by one party to the
                    other party as contemplated hereunder, either expressly, by implication, inducement, estoppel or otherwise, and that any license under such intellectual property rights must be express and in writing.

                </p>
                <p>
                    • The failure of either party to enforce any right resulting from breach of any provision of this Agreement by the other party will not be deemed a waiver of any right relating to a subsequent breach of such provision or of any other right hereunder.<br>
                </p>
                <p>
                    • This Agreement will be governed by the laws of India without reference to conflict of laws principle, if any. And the courts in Bangalore, Karnataka state alone will have exclusive jurisdiction in regard to NDA.<br>
                </p>
                <p>
                    • This agreement constitutes the sole and entire agreement between the parties with respect to the Confidential Information and all restrictions thereon; it supersedes any and all prior or contemporaneous oral or written agreements, negotiations, communications,
                    understandings and terms, whether express or implied regarding the Confidential Information, and may not be amended except in a writing signed by a duly authorized representative of the respective parties. Any other agreements between
                    the parties, including non-disclosure agreements, will not be affected by this Agreement.<br>
                </p>
                <p>
                    • This disclosing party disclaims all warranties regarding all Confidential Information disclosed pursuant to this Agreement, including all warranties as to the accuracy or utility of such Confidential Information.
                </p>

                <br><br>
                <br><br>
                <table style="border:1px solid black" class="col-md-12">
                    <tr style="text-align:left;height:50px;border:1px solid black">
                        <td style="border:1px solid black;text-align:left;font-size:12px;">Contact
                            <br /></td>
                        <td style="border:1px solid black;text-align:left;font-size:12px">Adhar Number<br />
                        </td>
                        <td style="border:1px solid black;text-align:left;font-size:12px">Mail<br />
                        </td>
                        <td style="border:1px solid black;text-align:left;font-size:12px">Mobile<br />
                        </td>
                    </tr>
                    <tr style="text-align:left;height:50px;border:1px solid black">
                        <td style="border:1px solid black;text-align:left;font-size:12px;">{{candidateName}}
                            <br /></td>
                        <td style="border:1px solid black;text-align:left;font-size:12px">{{adhaar}}
                            <br />
                        </td>
                        <td style="border:1px solid black;text-align:left;font-size:12px">{{mail}}
                            <br />
                        </td>
                        <td style="border:1px solid black;text-align:left;font-size:12px">{{mobnum}}
                            <br />
                        </td>
                    </tr>
                </table>
                <br><br><br><br>
                <p>
                    <br><br><br><br> AGREED: <br> Employee Name & Address: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company
                    Name & Address:<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ alldata.companyName}} <br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b style="float: right; font-weight: 500;">{{ alldata.regaddone }}
                        <span *ngIf="alldata.regaddtwo">, {{ alldata.regaddtwo }}</span
                        >
                        <span *ngIf="alldata.regaddthree"
                          >, {{ alldata.regaddthree }}</span
                        >, <br />
                        near {{ alldata.regaddlanmark }} <br />
                        {{ alldata.regaddcity }}, {{ alldata.regaddstate }}, <br />{{
                          alldata.regaddcountry
                        }}
                        - {{ alldata.regaddpincode }}
                      </b><br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br>


                    <br>
                    <br><br> Signature &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Signature of Authorized Official <br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name
                    & Title:


        </div>
        <div class="col-md-1">
            &nbsp;
        </div>
    </div>
</div>
<div *ngIf="crttype == 'OfferLetter'">
    <div class="col-md-12 row">
        <div class="col-md-2">
            &nbsp;
        </div>
        <br><br>
        <div id="printsectionOfferLetter" class="col-md-8 table-bordered" style="background-color:white;border-color:forestgreen;border-width:2px">
            <br><br>
            <br>
            <br><br> {{candidateName}}<br> <br> Dear {{candidateName}}, <b>{{ alldata.regaddone }}
                <span *ngIf="alldata.regaddtwo"
                  >, {{ alldata.regaddtwo }}</span
                >
                <span *ngIf="alldata.regaddthree"
                  >, {{ alldata.regaddthree }}</span
                >, <br />
                near {{ alldata.regaddlanmark }} <br />
                {{ alldata.regaddcity }}, {{ alldata.regaddstate }}, <br />{{
                  alldata.regaddcountry
                }}
                - {{ alldata.regaddpincode }}
              </b>. {{alldata.contactPersonPhone}}<br>{{alldata.webSite}}
            <br> ----------------------------------------------------------------------------------------------------------------------------
            <br> Date: {{TodayDate}}
            <br> Welcome to {{ alldata.companyName}} Family!<br>
            <p>
                With reference to the interview, you had with us, we are pleased to give you an offer of appointment as Software Engineer position on probation and your joining date will be advised to you in due course<br> <b>{{ alldata.regaddone }}
                    <span *ngIf="alldata.regaddtwo"
                      >, {{ alldata.regaddtwo }}</span
                    >
                    <span *ngIf="alldata.regaddthree"
                      >, {{ alldata.regaddthree }}</span
                    >, <br />
                    near {{ alldata.regaddlanmark }} <br />
                    {{ alldata.regaddcity }}, {{ alldata.regaddstate }}, <br />{{
                      alldata.regaddcountry
                    }}
                    - {{ alldata.regaddpincode }}
                  </b><br> {{alldata.contactPersonPhone}}<br> {{alldata.webSite}}. subject to your successfully completing your FREE ONLINE pre-training at {{ alldata.companyName}}<br> Academy in #C and Angular. <b>  Please note {{ alldata.companyName}} is an employer at will.</b>
            </p>

            <p>
                2.Your role is to make Software Solutions and to perform all other allied professional activities in the Company. You will be on probation for SIX months. During your probation, you will work in developing / testing software; software sales and marketing,
                deputation to work at other {{ alldata.companyName}} concerns; at custom places and all other related works which are assigned lo you by the Company from time to time. You shall also observe the discipline and punctually in timings (9:30 am to 7:30 pm on 6 days of the week with a lunch break from 1 pm to 1: 45 pm) and work diligently to ensure timely deliveries. You will also acquire adequate knowledge about the projects so as to maintain the same in future. During your
                probation, your remuneration will be consolidated amount of Rs. {{sal}}/- (In words) per month during the first six months. We shall fix your regular remuneration based on your performance soon after confirmation.
                <br>
            </p>
            <p>
                3.You shall always adhere to the Staff Regulations and the other policies of the company including the requirement of an agreement to serve the Company for a minimum period of 30 months from the date of your joining the service as a probationer.<br>
            </p>
            <p>
                4.You are advised to report on the joining date to be decided by the Company 9:30am with all of your original certificates and marks cards from the SSLC to Degree, Aadhar Card, Passport and also execute the NDA and Employment Agreement with {{ alldata.companyName}}
                on that day.<br>
            </p>
            <p>
                5. You are also advised to take due care and diligence about your personal safety in Bangalore outside of office. The Company takes no responsibility for it.<br>
            </p>
            <p>
                6.Please confirm and convey your acceptance of this offer by downloading this offer letter; affixing your signature and sending the scan copy of signed letter within a week from the date of this mail. Looking forward, Your sincerely <br>

            </p>
            <p>
                {{alldata.contactPersonName}}<br> Founder & CEO, <br> {{ alldata.companyName}} <br>
            </p>

        </div>
        <div class="col-md-1">
            &nbsp;
        </div>
    </div>
</div>
<div class="col-md-12 row">
    <br><br><br>
    <div class="col-md-4">&nbsp;</div>
    <div *ngIf="crttype == 'AgreementofService'" class="col-md-2">
        <input type="button" (click)="print('printsectionAgreementofService')" value="PRINT" class="form-control btn-warning" />
    </div>
    <div *ngIf="crttype == 'NDA'" class="col-md-2">
        <input type="button" (click)="print('printsectionNDA')" value="PRINT" class="form-control btn-warning" />
    </div>
    <div *ngIf="crttype == 'OfferLetter'" class="col-md-2">
        <input type="button" (click)="print('printsectionOfferLetter')" value="PRINT" class="form-control btn-warning" />
    </div>

    <!--<div class="col-md-2">
        <input type="button" ng-show="consumable" (click)="status(billdetails.patientUID)" value="DONE" class="form-control btn-primary" />
    </div>-->
    <div class="col-md-4">&nbsp;</div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
</div>