import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';

@Component({
  selector: 'app-applied',
  templateUrl: './applied.component.html',
  styleUrls: ['./applied.component.scss']
})
export class AppliedComponent implements OnInit {
jobid:any;
alldata:any;
  constructor(private route:ActivatedRoute,private service:RegistrationService) {
    this.route.params.subscribe((data:any)=>{
      if(data){
        this.jobid = data['id'];
        var uid = localStorage.getItem("userId");

        this.service.getallappliedjob(this.jobid,uid).subscribe((data:any)=>{
          if(data.length == 0 ){
            alert("No students have applied yet");
          }
          else{
            this.alldata = data;
            this.alldata.forEach(element => {
              if(element['branch']==1){
                element['branchName']="Computer Science";
              }
              else if(element['branch']==2){
                element['branchName']="Information Science";
              }
              else if(element['branch']==3){
                element['branchName']="Electronics";
              }
              else if(element['branch']==4){
                element['branchName']="Electricals";
              }
              else if(element['branch']==5){
                element['branchName']="Civil";
              }
              else if(element['branch']==6){
                element['branchName']="Mechanical";
              }

            });
          }
        });
      }
    })
   }

  ngOnInit(): void {
    this.sortbyusn();
  }
  sort = "1";

  sortby() {
    if (this.sort == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.alldata.sort((a, b) => a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : 0)
      this.sort = "2";
    }
    else if (this.sort == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.alldata.sort((a, b) => a.userName.toLowerCase() > b.userName.toLowerCase() ? -1 : a.userName.toLowerCase() < b.userName.toLowerCase() ? 1 : 0)

      this.sort = "1";

    }

  }

  sortusn = "1";

  sortbyusn() {
    if (this.sortusn == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.alldata.sort((a, b) => a.usn.toLowerCase() < b.usn.toLowerCase() ? -1 : a.usn.toLowerCase() > b.usn.toLowerCase() ? 1 : 0)
      this.sortusn = "2";
    }
    else if (this.sortusn == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.alldata.sort((a, b) => a.usn.toLowerCase() > b.usn.toLowerCase() ? -1 : a.usn.toLowerCase() < b.usn.toLowerCase() ? 1 : 0)

      this.sortusn = "1";

    }

  }
  getgroups(id){
  var uid = localStorage.getItem("userId");
if(id == '100'){
  location.reload();
}
else{
  this.service.getjobgroups(this.jobid,uid,id).subscribe((data:any)=>{
    if(data.length == 0 ){
      this.alldata = data;
    }
    else{
      this.alldata = data;
      this.alldata.forEach(element => {
        if(element['branch']==1){
          element['branchName']="Computer Science";
        }
        else if(element['branch']==2){
          element['branchName']="Information Science";
        }
        else if(element['branch']==3){
          element['branchName']="Electronics";
        }
        else if(element['branch']==4){
          element['branchName']="Electricals";
        }
        else if(element['branch']==5){
          element['branchName']="Civil";
        }
        else if(element['branch']==6){
          element['branchName']="Mechanical";
        }

      });
    }
  });
}}
}
