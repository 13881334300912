import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'app/shared/MailService/mail.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-read-mail',
  templateUrl: './read-mail.component.html',
  styleUrls: ['./read-mail.component.scss']
})
export class ReadMailComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  mailid: any;
  maildata: any;
  docments: boolean = false;
  Doc: any;
  imgdisplay: any;
  seleteddownload: boolean;
  closeResult: any;
  msg: any; m: string;
  date: any;
  zzz: any;
  current_url: any; displayUrl: any;
  current_url1: any;
  ID: any; pdfUrl: any; pdfUrl1: any; pdfUrl2: any; pdfUrl3: any;
  videoplay: any;
  videodate: any;
  audioplay: any;
  audiodate: any;
  memlist: any;
  tomem: any;
  replytouser: any;
  replayenable: boolean =false;
  mailsubject:any;
  message:any;
  frmDataa: FormData;
  SelectedFiles: any = [];
  selectedFriend: any = [];
  Friends: any;
  frndslist: any;
  Transid: void;
  errorMessages: string;
  email: any;
  serchfind: any;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  frndslistone: any;
  accid: any;
  forwordmaildata: any;
  From: string;
  To: string;
  NumId: string;
  BodyText: string;
  Subject: string;

  constructor(private router: ActivatedRoute,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal, private domSanitizer: DomSanitizer,
    private spinner: NgxSpinnerService, private datepipe: DatePipe, private http: HttpClient,
    private mailservice: MailService) {
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.mailid = params["id"];
      }
    });

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");

     this.From =  localStorage.getItem('fromuser');
     this.To = localStorage.getItem('touser');
     this.NumId = localStorage.getItem('Id');
     this.BodyText = localStorage.getItem('emailbody');
     this.Subject = localStorage.getItem('sub'); 

    }
  }

  ngOnInit(): void {
  }

  replay()
  {
    this.replayenable = true;
  }

  sendmail()
  {
    var det = {
      From: this.usermail,
      To: this.To,
      Subject: this.mailsubject,
      BodyText: this.message,
      reply:"1",
      numberId : this.NumId
    }
    this.spinner.show();

    this.mailservice.sendOrReplyMail(det).subscribe(data=>
      {
        alert(data);
        this.spinner.hide();
        this.route.navigate(['mail/inbox']);
      });
    }
}
