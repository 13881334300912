import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { InstitutionVm } from 'app/shared/VIewmodels/instituionvm';

@Component({
  selector: 'app-instituteregister',
  templateUrl: './instituteregister.component.html',
  styleUrls: ['./instituteregister.component.scss']
})
export class InstituteregisterComponent implements OnInit {
  Industry: any;
  Industrytype: any;
  focus2: boolean;
  email: any;
  phonetwo: boolean;
  serchfind: boolean;
  contemail: any;
  valcontemail: boolean;
  ischecked: any;
  contalone: any = null;
  conaltwo: any = null;
  contcity: any = null;
  conalthree: any = null;
  contlandmark: any = null;
  constate: any = null;
  contpincode: any = null;
  contCountry: any = null;
  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { 
    window.scrollTo(0,0);

  }

  ngOnInit() {
 
    if(localStorage.loadingpage == "true"){
      localStorage.loadingpage = "false";
    location.reload();
  }
    this.GetIndustry();
  }

  GetIndustry() {
    this.registrationService.GetIndrustlist().subscribe((response: any) => {
      this.Industry = response;
    });
  }
  onSelectIndrust(id) {
    this.Industrytype = id;
  }

  regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  phone = new RegExp(/^[6-9]\d{9}$/);
  contactadd(e, form: NgForm) {
    this.ischecked = e.target.checked;
    if (!this.ischecked) {
      this.contalone = null;
      this.conaltwo = null;
      this.conalthree = null;
      this.contcity = null;
      this.contlandmark = null;
      this.constate = null;
      this.contpincode = null;
      this.contCountry = null;
    } else if (this.ischecked) {
      this.contalone = form.controls["alone"].value;
      this.conaltwo = form.controls["altwo"].value;
      this.conalthree = form.controls["althree"].value;
      this.contcity = form.controls["city"].value;
      this.contlandmark = form.controls["landmark"].value;
      this.constate = form.controls["state"].value;
      this.contpincode = form.controls["pincode"].value;
      this.contCountry = form.controls["Country"].value;
    }
  }

  OnSubmitInstituteRegistration(form: NgForm) {
    this.email = form.controls["email"].value;
    this.phonetwo = this.phone.test(form.controls["contactpersonphone"].value);
    this.serchfind = this.regexp.test(this.email);
    this.contemail = form.controls["contactperemail"].value;
    this.valcontemail = this.regexp.test(this.contemail);
    if (
      form.controls["cname"].value == "" ||
      form.controls["cname"].value == null
    ) {
      alert("Enter Company Name");
    } else if (
      form.controls["estyear"].value == null ||
      form.controls["estyear"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter User Name");
    } else if (
      form.controls["numofemp"].value == null ||
      form.controls["numofemp"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter Password");
    } else if (
      form.controls["numofbra"].value == null ||
      form.controls["numofbra"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter Confirm Password");
    }  else if (
      form.controls["regId"].value == null ||
      form.controls["regId"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter Password");
    }  else if (
      form.controls["alone"].value == null ||
      form.controls["alone"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter User Name");
    } else if (
      form.controls["altwo"].value == null ||
      form.controls["altwo"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter User Name");
    } else if (
      form.controls["city"].value == null ||
      form.controls["city"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter User Name");
    } else if (
      form.controls["state"].value == null ||
      form.controls["state"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter User Name");
    } else if (
      form.controls["pincode"].value == null ||
      form.controls["pincode"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter User Name");
    } else if (
      form.controls["Country"].value == null ||
      form.controls["Country"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter User Name");
    } else if (
      form.controls["Website"].value == null ||
      form.controls["Website"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter Password");
    } else if (
      form.controls["email"].value == null ||
      form.controls["email"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter Confirm Password");
    } else if (!this.serchfind) {
      this.spinner.hide();
      alert("Please Enter valid Email");
    } else if (
      form.controls["contpersonname"].value == null ||
      form.controls["contpersonname"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter Confirm Password");
    } else if (
      form.controls["contactperemail"].value == null ||
      form.controls["contactperemail"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter Confirm Password");
    } else if (!this.valcontemail) {
      this.spinner.hide();
      alert("Please Enter valid Email");
    } else if (
      form.controls["contactpersonphone"].value == null ||
      form.controls["contactpersonphone"].value == ""
    ) {
      this.spinner.hide();
      alert("Enter Confirm Password");
    } else if (!this.phonetwo) {
      alert("Please Enter valid Mobile Number");
      this.spinner.hide();
    }  else {
      var cid = localStorage.getItem("uid");
      const inputRequest: InstitutionVm = {
        Id: null,
        InstituteId: cid,
        InstituteName: form.controls["cname"].value,
        Established: form.controls["estyear"].value,
        NumberofEmployees: form.controls["numofemp"].value,
        NumberofBranches: form.controls["numofbra"].value,
        RegistrationId: form.controls["regId"].value,
        IndustryType: this.Industrytype,
        Regaddone: form.controls["alone"].value,
        Regaddtwo: form.controls["altwo"].value,
        Regaddthree: form.controls["althree"].value,
        Regaddcity: form.controls["city"].value,
        Regaddcountry: form.controls["Country"].value,
        Regaddlanmark: form.controls["landmark"].value,
        Regaddpincode: form.controls["pincode"].value,
        Regaddstate: form.controls["state"].value,
        WebSite: form.controls["Website"].value,
        OfficialEmail: form.controls["email"].value,
        ContactPersonName: form.controls["contpersonname"].value,
        ContactPersonEmail: form.controls["contactperemail"].value,
        ContactPersonPhone: form.controls["contactpersonphone"].value,
        
      };
      this.registrationService
        .SaveInstitutionDetails(inputRequest)
        .subscribe((data: any) => {
          if (data != null) {
            alert("Registered Your Details Successfully");
            this.router.navigate([
              '/allfolders'
            ]);
            this.spinner.hide();
          } else {
            alert("Please Fill Details Properly");
            this.spinner.hide();
          }
        });
    }
  }
}