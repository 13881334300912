import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { UserService } from 'app/shared/UserService/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.scss'],
})
export class AdmindashboardComponent implements OnInit {
  job: any;
  isLoggedIn: boolean;
  userid: string;
  accountid: string;
  joblist: any;
  role: string;
  appcount;
  compcount;
  constructor(
    private registrationService: RegistrationService,
    private route: Router,
    private router: ActivatedRoute,
    private service: MasterService,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) {
    window.scrollTo(0, 0);

    if (localStorage.getItem('IsLoggedIn') == 'true') {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem('userId');
      this.accountid = localStorage.getItem('uid');
      this.role = localStorage.getItem('role');
    }
  }
  ngOnInit() {
    this.getallApplicants();
    this.getallcomp();
  }
  getallApplicants() {
    this.userService.GetallUsersApplicant().subscribe((data: any) => {
      this.appcount = data.length;
    });
  }
  getallcomp() {
    this.userService.GetallcompApplicant().subscribe((data: any) => {
      this.compcount = data.length;
    });
  }
}
