<p>allstudentlist works!</p>
<br /><br /><br /><br />
<div class="col-md-12 text-center">
  <div class="col-md-12 text-center row">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-center">
      <h2><strong> All Applicants</strong></h2>
      <table class="table" style="width: 100%">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Applicant Name</th>
            <th>Email</th>
            <th>Details</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let data of appcount; index as i">
            <td>{{ i + 1 }}</td>
            <td>{{ data.userName }}</td>
            <td>{{ data.email }}</td>
            <td>
              <button
                type="button"
                class="btn btn-primary"
                [routerLink]="['/profileview', data.userID]"
              >
                Details
              </button>
            </td>
            <td> <i style="cursor: pointer" class="fa fa-trash" (click)="deletestudent(data.uid)" ></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
