import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  readonly cmsurl = 'http://localhost:19210/api/Account/Savesresponse';
   readonly url =
     'https://blockchainmatrimony.com/jobzoneapi/api/DownloadFolderorFile/GetDownloadBSAFESyncFile';
   readonly portableurl =
     'https://blockchainmatrimony.com/jobzoneapi/api/DownloadFolderorFile/GetDownloadBSAFEPortableFile';
  //readonly url = 'https://blockchainmatrimony.com/jobzoneapi';
  //readonly portableurl = 'https://blockchainmatrimony.com/jobzoneapi';

  constructor(private http: HttpClient) {}

  Savesresponse(responce) {
    return this.http.post(this.cmsurl, responce);
  }

  downloadFile() {
    return this.http.get(this.url);
  }
  downloadFileportable() {
    return this.http.get(this.portableurl);
  }
}
