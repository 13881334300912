<br /><br /><br />
<h1 class="col-md-12 text-center">Jobs</h1>
<div class="col-md-12 text-center row">
  <div class="col-md-3"></div>
  <div class="col-md-6">
    <input
      type="text"
      class="form-control"
      placeholder="Search jobs on Location, Industry, Jobrole"
      (input)="onSearchChange($event.target.value)"
      [(ngModel)]="searchvalue"
    />
  </div>
</div>
<div class="wrapper" *ngIf="!searching">
  <div class="cards_wrap">
    <div class="card_item" *ngFor="let contest of joblist; let i = index">
      <div class="card_inner">
        <!-- <div class="card_top">
                     <img src="data:{{contest.FileType}};base64,{{contest.FilePoster}}" alt="{{contest.FileName}}" /> -->
        <!-- </div>  -->
        <div class="card_bottom">
          <div class="card_category">
            <strong> {{ contest.companyName }}</strong>
          </div>
          <div class="card_info">
            <p class="title">{{ contest.jobRole }}</p>
            <p class="card_info1" style="color: grey">
              <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
              {{ contest.jobLocation }}
            </p>
            <p class="card_info1" style="color: grey">
              <i class="fa fa-building" aria-hidden="true"></i>&nbsp;
              {{ contest.IndustryName }}
            </p>

            <p class="card_info1" style="font-size: 14px">
              {{ contest.jobDescription }}
            </p>
            <p class="card_info1" style="font-size: 14px">
              {{ contest.minimumExp }} yr/s <br />

              <!-- {{contest.ContestDescription ? contest.ContestDescription : 'No - Description'}} -->
            </p>
            <p class="card_info1">
              <button
                (click)="descriptions(contest.jobDescription)"
                data-target="#basicExample"
                data-toggle="modal"
              >
                Read Description
              </button>
            </p>
            <p style="display: flex; justify-content: space-between">
              <strong style="color: limegreen"
                >IDR {{ contest.payScale }}</strong
              >
            </p>
          </div>
          <!-- <div class="card_creator">
                        <a (click)="descriptions(contest)">Read Description</a>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="wrapper" *ngIf="searching">
  <div class="col-md-12 text-center" *ngIf="joblistcopy?.length == '0'">
    <h3>No jobs to show in {{ searchvalue }}</h3>
  </div>
  <div class="cards_wrap">
    <div class="card_item" *ngFor="let contest of joblistcopy; let i = index">
      <div class="card_inner">
        <!-- <div class="card_top">
                     <img src="data:{{contest.FileType}};base64,{{contest.FilePoster}}" alt="{{contest.FileName}}" /> -->
        <!-- </div>  -->
        <div class="card_bottom">
          <div class="card_category">
            <strong> {{ contest.companyName }}</strong>
          </div>
          <div class="card_info">
            <p class="title">{{ contest.jobRole }}</p>
            <p class="card_info1" style="color: grey">
              <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
              {{ contest.jobLocation }}
            </p>
            <p class="card_info1" style="color: grey">
              <i class="fa fa-building" aria-hidden="true"></i>&nbsp;
              {{ contest.IndustryName }}
            </p>

            <p class="card_info1" style="font-size: 14px">
              {{ contest.jobDescription }}
            </p>
            <p class="card_info1" style="font-size: 14px">
              {{ contest.minimumExp }} - {{ contest.maximumExp }} yr/s <br />

              <!-- {{contest.ContestDescription ? contest.ContestDescription : 'No - Description'}} -->
            </p>
            <p class="card_info1">
              <button
                (click)="descriptions(contest.jobDescription)"
                data-target="#basicExample"
                data-toggle="modal"
              >
                Read Description
              </button>
            </p>
            <p style="display: flex; justify-content: space-between">
              <strong style="color: limegreen"
                >IDR {{ contest.payScale }}</strong
              >
            </p>
          </div>
          <!-- <div class="card_creator">
                        <a (click)="descriptions(contest)">Read Description</a>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="basicExample"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="margin-top: 20%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          {{ Jobdescription }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          style="cursor: pointer"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
