import { Component, OnInit, ViewChild } from '@angular/core';
import Peer from 'peerjs'; 
import { ActivatedRoute, Router } from '@angular/router';
import { CodeService } from 'app/newcomponents/mcq/services/code.service';
// declare var Peer:any;

@Component({
  selector: 'app-joinmeeting',
  templateUrl: './joinmeeting.component.html',
  styleUrls: ['./joinmeeting.component.scss']
})
export class JoinmeetingComponent implements OnInit {
hostid;
private peer: Peer;
  peerIdShare: string;
  peerId: string;
  private lazyStream: any;
  currentPeer: any;
  isSafari:any;
  private peerList: Array<any> = [];
  hostjoined: boolean = false;
  constructor(private route:ActivatedRoute,private service:CodeService,private router:Router) {
    this.peer = new Peer();
    this.route.params.subscribe((data:any)=>{
      this.hostid=data['id'];
    });
    window.scrollTo(0,0);
    if(localStorage.userId!=null){
      this.myid=localStorage.userId
    }
    else{
      this.router.navigate(['/newsignin']);
      return;
    }
   }
mypeerid;
// peer;
myid;
  ngOnInit(): void {
    this.getPeerId();
    this.isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
       navigator.userAgent &&
       navigator.userAgent.indexOf('CriOS') == -1 &&
       navigator.userAgent.indexOf('FxiOS') == -1;


    let video;
    var remove = document.getElementById("footerremove");
    if(remove){
      remove.style.display="none";
    }
    setTimeout(() => {
       video = this.myvideo.nativeElement;
      console.log(video)
    }, 5000);
    var now = new Date();
    console.log(now.getTime())
    // this.peer = new Peer();
    // this.peer = new Peer({
    //   secure: true, 
    //             host: 'peerjsapi.blockchainmatrimony.com', 
    //             port: 443,
    //             path:"/",
    //             config: {'iceServers': [
    //               { url: 'stun:stun.l.google.com:19302' }
    //             ]} 
    // });
    // let peerid;
    // this.peer.on('open', function(id) {
    //   peerid=id
    //   console.log('My peer ID is: ' + id);
    // });
    setTimeout(() => {
      this.mypeerid = this.peerId;
      if(this.mypeerid==null||this.mypeerid==undefined){
        location.reload();
      }
      else{
        this.service.storePeer(this.hostid,this.myid,this.mypeerid).subscribe((data:any)=>{
          if(data==-1){
            alert("This meeting is over");
          }
          else if(data==0){
            alert("Either the meeting is over or you are not part of this meeting");
          }
          else{
            this.anotherid=data;
            this.videoconnect();
          }
          console.log(data);
        });
      }
    },3000);
    

    // var n = <any>navigator;
    
    // n.getUserMedia =  ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia );
    
    // this.peer.on('call', function(call) {
    //   n.webkitGetUserMedia({video: true, audio: true},function(stream) {
    //     call.answer(stream);
    //     call.on('stream', function(remotestream){
    //       video.srcObject = remotestream;
    //       video.play();
    //     })
    //   }, function(err) {
    //     console.log('Failed to get stream other', err);
    //   })
    // })
  }
  @ViewChild('myvideo') myvideo: any;
  @ViewChild('onlymyvideo') onlymyvideo: any;

anotherid;
  videoconnect(){
    let video = this.myvideo.nativeElement;
    // var localvar = this.peer;
    // var fname = this.anotherid;
    let myonlyvideo = this.onlymyvideo.nativeElement;
    // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    
    var n = <any>navigator;
    
     n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );
    
     n.getUserMedia({video: true, audio: true}, function(stream) {
       myonlyvideo.srcObject=stream;
       myonlyvideo.play();
       myonlyvideo.muted=true;
    //   var call = localvar.call(fname, stream);
    //   call.on('stream', function(remotestream) {
    //     video.srcObject = remotestream;
    //     video.play();
    //   })
    // }, function(err){
    //   console.log('Failed to get stream', err);
    })
    this.callPeer(this.anotherid)
    
  }

  private getPeerId = () => {
    this.peer.on('open', (id) => {
      this.peerId = id;
    });

    this.peer.on('call', (call) => {
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      }).then((stream) => {
        this.lazyStream = stream;
        console.log(call);
        if(!this.isSafari && call.metadata.isSafari || !this.isSafari && !call.metadata.isSafari ){
          call.answer(stream);
          call.on('stream', (remoteStream) => {
            if (!this.peerList.includes(call.peer)) {
              this.streamRemoteVideo(remoteStream);
              this.currentPeer = call.peerConnection;
              this.peerList.push(call.peer);
            }
          });
        }
        
        else{
          this.connectWithPeer(call.peer);
        }
      
      }).catch(err => {
        console.log(err + 'Unable to get media');
      });
    });
  }
  connectWithPeer(id?): void {
    if(id){
      this.callPeer(id)
    }
    else{
      this.callPeer(this.peerIdShare);

    }
  }

  private callPeer(id: string): void {
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then((stream) => {
      this.lazyStream = stream;

      const call = this.peer.call(id, stream, {metadata: {isSafari: this.isSafari}});
      call.on('stream', (remoteStream) => {
        if (!this.peerList.includes(call.peer)) {
          this.streamRemoteVideo(remoteStream);
          this.currentPeer = call.peerConnection;
          this.peerList.push(call.peer);
        }
      });
    }).catch(err => {
      console.log(err + 'Unable to connect');
    });
  }
  
  private streamRemoteVideo(stream: any): void {
    const video = document.createElement('video');
    video.setAttribute('playsinline','');
    video.classList.add('video');
    video.srcObject = stream;
    video.play();

    document.getElementById('remote-video').append(video);
  }

}
