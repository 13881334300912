import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DepartmentService } from 'app/shared/department.service';
import { RoleService } from 'app/shared/role.service';
import { DesignationService } from 'app/shared/designation.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserdetailsService } from 'app/shared/userdetails.service';
import { EnterpriseService } from 'app/shared/enterprise.service';
import { EmailValidation } from 'app/shared/VIewmodels/emailvalidation';
import { UserDetails } from 'app/shared/VIewmodels/userdetails';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  DepartmentList:any= [];
  DesignationList:any= [];
  RoleList: any=[];
  closeResult: any;
  editfullname: any;
  editmail: any;
  edituserid: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  fullname: any;
  email: any;
  selectedrole: any;
  selecteddesignation: any;
  selecteddepartment: any;
  location: any;
  public dismissalert = false;
  notify: string;
  UserList: any;
  editrole: any;
  editdesignation: any;
  editdepartment: any;
  editlocation: any;
  editemail: any;
  p: number = 1;
  customerid: any;
  pass: any;
  validateemail: any;
  mailresult: any;
  validmail: boolean=false;
  validateusername: any;
  usernameresult: any;
  validusername: boolean;
  constructor(private spinner: NgxSpinnerService, private departmentService: DepartmentService, private designationService: DesignationService,
    private roleService: RoleService,private enterpriseservice:EnterpriseService, private modalService: NgbModal, private userservice: UserdetailsService) { }

  ngOnInit(): void {
    this.Designations();
    this.Roles();
    this.Users();
  }

  Designations() {
    this.spinner.show();
    this.designationService.getAllDesignations().subscribe((Data: any) => {
      this.DesignationList = Data;
      this.spinner.hide();
    });
  }
  Roles() {
    this.spinner.show();
    this.roleService.getAllRoles().subscribe((Data: any) => {
      this.RoleList = Data;
      this.spinner.hide();
    });
  }
  Users() {
    this.spinner.show();
    this.userservice.getAllUsers().subscribe((Data: any) => {
      this.UserList = Data;
      this.spinner.hide();
    });
  }
  OnselectUserRole(id) {
    this.selectedrole = id
    this.spinner.show();
    this.departmentService.getParticularDepart(this.selectedrole).subscribe((Data: any) => {
      this.DepartmentList = Data;
      this.spinner.hide();
    });
  }
  OnselectUserDesignation(id) {
    this.selecteddesignation = id;
  }
  OnselectUserDepartment(id) {
    this.selecteddepartment = id;
    this.spinner.show();
    this.designationService.getParticularDesignations( this.selecteddepartment).subscribe((Data: any) => {
      this.DesignationList = Data;
      this.spinner.hide();
    });
  }
  addUser(adduser) {
    this.modalService.open(adduser).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  EditUser(updatedata, updateuser) {
    this.editfullname = updatedata.userName
    this.editemail = updatedata.email
    this.edituserid = updatedata.id
    this.editrole = updatedata.role
    this.editdesignation = updatedata.designation
    this.editdepartment = updatedata.department
    this.editlocation = updatedata.location
    this.customerid = updatedata.userId;
    this.pass = updatedata.password;
    this.createdon = updatedata.createdOn;
    this.modalService.open(updateuser).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }
  deleteUser(deletedata, deleteuser) {
    this.DeleteName = deletedata.userName;
    this.deleteid = deletedata.id;
    this.modalService.open(deleteuser).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  SaveUser() {
    if(this.validmail==false){

    const inputRequest: UserDetails = {
      Id: 0,
      UserId:0,
      Password:null,
      UserName: this.fullname,
      Email: this.email,
      Role: this.selectedrole,
      Designation: this.selecteddesignation,
      Department: this.selecteddepartment,
      Location: this.location,
      CreatedOn: "",
    }
    this.spinner.show();
    this.userservice.saveUser(inputRequest).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "User added Successfully";
          this.selectedrole=null;
          this.selecteddesignation =null
          this.selecteddepartment=null;
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.Users();
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          this.selectedrole=null;
          this.selecteddesignation =null
          this.selecteddepartment=null;
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.Users();
        }
      });
    }
  }

  UpdateUser() {
    if (this.selectedrole == null || this.selectedrole == undefined) {
      this.selectedrole = this.editrole
    }
    if (this.selecteddesignation == null || this.selecteddesignation == undefined) {
      this.selecteddesignation = this.editdesignation
    }
    if (this.selecteddepartment == null || this.selecteddepartment == undefined) {
      this.selecteddepartment = this.editdepartment
    }
    const inputRequest1: UserDetails = {
      Id: this.edituserid,
      UserId: this.customerid,
      Password: this.pass,
      UserName: this.editfullname,
      Email: this.editemail,
      Role: this.selectedrole,
      Designation: this.selecteddesignation,
      Department: this.selecteddepartment,
      Location: this.editlocation,
      CreatedOn: this.createdon,
    }
    this.spinner.show();
    this.userservice.UpdateUser(inputRequest1).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "User Updated Succesfully";
          this.selectedrole=null;
          this.selecteddesignation =null
          this.selecteddepartment=null;
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          this.selectedrole=null;
          this.selecteddesignation =null
          this.selecteddepartment=null;
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();
        }
      });
  }
  DeleteUserRow() {
    this.spinner.show();
    this.userservice.deleteUser(this.deleteid).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "User Deleted Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();
        }
      });
  }
  closealert() {
    this.dismissalert = false;
  }
  GetDepartment(deptid) {
    for (let i = 0; i < this.DepartmentList.length; i++) {
      if (deptid == this.DepartmentList[i].id) {
        return this.DepartmentList[i].departmentName;
      }
    }
  }
  GetRole(rid) {
    for (let i = 0; i < this.RoleList.length; i++) {
      if (rid == this.RoleList[i].id) {
        return this.RoleList[i].roleName;
      }
    }
  }
  GetDesignation(desid) {
    for (let i = 0; i < this.DesignationList.length; i++) {
      if (desid == this.DesignationList[i].id) {
        return this.DesignationList[i].designationName;
      }
    }
  }
  Validatemail(){
    this.validateemail = this.email;
    this.spinner.show();
    const data: EmailValidation = {
      Email: this.validateemail
    }
    this.enterpriseservice.validateemail(data).subscribe((Data: any) => {
      this.mailresult = Data;
      if(this.mailresult=="exist"){
        this.validmail = true;
      }
      else{
        this.validmail = false;
      }
      this.spinner.hide();
    })
  }
  Validateusername(){
    this.validateusername = this.fullname;
    this.spinner.show();
    const data: EmailValidation = {
      Email: this.validateusername
    }
    this.enterpriseservice.validateusername(data).subscribe((Data: any) => {
      this.usernameresult = Data;
      if(this.usernameresult=="exist"){
        localStorage.setItem("validateval", "false")
        this.validusername = true;
      }
      else{
        localStorage.setItem("validateval", "true")
        this.validusername = false;

      }
      this.spinner.hide();

    })
  }
}
