
import { Option } from './option';

export class Question {
    id: number;
    name: string;
    questionTypeId: number;
    options: Option[];
    answered: boolean;

    constructor(data: any) {
        data = data || {};
        this.id = data[0].id;
        this.name = data[0].question;
         this.questionTypeId = 1;
        this.options = [];
        data.forEach(o => {
            this.options.push(new Option(o));
        });
    }
}
