import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CountryService } from 'app/shared/country.service';
import { Country } from 'app/shared/VIewmodels/country';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {
  closeResult: any;
  CountryName: any;
  countryname: any;
  CountryDesc: any;
  countrydesc: any;
  public dismissalert = false;
  notify: string
  CountryList: any;
  p: number = 1;
  editcountryName: any;
  editdesc: any;
  editcontryid: any;
  ucountryname: any;
  ucountrydesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  constructor(private modalService: NgbModal, private spinner: NgxSpinnerService, private countryService: CountryService) { }

  ngOnInit(): void {
    this.Countries();
    this.CountryName = "";
    this.CountryDesc = "";
  }
  Countries() {
    this.spinner.show();
    this.countryService.getAllCountries().subscribe((Data: any) => {
      this.CountryList = Data;
      this.spinner.hide();

    })
  }
  addCountry(addcountry) {
      this.modalService.open(addcountry).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  EditCountry(updatedata, updatecountry) {
    this.editcountryName = updatedata.countryName
    this.editdesc = updatedata.countryDesc
    this.editcontryid = updatedata.countryId
    this.createdon = updatedata.createdOn;
    this.createdby=updatedata.createdBy;
    this.modalService.open(updatecountry).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     
    });
  }
  deleteCountry(deletedata,deletecountry) {
    this.DeleteName=deletedata.countryName;
    this.deleteid = deletedata.countryId;
    this.modalService.open(deletecountry).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  SaveCountry() {
    const inputRequest: Country = {
      CountryId: 0,
      CountryName:this.countryname,
      CountryDesc:this.countrydesc,
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
    }
    this.spinner.show();
    this.countryService.saveCountry(inputRequest).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "Country added Successfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.Countries();
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.Countries();
        }
      });
  }

  UpdateCountry() {
    const inputRequest1: Country = {
      CountryId: this.editcontryid,
      CountryName: this.editcountryName,
      CountryDesc: this.editdesc,
      CreatedBy:  this.createdby,
      CreatedOn:  this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
    }
    this.spinner.show();
    this.countryService.UpdateCountry(inputRequest1).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "Country Updated Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Countries();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Countries();
          this.spinner.hide();
        }
      });
  }
  DeleteCountryRow() {
    this.spinner.show();
    this.countryService.deleteCountry(this.deleteid).subscribe(
      (data: any) => {
        if (data =="success") {
          this.notify = "Country Deleted Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Countries();
          this.spinner.hide();

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Countries();
          this.spinner.hide();
        }
      });
  }
  closealert(){
    this.dismissalert=false;
  }
}
