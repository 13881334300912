import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { UserService } from 'app/shared/UserService/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  isLoggedIn: boolean;
  userid: string;
  accountid: string;

  role: string;
  constructor(
    private registrationService: RegistrationService,
    private route: Router,
    private router: ActivatedRoute,
    private service: MasterService,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) {
    window.scrollTo(0, 0);

    if (localStorage.getItem('IsLoggedIn') == 'true') {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem('userId');
      this.accountid = localStorage.getItem('uid');
      this.role = localStorage.getItem('role');
    }
  }
  ngOnInit() {

     this.actionMethod()
      
    
  }
  actionMethod() {
    var alrt =
    {
      UserId: this.userid,
    JobAlert:true
    };
    this.registrationService.alert(alrt).subscribe((response: any) => {
      console.log(response);
     });
  }
   
}