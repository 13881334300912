<div>
  <div
    class="col-md-6"
    style="margin: auto; min-height: 100vh; align-items: center"
  >
    <br /><br /><br />
    <br /><br /><br />
    <h1><strong>Employee Background Verification includes:</strong></h1>

    <br /><br />

    <ol>
      <li>
        <strong>Public records:</strong> These can easily be accessed from the
        court records and criminal history by any law enforcement agency.
      </li>
      <br />
      <li>
        <strong>Work history:</strong> It can be obtained from one&#8217;s past
        employers, Duration of being hired with them, Job role in each company,
        Pay, Performance history, Conduct in the company, Medical history.
      </li>
      <br />
      <li>
        <strong>Education records:</strong> Companies will reach out to your
        universities to match your credentials to avoid forging degrees, fake
        certificates, bogus grades- the most common types of corporate frauds.
      </li>
      <br />
      <li>
        <strong>Address verification: </strong>It is done by sending people to
        physical locations to check out a candidate’s address.
      </li>
    </ol>
  </div>
  <br /><br /><br /><br /><br />
</div>
