<!-- <br><br><br><br><br>

<h1>My id - {{mypeerid}}</h1>
<input type="text" [(ngModel)]="anotherid">
<button (click)="connect()">Connect</button>
<button (click)="videoconnect()">VideoChat</button>
<video #myvideo></video> -->
<br><br><br><br>

<div class="col-md-12" style="font-size: 16px;">Question : {{question.question}}</div>

<br><br><br> *Note: Please write your code only in Python 3 Language.(Single submission) <br> **Note: Please take the inputs using sys.argv itself. <br> Ex: inputa = sys.argv[1:] <br> Thank you!
<ngx-codemirror [(ngModel)]="content" [options]="{
    lineNumbers: true,
    theme: 'material',
    mode: 'python'
  }"></ngx-codemirror>
<br>
<button class="btn btn-primary" (click)="fetch()">submit</button>