import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-alljoblist',
  templateUrl: './alljoblist.component.html',
  styleUrls: ['./alljoblist.component.scss'],
})
export class AlljoblistComponent implements OnInit {
  joblist;
  roles;
  accountid;
  constructor(
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private spinner: NgxSpinnerService,
    private service: MasterService
  ) {
    this.route.queryParams.subscribe((id: any) => {
      this.accountid = id['accid'];
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.service.getrole().subscribe((data: any) => {
      this.roles = data;

      this.registrationService
        .GetJobList(this.accountid)
        .subscribe((response: any) => {
          this.joblist = response;
          console.log(this.joblist);

          this.joblist.forEach((element) => {
            this.roles.forEach((element1) => {
              if (element['jobRole'] == element1['id']) {
                element['jobRole'] = element1.role;
              }
            });
          });
          this.service.getsalary().subscribe((data: any) => {
            this.joblist.forEach((element) => {
              data.forEach((element1) => {
                if (element1['id'] == element['payScale']) {
                  element['payScale'] = element1['salary'];
                }
              });
            });
          });

          this.registrationService
            .GetHighQualification()
            .subscribe((data: any) => {
              this.joblist.forEach((element) => {
                data.forEach((element1) => {
                  if (element['highestQualification'] == element1['id']) {
                    element['highestQualification'] =
                      element1.highestQualification;
                  }
                });
              });
            });
        });
      this.spinner.hide();
    });
  }
  Jobdescription;
  descriptions(val) {
    this.Jobdescription = val;
  }
}
