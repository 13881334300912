import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QuestionService } from '../services/question.service';
declare var Peer:any;
@Component({
  selector: 'app-videostreaming',
  templateUrl: './videostreaming.component.html',
  styleUrls: ['./videostreaming.component.scss']
})
export class VideostreamingComponent implements OnInit {
  @ViewChild('myvideo') myvideo: any;
  
  peer;
  anotherid;
  mypeerid;
  refid;
  studentid;
    constructor(private route:ActivatedRoute,private router:Router,private sevice:QuestionService) {
      this.route.params.subscribe((data:any)=>{
this.refid=data['id'];
      });
      if(!localStorage.userId){
        this.router.navigate(['/newsignin']);
        return;

      }
this.studentid=localStorage.userId;
     }

  ngOnInit(): void {

    let video;
    setTimeout(() => {
       video = this.myvideo.nativeElement;
      console.log(video)
    }, 5000);
    this.peer = new Peer();
    this.peer.on('open', function(id) {
      this.peer.id=id
      console.log('My peer ID is: ' + id);
    });
    setTimeout(() => {
      this.mypeerid = this.peer.id;
      if(this.mypeerid==null||this.mypeerid==undefined){
        location.reload();
      }
      else{
        this.sevice.storepeeridstudent(this.studentid,this.refid,this.mypeerid).subscribe((data:any)=>{
          if(data==1){

          }
          else{
            this.anotherid=data;
            this.videoconnect();
          }
          
        });
      }
    },3000);
    
    this.peer.on('connection', function(conn) {
  conn.on('data', function(data){
    // Will print 'hi!'
    console.log(data);
  });
});
var n = <any>navigator;
    
    n.getUserMedia =  ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia );
    
    this.peer.on('call', function(call) {
      n.webkitGetUserMedia({video: true, audio: true},function(stream) {
        call.answer(stream);
        call.on('stream', function(remotestream){
          video.srcObject = remotestream;
          video.play();
        })
      }, function(err) {
        console.log('Failed to get stream other', err);
      })
    })
  }
  connect(){
    var conn = this.peer.connect(this.anotherid);
  conn.on('open', function(){
  conn.send('Message from that id');
  });
  }
  
  videoconnect(){
    let video = this.myvideo.nativeElement;
    var localvar = this.peer;
    var fname = this.anotherid;
    
    // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    
    var n = <any>navigator;
    
     n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );
    
     n.getUserMedia({video: true, audio: true}, function(stream) {
      var call = localvar.call(fname, stream);
      call.on('stream', function(remotestream) {
        video.srcObject = remotestream;
        video.play();
      })
    }, function(err){
      console.log('Failed to get stream', err);
    })
    
    
  }
}
