import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/MessageService';
import { UserService } from 'app/shared/UserService/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-job-skill-category-master',
  templateUrl: './job-skill-category-master.component.html',
  styleUrls: ['./job-skill-category-master.component.scss']
})
export class JobSkillCategoryMasterComponent implements OnInit {
  id: any;
  groupName: any;
  skillGroups: any;
  DateTime: Date;
  uid: string;
  get f() { return this.signupForm.controls; }
  @ViewChild('closebutton') closebutton;
  @ViewChild('closeMobilebutton') closeMobilebutton;
  @ViewChild('closeEmailbutton') closeEmailbutton;
  submitted = false;
  signupForm = new FormGroup({
   groupName: new FormControl(),

  });
  showSave: boolean;
  showUpdate: boolean;

  constructor(private formBuilder: FormBuilder,private messageService: MessageService,private spinner: NgxSpinnerService,
    private httpService: HttpClient,  private userService: UserService,
    private datepipe: DatePipe) { 
    this.signupForm = formBuilder.group({
      groupName: ['', Validators.required],
    })
    this.uid =  localStorage.getItem("userId");

  }

  ngOnInit(): void {
    this.GetSkillgroupDetails();
  }
  addClick(){
    this.showSave = true;
    this.showUpdate = false;
    if (this.groupName != "") {
      this.groupName = "";
    }
  }
  Editgroup(id,name){
    this.id =id;
    this.groupName = name;
    this.showSave = false;
    this.showUpdate = true;
  }
  GetSkillgroupDetails() {
    this.spinner.show();
    this.userService.getskillgroupDetails().subscribe((response: any) => {
      this.skillGroups = response;
      this.spinner.hide();
    });
  }
  SavegroupName(){
    
    if (this.signupForm.invalid) {
      return
    }
    else {
      this.closebutton.nativeElement.click();

      this.DateTime = new Date();

      const frmData = new FormData();
    frmData.append("GroupName", this.groupName);
    frmData.append("Id", "0");

      this.spinner.show();

      this.httpService.post('https://blockchainmatrimony.com/jobzoneapi/api/JobSkillGroups/PostSavegroupName/', frmData).subscribe(
          data => {
            this.spinner.hide();
                  if(data == "success"){
                    alert("Saved Successfully!!");
                    this.GetSkillgroupDetails();  
                  }
                  else{
                    alert("Something went wrong. Please try again..!!");
                    this.GetSkillgroupDetails();  
                  }
                  this.spinner.hide();
         
          },
          
          (err: HttpErrorResponse) => {
            alert("Something went wrong. Please try again..!! ")
            console.log(err.message);    // Show error, if any.
          });
    

    }
  }
  UpdategroupName() {
    this.submitted = true;

    if (this.signupForm.invalid) {
      return
    }
    else {
      this.closebutton.nativeElement.click();

      this.DateTime = new Date();

      const frmData = new FormData();
    frmData.append("GroupName", this.groupName);
    frmData.append("Id", this.id);

      this.spinner.show();

      this.httpService.post('https://blockchainmatrimony.com/jobzoneapi/api/JobSkillGroups/PostgroupUpdateName/', frmData).subscribe(
          data => {
            this.spinner.hide();
                  if(data == "success"){
                    alert("Updated Successfully!!");
                    this.GetSkillgroupDetails();  
                  }
                  else{
                    alert("Something went wrong. Please try again..!!");
                    this.GetSkillgroupDetails();  
                  }
                  this.spinner.hide();
         
          },
          
          (err: HttpErrorResponse) => {
            alert("Something went wrong. Please try again..!! ")
            console.log(err.message);    // Show error, if any.
          });
    

    }
  }




  
}
