import { Component, OnInit } from '@angular/core';
import { PlaytypeJobPortalVM, Jobzone_InstitutionMaster } from 'app/shared/VIewmodels/PlaytypeJobPortalVM';
import { NgForm } from '@angular/forms';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-institution',
  templateUrl: './institution.component.html',
  styleUrls: ['./institution.component.scss']
})
export class InstitutionComponent implements OnInit {
  plantype: string;
  amount: string;
  planlist: any;
  palnid: any;
  plaid: any;

  constructor(private masterservices:MasterService , private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      document.getElementById("save").style.display = "block";
      document.getElementById("update").style.display = "none";
    }, 500);
    this.PlansList();
    this.instiname = "";
  }
  ResetData(){
    this.instiname = "";
  }
  institution:any;
  PlansList() {
    this.masterservices.getjobzoneinstitution().subscribe((data:any)=>{
      this.institution = data;
    })
  }
  instiname:any;
  SavePlanType(form: NgForm) {
    const inputRequest: Jobzone_InstitutionMaster = {
      Id: 0,
      InstitutionName:this.instiname
    }
    this.masterservices.postinstitution(inputRequest).subscribe((data: any) => {
      if (data == null) {
        alert("Added Successfully");
        this.router.navigate(['/institutionmaster']);
        window.location.reload();

      }
      else {
        alert("Something went Wrong");
        this.router.navigate(['/institutionmaster']);
        window.location.reload();
      }
    });
  }
  instiid:any;
  editplantype(data: any) {
    document.getElementById("save").style.display = "none";
    document.getElementById("update").style.display = "block";

    this.instiid = data.id;
    this.instiname = data.institutionName;
  }

  UpdatePlanType(form: NgForm) {
    document.getElementById("save").style.display = "block";
    document.getElementById("update").style.display = "none";

    const inputRequest1: Jobzone_InstitutionMaster = {
      Id: this.instiid,
      InstitutionName: this.instiname,
    }
    this.masterservices.Updateinstitution(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          alert("Institution Name Updated Succesfully");
          this.PlansList();
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }
  deleteplantype(data: { id: any; }) {
    this.plaid = data.id;
    this.masterservices.deletePlantype(this.plaid).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Institution Deleted Succesfully");
          this.PlansList();
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }

}
