//import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentsTest } from 'app/shared/VIewmodels/studenttest';
import { Quiz } from 'app/shared/VIewmodels/quiz';
import { QuizConfig } from 'app/shared/VIewmodels/quiz-config';
import { QuestionService } from '../services/question.service';
import { ActivatedRoute, Router } from '@angular/router';
import { onlyid, pythoncode } from 'app/shared/VIewmodels/onlyid';
import { Question } from 'app/shared/VIewmodels/question';
import { Option } from 'app/shared/VIewmodels/option';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VideorecordService } from 'app/shared/videorecord/videorecord.service';
import * as $ from 'jquery';
declare var MediaRecorder: any;
declare var Peer:any;

import * as RecordRTC from 'recordrtc';
import { CodeService } from '../services/code.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @ViewChild('video') videoElementRef: ElementRef;

  videoElement: HTMLVideoElement;
  recordVideoElement: HTMLVideoElement;
  mediaRecorder: any;
  recordedBlobs: any=[];
  isRecording: boolean = false;
  downloadUrl: string;
  //stream: MediaStream;
  isLoggedIn: boolean;
  uid: string;
  accid: string;
  us: any;
  frmDataa: any;
  convert1: any;
  vc: any;
  videoBuffer: Blob;
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  quizName: any;
  config: QuizConfig = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 1800,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': false,
    'showPager': true,
    'theme': 'none'
  };
  done=0;
  idx = 'clip1';
  @ViewChild('myvideo') myvideo: any;
  
  peer;
  anotherid;
  mypeerid;
  refid;
  studentid;
  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  startTime: Date;
  endTime: Date;
  ellapsedTime = '00:00';
  duration = '';
  quizId:any;
  alreadytaken;
  userid;
  constructor(private codeService:CodeService,private spinner: NgxSpinnerService,private questionService:QuestionService,private route:ActivatedRoute,private router:Router,private httpservice:HttpClient,private videorecordservice:VideorecordService) {
   //
    this.route.params.subscribe((response)=>{
      this.quizId = response['id'];
    });
    localStorage.already=0;
    this.pagechange = 0;
    localStorage.pagechangetest = 0;
//video
if(!localStorage.userId){
  this.router.navigate(['/newsignin']);
  return;

}

this.studentid=localStorage.userId;
this.userid = localStorage.userId;
localStorage.setItem("CourseId",this.quizId);


//video end

   }
   
pagechange;
  ngOnInit() {
    this.mcqquiz=false
   // alert(this.startRecording1())
  
    
  
    navigator.mediaDevices
    .getUserMedia({
      video: {
        width: 360
      },
    audio:true
      
    })
    
    .then(stream => {
      this.videoElement = this.videoElementRef.nativeElement;
      this.recordVideoElement = this.recordVideoElementRef.nativeElement;

      this.stream = stream;
      this.videoElement.srcObject = this.stream;
    });
    let parent;
      parent = document.getElementById("videosrecord");

    //video

    let video;
    setTimeout(() => {
       video = this.myvideo.nativeElement;
      console.log(video)
    }, 5000);
    this.peer = new Peer();
    this.peer.on('open', function(id) {
      this.peer.id=id
      console.log('My peer ID is: ' + id);
    });
    setTimeout(() => {
      this.mypeerid = this.peer.id;
      //if(this.mypeerid==null||this.mypeerid==undefined){
      //  location.reload();

     // }
     // else{
        $(window).blur(function() {
          localStorage.pagechangetest = +localStorage.pagechangetest +1;
          var a = window.location.href;
          
          if(a.split('/')[3]==="test"){
          if(localStorage.pagechangetest > '3'){
            document.getElementById('fetch').click();
          }
          //if anyone want to give permisssionnnn please change the if condition < to > '3'
       alert('You have changed the tab '+localStorage.pagechangetest+" times");
        }
          //do something else
       });
       //this.mcqquiz=true;
        this.questionService.storepeeridstudent(this.studentid,this.quizId,this.mypeerid).subscribe((data:any)=>{
          if(data==1){

          }
          else{
            if(localStorage.already!="1"){
            this.anotherid=data;
            this.videoconnect();
            }
          }
          
        });
      
    },5000);
    
    this.peer.on('connection', function(conn) {
  conn.on('data', function(data){
    // Will print 'hi!'
    console.log(data);
  });
});
var n = <any>navigator;
    
    n.getUserMedia =  ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia );
    
    this.peer.on('call', function(call) {
      n.webkitGetUserMedia({video: true, audio: true},function(stream) {
        call.answer(stream);
        call.on('stream', function(remotestream){
          video.srcObject = remotestream;
          video.play();
          var options = {
            mimeType: 'video/webm', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
            
            bitsPerSecond: 128000 // if this line is provided, skip above two
          };
          this.recordRTC = RecordRTC(stream, options);
      this.recordRTC.startRecording();
        })
      }, function(err) {
        console.log('Failed to get stream other', err);
      })
    })


    //video end
 

    this.pagechange = 0;

    const id:pythoncode={
      stdid : localStorage.userId,
      id:0,
      code:null,
      quizid:this.quizId
    }
    this.questionService.checktest(id).subscribe((data:any)=>{
      if(data == 1)
      {
        this.alreadytaken = 1;
        localStorage.already = 1;

        alert("You have already taken the test");
        this.router.navigate(['/']);
      }

    })
    this.quizName = this.quizId;
    this.loadQuiz(this.quizName);
   
  }




  startRecording() {
    this.mcqquiz=false
    this.mcqquiz=true
    let mediaConstraints = {
      video: true
      , audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
}
connect(){
  var conn = this.peer.connect(this.anotherid);
conn.on('open', function(){
conn.send('Message from that id');
});
}

videoconnect(){
  let video = this.myvideo.nativeElement;
  var localvar = this.peer;
  var fname = this.anotherid;
  
  // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
  
  var n = <any>navigator;
  
   n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );
  
   let mediaConstraints = {
    video: true
    , audio: true
  };
  navigator.mediaDevices
    .getUserMedia(mediaConstraints)
    .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  
  
}
errorCallback() {
  //handle error here
//alert("please allow camera to continue");
//location.reload();
}
stream;
recordRTC;

successCallback(stream: MediaStream) {
  let video = this.myvideo.nativeElement;
  var localvar = this.peer;
  var fname = this.anotherid;
  var call = localvar.call(fname, stream);
    call.on('stream', function(remotestream) {
      video.srcObject = remotestream;
      video.play();
     
    })
  var options = {
        mimeType: 'video/webm', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
        
        bitsPerSecond: 128000 // if this line is provided, skip above two
      };
      this.stream = stream;
      this.recordRTC = RecordRTC(stream, options);
      this.recordRTC.startRecording();
     
      this.toggleControls();
    }

    toggleControls() {
      let video: HTMLVideoElement = this.myvideo.nativeElement;
      video.muted = !video.muted;
      video.controls = !video.controls;
      video.autoplay = !video.autoplay;
    }

    stopRecording() {
      let recordRTC = this.recordRTC;
      recordRTC.stopRecording(this.processVideo.bind(this));
      let stream = this.stream;
      console.log(stream);
      stream.getAudioTracks().forEach(track => track.stop());
      stream.getVideoTracks().forEach(track => track.stop());
    }
    recorderdvideo:any;
formadat=new FormData();
    processVideo(audioVideoWebMURL) {
      let video: HTMLVideoElement = this.myvideo.nativeElement;
      let recordRTC = this.recordRTC;
      video.src = audioVideoWebMURL;
      console.log("Hello");
      console.log(audioVideoWebMURL)
      this.toggleControls();
      var recordedBlob = recordRTC.getBlob();
      var blob = recordRTC.getBlob();

      var file = new File([blob], 'filename.webm', {
          type: 'video/webm'
      });
  
      this.formadat.append('file', file);
      this.formadat.append('CourseId',this.quizId);
var marks = this.marksObtained.toString();
      this.formadat.append('marks',marks);
      this.formadat.append('level',null);

      this.formadat.append('id',localStorage.userId); // upload "File" object rather than a "Blob"
      recordRTC.getDataURL(function (dataURL) {
localStorage.video=dataURL;
       });
    }

    download() {
      this.recordRTC.save('video.mp4');
    }
  // use ngOnDestroy to detach event handlers and remove the player




  loadQuiz(quizName: any) {
  
    const id:onlyid={
      id : quizName
    }
    this.questionService.getQuestions(id).subscribe(res => {
    console.log(res);
    this.quiz = new Quiz(res);
    this.pager.count = this.quiz.questions.length;
    this.startTime = new Date();
    this.ellapsedTime = '00:00';
    this.timer = setInterval(() => { this.tick(); }, 1000);
    this.duration = this.parseTime(this.config.duration);
    });
   
    this.mode = 'quiz';
   
  }

  tick() {
    const now = new Date();
    const diff = (now.getTime() - this.startTime.getTime()) / 1000;
    if (diff >= this.config.duration) {
      this.onSubmit();
      var interval_id = window.setInterval("", 9999); // Get a reference to the last
                                                // interval +1
for (var i = 1; i < interval_id; i++)
        window.clearInterval(i);
    }

    this.ellapsedTime = this.parseTime(diff);
  }

  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option) {
    if (question.questionTypeId === 1) {
      question.options.forEach((x) => { if (x.id !== option.id) x.selected = false; });
    }

    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
    }
  }

  isAnswered(question: Question) {
    return question.options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  isCorrect(question: Question) {
    return question.options.every(x => x.selected === x.isAnswer) ? 'correct' : 'wrong';

  };
answers:any=[];
codepresent = false;

marksObtained = 0;
 onSubmit() {
  
    let answers = [];
    this.quiz.questions.forEach(x => answers.push({ 'quizId': this.quiz.id, 'questionId': x.id, 'answered': x.answered }));
  
    this.quiz.questions.forEach(x=> this.answers.push(x.options.every(x => x.selected === x.isAnswer) ? 'correct' : 'wrong'));
      // Post your data to the server here. answers contains the questionId and the users' answer.
      this.answers.forEach(element => {
        if(element == "correct"){
          this.marksObtained+=1;
        }
      });
      const marksData: StudentsTest={
        ChapterId:null,
        CourseId:this.quizId,
        DomainId:null,
        GradeObtained:null,
        ID:null,
        IsEnable:true,
        Level:null,
        MarksObtained:this.marksObtained,
        StudentId:localStorage.getItem("userId"),
        TechnologyId:null,
        Video:null
      }
      if(!this.done){
      this.questionService.storeMarks(marksData).subscribe((data:any)=>{
        this.done = 1;
        
        const quiz:onlyid={
          id:this.quizId
        }
        this.questionService.getcodequestion(quiz).subscribe((data:any)=>{
          if(data.length>0){
            this.codepresent = true
  
          }
          else{
            this.codepresent = false;
          }
        })
      });
      //this.router.navigate(['/newhome']);
      this.stopRecording1()
    }
      this.mode = 'result';
 
   localStorage.setItem("CourseId",this.quizId);
  }
  question;
  pythoncoding=false;
  mcqquiz=true;
  gotocode(){
    
    const id:onlyid={
      id:this.quizId
    }
    this.questionService.getcodequestion(id).subscribe((data:any)=>{
      
      this.question = data[0];
      if(this.question!=null){
        this.pythoncoding=true;
        this.mcqquiz=false;
      }
      console.log(this.question);
    })
    this.router.navigate(['/codecompiler',this.quizId]);
  }
  content;
  intervalid;
  fetch(){
    this.stopRecording();
    this.intervalid=setInterval(() => {
      if(localStorage.video){
        this.recorderdvideo=localStorage.video
        localStorage.removeItem('video');
        console.log(this.recorderdvideo);
      }
      if(this.recorderdvideo){
       
          window.clearInterval(this.intervalid);
        
    const codeString:pythoncode={
      id:0,
      code:this.content,
      quizid:this.question.id,
      stdid:this.userid
    }
    let vid = this.myvideo.nativeElement;
    this.codeService.savecode(codeString).subscribe((response:any)=>{
        if(response == 1){
          this.questionService.storevideo(this.formadat).subscribe((data:any)=>{
            alert("Your submission is saved ! Thank you for participating in this test");
           
          
           // this.recordRTC.stopRecording();
          this.router.navigate(['/']);
            console.log(data);
          })
          
        }
  
        
    })
  }}, 1000);
}

//Abhishek

startRecording1() {
  this.mcqquiz=true;
  this.recordedBlobs = [];
  let options: any = { mimeType: 'video/webm' };

  try {
    this.mediaRecorder = new MediaRecorder(this.stream, options);
  } catch (err) {
    console.log(err);
  }

  this.mediaRecorder.start(); // collect 100ms of data
  this.isRecording = !this.isRecording;
  this.onDataAvailableEvent();
 // this.onStopRecordingEvent();

}




playRecording() {
  if (!this.recordedBlobs || !this.recordedBlobs.length) {
    console.log('cannot play.');
    return;
  }
  this.recordVideoElement.play();
}

onDataAvailableEvent() {
  try {
    this.mediaRecorder.ondataavailable = (event: any) => {
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data);
      }
    };
  } catch (error) {
    console.log(error);
  }
}
//commented 15-12-2022
// onStopRecordingEvent() {
//   try {
   
//     };
//   } catch (error) {
//     console.log(error);
//   }
// }
// stopRecording1() {
//   this.mediaRecorder.stop();
//   this.isRecording = !this.isRecording;
  
//   console.log('Recorded Blobs: ', this.recordedBlobs);
  

// // this.videoBuffer = new Blob(this.recordedBlobs, {
// //     type: 'video/webm'
// //   });
//   this.mediaRecorder.onstop = (event: Event) => {
//     //this.vc=this.recordedBlobs.siz;
//     this.videoBuffer = new Blob(this.recordedBlobs, {
//      type: 'video/webm'
//     //type: 'mp4'
//     });
//     this.downloadUrl = window.URL.createObjectURL(this.videoBuffer); // you can download with <a> tag
//     this.recordVideoElement.src = this.downloadUrl;
//     var det = {
//       Id:0,
//       UserId:this.uid,
//       FileName:"",
//       FileBlobLink:""
      
//     }
//   this.convert1=this.videoBuffer;
//   var frmDataa = new FormData();
// frmDataa.append("MessageData", this.studentid);
//   frmDataa.append("fileupload", this.convert1);
//   frmDataa.append("MessageData1", this.quizId);

//   this.httpservice.post('https://blockchainmatrimony.com/jobzoneapi/api/videorecord/savevideorecord/',frmDataa).subscribe(
//     data => {

//       alert("Your submission is saved ! Thank you for participating in this test");
//     })
  
// }
// }
//15-12-2022
stopRecording1() {
 // this.spinner.show();
  this.mediaRecorder.stop();
  this.isRecording = !this.isRecording;
  
  console.log('Recorded Blobs: ', this.recordedBlobs);
  

// this.videoBuffer = new Blob(this.recordedBlobs, {
//     type: 'video/webm'
//   });
  this.mediaRecorder.onstop = (event: Event) => {
    //this.vc=this.recordedBlobs.siz;
    this.videoBuffer = new Blob(this.recordedBlobs, {
      type: 'video/webm'
    });
    this.downloadUrl = window.URL.createObjectURL(this.videoBuffer); // you can download with <a> tag
    this.recordVideoElement.src = this.downloadUrl;
    var det = {
      Id:0,
      UserId:this.uid,
      FileName:"",
      FileBlobLink:""
      
    }
  this.convert1=this.videoBuffer;
  var frmDataa = new FormData();
frmDataa.append("MessageData", this.studentid);
  frmDataa.append("fileupload", this.convert1);
  frmDataa.append("MessageData1", this.quizId);

  this.httpservice.post('https://blockchainmatrimony.com/Jobzoneapi/api/videorecord/savevideorecord/',frmDataa).subscribe(
    data => {

      alert("Your submission is saved ! Thank you for participating in this test");
    })
  //  this.spinner.hide();
}
}

}
