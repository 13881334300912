import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Designation } from './VIewmodels/designation';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {
  readonly url="https://blockchainmatrimony.com/jobzoneapi/api/";
  constructor(private http:HttpClient) { }
  saveDesignation(data:Designation){
    return this.http.post(this.url +"Designation/PostSaveDesignation",data);
  }

  getAllDesignations(){
    return this.http.get(this.url +"Designation/GetAllDesignations");
  }

  deleteDesignation(id: number){
    return this.http.delete(this.url +"Designation/DeleteDesignationData/"+ id);
  }

  UpdateDesignation(data:Designation){
    return this.http.put(this.url +"Designation/PutDesignation",data);
  }
  getParticularDesignations(id){
    return this.http.get(this.url +"Designation/GetParticularDesignation/"+ id);

  }
}
