import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/UserService/user.service';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  isLoggedIn: boolean;
  userid: string;
  accountid: string;
  joblist: any;
role:any;
  CompanyName:any;
  constructor(private router:Router, private userser:UserService,private registrationService: RegistrationService,private service:MasterService) {
    if (localStorage.loadingpage == "true") {
      localStorage.loadingpage = "false";
      location.reload();
    }
    this.role = localStorage.getItem("role");

    window.scrollTo(0,0);

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem("userId");
      this.accountid = localStorage.getItem("uid")
    }
   }


   ngOnInit(): void {

   setTimeout(() => {
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }
   }, 5000);
   
    this.GetJobMatchList();
  }
  Certi:any;
roles:any;
  GetJobMatchList() {
    this.registrationService.getjoblistforins(this.accountid).subscribe((response: any) => {
      if(response == 1){
        this.joblist = null;
      }
      else{
      this.joblist = response;
      this.registrationService.GetHighQualification().subscribe((data:any)=>{
        this.joblist.forEach(element => {
          data.forEach(element1 => {
            if(element['highestQualification']==element1['id']){
              element['highestQualification']=element1.highestQualification
            }
          });
            
         
        });
      })
      this.service.getsalary().subscribe((data:any)=>{
        this.joblist.forEach(element => {
          data.forEach(element1 => {
              if(element1['id']==element['payScale']){
                element['payScale'] = element1['salary']
              }
          });
        });
      })
      this.service.getrole().subscribe((data:any)=>{
        this.roles = data;
      this.joblist.forEach(element => {
        this.roles.forEach(element1 => {
          if(element['jobRole']==element1['id']){
            element['jobRole']=element1.role
            
          }
        });
      });
          
       
      });
      }
      console.log(this.joblist);
    });
  }

  gotoapplied(id){
    this.router.navigate(['/applied',id]);
  }
  sort = "1";

  sortby() {
    if (this.sort == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.joblist.sort((a, b) => a.companyName.toLowerCase() < b.companyName.toLowerCase() ? -1 : a.companyName.toLowerCase() > b.companyName.toLowerCase() ? 1 : 0)
      this.sort = "2";
    }
    else if (this.sort == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.joblist.sort((a, b) => a.companyName.toLowerCase() > b.companyName.toLowerCase() ? -1 : a.companyName.toLowerCase() < b.companyName.toLowerCase() ? 1 : 0)

      this.sort = "1";

    }

  }

  sortsalary = "1";

  sortbysalary() {
    if (this.sortsalary == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.joblist.sort((a, b) => a.payScale.toLowerCase() < b.payScale.toLowerCase() ? -1 : a.payScale.toLowerCase() > b.payScale.toLowerCase() ? 1 : 0)
      this.sortsalary = "2";
    }
    else if (this.sortsalary == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.joblist.sort((a, b) => a.payScale.toLowerCase() > b.payScale.toLowerCase() ? -1 : a.payScale.toLowerCase() < b.payScale.toLowerCase() ? 1 : 0)

      this.sortsalary = "1";

    }

  }
}