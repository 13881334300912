import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MoveFolderOrDocument } from '../VIewmodels/MoveFolderOrDocument';
import { MoveDocData } from '../VIewmodels/MoveDocData';

@Injectable({
  providedIn: 'root',
})
export class MovesService {
  readonly url = 'https://blockchainmatrimony.com/jobzoneapi/api';
  constructor(private http: HttpClient) {}

  saveMoveFolderOrDocument(data: MoveFolderOrDocument) {
    return this.http.post(
      this.url + 'MoveFolderOrDocument/PostMoveFolderOrDocument',
      data
    );
  }
  moveDocument(data: MoveDocData) {
    return this.http.post(
      this.url + 'MoveFolderOrDocument/PostMoveDocument',
      data
    );
  }
}
