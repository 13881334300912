import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeService } from '../mcq/services/code.service';
import { QuestionService } from '../mcq/services/question.service';


@Component({
  selector: 'app-onlinecodecompiler',
  templateUrl: './onlinecodecompiler.component.html',
  styleUrls: ['./onlinecodecompiler.component.scss']
})
export class OnlinecodecompilerComponent implements OnInit{
  lang:any
  output:any
    number: number=5;
    code:any
    inputs:any
    result: any=[];
    output1: any;
  
    @ViewChild('myvideo') myvideo: any;
    quizid: any;
    userid: any;
    peer: any;
    anotherid: any;
    question: any;
    outputs: any;
  
    constructor(private codeService:CodeService,private router:Router,private route:ActivatedRoute,private questionservice:QuestionService,private httpService: HttpClient) { 
      this.route.params.subscribe((data:any)=>{
        this.quizid=data['id'];
      });
      this.userid = localStorage.userId;
      localStorage.myid = "";
     }
    ngOnInit(): void {
      const id:any={
        id:this.quizid
      }
      this.questionservice.getcodequestion(id).subscribe((data:any)=>{
        
        this.question = data[0];
        console.log(this.question);
      })
      }
      compile(){
       var parameters = {
         code:this.code,
         language:this.lang,
         input:this.inputs,
        }
      
      this.httpService.post('http://codex-api.herokuapp.com/',parameters).subscribe((data) => {
        if(data == null || data == undefined)
              { 
                alert("Could'nt compile, something went wrong!!")
              }  
              this.result.push(data);
              if(this.result[0].success == false){
                this.output1 = this.result[0].error
              }
              else{
                this.output1 = this.result[0].output
              }
            })
            console.log(this.result)
          
      }



    //{
    //   "script" : "<?php echo \"hello\"; ?>",
    // "language": "php",
    // "versionIndex": "0",
    // "clientId": "28b27440cd3fe4ec027ade4d3954c3eb",
    // "clientSecret":"f4a268b25d6ad624def83f851ac849f3c1988060e5ee9b92bf7c9826259b2e10"
    //}


    //   compile() {
    //     var program= {
    //       script : this.code,
    //       language: this.lang,
    //       versionIndex: "0",
    //       clientId: "8ae7e5c87bd96c0e8131ace5e0c75b79",
    //       clientSecret:"ca5f7b9fbc0c9db101efc06764d756544c48c79ef90a8e6be5a69946578ffda80"
    //   };
 
    //  // var pro = JSON(program)
    //  var reqHeader = new HttpHeaders({'Content-Type': 'application/json;'});  
    //  this.httpService.post('http://api.jdoodle.com/v1/execute',program).subscribe((data) => {
    //     if(data == null || data == undefined)
    //     { 
    //       alert("Could'nt compile, something went wrong!!")
    //     }  
    //     this.result.push(data);
    //     if(this.result[0].success == false){
    //       this.output1 = this.result[0].error
    //     }
    //     else{
    //       this.output1 = this.result[0].output
    //     }
    //   })
    //       }

// compiler() {
//   const program:any = {
//     "async": true,
//       "crossDomain": true,
//       "url": "https://codex7.p.rapidapi.com/",
//       "method": "POST",
//       "headers": {
//         "content-type": "application/x-www-form-urlencoded",
//         "X-RapidAPI-Key": "c0addedad0msh5eeb17429c26fb9p1c0652jsnc4db5ce42388",
//         "X-RapidAPI-Host": "codex7.p.rapidapi.com"
//       },
//       "data": {
//         "code": this.code,
//         "language": this.lang
//       }
// }
// this.httpService.post('https://codex7.p.rapidapi.com/',program).subscribe((data) => {
//         {
//           console.log(data);
//         }  
//       })
//     }

      submitResult(){
        this
        const codeString:any={
          id:0,
          code:this.code,
          output:this.output1,
          QuestionId:this.question.id,
          StudentId:this.userid,
          JobId:this.quizid,
        }
        this.codeService.savewrittencode(codeString).subscribe((response:any)=>{
            if(response == 1){
              alert("Your submission is saved ! Thank you for participating in this test");
              this.router.navigate(['/']);
            }
      
            
        })
      }
   
  connect(){
    var conn = this.peer.connect(this.anotherid);
  conn.on('open', function(){
  conn.send('Message from that id');
  });
  }
  
  videoconnect(){
    let video = this.myvideo.nativeElement;
    var localvar = this.peer;
    var fname = this.anotherid;
    
    // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    
    var n = <any>navigator;
    
     n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );
    
     n.getUserMedia({video: true, audio: true}, function(stream) {
      var call = localvar.call(fname, stream);
      // call.on('stream', function(remotestream) {
      //   // video.srcObject = remotestream;
      //   // video.play();
      // })
    }, function(err){
      console.log('Failed to get stream', err);
    })
    
    
  }
  // clearoutput(){
  //   this.output1 = ""
  // }
  }