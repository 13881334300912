<br/><br/>
<br/>
<br/>
<h2 style="text-align:center">Job Skill Group List</h2>
<div class="row col-sm-12">
    <div class="col-sm-9"></div>
    <div class="col-sm-2">
        <button style="background-color: green" class="btn btn-success" data-toggle="modal" data-target="#EditName"
            mdbWavesEffect (click)="addClick()">
            Add Skill Group
        </button>
    </div>
</div>
<br/>

<div class="col-md-12 row">
  <div class="col-md-3">&nbsp;</div>
  <div class="col-md-6">

    <div class="container" style="margin-top: 5%">
    
      <table class="table table-striped table-bordered">
          <thead>
              <tr>
                <th>
                  Sl No.
                </th>
                  <th>
                      Group Name
                  </th>
                  <th>
                    Action
                </th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let aa of skillGroups">
                <td> {{aa.id}}</td>
                  <td>{{aa.category}}</td>
                  <td>
                    <b  data-toggle="modal"
                    data-target="#EditName" (click)="Editgroup(aa.id,aa.category)">Edit</b>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>


  </div>
</div>


<!-- edit Name modal -->
<div class="modal modal-angular fade" id="EditName" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center;font-weight: 600">Update Skill Group Name</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
              </button>
          </div>
          <form [formGroup]="signupForm">
              <div class="modal-body text-center">
                  <div class="col-md-12">&nbsp;</div>
                  <div class="col-md-12 row" *ngIf="skillGroups">
                      <label>Sill Group Name</label>
                      <input [ngClass]="{ 'is-invalid': submitted && f.groupName.errors }" class="form-control"
                          placeholder="Group Name" formControlName="groupName" name="groupName" type="text" autofocus
                          [(ngModel)]="groupName">
                      <div class="col-md-12">&nbsp;</div>
                      <div *ngIf="submitted && f.groupName.errors" class="invalid-feedback"
                          style="margin-top: -20px;">
                          <div style="color: red;" *ngIf="f.groupName.errors.required">Group Name is
                              required</div>
                      </div>
                  </div>
              </div>
          </form>
          <div class="modal-footer">

                  <button  data-dismiss="modal" *ngIf="showSave" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect
                  (click)="SavegroupName()">Save</button>
              <button  data-dismiss="modal" *ngIf="showUpdate" type="submit" class="btn btn-success btn-sm waves-light"
                  (click)="UpdategroupName()">Update </button>
        

              <button type="button" class="btn btn-warning" #closebutton data-dismiss="modal"
                  mdbWavesEffect>Close</button>
          </div>
      </div>
  </div>
</div>