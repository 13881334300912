import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { UserService } from 'app/shared/UserService/user.service';
import { ChatDetail } from 'app/shared/VIewmodels/ChatDetail';

@Component({
  selector: 'app-documentgeneration',
  templateUrl: './documentgeneration.component.html',
  styleUrls: ['./documentgeneration.component.scss']
})
export class DocumentgenerationComponent implements OnInit  {
  userDetails: any;
  username: any;
  email: any;
  role: any;
  salaryrange: any;
  perks: any;
  industry: any;
  growth: any;
  loc1: any;
  loc2: any;
  loc3: any;
  b: any[] = [1, 2, 8, 5];
  TodayDate = new Date().toLocaleDateString()
  className: string;
  crttype: any;
  Users: any = [];
  userid: any;
  isLoggedIn: boolean;
  fullName: string;
  touserid: any;
  chatopen: boolean;
  IndividualChatDetail: any;
  uid: boolean;
  chattoid: any;
  chatvar: any;
  chattouserid: any;
  chaatto: any;
  IndividualChatRecivedDetails: any;
  IndividualChatSentDetails: any;
  UserImage: any;
  BMid: string;
  touser: any;
  currentuser: string;
  data: any;
  chtnm: any;
  cnnTableData: any = [];
  closeResult: string;
  accid: any;
  m: any;
  Friends: any;
  accountid: string;
id:any;
  ResumeFile: any;
  pdfUrl: any;

  candidateName: any;
  parentName: any;
  address: any;
  adhaar: any;
  mobnum: any;
  mail: any;
  timeduration: any;
  sal: any;
  lastdate: any;
  alldata: any=[];
  Industry: any;

  constructor(
    private userService: RegistrationService,
    private userser: UserService,
   private router:ActivatedRoute,
   private modalService: NgbModal,
   private masterService:MasterService
  ) {
    this.userid = localStorage.getItem("userId");
this.router.params.subscribe((data:any)=>{
  if(data){
    this.id = data['id'];
  }
})
if (localStorage.getItem("IsLoggedIn") == "true") {
  this.isLoggedIn = true;
  //this.uid = localStorage.getItem("userId");
  this.accid = localStorage.getItem("uid");
}
   window.scrollTo(0,0);
  }
  image: any;
  ngOnInit(): void {
     //this.username = localStorage.profileUserName;
    //this.email = localStorage.profileEmail;

    this.candidateName = localStorage.candidateName;
    this.mail = localStorage.mail;
    this.parentName = localStorage.parentName;
    this.mobnum = localStorage.mobnum;
    this.adhaar = localStorage.adhaar;
    this.address = localStorage.address;

    this.userService.getImage(this.id).subscribe((data: any) => {
      this.image = data;
      this.image = "data:image/png;base64," + this.image;
      console.log("userimg ",this.image);
    });
    this.userService.getprofileforcompany(this.id).subscribe((data: any) => {
      this.userDetails = data;
      this.userService.getrole(this.userDetails?.role).subscribe((data: any) => {
        this.role = data;
      });
      this.userService
        .getsalary(this.userDetails?.salarymin)
        .subscribe((data: any) => {
          this.salaryrange = data;
        });
      this.userService
        .getperks(this.userDetails?.perks)
        .subscribe((data: any) => {
          this.perks = data;
        });
      this.userService
        .getindustry(this.userDetails?.industry)
        .subscribe((data: any) => {
          this.industry = data;
        });
      this.userService
        .getgrowth(this.userDetails?.growth)
        .subscribe((data: any) => {
          this.growth = data;
        });
      this.userService
        .geteducation(this.userDetails?.salarymax)
        .subscribe((data: any) => {
          this.education = data;
        });
      console.log(this.userDetails);
    });
    this.getExp();
    this.getbio();
    this.getUserImage();
    this.getEdu();
    this.getSkills();

    setTimeout(() => {
      var footer = document.getElementById("footer");
      if (footer) {
        footer.style.display = "block";
      }
    }, 1000);
  }
  getbio() {
    this.userser.getbio(this.id).subscribe((respose: any) => {
      this.bio = respose;
      console.log(respose);
    });
  }
  education: any;
  getmarks() {
    this.userser.getMarks(this.id).subscribe((respose: any) => {
      this.marks = respose;
      console.log(respose);
    });
  }

  imgp = false;
  usrimage: any;
 
  getUserImage() {
    this.userser.getUserImage(this.id).subscribe((respose: any) => {
      if (respose != "0") {
        this.imgp = true;
        this.usrimage = respose;
      } else {
        this.imgp = false;
      }
    });
  }

  edrad = false;
  Cname: any;
  Course: any;
  date: any;

  Edudet: any;

  getEdu() {
    this.userser.getEduDet(this.id).subscribe((respose: any) => {
      this.Edudet = respose;
      console.log(respose);
    });
  }
  Skilldet: any;
  getSkills() {
    this.userser.getSkillDet(this.id).subscribe((respose: any) => {
      this.Skilldet = respose;
      console.log(respose);
    });
  }
  requestingfor: any;
  edid: any;
 





  Skill: any;

  skid = null;






  fileToUploaddoc: FileList;
  Certi: any;
  marks: any;

  ubio: any;
  bio: any;
  base64: string;
 
  // ---------------------------------------------------
 

  messa: any;
  SaveUserChat() {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: this.currentuser,
      ChatTo: null,
      ChatToName: null,
      ChatMessage: this.messa,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.touser,
    };
    this.userser.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Message Sent Successfully");
      }
    });
  }



  addFriend( addfriendcontent) {
    this.touser = this.id;
    // this.OnSelectUser(id);
    this.modalService.open(addfriendcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.accid;
      }
    );
  }
 
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

 
 


  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

 

  Edudet1: any;

  edrad1: boolean;
  Cname1: string;
  Course1: string;

  edid1: any;




  getExp() {
    this.userser.getExpDet(this.id).subscribe((respose: any) => {
      this.Edudet1 = respose;
      console.log(respose);
    });
  }


  GetResume() {
    this.userser.getresumefile(this.id).subscribe((respose: any) => {
      if(respose != "0")
      {
        this.ResumeFile = respose;
        this.pdfUrl = this.ResumeFile.file;
      }
     else
     {
      this.pdfUrl = null;
     }
   
    });
  }
  print (printSectionId) {
    var innerContents = document.getElementById(printSectionId).innerHTML;
  
    var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=yes');
    popupWinindow.document.open();
    popupWinindow.document.write('<html> <head><style>  a{   color: black;        text-decoration: none;    }    a:hover{        cursor: inherit;    }    #main-report{        width: 100%;    }    table {         color: #333;        font-family: Helvetica, Arial, sans-serif;        width: 950px;         border-collapse: collapse;         border-spacing: 0;     }    td, th {   /* No more visible border */        height: 30px;         font-size: 9px;    }    th {        background: #DFDFDF;  /* Darken header a bit */        font-weight: bold;   }    td {        background: #FAFAFA;        text-align: center;}   /* Cells in even rows (2,4,6...) are one color */     tr:nth-child(even) td { background: #F1F1F1; }    /* Cells in odd rows (1,3,5...) are another (excludes header cells)  */     tr:nth-child(odd) td { background: #FEFEFE; }   table, tr, td, th, tbody, thead, tfoot {        page-break-inside: important;   }    @page {            border: solid white thick;        margin: 40px;        padding: 2cm    } </style>    </head><body onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
    //window.print();
  
  };

  save(){
    this.GetProfileDetails()
    if(this.timeduration == null){
      alert("Please Enter From Date")
    }else if(this.lastdate == null){
      alert("Please Enter To Date")
    }else if( this.sal == null){
      alert("Please Enter Probation Period Salary")
    }else{
      this.candidateName = this.candidateName
      this.parentName = this.parentName
      this.address = this.address
      this.adhaar = this.adhaar
      this.mobnum = this.mobnum
      this.mail = this.mail
      this.timeduration = this.timeduration
      this.lastdate=this.lastdate
      this.sal = this.sal
    }
  }
  GetProfileDetails() {
    this.userser.getcompanyprofile(this.accid).subscribe((data:any)=>{
      this.alldata = data[0];
      this.masterService.getindustry().subscribe((data:any)=>{
       this.Industry = data;
       this.Industry.forEach(element => {
         if(element['id'] == this.alldata.industryType){
           this.alldata.industry = element['indrustType']
         }
       });
     })     
      //this.spinner.hide();
     })
   }
}
