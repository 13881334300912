import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { PlaytypeJobPortalVM } from 'app/shared/VIewmodels/PlaytypeJobPortalVM';

@Component({
  selector: 'app-company-payu-plans',
  templateUrl: './company-payu-plans.component.html',
  styleUrls: ['./company-payu-plans.component.scss']
})
export class CompanyPayuPlansComponent implements OnInit {

  plantype: string;
  amount: string;
  planlist: any;
  palnid: any;
  plaid: any;

  constructor(private masterservices:MasterService , private router: Router) { }

  ngOnInit() {
    this.PlansList();
    this.plantype = "";
    this.amount = "";
  }
  ResetData(){
    this.plantype = "";
    this.amount = "";
  }

  PlansList() {
    this.masterservices.getPlanTypeCompany().subscribe((response: any) => {
      this.planlist = response;
    })
  }
  numofemp="0 - 10";
 numofemployee(value){
this.numofemp=value;
console.log(value)
 }
  SavePlanType(form: NgForm) {
    const inputRequest: PlaytypeJobPortalVM = {
      Id: 0,
      Type: form.controls["plantype"].value,
      Price: form.controls["amount"].value,
      Period:this.numofemp
    }
    this.masterservices.savePlanTypeCompany(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Added Successfully");
        this.router.navigate(['/companypayuplanlist']);
      }
      else {
        alert("Something went Wrong");
        this.router.navigate(['/companypayuplanlist']);
      }
    });
  }

  editplantype(data: { type: any; id: any; price:any;}) {
    this.plantype = data.type;
    this.palnid = data.id;
    this.amount = data.price;
  }

  UpdatePlanType(form: NgForm) {
    const inputRequest1: PlaytypeJobPortalVM = {
      Id: this.palnid,
      Type: form.controls["plantype"].value,
      Price: form.controls["amount"].value,
      Period:this.numofemp
    }
    this.masterservices.UpdatePlanTypeCompany(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          alert("Updated Succesfully");
          this.PlansList();
        } else {
          alert("Something Went Wrong. Try again.!!");

        }
      });
  }
  deleteplantype(data: { id: any; }) {
    this.plaid = data.id;
    this.masterservices.deletePlantypeCompany(this.plaid).subscribe(
      (data: any) => {
        if (data != null) {
          alert(" Deleted Succesfully");
          this.PlansList();
        } else {
          alert("Went Wrong. Try again.!!");
        }
      });
  }

}
