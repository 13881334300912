<div class="whole-page">
    <br><br><br><br>
    <div class="whole-section">
        <div class="top-section">
            <div class="top-section-profile-name">{{username}}</div>
            <img src="assets/img/avatar.svg" alt="">
        </div>
        <div class="bottom-section">
            <div class="bottom-section-title">Your Details &nbsp;</div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Name</div>
                <div class="bottom-section-right">: {{username}} </div>
            </div>

            <div class="bottom-section-details">
                <div class="bottom-section-left">Email</div>
                <div class="bottom-section-right">: {{email}} </div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Preferred Role</div>
                <div class="bottom-section-right">: {{role.role}} </div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Preferred Salary Range</div>
                <div class="bottom-section-right">: {{salaryrange.salary}}</div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Preferred Perks</div>
                <div class="bottom-section-right">: {{perks.perks}}</div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Preferred Industry</div>
                <div class="bottom-section-right">: {{industry.indrustType}}</div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Preferred Growth</div>
                <div class="bottom-section-right">: {{growth.growth1}}</div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Education</div>
                <div class="bottom-section-right">: {{education.highestQualification}}</div>
            </div>
            <!-- <div class="bottom-section-details">
                <div class="bottom-section-left">Preferred Locations</div>
                <div class="bottom-section-right">: {{loc1}} <br>{{loc2}} <br> {{loc3}}</div>
            </div> -->
            <!-- <div class="bottom-section-details">
                <div class="bottom-section-left">Education</div>
                <div class="bottom-section-right">: Surjith</div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Skills</div>
                <div class="bottom-section-right">: Surjith</div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Marks Card</div>
                <div class="bottom-section-right">: Surjith</div>
            </div>
            <div class="bottom-section-details">
                <div class="bottom-section-left">Certificates</div>
                <div class="bottom-section-right">: Certificates</div>
            </div> -->



            <!-- Modal copies -->

            <!------------------------------------------- Modal HTML delete --------------------------->
            <div id="myModal" class="modal fade">
                <div class="modal-dialog modal-confirm">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="icon-box">
                                <i class="material-icons">&#xE5CD;</i>
                            </div>
                            <h4 class="modal-title">Are you sure?</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                      &times;
                    </button>
                        </div>
                        <div class="modal-body">
                            <p>
                                Do you really want to delete these records? This process cannot be undone.
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" data-dismiss="modal">
                      Cancel
                    </button>
                            <button type="button" class="btn btn-danger">Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <------------------------------------------popup---------------------------------->
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Edit</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                    </button>
                        </div>
                        <div class="modal-body">
                            <div class="container fileUploadWrapper">
                                <div class="col-md-12">
                                    <ul style="list-style:none;">
                                        <div class="modal-body">
                                            <form>
                                                <h5 class="modal-title" id="modal-basic-title">
                                                    College Name
                                                </h5>
                                                <br />
                                                <input type="text" id="requestingfor" [(ngModel)]="requestingfor" class="form-control" name="requestingfor" /><br />

                                                <h5 class="modal-title" id="modal-basic-title">
                                                    Course
                                                </h5>
                                                <br />
                                                <input type="text" id="requestingfor" [(ngModel)]="requestingfor" class="form-control" name="requestingfor" /><br />
                                            </form>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer;">
                  Cancel
                </button>
                            <button type="button" class="btn btn-primary" style="cursor: pointer;">
                  Upload
                </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Education Details</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                        </div>
                        <div class="modal-body">
                            <div class="container fileUploadWrapper">
                                <div class="col-md-12">
                                    <ul style="list-style:none;">
                                        <div class="modal-body">
                                            <form>
                                                <h5 class="modal-title" id="modal-basic-title">
                                                    College Name
                                                </h5>
                                                <br />
                                                <input type="text" id="Cname" [(ngModel)]="Cname" class="form-control" name="Cname" /><br />

                                                <h5 class="modal-title" id="modal-basic-title">
                                                    Course
                                                </h5>
                                                <br />
                                                <input type="text" id="Course" [(ngModel)]="Course" class="form-control" name="Course" /><br />
                                                <h5 class="modal-title" id="modal-basic-title">
                                                    Date
                                                </h5>
                                                <br />
                                                <input type="date" id="date" [(ngModel)]="date" class="form-control" name="date" /><br />

                                            </form>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer;">
                  Cancel
                </button>
                            <button type="button" class="btn btn-primary" (click)="AddEdu()" style="cursor: pointer;" *ngIf="edrad">
                  Add
                </button>
                            <button type="button" class="btn btn-primary" (click)="updatedu()" style="cursor: pointer;" *ngIf="!edrad">
                update
              </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -----------------------------------------Skills-------------------------------------------------------------------- -->
            <div class="bottom-section-details" style="z-index: 2;">
                <div class="bottom-section-left">Skills</div>
                <div class="bottom-section-right">:
                    <div *ngFor="let a of Skilldet">
                        <div class="col-md-12 row" style="margin-top: -20px;">

                            <div class="col-md-6" style="padding-left: 8px;">

                                <span style="width: max-content;"> {{a.skill1}}</span>
                            </div>
                            <div class="col-md-2">&nbsp;</div>
                            <div class="col-md-1">
                                <div>
                                    <img data-toggle="modal" data-target="#Addskill" (click)="editskl(a)" src="/assets/img/edit.png" width="30vw" height="30vw" style="z-index: 10;" />
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <img src="/assets/img/delete.svg" (click)="deleteskl(a)" width="30vw" height="30vw" style="z-index: 10;" />
                                </div>
                            </div>
                        </div>
                        <br><br>
                    </div>
                    <span style="display: flex; justify-content: flex-end; padding-right: 20px;">
                            <button data-toggle="modal" data-target="#Addskill" style="border-radius: 10px;background-color: blue;color: white;padding: 5px 10px 5px 10px;" (click)="addskl()">&nbsp;&nbsp;Add</button>
                        </span>
                </div>
                <br><br><br><br>
            </div>

            <!--                 
                <div class="col-md-12 row ">
                    <div class="col-md-1 "></div>
                    <div class="col-md-5 ">
                        <h1>Skills</h1>
                    </div>
                    <div class="col-md-4 ">&nbsp;</div>
                    <div class="col-md-2 ">
                        <div>
                            <button data-toggle="modal " data-target="#Addskill " style="border-radius: 10px;background-color: blue;color: white;border: none;padding: 5px 10px 5px 10px; " (click)="addskl() ">Add</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>


                <div *ngFor="let a of Skilldet ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 ">&nbsp;</div>
                        <div class="col-md-4 ">
                            <span style="font-size: 15px "><b>{{a.skill1}}</b></span><br>
                        </div>
                        <div class="col-md-2 ">&nbsp;</div>
                        <div class="col-md-1 ">
                            <div data-toggle="modal " data-target="#Addskill ">
                                <img src="/assets/img/edit.png " (click)="editskl(a) " width="30vw " height="30vw " />
                            </div>
                        </div>
                        <div class="col-md-1 ">
                            <div>
                                <img src="/assets/img/delete.svg " (click)="deleteskl(a) " width="30vw " height="30vw " />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 ">&nbsp;</div>
                        <div class="col-md-8 ">
                            <hr>
                        </div>
                    </div>

                </div> -->

            <div class="modal fade" id="Addskill" tabindex="-1 " role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Skill Details</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                        </div>
                        <div class="modal-body">
                            <div class="container fileUploadWrapper">
                                <div class="col-md-12">
                                    <ul style="list-style:none;">
                                        <div class="modal-body">

                                            <h5 class="modal-title" id="modal-basic-title">
                                                Skill
                                            </h5>
                                            <br />
                                            <input type="text" class="form-control" id="Skill" [(ngModel)]="Skill" name="Skill" /><br />



                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer;">
                  Cancel
                </button>

                            <button type="button" class="btn btn-primary" (click)="Addskill()" style="cursor: pointer;" *ngIf="edrad">
                  Add
                </button>
                            <button type="button" class="btn btn-primary" (click)="updateskill()" style="cursor: pointer;" *ngIf="!edrad">
                  Update
                </button>
                        </div>
                    </div>
                </div>
            </div>



            <!-- ---------------------------------------------markscard--------------------------------------------------------------------------------- -->

            <!-- <div class="bottom-section-details " style="z-index: 3; ">
                <div class="bottom-section-left ">Markscard</div>
                <div class="bottom-section-right ">:
                    <div *ngFor="let a of marks ">
                        <div class="col-md-12 row " style="margin-top: -20px; ">

                            <div class="col-md-6 " style="padding-left: 8px; ">

                                <span style="width: max-content; "> {{a.documentName}}</span>
                            </div>
                            <div class="col-md-2 ">&nbsp;</div>
                            
                            <div class="col-md-1 ">
                                <div>
                                    <img src="/assets/img/delete.svg " (click)="deletedoc(a) " width="30vw " height="30vw " />
                                </div>
                            </div>
                        </div>
                        <br><br>
                    </div>
                    <span style="display: flex; justify-content: flex-end; padding-right: 20px; ">
                            <button data-toggle="modal " data-target="#Addmksc " style="border-radius: 10px;background-color: blue;color: white;padding: 5px 10px 5px 10px; ">&nbsp;&nbsp;Add</button>
                        </span>
                </div>
                <br><br>
            </div> -->



            <!-- 
            <div class="col-md-12 row ">
                <div class="col-md-1 ">&nbsp;</div>
                <div class="col-md-5 ">
                    <h1>Markscard</h1>
                </div>
                <div class="col-md-4 ">&nbsp;</div>
                <div class="col-md-2 ">
                    <div>
                        <button data-toggle="modal " data-target="#Addmksc " style="border-radius: 10px;background-color: blue;color: white;padding: 5px 10px 5px 10px; ">Add</button>
                    </div>
                </div>
            </div> -->



            <!-- <div *ngFor="let a of marks ">
                <div class="col-md-12 row ">
                    <div class="col-md-2 ">&nbsp;</div>
                    <div class="col-md-4 ">
                        <span style="font-size: 15px "><b>{{a.documentName}}</b></span><br>
                    </div>
                    <div class="col-md-2 ">&nbsp;</div>
                    <div class="col-md-1 ">
                       
                        &nbsp;
                    </div>
                    <div class="col-md-1 ">
                        <div>
                            <img src="/assets/img/delete.svg " (click)="deletedoc(a) " width="30vw " height="30vw " />
                        </div>
                    </div>
                </div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 ">&nbsp;</div>
                    <div class="col-md-8 ">
                        <hr>
                    </div>
                </div>

            </div> -->


            <!-- -----------------------------------------------Certificates------------------------------------------------------------------------- -->


            <!-- <div class="col-md-12 row ">
                <div class="col-md-1 ">&nbsp;</div>
                <div class="col-md-5 ">
                    <h1>Certificates</h1>
                </div>
                <div class="col-md-4 ">&nbsp;</div>
                <div class="col-md-2 ">
                    <div>
                        <button data-toggle="modal " data-target="#Addcerti " style="border-radius: 10px;background-color: blue;color: white;padding: 5px 10px 5px 10px; ">Add</button>
                    </div>
                </div>
            </div> -->

            <!-- 
            <div *ngFor="let a of Certi ">
                <div class="col-md-12 row ">
                    <div class="col-md-2 ">&nbsp;</div>
                    <div class="col-md-4 ">
                        <span style="font-size: 15px "><b>{{a.documentName}}</b></span><br>
                    </div>
                    <div class="col-md-2 ">&nbsp;</div>
                    <div class="col-md-1 ">
                       
                        &nbsp;
                    </div>
                    <div class="col-md-1 ">
                        <div>
                            <img src="/assets/img/delete.svg " (click)="deletedoc(a) " width="30vw " height="30vw " />
                        </div>
                    </div>
                </div> -->

            <!-- <div class="col-md-12 row ">
                    <div class="col-md-2 ">&nbsp;</div>
                    <div class="col-md-8 ">
                        <hr>
                    </div>
                </div>

            </div>



        </div> -->


            <div class="modal fade" id="Addmksc " tabindex="-1 " role="dialog " aria-labelledby="exampleModalLabel " aria-hidden="true ">
                <div class="modal-dialog " role="document ">
                    <div class="modal-content ">
                        <div class="modal-header ">
                            <h5 class="modal-title " id="exampleModalLabel ">Add Marks Cards</h5>
                            <button type="button " class="close " data-dismiss="modal " aria-label="Close ">
                  <span aria-hidden="true ">&times;</span>
                </button>
                        </div>
                        <div class="modal-body ">
                            <div class="container fileUploadWrapper ">
                                <div class="col-md-12 ">
                                    <ul style="list-style:none; ">
                                        <div class="modal-body ">


                                            <br />
                                            <div class="form-group ">
                                                <label for="file ">Choose Marks Card Documents</label>
                                                <input type="file " id="file " multiple (change)="handleFileInput1($event.target.files) ">


                                            </div>


                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer ">
                            <button type="button " id="cancelUpload " class="btn btn-secondary " data-dismiss="modal " style="cursor: pointer; ">
                  Cancel
                </button>
                            <button type="button " class="btn btn-primary " (click)="uploaddocs( 'Marks Cards') " style="cursor: pointer; ">
                  Upload
                </button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="Addcerti" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Documents</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                        </div>
                        <div class="modal-body">
                            <div class="container fileUploadWrapper">
                                <div class="col-md-12">
                                    <ul style="list-style:none;">
                                        <div class="modal-body">


                                            <br />
                                            <div class="form-group">
                                                <label for="file">Choose Documents</label>
                                                <input type="file" id="file" multiple (change)="handleFileInput1($event.target.files)">


                                            </div>


                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer;">
                  Cancel
                </button>
                            <button type="button" class="btn btn-primary" (click)="uploaddocs( 'Certificates')" style="cursor: pointer;">
                  Upload
                </button>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </div>