import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-backgroundverification',
  templateUrl: './backgroundverification.component.html',
  styleUrls: ['./backgroundverification.component.scss'],
})
export class BackgroundverificationComponent implements OnInit {
  constructor() {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {}
}
