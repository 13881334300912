import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { HomeoneComponent } from './homeone/homeone.component';
// import { PaymentComponent } from './payment/payment.component';
// import { SubplansComponent } from './subplans/subplans.component';
// import { MyPlansComponent } from './my-plans/my-plans.component';
// import { BillComponent } from './bill/bill.component';
// import { DashComponent } from './dash/dash.component';
import { NewhomeComponent } from './newComponents/newhome/newhome.component';
import { ForgotpwdComponent } from './newcomponents/forgotpwd/forgotpwd.component';
import { AppjobComponent } from './newComponents/appjob/appjob.component';
import { ProfilepagenewComponent } from './newComponents/profilepagenew/profilepagenew.component';
import { SharedEmployerComponent } from './newComponents/shared-employer/shared-employer.component';
import { ProfileregComponent } from './newcomponents/profilereg/profilereg.component';
import { AllfoldersComponent } from './USRM/allfolders/allfolders.component';
import { AllfilesComponent } from './USRM/allfiles/allfiles.component';
import { NewbothsigninComponent } from './newComponents/newbothsignin/newbothsignin.component';
import { SharefileComponent } from './newComponents/sharefile/sharefile.component';
import { DeletedfilesComponent } from './newComponents/deletedfiles/deletedfiles.component';
import { StudentchatComponent } from './newComponents/studentchat/studentchat.component';
import { JobpostinsComponent } from './newComponents/jobpostins/jobpostins.component';
import { JobviewforinsComponent } from './newComponents/jobviewforins/jobviewforins.component';
import { InstituteregisterComponent } from './newComponents/instituteregister/instituteregister.component';
import { InternshippostingComponent } from './newComponents/internshipposting/internshipposting.component';
import { TrainingpostComponent } from './newComponents/trainingpost/trainingpost.component';
import { InternshipviewforinsComponent } from './newComponents/internshipviewforins/internshipviewforins.component';
import { TrainingviewComponent } from './newComponents/trainingview/trainingview.component';
import { CompanyprofileComponent } from './newComponents/companyprofile/companyprofile.component';
import { Profile1Component } from './newComponents/profile1/profile1.component';
import { CompanyMatchListComponent } from './company-match-list/company-match-list.component';
import { ProfileviewusersComponent } from './newcomponents/profileviewusers/profileviewusers.component';
import { EregistrationComponent } from './newcomponents/eregistration/eregistration.component';
import { PlacedComponent } from './newcomponents/placed/placed.component';
import { ReportComponent } from './newcomponents/report/report.component';
import { CompletedComponent } from './newcomponents/completed/completed.component';
import { AppliedComponent } from './newcomponents/applied/applied.component';
import { TableComponent } from './newcomponents/table/table.component';
import { dashCaseToCamelCase } from '@angular/compiler/src/util';
import { DashboardComponent } from './newcomponents/dashboard/dashboard.component';
import { AdminComponent } from './newComponents/admin/admin.component';
import { InstitutionComponent } from './newcomponents/masters/institution/institution.component';
import { ApproleComponent } from './newcomponents/masters/approle/approle.component';
import { AddoptionsComponent } from './newcomponents/mcq/addoptions/addoptions.component';
import { AddquestionsComponent } from './newcomponents/mcq/addquestions/addquestions.component';
import { TestBed } from '@angular/core/testing';
import { TestComponent } from './newcomponents/mcq/test/test.component';
import { CodemirrorComponent } from './newComponents/mcq/codemirror/codemirror.component';
import { McqreportComponent } from './newComponents/mcq/mcqreport/mcqreport.component';
import { PlacementprofileComponent } from './newComponents/placementprofile/placementprofile.component';
import { VideostreamingComponent } from './newComponents/mcq/videostreaming/videostreaming.component';
import { VideoreceiverComponent } from './newComponents/mcq/videoreceiver/videoreceiver.component';
import { hostViewClassName } from '@angular/compiler';
import { HosteventComponent } from './newComponents/VideoInterview/hostevent/hostevent.component';
import { JoinmeetingComponent } from './newComponents/VideoInterview/joinmeeting/joinmeeting.component';
import { HostjoinComponent } from './newComponents/VideoInterview/hostjoin/hostjoin.component';
import { CompanyPayuPlansComponent } from './Masters/company-payu-plans/company-payu-plans.component';
import { AlljoblistComponent } from './alljoblist/alljoblist.component';
import { AllcompanylistComponent } from './allcompanylist/allcompanylist.component';
import { AllstudentlistComponent } from './allstudentlist/allstudentlist.component';
import { AdmindashboardComponent } from './admindashboard/admindashboard.component';
import { JobrolemasterComponent } from './jobrolemaster/jobrolemaster.component';
import { IndustrymasterComponent } from './industrymaster/industrymaster.component';
import { BackgroundverificationComponent } from './backgroundverification/backgroundverification.component';
import { GotojobpostingComponent } from './gotojobposting/gotojobposting.component';
import { AlertComponent } from './alert/alert.component';
import { SkillComponent } from './Masters/skill/skill.component';
import { LocationComponent } from './Masters/location/location.component';
import { JobSkillCategoryMasterComponent } from './Masters/job-skill-category-master/job-skill-category-master.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { MsviewComponent } from './msview/msview.component';
import { SocialComponent } from './social/social.component';





import { JobreadmailComponent } from './jobmail/jobreadmail/jobreadmail/jobreadmail.component';
import { JobmailcomposeComponent } from './jobmail/jobmailcomposee/jobmailcomposee/jobmailcomposee.component';
import { JobinboxmailComponent } from './jobmail/jobinboxmail/jobinboxmail/jobinboxmail.component';
import { JobsentmailComponent } from './jobmail/jobsentmail/jobsentmail/jobsentmail.component';
import { JobarchivemailComponent } from './jobmail/jobarchivemail/jobarchivemail/jobarchivemail.component';
import { JobdeletedmailComponent } from './jobmail/jondeletedmail/jobdeletedmail/jobdeletedmail.component';
import { MailcomposeeComponent } from './newComponents/Mails/mailcomposee/mailcomposee/mailcomposee.component';
import { InboxMailsComponent } from './newComponents/Mails/inboxmails/inbox-mails/inbox-mails.component';
import { ReadmailsComponent } from './newComponents/Mails/readmails/readmails/readmails.component';
import { ArchivemailsComponent } from './newComponents/Mails/archivemails/archivemails/archivemails.component';
import { SentmailsComponent } from './newComponents/Mails/sentmails/sentmails/sentmails.component';
import { DeletedMailsComponent } from './newComponents/Mails/deletedmails/deleted-mails/deleted-mails.component';
import { MailComponent } from './Mail/mail/mail/mail.component';
import { FriendrequestComponent } from './newComponents/friendrequest/friendrequest/friendrequest.component';
import { PhysicalvideoComponent } from './newComponents/VideoInterview/physicalvideo/physicalvideo.component';
import { RecordedphysicalvideosComponent } from './newComponents/VideoInterview/recordedphysicalvideos/recordedphysicalvideos.component';
import { OnlinecodecompilerComponent } from './newComponents/onlinecodecompiler/onlinecodecompiler.component';
import { DocumentgenerationComponent } from './documentgeneration/documentgeneration.component';


export const routes: Routes = [
  {
    path:'jobmail',
    component:JobinboxmailComponent
  },
  {
    path:'jobcompose',
    component:JobmailcomposeComponent
  },
  {
    path:'jobsent',
    component:JobsentmailComponent  
  },
  {
    path:'jobarchive',
    component:JobarchivemailComponent
  },
  {
    path:'jobdelete',
    component:JobdeletedmailComponent
  },
  {
    path:'readmsgjob/:id',
    component:JobreadmailComponent
  },

  {
    path:'mcompose',
    component:MailcomposeeComponent
  },
  {
    path:'inboxmails',
    component:InboxMailsComponent
  },
  {
    path:'readmsg/:id',
    component:ReadmailsComponent
  },
  {
    path:'archive',
    component:ArchivemailsComponent
  },
  
  {
    path:'sentmails',
    component:SentmailsComponent
  },
{
  path:'del',
  component:DeletedMailsComponent
},
  
  {
    path: '',
    component: NewhomeComponent,
  },

  {
    path: 'newhome',
    component: NewhomeComponent,
  },
  // {
  //   path: 'jobreg',
  //   loadChildren: () =>
  //     import('./job-reg/jobreg/jobreg.module').then((m) => m.JobregModule),
  // },

  {
    path: 'jobposting',
    loadChildren: () =>
      import('./jobpost/jobpost/jobpost.module').then((m) => m.JobpostModule),
  },
  {
    path: 'companyRegistration',
    loadChildren: () =>
      import('./companyregistration/companyreg/companyreg.module').then(
        (m) => m.CompanyregModule
      ),
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./support/support/support.module').then((m) => m.SupportModule),
  },

  // {
  //   path: 'newlogin',
  //   loadChildren: () => import('./newComponents/newlogin/newlogin/newlogin.module').then(m => m.NewloginModule)
  // }
  // ,
  // {
  //   path: 'up',
  //   loadChildren: () => import('./userprof/userprof/userprof.module').then(m => m.UserprofModule)
  // }
  // ,
  // {
  //   path: 'faq',
  //   loadChildren: () => import('./newComponents/newfaq/faq/faq.module').then(m => m.FaqModule)
  // },

  // {
  //   path: 'howto',
  //   loadChildren: () => import('./newComponents/newfaq/faq/faq.module').then(m => m.FaqModule)
  // },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./newComponents/privacy/privacy/privacy.module').then(
        (m) => m.PrivacyModule
      ),
  },
  {
    path: 'termsofservice',
    loadChildren: () =>
      import('./newComponents/termsofservice/terms/terms.module').then(
        (m) => m.TermsModule
      ),
  },
  // {
  //   path: 'help',
  //   loadChildren: () => import('./newComponents/helppage/help/help.module').then(m => m.HelpModule)
  // },

  {
    path: 'admin',
    component: AdminComponent,
  },
  {
    path: 'payuplans/:id',
    loadChildren: () =>
      import('./PayU/payu-plans/payuplans/payuplans.module').then(
        (m) => m.PayuplansModule
      ),
  },

  {
    path: 'payment/:id',
    loadChildren: () =>
      import('./PayU/payu/payu/payu.module').then((m) => m.PayuModule),
  },
  {
    path: 'joblist',
    loadChildren: () =>
      import('./job-list/job-list/job-list.module').then(
        (m) => m.JobListModule
      ),
  },
  {
    path: 'sjoblist',
    loadChildren: () =>
      import('./seeker-match/seekerm/seekerm.module').then(
        (m) => m.SeekermModule
      ),
  },
  {
    path: 'seekerlist/:id',
    component: CompanyMatchListComponent,
  },
  {
    path: 'Seekerpayuplanlist',
    loadChildren: () =>
      import('./Masters/seeker-payu-plans/seekerpay/seekerpay.module').then(
        (m) => m.SeekerpayModule
      ),
  },

  {
    path: 'companypayuplanlist',
    loadChildren: () =>
      import('./Masters/company-payu-plans/companypay/companypay.module').then(
        (m) => m.CompanypayModule
      ),
  },

  {
    path: 'newsignup',
    loadChildren: () =>
      import(
        './newComponents/newbothregistration/newbothreg/newbothreg.module'
      ).then((m) => m.NewbothregModule),
  },

  {
    path: 'newsignin',
    component: NewbothsigninComponent,
  },
  {
    path: 'profileview/:id',
    component: ProfileviewusersComponent,
  },

  {
    path: 'forgotpassword',
    component: ForgotpwdComponent,
    // },
    // {
    // path: 'newprofile',
    // loadChildren: () => import('./newComponents/profilereg/profilereg.module').then(m => m.ProfileregModule)
    // },
  },
  {
    path: 'newprofile',
    component: ProfileregComponent,
    // loadChildren: () => import('./newComponents/profilereg/profilereg.module').then(m => m.ProfileregModule)
  },
  {
    path: 'appjobseek',
    component: AppjobComponent,
  },
  {
    path: 'addoptions',
    component: AddoptionsComponent,
  },
  {
    path: 'addquestions/:id',
    component: AddquestionsComponent,
  },
  {
    path: 'test/:id',
    component: TestComponent,
  },
  {
    path: 'appprofile',
    component: Profile1Component,
  },
  {
    path: 'coding/:id',
    component: CodemirrorComponent,
  },
  {
    path: 'report/:id',
    component: McqreportComponent,
  },
  {
    path: 'applicantshare',
    component: SharedEmployerComponent,
  },
  {
    path: 'allfolders',
    component: AllfoldersComponent,
  },
  {
    path: 'allsubfolders/:id',
    component: AllfilesComponent,
  },
  {
    path: 'shared',
    component: SharefileComponent,
  },
  {
    path: 'deleted',
    component: DeletedfilesComponent,
  },
  {
    path: 'studentchat',
    component: StudentchatComponent,
  },
  {
    path: 'jobregforins',
    component: JobpostinsComponent,
  },
  {
    path: 'jobviewforins',
    component: JobviewforinsComponent,
  },
  {
    path: 'newsignin/:id',
    component: NewbothsigninComponent,
  },
  {
    path: 'internview',
    component: InternshipviewforinsComponent,
  },
  {
    path: 'trainings',
    component: TrainingviewComponent,
  },
  // {
  // path: 'postcode',
  // component: PostcodeComponent
  // },
  // {
  // path: 'invitecompany',
  // component:
  // },

  {
    path: 'instituteReg',
    component: InstituteregisterComponent,
  },
  {
    path: 'internshippost',
    component: InternshippostingComponent,
  },
  {
    path: 'trainingpost',
    component: TrainingpostComponent,
  },
  {
    path: 'companyprofile',
    component: CompanyprofileComponent,
  },
  {
    path: 'eregistration',
    component: EregistrationComponent,
  },
  {
    path: 'placed',
    component: PlacedComponent,
  },
  {
    path: 'report',
    component: ReportComponent,
  },
  {
    path: 'completed',
    component: CompletedComponent,
  },
  {
    path: 'applied',
    component: AppliedComponent,
  },
  {
    path: 'applied/:id',
    component: AppliedComponent,
  },
  {
    path: 'table',
    component: TableComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'institutionmaster',
    component: InstitutionComponent,
  },
  {
    path: 'approlemaster',
    component: ApproleComponent,
  },
  {
    path: 'placementprofile',
    component: PlacementprofileComponent,
  },
  {
    path: 'videostream/:id',
    component: VideostreamingComponent,
  },
  {
    path: 'videoreceiver/:id',
    component: VideoreceiverComponent,
  },
  {
    path: 'videointerview',
    component: HosteventComponent,
  },
  {
    path: 'joinmeeting/:id',
    component: JoinmeetingComponent,
  },
  {
    path: 'hostjoin',
    component: HostjoinComponent,
  },
  {
    path: 'paymentchanges',
    component: CompanyPayuPlansComponent,
  },

  // -------------------new---------------
  {
    path: 'backgroundverification',
    component: BackgroundverificationComponent,
  },
  {
    path: 'gotojobposting',
    component: GotojobpostingComponent,
  },
  {
    path: 'industrymaster',
    component: IndustrymasterComponent,
  },
  {
    path: 'jobrolemaster',
    component: JobrolemasterComponent,
  },
  {
    path: 'admindashboard',
    component: AdmindashboardComponent,
  },
  {
    path: 'allstudentlist',
    component: AllstudentlistComponent,
  },

  {
    path: 'allcompanylist',
    component: AllcompanylistComponent,
  },
  {
    path: 'alljoblist',
    component: AlljoblistComponent,
  },
  {
    path: 'alert',
    component: AlertComponent,
  },
  {
    path: 'skill',
    component: SkillComponent,
  },
  {
    path: 'location1',
    component: LocationComponent,
  },
  {
    path: 'skillgroup',
    component: JobSkillCategoryMasterComponent,
  },
  {
    path: 'about',
    component: AboutusComponent,
  },
  {
    path: 'msview',
    component: MsviewComponent,
  },
  {
    path: 'Social',
    component: SocialComponent,
  },
  {
    path: 'mail',
    component: MailComponent,
  },
  {
    path: 'mail/:id',
    component: MailComponent,
  },
  {
    path:'friendrequest',
    component:FriendrequestComponent
  },
  {
    path: 'physicalvideoconference',
    component: PhysicalvideoComponent,
  },
  {
    path: 'recordedvideos',
    component: RecordedphysicalvideosComponent
  },
  {
    path: 'codecompiler/:id',
    component: OnlinecodecompilerComponent
  },
  {
    path: 'certificates/:id',
    component: DocumentgenerationComponent
  },
];


@NgModule({
  // schemas: [
  // CUSTOM_ELEMENTS_SCHEMA
  // ],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
