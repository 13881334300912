<div>&nbsp;</div>
<div>&nbsp;</div>



<app-nav-bar></app-nav-bar>

<!-- MultiStep Form -->
<div class="container-fluid" id="grad1">
    <div class="row justify-content-center mt-0">
        <div class="col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2">
            <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                <h2><strong>Sign Up</strong></h2>
                <p></p>
                <div class="row">
                    <div class="col-md-12 mx-0">
                        <form id="msform" [formGroup]="EnterpriseForm">
                            <!-- progressbar -->
                            <ul id="progressbar">
                                <li class="active" id="account"><strong>Institution</strong></li>
                                <li id="personal"><strong>Logo</strong></li>
                                <li id="confirm"><strong>Finish</strong></li>

                            </ul>
                            <!-- fieldsets -->
                            <fieldset>
                                <div class="form-card">
                                    <h2 class="fs-title" style="text-align: center;">Institution Information</h2>
                                    <select class='select-option' style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-top: 10px;text-decoration: none;" (change)='onchangeinstitution($event)'>
                                        <option value="" selected disabled hidden style="color: #312f5356;">Please select your Institution Name</option>
                                    <option class='option' *ngFor="let insti of institution"  value="{{insti.id}}" >{{insti.institutionName}}</option>
                                
                                    </select>
                                    <br>
                                    <br>
                                    <input formControlName="email" [(ngModel)]="email" type="text" name="email" (change)="Validateone()" placeholder="Official Email" />
                                    <br>

                                    <input formControlName="phnno" [(ngModel)]="phnno" type="text" name="phnno" (change)="Validateone()" placeholder="Mobile" />
                                    <div *ngIf="submitted && f.phnno.errors" class="invalid-feedback">
                                        <div *ngIf="f.phnno.errors.required">Phone Number is required</div>
                                    </div>
                                    <!-- <input formControlName="noemp" [(ngModel)]="noemp" type="number" name="noemp" placeholder="Number of Employees" (change)="Validateone()" [ngClass]="{ 'is-invalid': submitted && f.noemp.errors }" />
                                    <div *ngIf="submitted && f.noemp.errors" class="invalid-feedback">
                                        <div *ngIf="f.noemp.errors.required">Number of Branches is required</div>
                                    </div> -->
                                    <!-- <select type="password" name="pwd" placeholder="Country" /> -->
                                    <input formControlName="pincode" [(ngModel)]="pincode" type="text" name="pincode" placeholder="Pincode" (blur)="getalldata()" />
                                    <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                        <div *ngIf="f.pincode.errors.required">Pincode is required</div>
                                    </div>

                                    <input formControlName="city" [(ngModel)]="city" type="text" name="city" (change)="Validateone()" placeholder="District" />
                                    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                        <div *ngIf="f.city.errors.required">Number of Branches is required</div>
                                    </div>
                                    <input formControlName="state" [(ngModel)]="state" type="text" name="state" (change)="Validateone()" placeholder="State" />
                                    <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                        <div *ngIf="f.state.errors.required">Number of Branches is required</div>
                                    </div>
                                    <input formControlName="Country" [(ngModel)]="Country" type="text" name="Country" (change)="Validateone()" placeholder="Country" />
                                    <div *ngIf="submitted && f.Country.errors" class="invalid-feedback">
                                        <div *ngIf="f.Country.errors.required">Number of Branches is required</div>
                                    </div>





                                </div>
                                <input type="button" class="next action-button" name="next" value="Next Step" />
                            </fieldset>
                            <fieldset>
                                <div class="form-card">
                                    <h2 class="fs-title" style="text-align: center;">Upload a logo or drop here</h2>
                                    <div class="container fileUploadWrapper">
                                        <div class="col-md-12">
                                            <div class="fileupload" appDragDropFileUpload accept="image/*" (click)="fileField.click()" (fileDropped)="onDrop($event)">
                                                <span class="ddinfo">Choose an object or drag here</span>
                                            </div>
                                            <input type="file" accept="image/*" name="avatars" #fileField (change)="onChange($event)">
                                        </div>
                                        <h3 *ngIf="validfile" style="color: red;">Please Upload Only One File of type png or jpg or jpeg</h3>
                                    </div>
                                </div>
                                <input type="button" name="previous" class="previous action-button-previous" value="Previous" (change)="Validateone()" />
                                <input type="button" name="next" class="next action-button" value="Next Step" (click)="RegisterUser()" />
                            </fieldset>





                            <fieldset>
                                <div class="form-card">
                                    <h2 class="fs-title text-center">Success !</h2> <br><br>
                                    <div class="row justify-content-center">
                                        <div class="col-3"> <img src="https://img.icons8.com/color/96/000000/ok--v2.png" class="fit-image"> </div>
                                    </div> <br><br>
                                    <div class="row justify-content-center">
                                        <div class="col-7 text-center">
                                            <h5>You Have Successfully Set Up Your Institution!</h5>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-7 text-center">
                                            <button type="button" class="btn btn-primary" [routerLink]="['/report']"> Dashboard </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="displayalert">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <i class="material-icons" style="color: white">close</i>
        </button>
            <span style="color: white;margin-top: 6px;">
          <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>