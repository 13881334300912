import { Component, OnInit } from '@angular/core';
import { optionVM } from 'app/shared/VIewmodels/optionvm';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { OptionService } from '../services/option.service';
import { QuestionService } from '../services/question.service';
import { ChapterService } from '../services/chapter.service';
import { CourseService } from '../services/course.service';

@Component({
  selector: 'app-addoptions',
  templateUrl: './addoptions.component.html',
  styleUrls: ['./addoptions.component.scss']
})
export class AddoptionsComponent implements OnInit {
  courseId: any;
  CourseId: any;
  Courses: any;
  Question: any;
  ChapterId: any;
  Questions: any;
  ID: any;
  QueId: any;
  Options: any;
  Option: any;
  constructor(private Courseservice: CourseService, private ChapterService: ChapterService,private questionservice:QuestionService,private optionser :OptionService,private router:Router) { }
  userChapter: optionVM;
  showSave: boolean;
  showUpdate: boolean;
  cityName: string;
  cityDesc: string;
  Cities: any;
  cityid: number;
  Chapterid: number;
  ChapterName: string;
  ChapterDesc: string;
  bid: number;
  Chapters: any;
  p: number = 1
  public dismissalert = false;
  notify: string
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
    this.GetCourses();
   // this.GetChapters();
   // this.getQuestions();
   this.getoptions();
   
  }

  getQuestions(id)
  {
    this.questionservice.getcoursequestions(id).subscribe((response: any) => {
      this.Questions = response;
      

    });
  }
  GetCourses() {
    this.Courseservice.getAllCourse().subscribe((response: any) => {
      this.Courses = response;
      

    });
  }
  GetChapters() {
    
    this.ChapterService.getAllChapteres().subscribe((response: any) => {
      this.Chapters = response;
      

    });
  }

  getoptions()
  {
    this.optionser.getAlloptions().subscribe((response: any) => {
      this.Options = response;
      

    });
  }

  addClick() {
    this.showSave = true;
    this.showUpdate = false;
    this.CourseId =null;
    this.Question ="";
    this.ChapterName = "";
    this.Chapterid = null;
    this.Option ="";
    this.QueId =null;
  }
  //edit Chapter
  editChapter(data: any) {
    this.showSave = false;
    this.showUpdate = true;
    this.ChapterId = data.ChapterId;
    this.ID = data.ID;
  //  this.ChapterName = data.Chapter;
   this.QueId = data.Question;
   this.CourseId = data.CourseId;
   this.GetChapters1(Number(this.CourseId));
   this.Option = data.option;
  }
  onSelectCity(data: any) {
    this.GetChapters1(Number(this.CourseId));

    this.CourseId = data
  }


  GetChapters1(id) {
    
    this.ChapterService.getcourseChapteres(id).subscribe((response: any) => {
      this.Chapters = response;
      

    });
  }

  SaveChapter(form: NgForm) {
    const inputRequest: optionVM = {
      ID: this.Chapterid,
     QuestionID :  this.QueId,
      Chapter: this.ChapterId,
     Course :  this.CourseId,
     option :form.controls["Option"].value,
  
    }
    

    this.optionser.saveoption(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Chapter Saved Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getoptions();
          
        }else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getoptions();
          
        }
      }
    );
  }
  UpdateChapter(form: NgForm) {
    
    const inputRequest1: optionVM = {
      ID: this.ID,
     QuestionID :  this.QueId,
      Chapter: this.ChapterId,
     Course :  this.CourseId,
     option :form.controls["Option"].value,
  
    }
    
    this.optionser.UpdateoptionRow(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          this.notify = "Questions Updated Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getoptions();
          
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getoptions();
          

        }
      }
    );
  }
  deleteChapter(data: any) {
    this.bid = data.ID;
  }
  deleteChapterRow() {
    
    this.optionser.deleteoptiondata(this.bid).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Questions Deleted Succesfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getoptions();
          

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.getoptions();
          
        }
      });
  }
  onSelectChapter(data)
  {
    this.ChapterId = data;
    this.getQuestions(this.ChapterId);
  }
  onSelectQue(data)
  {
    this.QueId = data;
    
  }
}

