
<br><br><br><br><br> 
<div class="col-md-12 row"  *ngIf="!mcqquiz">
    <div class="col-md-2"></div>
    <div class="col-md-8">
<h3 style="text-align: left;"><b>General information:</b></h3>
<p style="text-align: left;">1. The examination will comprise of Objective type Multiple Choice Questions (MCQs)</p>
<p style="text-align: left;">2. All questions are compulsory and each carries One mark.</p>
<p style="text-align: left;">3. The Time Duration is Given will Be <span>{{duration}}</span> Minutes.</p>
<p style="text-align: left;">4. There will be NO NEGATIVE MARKING for the wrong answers.</p>
<p style="text-align: left;"></p>
    
    
    
    
<h3 style="text-align: left;"><b>Information & Instructions:</b></h3>
<p style="text-align: left;">1. Every student will take the examination on a Laptop/Desktop/Smart Phone.</p>
<p style="text-align: left;">2. The students just need to click on the Right Choice / Correct option from the
    multiple choices /options given with each question. For Multiple Choice Questions,
    each question has four options, and the candidate has to click the appropriate
    option.</p>
<p  style="text-align: left;">3. If you try to change the tab the test will be automatically submitted and it will be recorded.</p></div>
</div>
<!-- <p style="text-align: center;"></p>
<p style="text-align: center;"></p>
<p style="text-align: center;"></p> -->
   
    <!-- 1. The examination does not require using any paper, pen, pencil and calculator.
    
   
    4. Each student will get questions and answers in different order selected randomly
    from a fixed Questions Databank.
    .</p>
<p> </p> -->
<!-- <button class="btn btn-primary btn-lg"   >Start Recording</button> -->

<div class="row1">
    <div class="col-xs-12 text-right">
        <div style="text-align:center">
        
            <video class="video"  #video autoplay controls ></video>
    <span class="m-1" controls #recordedVideo></span>
            <br>
            <button class="btn btn-primary btn-lg" id='videosrecord'  (click)="startRecording1()"  >Start the Test</button>
            <!-- <button class="btn btn-warning btn-lg"   (click)="stopRecording1()">Stop Recording</button>  -->
          <!-- </div> -->
        <!-- <video #myvideo class="video"></video>   *ngIf="!isRecording"   -->
    </div>
</div>
<div *ngIf="mcqquiz">

    <div id="quiz">

        <h2 class="text-center font-weight-normal">{{quiz.name}}</h2>
        <hr />

        <div *ngIf="mode=='quiz' && quiz">
            <div *ngFor="let question of filteredQuestions;">
                <div class="badge badge-info">Question {{pager.index + 1}} of {{pager.count}}.</div>
                <div *ngIf="config.duration" class="badge badge-info float-right">Time: {{ellapsedTime}} / {{duration}}</div>
                <h3 class="font-weight-normal">{{pager.index + 1}}.
                    <span [innerHTML]="question.name"></span>
                </h3>
                <div class="row text-left options">
                    <div class="col-6" *ngFor="let option of question.options">
                        <div class="option">
                            <label class="font-weight-normal" [attr.for]="option.id">
                <input id="{{option.id}}" type="checkbox" [(ngModel)]="option.selected" (change)="onSelect(question, option);" /> {{option.name}}
              </label>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="text-center">
                <button class="btn btn-default" *ngIf="config.allowBack" (click)="goTo(0);">First</button>
                <button class="btn btn-default" *ngIf="config.allowBack" (click)="goTo(pager.index - 1);">Prev</button>
                <button class="btn btn-primary" (click)="goTo(pager.index + 1);">Next</button>
                <button class="btn btn-default" *ngIf="config.allowBack" (click)="goTo(pager.count - 1);">Last</button>
                <!--<pagination *ngIf="config.showPager" direction-links="false" total-items="totalItems" items-per-page="itemsPerPage" ng-model="currentPage" ng-change="pageChanged()"></pagination>-->
            </div>
        </div>

        <div class="row text-center" *ngIf="mode=='review'">
            <div class="col-4 cursor-pointer" *ngFor="let question of quiz.questions; let index = index;">
                <div (click)="goTo(index)" class="p-3 mb-2 {{ isAnswered(question) == 'Answered'? 'bg-info': 'bg-warning' }}">{{index + 1}}. {{ isAnswered(question) }}</div>
            </div>
        </div>
        <div class="result" *ngIf="mode=='result'">
            <br><br><br>
            <div class="col-md-12 text-center" *ngIf="codepresent"> <button type="submit" class="btn btn-primary" (click)="gotocode()">Coding Round</button> </div>
            <div class="col-md-12 text-center" *ngIf="!codepresent"> Thank you for taking part in this contest! you will be contacted if shortlisted! <br>All the best. <button type="submit" class="btn btn-primary" [routerLink]="['/']">Go to Home</button> </div>

        </div>
        <hr />
        <div *ngIf="mode!='result'">
            <button class="btn btn-warning" (click)="mode = 'quiz'">Quiz</button>
            <button class="btn btn-info" (click)="mode = 'review'">Review</button>
            <button class="btn btn-primary" id="submitquiz" (click)="onSubmit();">Submit Quiz</button>
        </div>
    </div>


</div>
<button class="btn btn-primary" id="fetch" style="visibility:hidden;" (click)="onSubmit()">submit</button>
<div *ngIf="pythoncoding">
    <br><br><br><br>

    <div class="col-md-12" style="font-size: 16px;">Question : {{question.question}}</div>

    <br><br><br> *Note: Please write your code only using Python 3.(Single submission) <br> **Note: Please take the inputs using sys.argv itself. <br> Ex: inputa = sys.argv[1:] <br> Thank you!
    <ngx-codemirror [(ngModel)]="content" [options]="{
    lineNumbers: true,
    theme: 'material',
    mode: 'python'
  }"></ngx-codemirror>
    <br>
    <button class="btn btn-primary" (click)="fetch()">submit</button>
</div>