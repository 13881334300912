<!-- <br />

<div class="whole-page">
  <br /><br /><br /><br /><br />
  <div
    class="col-md-12 text-center"
    style="font-weight: 500; font-size: 20px;"
    *ngIf="jonlist == undefined || jonlist == null || jonlist == ''"
    style="font-weight: 700; font-size: 22px; color: whitesmoke;"
  >
    No Internships have been posted yet!!!
  </div>
  <div
    class="col-md-12 text-center"
    style="font-weight: 500; font-size: 20px;"
    *ngIf="jonlist.length > 0"
    style="font-weight: 700; font-size: 22px; color: whitesmoke;"
  >
    Company Details
  </div>
  <br />
  <div *ngFor="let job of jonlist">
    <button class="collapsible">
      <span>{{ job.companyName }}</span>
    </button>
    <div class="whole-content">
      <div class="content">
        Posted By : <b>{{ job.jobposterName }} </b> <br />
        Job Description : <b>{{ job.jobDescription }}</b> <br />
        Internship Category : <b>{{ job.jobCategory }} </b>

        <br />
        Duration :<b> {{ job.minimumExp }} </b>
        <br />
        Fees :<b> {{ job.maximumExp }}</b> <br />
        Key Skills : <b>{{ job.keySkills }}</b> <br />
        <!-- Pay Scale : <b>{{ job.payScale }}</b> <br /> -->
<!-- Internship Location : <b>{{ job.jobLocation }}</b> <br />
        Internship Poster Email : <b>{{ job.jobposterEmail }}</b>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="exampleModal1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          Are you sure to share your details with
          <span *ngIf="CompanyName">{{ CompanyName.companyName }}</span> ?
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          id="cancelUpload"
          class="btn btn-secondary"
          data-dismiss="modal"
          style="cursor: pointer;"
        >
          Cancel
        </button>
        <button type="submit" class="btn btn-primary" (click)="shareDetails()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div> -->

<br />

<div class="whole-page">
    <br /><br /><br /><br />
    <div class="col-md-12 text-center" style="font-weight: 700; font-size: 22px; color: whitesmoke;" *ngIf="jonlist == undefined || jonlist == null || jonlist == ''">
        No Internships have been posted yet!!!
    </div>
    <div class="col-md-12 text-center" *ngIf="jonlist.length > 0" style="font-weight: 700; font-size: 22px; color: whitesmoke;">
        Company Details
    </div>
    <main>
        <section class="container register-form">
            <div style="display: flex;" class="col-md-12 row">
                <div class="complete-form col-md-12" *ngFor="let job of jonlist">
                    <div class="form">
                        <div class="row text-center justify-content-center">
                            <div class="col-lg-12">
                                <div class="col-lg-12">
                                    <br />
                                    <div class="note">
                                        <p style="font-size: x-large;">{{ job.companyName }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-content">
                            <div class="container">
                                <div class="row justify-content-center mt--200"></div>
                                <form>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label for="fname" style="
                          font-size: 15px;
                          color: #8a061c;
                          word-break: break-all;
                        ">About Internship :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="
                          font-size: 15px;
                          color: #8a061c;
                          word-break: break-all;
                        "
                        >{{ job.jobDescription }}</label
                      >
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >About Company:</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="
                          font-size: 15px;
                          color: #8a061c;
                          word-break: break-all;
                        "
                      >
                        {{ job.jobCategory }}
                      </label>
                                        </div>

                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label for="fname" style="font-size: 15px; color: #8a061c;">Skills Required :</label
                      >
                    </div>
                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.keySkils }}
                      </label>
                                        </div>

                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label for="fname" style="font-size: 15px; color: #8a061c;">Duration :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.minimumExp }}</label
                      >
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Fee:</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.maximumExp }}</label
                      >
                    </div>

                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Location :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.jobLocation }}</label
                      >
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Posted By :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.jobposterName }}</label
                      >
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                      >
                        Email :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.jobposterEmail }}</label
                      >
                    </div>

                    <!-- ------------------------------- -->
                    <div class="col-md-12">&nbsp;</div>

                    <div
                      class="modal fade"
                      id="exampleModal1"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Confirmation
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="container">
                              Are you sure to share your details with
                              <span *ngIf="CompanyName">{{
                                CompanyName.companyName
                              }}</span>
                              ?
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              id="cancelUpload"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                              style="cursor: pointer;"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              class="btn btn-primary"
                              (click)="shareDetails()"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1">&nbsp;</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>

<div
  class="modal fade"
  id="exampleModal1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          Are you sure to share your details with
          <span *ngIf="CompanyName">{{ CompanyName.companyName }}</span> ?
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          id="cancelUpload"
          class="btn btn-secondary"
          data-dismiss="modal"
          style="cursor: pointer;"
        >
          Cancel
        </button>
        <button type="submit" class="btn btn-primary" (click)="shareDetails()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>