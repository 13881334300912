import { Component, OnInit, ViewChild } from '@angular/core';
import Peer from 'peerjs';
import { TranslationWidth, ɵPLATFORM_SERVER_ID } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
// import { PeerConnectOption} from 'peerjs';
import { CodeService } from 'app/newcomponents/mcq/services/code.service';
import * as $ from 'jquery';
// declare var Peer:any;
@Component({
  selector: 'app-hostjoin',
  templateUrl: './hostjoin.component.html',
  styleUrls: ['./hostjoin.component.scss']
})
export class HostjoinComponent implements OnInit {
  @ViewChild('myvideo') myvideo: any;
  userid;
  // peer:any;
  anotherid;
  mypeerid;
  refid;
  hostid;
  qid;
  private peer: Peer;
  peerIdShare: string;
  peerId: string;
  private lazyStream: any;
  currentPeer: any;
  isSafari: any;
  private peerList: Array<any> = [];
  constructor(private route: ActivatedRoute, private sevice: CodeService, private router: Router) {
    this.userid = localStorage.userId;
    this.peer = new Peer();
    window.scrollTo(0, 0);
    if (localStorage.userId == null || localStorage.userId == undefined) {
      this.router.navigate(['/newsignin']);
      return;
    }
  }
  a: any;
  ngOnInit(): void {

    this.getPeerId();
    this.isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
       navigator.userAgent &&
       navigator.userAgent.indexOf('CriOS') == -1 &&
       navigator.userAgent.indexOf('FxiOS') == -1;
    // setInterval(()=>{
    // this.a= document.getElementById('videos');
    //   console.log(typeof(this.a.childNodes[0]));
    //   // parent.appendChild(a[0]);
    //   let htmlStyles = window.getComputedStyle(document.documentElement,null);
    //   let rowNum = parseInt(htmlStyles.getPropertyValue("--rowNum"));
    //   let colNum = parseInt(htmlStyles.getPropertyValue("--colNum"));
    //   let gridItemsCount = document.querySelectorAll('.grid-item').length;
    //   document.documentElement.style.setProperty(`--rowNum`, Math.ceil(gridItemsCount/colNum).toString());
    // },10000)
    var remove = document.getElementById("footerremove");
    if (remove) {
      remove.style.display = "none";
    }
    let parent;
    let myonlyvideo;
    setTimeout(() => {
      parent = document.getElementById("videos");
      myonlyvideo = this.onlymyvideo.nativeElement;

    }, 5000);
    var now = new Date;
    // this.peer = new Peer();
    // this.peer = new Peer({
    //   secure: true,
    //   host: 'peerjsapi.blockchainmatrimony.com',
    //   port: 443,
    //   path: "/",
    //   config: {
    //     'iceServers': [
    //       { url: 'stun:stun.l.google.com:19302' }
    //     ]
    //   }
    // });
    // let peerid;
    // this.peer.on('open', function (id) {
    //   peerid = id
    //   console.log('My peer ID is: ' + id);
    // });
    setTimeout(() => {
      this.mypeerid = this.peerId;
      if (this.mypeerid == null || this.mypeerid == undefined) {
        console.log("here");

      }
      else {
        this.sevice.storePeerHost(this.userid, this.qid, this.mypeerid).subscribe((data: any) => {
          if (data == 0 || data == 1) {
            alert("You don't have any meetings scheduled today");
          }
          else {

          }
          console.log(data);
        });
      }
    }, 3000);

    this.peer.on('connection', function (conn) {
      conn.on('data', function (data) {
        // Will print 'hi!'
        console.log(data);
      });
    });

    var n = <any>navigator;

    n.getUserMedia = (n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia);

    // this.peer.on('call', function (call) {
    //   this.userid = this.userid + 1;
    //   let divclass = document.createElement("div");
    //   divclass.setAttribute("class", "grid-item");
    //   let video = document.createElement("video");
    //   video.setAttribute("style", "width:100%;height:100%");
    //   divclass.append(video);
    //   parent.appendChild(divclass);
    //   let htmlStyles = window.getComputedStyle(document.documentElement, null);
    //   let rowNum = parseInt(htmlStyles.getPropertyValue("--rowNum"));
    //   let colNum = parseInt(htmlStyles.getPropertyValue("--colNum"));
    //   let gridItemsCount = document.querySelectorAll('.grid-item').length;
    //   document.documentElement.style.setProperty(`--rowNum`, Math.ceil(gridItemsCount / colNum).toString());
    //   n.webkitGetUserMedia({ video: true, audio: true }, function (stream) {
    //     myonlyvideo.srcObject = stream;
    //     myonlyvideo.play();
    //     myonlyvideo.muted = true;

    //     console.log(stream);
    //     call.answer(stream);
    //     call.on('stream', function (remotestream) {

    //       console.log("adding stream");

    //       video.srcObject = remotestream;
    //       video.play();
    //       console.log(remotestream)
    //       //document.getElementById("videos").appendChild(document.createElement("video").srcObject=remotestream) ;


    //     })
    //   }, function (err) {
    //     console.log('Failed to get stream other', err);
    //   })
    // })
  }
  addstream() {
    console.log("adding stream");
    document.getElementById("videos").appendChild(document.createElement("video").srcObject = localStorage.userid);


  }
  private getPeerId = () => {
    this.peer.on('open', (id) => {
      this.peerId = id;
    });

    this.peer.on('call', (call) => {
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      }).then((stream) => {
        this.lazyStream = stream;
        console.log(call);
        if(!this.isSafari && call.metadata.isSafari || !this.isSafari && !call.metadata.isSafari ){
          call.answer(stream);
          call.on('stream', (remoteStream) => {
            if (!this.peerList.includes(call.peer)) {
              this.streamRemoteVideo(remoteStream);
              this.currentPeer = call.peerConnection;
              this.peerList.push(call.peer);
            }
          });
        }
        else{
          this.connectWithPeer(call.peer);
        }
      
      }).catch(err => {
        console.log(err + 'Unable to get media');
      });
    });
  }

  connectWithPeer(id?): void {
    if(id){
      this.callPeer(id)
    }
    else{
      this.callPeer(this.peerIdShare);

    }
  }

  private callPeer(id: string): void {
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then((stream) => {
      this.lazyStream = stream;

      const call = this.peer.call(id, stream, {metadata: {isSafari: this.isSafari}});
      call.on('stream', (remoteStream) => {
        if (!this.peerList.includes(call.peer)) {
          this.streamRemoteVideo(remoteStream);
          this.currentPeer = call.peerConnection;
          this.peerList.push(call.peer);
        }
      });
    }).catch(err => {
      console.log(err + 'Unable to connect');
    });
  }
  private streamRemoteVideo(stream: any): void {
    const video = document.createElement('video');
    video.setAttribute('playsinline','');
    video.classList.add('video');
    video.srcObject = stream;
    video.play();

    document.getElementById('remote-video').append(video);
  }
  @ViewChild('onlymyvideo') onlymyvideo: any;

  connect() {
    var conn = this.peer.connect(this.anotherid);
    // conn.on('open', function () {
    //   conn.send('Message from that id');
    // });
  }

  // videoconnect(){
  //   let video = this.myvideo.nativeElement;
  //   var localvar = this.peer;
  //   var fname = this.anotherid;

  //   // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

  //   var n = <any>navigator;

  //    n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );

  //    n.getUserMedia({video: true, audio: true}, function(stream) {
  //     var call = localvar.call(fname, stream);
  //     call.on('stream', function(remotestream) {
  //       video.srcObject = remotestream;
  //       video.play();
  //     })
  //   }, function(err){
  //     console.log('Failed to get stream', err);
  //   })


  // }
  del() {
    $('.grid-item:last-child').parentNode.removeChild($('.grid-item:last-child'));
    //updateVar();
  }
}

