import { Component, OnInit } from '@angular/core';
import { tst } from 'app/shared/VIewmodels/test';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OtpViewModel } from 'app/shared/VIewmodels/OtpViewModel';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/shared/UserService/user.service';
import { MessageService } from 'app/shared/MessageService';

import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Signup } from 'app/shared/VIewmodels/Signup';
var authorizationData;
@Component({
  selector: 'app-newbothsignin',
  templateUrl: './newbothsignin.component.html',
  styleUrls: ['./newbothsignin.component.scss']
})


export class NewbothsigninComponent implements OnInit {
  email:any;
  password:any;
  isLoginError:any;
  rdurl:any;
  constructor(private userservice:UserService,private authService:AuthService,private route:ActivatedRoute, private messageService:MessageService,private userService:UserService, private http:HttpClient, private registrationService:RegistrationService,private router:Router,private spinner:NgxSpinnerService) {
    window.scrollTo(0,0);
 

    this.route.params.subscribe(params => {
      if (params["id"]) {
        let id = params["id"];
        let spv = id.split('-');
        if(spv.length>2){
        localStorage.setItem("invinsuid",spv[0]);
        this.rdurl = spv[1];
        var uid = localStorage.getItem("uid");
        if(uid!=null && uid == spv[2])
        {
          if(this.rdurl=="I")
       {
      //   this.router.navigate(['/jobregforins']);
      this.router.navigate(['/internshippost']);
       }
       else if(this.rdurl=="J")
       {
         this.router.navigate(['/jobregforins']);
       }
       else
       {
        this.router.navigate(['/trainingpost']);
       }
      }
      
        }
        else{
          var useridd=spv[1];
          this.getup(useridd);
        }

      }
    });
  }
getup(id){
  const usid:Signup={
    Id:null,
    Branch:null,
    BranchName:null,
    ConfirmPassword:null,
    Email:null,
    FirstName:null,
    Institution:null,
    LastName:null,
    Password:null,
    PhoneNumber:null,
    PlanId:id,
    Question:null,
    SubRoleForApplicant:null,
    USN:null,UserName:null
  }
  this.userService.getUserpassword(usid).subscribe((data:any)=>{
    this.email=data.email;
    this.password=data.confirmPassword;
    this.OnSubmit();
  })
}
  ngOnInit(): void { 
    var adata= localStorage.getItem('authdata');
  authorizationData = JSON.parse(adata);


  let url = window.location.href;
  if(url.includes('?'))
  {
   let spd= url.split('?');
   let acstsp = spd[1].split('=');
    let accesstoken = acstsp[1];
    
    let acst = accesstoken.split('&')[0];

   // let authURL ="https://www.linkedin.com/oauth/v2/accessToken?client_id=81fsghpn98dxwx&client_secret=ECnoIag5FdZ9hE0e&grant_type=authorization_code&code="+acst+"&redirect_uri=https://jobs-zone.com/loginf";
  
   let authURL ="https://blockchainmatrimony.com/jobzoneapi/api/Test/postacct1";
  const abc:tst={
val :acst
  }
  

     this.http.post(authURL,abc).subscribe((res:any)=> {
      if(res!="1")
      {

        var regtype =  localStorage.getItem("regtype");
        const inputRequest: OtpViewModel = {
          Id: 0,
          Email: res.email,
          Otp: "1",
          FirstName: res.fname,
          LastName: regtype,
          UserName: null,
          PhoneNumber: null,
          Password: null,
          ConfirmPassword: null,
          ProvinceId :null ,
          PlanId: null,
          AccessToken:res.actk
        }
        this.registrationService.LoginVerification(inputRequest).subscribe(
          (data: any) => {
            if(data!="1"){
              alert("Login Succesfull");
              localStorage.removeItem("regtype");
              this.spinner.hide();         
              localStorage.setItem("CusId", data.accountNo)
              if(data.userRole == 2){
                this.router.navigate(['/companyRegistration']);
              }
              else{
                this.router.navigate(['/newprofile']);
              }
              }
              else{
                alert("Something went wrong! Please try again");
                
              }
          });





       // this.router.navigate(['/login']);
      }
      else
      {
        this.router.navigate(['/newhome']);
      }
     });
  }
  }



role:any;

  OnSubmit(){
    localStorage.setItem('loadingpage',"true");
   // this.loading = true;
   if(this.email == null || this.email == ""){
     alert("Please enter Your Email");
   }
   else if(this.password == null || this.password == ""){
    alert("Please enter Your password");
  }
  else if(this.email == "admin" && this.password == "Srichid@123"){
    localStorage.loadingpage = "true";
    localStorage.admin = "admin";
    this.router.navigate(['/newhome']);
  }
  else{
    this.spinner.show();
  
    this.userService.userAuthentication(this.email,this.password).subscribe((data : any)=>
    {
    var firstName = data.userName;
      var movies = [ data.access_token, this.email, data.roleName, data.refresh_token,true,data.userUid, 
                     firstName[0],data.username, data.nameofRole, data.departmentname,data.userId,data.uid,data.area,data.IsMember ];
  console.log(data)
     localStorage.setItem('IsLoggedIn',"true");
     localStorage.setItem('userToken',data.access_token);
     localStorage.setItem('authorizationData',data);
     localStorage.setItem('Token_type',data.token_type);
     localStorage.setItem("UserName",data.fullName);
     //localStorage.setItem("this.password",password);
     localStorage.setItem("role",data.roleName);
     localStorage.setItem("userId",data.userId);
     localStorage.setItem("uid",data.uid);
     localStorage.setItem("MailId",data.mailId);
     //localStorage.setItem("area",data.area);
     //localStorage.setItem("isMember",data.IsMember);
     //localStorage.setItem("gst",data.gst);
     localStorage.setItem('authdata',JSON.stringify(movies) );
  localStorage.storage = data.storage;
  
      // send message to subscribers via observable subject
      this.messageService.sendMessage('true');
      this.messageService.sendName(data.userName);
     if(data.roleName == "2" ){
        localStorage.company = "company";
      }
      else if (data.roleName == "3") {
        localStorage.seeker = "seeker";
      }
      //var landingUrl = "http://" + window.location.host + "/home";
      // var landingUrl = "http://" + window.location.host + "/newhome";
      // window.location.href = landingUrl;
      if(this.rdurl != null)
      {
       if(this.rdurl=="I")
       {
      //   this.router.navigate(['/jobregforins']);
      this.router.navigate(['/internshippost']);
       }
       else if(this.rdurl=="J")
       {
         this.router.navigate(['/jobregforins']);
       }
       else
       {
        this.router.navigate(['/trainingpost']);
       }
      }
      
      else
      {
      
   
   
      this.role = data.roleName;
      this.userService.afterlogin(data.uid).subscribe((response:any)=>{
        if(response == "registration"){
          this.router.navigate(['/companyRegistration']);
        }
        else if(response == "payu"){
          var uidc = localStorage.getItem("uid");

          this.userService.getcompanyprofile(uidc).subscribe((data:any)=>{
            console.log(data);
            var uid=localStorage.getItem("userId");

            this.router.navigate(['/payuplans/'+uid+","+data[0].numberofEmployees]);
            
          })
          
        }
        else if(response == "newhome"){
          this.router.navigate(['/newhome']);
        }
        else if(response == "newprofile"){
          this.router.navigate(['/newprofile']);
        }
        else if(response == "profile")
        {
          this.router.navigate(["/appprofile"]);
        }
        else if(response == "dashboard"){
          this.router.navigate(["/report"]);

        }
        else{
          this.router.navigate(["/eregistration"]);
        }
      })
      //  if(this.role==2)
      //  {
      //    //var landingUrl = "http://" + window.location.host + "/home";
      //    // var landingUrl = "http://" + window.location.host + "/newhome";
      //    // window.location.href = landingUrl;

      //    this.router.navigate(['/newhome']);
      //    //this.getmenu(this.role);
      //  }else if(this.role==3){
      //    // var landingUrl = "http://" + window.location.host + "/newhome";
      //    // //var landingUrl = "http://" + window.location.host + "/home";
      //    // window.location.href = landingUrl;
      //    this.router.navigate(['/appprofile']);
      //  }
      //  else{
      //    this.router.navigate(['/report']);
      //  }
     }
      this.spinner.hide();
  
      // location.reload();
   }, 
   (err : HttpErrorResponse)=>{
    this.isLoginError = true;
    alert("Invalid credentials");
    this.spinner.hide();
  
   }
   
  )
  
  }
  
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialusers => {  
      console.log(socialusers);  
      let name=socialusers.name.split(" ");
      let fname=name[0];
      let lname=name[1];
      let email=socialusers.email;
      let actk=socialusers.authToken;
      this.savefromsocio(fname,lname,email,actk);
      
      //this.router.navigate(['/home']);  

      //this.Savesresponse(socialusers);  
    });  
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialusers => {  
      console.log(socialusers);  
      let name=socialusers.name.split(" ");
      let fname=name[0];
      let lname=name[1];
      let email=socialusers.email;
      let actk=socialusers.authToken;
      this.savefromsocio(fname,lname,email,actk);
      
      //this.router.navigate(['/home']);  

      //this.Savesresponse(socialusers);  
    });  ;
  } 
  savefromsocio(fname,lname,email,actk)
  {
    var regtype = localStorage.getItem("regtype");    
    const inputRequest: OtpViewModel = {
      Id: 0,
      Email: email,
      Otp: "1",
      FirstName: fname,
      LastName: lname,
      UserName: null,
      PhoneNumber: null,
      Password: null,
      ConfirmPassword: null,
      ProvinceId :null ,
      PlanId: null,
      AccessToken:actk
    }
    this.registrationService.LoginVerification(inputRequest).subscribe(
      (data: any) => {
        // if(data!="1"){
        // alert("Registered Succesfully");
        // localStorage.removeItem("regtype");
        // this.spinner.hide();         
        // localStorage.setItem("CusId", data.accountNo)
        // localStorage.iddd= data.userId;
        // if(data.userRole == 2){
        //   this.router.navigate(['/companyRegistration']);
        // }
        // else{
        //   this.router.navigate(['/jobreg']);
        // }
        // }
        if (data!="1") {
          localStorage.setItem('IsLoggedIn',"true");
                  localStorage.setItem('userToken',data.AccessToken);
                  localStorage.setItem("UserName",data.userName);
                  localStorage.storage = data.storage;
  
                  localStorage.setItem("role",data.role);
                  localStorage.setItem("userId",data.userId);
                  localStorage.setItem("area",data.area);
                  localStorage.setItem("isMember",data.IsMember);
                  localStorage.setItem("GUID",data.guid);
                  this.messageService.sendMessage('true');
                  this.messageService.sendName(data.userName);

          alert("Login Succesfull");
          localStorage.loadingpage = "true";
          
          this.router.navigate(['/newhome']);
        }
        else{
          alert("Something went wrong! Please try again");
          location.reload();
        }
      
      });
  }

  signInWithLI(): void {
    window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81fsghpn98dxwx&redirect_uri=https://blockchainsafe.in/newlogin&state=gfvtfvgfv&scope=r_liteprofile%20r_emailaddress%20w_member_social","_self");
    }
  
 }


