import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { SkillMaster } from 'app/shared/VIewmodels/SkillMaster';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {
plantype: string;
  amount: string;
  planlist: any;
  palnid: any;
  plaid: any;
  Id: any;
  Skill: any;
  constructor(private masterservices: MasterService, private router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('save').style.display = 'block';
      document.getElementById('update').style.display = 'none';
    }, 500);
    this.PlansList();
    this.SkillName = '';
  }
  ResetData() {
    this.SkillName = '';
  }
  SkillName: any;
  PlansList() {
    this.masterservices.getSkill().subscribe((data: any) => {
      this.Skill = data;
      console.log(this.Skill);
    });
  }

  SavePlanType(form: NgForm) {
    const inputRequest: SkillMaster = {
      Id: 0,
      skill: this.SkillName,
    };
    this.masterservices.postSkill(inputRequest).subscribe((data: any) => {
      if (data == null) {
        alert('Added Successfully');
        this.router.navigate(['/skill']);
        window.location.reload();
      } else {
        alert('Something went Wrong');
        this.router.navigate(['/skill']);
        window.location.reload();
      }
    });
  }
  instiid: any;
  editplantype(data: any) {
    document.getElementById('save').style.display = 'none';
    document.getElementById('update').style.display = 'block';
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.instiid = data.id;
    this.SkillName = data.skill;
  }

  UpdatePlanType(form: NgForm) {
    document.getElementById('save').style.display = 'block';
    document.getElementById('update').style.display = 'none';
    console.log(this.SkillName);
    const inputRequest1: SkillMaster = {
      Id: this.instiid,
      skill: this.SkillName,
    };
    this.masterservices.UpdateSkill(inputRequest1).subscribe((data: any) => {
      if (data == 1) {
        alert('Skill  Updated Succesfully');
        this.PlansList();
        window.location.reload();
      } else {
        alert('Something Went Wrong. Try again !');
        window.location.reload();
      }
    });
  }
  deleteplantype(data: { id: any }) {
    this.plaid = data.id;
    this.masterservices.deleteSkill(this.plaid).subscribe((data: any) => {
      if (data != null) {
        alert('Skill Deleted Succesfully');
        this.PlansList();
        window.location.reload();
      } else {
        alert('Something Went Wrong. Try again !');
        window.location.reload();
      }
    });
  }
}