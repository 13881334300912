import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/shared/UserService/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { ChangePassword } from 'app/shared/VIewmodels/ChangePassword';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { CompanyRegistrationVM } from 'app/shared/VIewmodels/CompanyRegistrationVM';

@Component({
  selector: 'app-companyprofile',
  templateUrl: './companyprofile.component.html',
  styleUrls: ['./companyprofile.component.scss']
})
export class CompanyprofileComponent implements OnInit {
  profile: any;
  isLoggedIn: boolean;
  uid: string;
  accid: string;
  closeResult: string;
  m: string;
edit=false;

  userImage:any;
  p1: any;
  p2: any;
  displayUID: string;
  constructor(private userservice: UserService,private router : Router,private modalService: NgbModal
    ,private spinner:NgxSpinnerService,private masterService:MasterService,private registrationService:RegistrationService) {
      this.spinner.show();
    this.displayUID = localStorage.getItem("userId");
    window.scrollTo(0,0);
    this.userid =  localStorage.getItem("userId");

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.accid = localStorage.getItem("uid");
    }
  }
industry:any;
userid = null;
  ngOnInit() {
    this.GetProfileDetails();
   
  }
  Industry:any;
  cname:any;
  estyear:any;
  email:any;
  numofemp:any;
  numofbra:any;
  regId:any;
  gst:any;
  pan:any;
  headquarters:any;
  Website:any;
  contpersonname:any;
  contactperemail:any;
  contactpersonphone:any;
  alone:any;
  altwo:any;
  althree:any;
  landmark:any;
  pincode:any;
  city:any;
  state:any;
  Country:any;
  contalone:any;
  conaltwo:any;
  conalthree:any;
  contlandmark:any;
  contpincode:any;
  contcity:any;
  contCountry:any;
  constate:any;

  editchange(form:NgForm)
  {
    this.edit = true;
    this.cname = this.alldata.companyName;
    this.estyear = this.alldata.established;
    this.numofemp=this.alldata.numberofEmployees
    this.numofbra=this.alldata.numberofBranches
    this.regId=this.alldata.registrationId
    this.gst=this.alldata.gst
    this.pan=this.alldata.pan
    this.headquarters=this.alldata.headquarters
    this.Website=this.alldata.webSite
    this.email=this.alldata.officialEmail
    this.contpersonname=this.alldata.contactPersonName
    this.contactperemail=this.alldata.contactPersonEmail
    this.contactpersonphone=this.alldata.contactPersonPhone
    this.industry=this.alldata.industryType
    this.alone=this.alldata.regaddone
    this.altwo=this.alldata.regaddtwo
    this.althree=this.alldata.regaddthree
    this.landmark=this.alldata.regaddlanmark
    this.pincode=this.alldata.regaddpincode
    this.city=this.alldata.regaddcity
    this.state=this.alldata.regaddstate
    this.Country=this.alldata.regaddcountry
    this.contalone=this.alldata.contactaddone
    this.conaltwo=this.alldata.contactaddtwo
    this.conalthree=this.alldata.contactaddthree
    this.contlandmark=this.alldata.contactaddlanmark
    this.contpincode=this.alldata.contactaddpincode
    this.contcity=this.alldata.contactaddcity
    this.contCountry=this.alldata.contactaddcountry
    this.constate=this.alldata.contactaddstate
  }
  alldata:any;

  editchangeback(){
    this.edit=false;
  }
  GetProfileDetails() {
   this.userservice.getcompanyprofile(this.accid).subscribe((data:any)=>{
     this.alldata = data[0];
    
     this.masterService.getindustry().subscribe((data:any)=>{
      this.Industry = data;
      this.Industry.forEach(element => {
        if(element['id'] == this.alldata.industryType){
          this.alldata.industry = element['indrustType']
        }
      });
    })
    
     
     this.spinner.hide();
    })
  }

  changePassword() {
    this.router.navigate(['/changepswd']);
  }

  handleFileSelect(evt){
    var files = evt.target.files;
    var file = files[0];
  
  if (files && file) {
      var reader = new FileReader();

      reader.onload =this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
  }
}

_handleReaderLoaded(readerEvt) {
   var binaryString = readerEvt.target.result;
          this.userImage= btoa(binaryString);
          this.userservice.updateProfile(this.userImage).subscribe((data:any)=>{
            if(data=="1"){
              alert()
            }
          })
  }

  ViewDocument(bb, pdfcontent) {
      
    this.modalService.open(pdfcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = "yvtfcfu";
    });
 
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
ChangePassword(form:NgForm)
{

  this.p1=form.controls["passwordone"].value;
  this.p2=form.controls["passwordtwo"].value;
  if(this.p1 != this.p2){
      alert("Enter Password Correctly")
  }
  const inputRequest:ChangePassword={
    CustomerId:this.displayUID,
    Email:"",
    Password:form.controls["passwordone"].value,
    ConfirmPassword:form.controls["passwordtwo"].value,
  }
  this.userservice.postchangePasswordAfterLogin(inputRequest).subscribe(
    (data: any) => {
      if (data != null) {
        alert("Your Password Was Successfully Changed");
        this.router.navigate(['/userprofile']);
      }else{
        alert("Something Went Wrong. Try again.!!");
        window.location.reload();
      } 
    });
}
redeemdata(){
  document.getElementById("storage").style.display = "block";
}
redeemd(){
  this.userservice.redeemdata(this.displayUID).subscribe((data:any)=>{
    localStorage.storage = data;
    alert("Storage has been upgraded");
    location.reload();
  });
}
redeemcash(){
  document.getElementById("cashid").style.display = "block";
}
redeemcashok(){
  this.router.navigate(['/redeemcash']);
}

phonetwo:any;
serchfind:any;
contemail:any;
valcontemail:any;
regexp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
phone = new RegExp(/^[6-9]\d{9}$/);
OnSubmitCompanyRegistration(form: NgForm) {
  this.email = form.controls["email"].value;
  this.phonetwo = this.phone.test(form.controls["contactpersonphone"].value);
  this.serchfind = this.regexp.test(this.email);
  this.contemail = form.controls["contactperemail"].value;
  this.valcontemail = this.regexp.test(this.contemail);
  if (
    form.controls["cname"].value == "" ||
    form.controls["cname"].value == null
  ) {
    alert("Enter Company Name");
  } else if (
    form.controls["estyear"].value == null ||
    form.controls["estyear"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Established Year");
  } else if (
    form.controls["numofemp"].value == null ||
    form.controls["numofemp"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter No of Employees");
  } else if (
    form.controls["numofbra"].value == null ||
    form.controls["numofbra"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Branches");
  } else if (
    form.controls["headquarters"].value == null ||
    form.controls["headquarters"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Head Quarter");
  } else if (
    form.controls["regId"].value == null ||
    form.controls["regId"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Registration Id");
  } else if (
    form.controls["gst"].value == null ||
    form.controls["gst"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Enter GST");
  } else if (
    form.controls["alone"].value == null ||
    form.controls["alone"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Address");
  } else if (
    form.controls["altwo"].value == null ||
    form.controls["altwo"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Address Line Two");
  } else if (
    form.controls["city"].value == null ||
    form.controls["city"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter City");
  } else if (
    form.controls["state"].value == null ||
    form.controls["state"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter state");
  } else if (
    form.controls["pincode"].value == null ||
    form.controls["pincode"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Pincode");
  } else if (
    form.controls["Country"].value == null ||
    form.controls["Country"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Country");
  } else if (
    form.controls["Website"].value == null ||
    form.controls["Website"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Website URL");
  } else if (
    form.controls["email"].value == null ||
    form.controls["email"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Email");
  } else if (!this.serchfind) {
    this.spinner.hide();
    alert("Please Enter valid Email");
  } else if (
    form.controls["contpersonname"].value == null ||
    form.controls["contpersonname"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Contact Person Name");
  } else if (
    form.controls["contactperemail"].value == null ||
    form.controls["contactperemail"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Email");
  } else if (!this.valcontemail) {
    this.spinner.hide();
    alert("Please Enter valid Email");
  } else if (
    form.controls["contactpersonphone"].value == null ||
    form.controls["contactpersonphone"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Phone No");
  } else if (!this.phonetwo) {
    alert("Please Enter valid Mobile Number");
    this.spinner.hide();
  } else if (
    form.controls["contalone"].value == null ||
    form.controls["contalone"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Address");
  } else if (
    form.controls["conaltwo"].value == null ||
    form.controls["conaltwo"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Address line two");
  } else if (
    form.controls["contcity"].value == null ||
    form.controls["contcity"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter City");
  } else if (
    form.controls["constate"].value == null ||
    form.controls["constate"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter State");
  } else if (
    form.controls["contpincode"].value == null ||
    form.controls["contpincode"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Pincode");
  } else if (
    form.controls["contCountry"].value == null ||
    form.controls["contCountry"].value == ""
  ) {
    this.spinner.hide();
    alert("Enter Country");
  } else {
    this.spinner.show();
    var cid = localStorage.getItem("uid");
    const inputRequest: CompanyRegistrationVM = {
      Id: null,
      CompanyId: cid,
      CompanyName: form.controls["cname"].value,
      Established: form.controls["estyear"].value,
      NumberofEmployees: form.controls["numofemp"].value,
      NumberofBranches: form.controls["numofbra"].value,
      RegistrationId: form.controls["regId"].value,
      GST: form.controls["gst"].value,
      PAN:form.controls["pan"].value,
      IndustryType: this.industry,
      Headquarters: form.controls["headquarters"].value,
      Regaddone: form.controls["alone"].value,
      Regaddtwo: form.controls["altwo"].value,
      Regaddthree: form.controls["althree"].value,
      Regaddcity: form.controls["city"].value,
      Regaddcountry: form.controls["Country"].value,
      Regaddlanmark: form.controls["landmark"].value,
      Regaddpincode: form.controls["pincode"].value,
      Regaddstate: form.controls["state"].value,
      WebSite: form.controls["Website"].value,
      OfficialEmail: form.controls["email"].value,
      ContactPersonName: form.controls["contpersonname"].value,
      ContactPersonEmail: form.controls["contactperemail"].value,
      ContactPersonPhone: form.controls["contactpersonphone"].value,
      Contactaddone: form.controls["contalone"].value,
      Contactaddtwo: form.controls["conaltwo"].value,
      Contactaddthree: form.controls["conalthree"].value,
      Contactaddlanmark: form.controls["contlandmark"].value,
      Contactaddstate: form.controls["constate"].value,
      Contactaddcity: form.controls["contcity"].value,
      Contactaddcountry: form.controls["contCountry"].value,
      Contactaddpincode: form.controls["contpincode"].value,
    };
    this.registrationService
      .SaveCompanyDetails(inputRequest)
      .subscribe((data: any) => {
        if (data != null) {
          alert("Registered Your Details Successfully");
          location.reload();
          this.spinner.hide();
        } else {
          alert("Please Fill Details Properly");
          this.spinner.hide();
        }
        this.spinner.hide();
      });
  }
}
}