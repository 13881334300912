
import { Component, OnInit, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { ApplicantDetail } from 'app/shared/VIewmodels/registerapplicant';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImageCroppedEvent } from 'ngx-image-cropper';


@Component({
  selector: 'app-profilereg',
  templateUrl: './profilereg.component.html',
  styleUrls: ['./profilereg.component.scss'],
})
export class ProfileregComponent implements OnInit {
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: WebcamImage = null;

  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  public nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  public pictureTaken = new EventEmitter<WebcamImage>();

  // toggle webcam on/off

  // webcam snapshot trigger
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  SelectedFiles: any;
  num: any;
  foldercreated: boolean;
  folderCreating: boolean;
  pid: any;
  createdmsg: string;
  n: number;
  otherindustry: boolean=false;
  othersrole: boolean=false;
  Otherqualification: boolean=false;
  sMsg: any;
  allSubFolders: any;
  userName: string | Blob;
  uid: string | Blob;
  files1: any;
  Creatingmsg: string;
  generalprogressbar: boolean;
  showper: boolean;
  drawsign: boolean;
  choosesign: boolean;
  uploadign: boolean;
  sigimg: any;
  signatureImage: any;
  perks: any;
  roles: any;
  salaries: any;
  industries: any;
  growths: any;
  role: any;
  perk: any;
  salary: any;
  industry: any;
  growth: any;
  rolen: any;
  designation: any;
  loc1: any;
  loc2: any;
  loc3: any;
  userid: any;
  imageres: Object;
  sgimg: any;
  sigres: Object;
  config: any;
   dataModel;
  genders: any;
   gender: any;
  panimg: any;
  pansig: any;
  otherindustries: any;
  otherqualification: any;
  otherrole: any;
  sendHQ: any;
  croppedImage: string;
  signatureBinary: string;
  imageChangedEvent: Event;

  fulladdress:any;
  aadhaarnumber:any;
  mailid:any;
  mobilnumber:any;
  parentname:any;
  fullname:any;

  constructor(
    private httpService: HttpClient,
    private service: MasterService,
    private regservice: RegistrationService,
    private spinner: NgxSpinnerService,
 ) {
    this.userid = localStorage.getItem('userId');
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
        this.config = {
      displayKey:"indrustType", //if objects array passed which key to be displayed defaults to description
      search:true, //true/false for the search functionlity defaults to false,
      height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder:'Select Industry', // text to be displayed when no item is selected defaults to Select,
      customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder:'Search', // label thats displayed in search input,
      searchOnKey: 'indrustType', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      }
      setTimeout(() => {
        var b = document.getElementsByClassName('ngx-dropdown-container')[0] as HTMLElement;
        var a = document.getElementsByClassName('ngx-dropdown-button')[0] as HTMLElement;
        if(a){
          console.log(a); 
          a.setAttribute("style","min-height: 30px !important;  padding: 5px 10px 5px 10px !important;background-color: white !important;width: 95% !important;border: none !important; border-bottom: 1px solid #1e5ee5 !important; margin-bottom: 5px !important;text-decoration: none !important; border-radius: 0 !important;")
        }
        if(b){
          b.setAttribute("style","width:100%")
        }
      }, 1000);
    this.getmaster();

    $(document).ready(function () {
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $('fieldset').length;

      setProgressBar(current);

      $('.next').click(function () {
        // $("input").prop('required',true);
        var a = $('#designation').val();
        var b = $('#role').val();
        var c = $('#industry').val();
        var d = $('#loc1').val();
        var e = $('#salary').val();
        var f = $('#hq').val();
        var g = $('#perks').val();
        var h = $('#growth').val();
         var i = $('#gender').val();
         var j = $('#otherindustries').val();
         var k = $('#otherqualification').val();
          var l = $('#otherrole').val();
        var upimg = localStorage.uimg;
        var webimg = localStorage.webimg;
        var upimgs = localStorage.upimgs;
        var webimgs = localStorage.webimgs;
        if (
          current == 1 &&
          (a == '' ||
            b == null ||
            c == null ||
            d == '' ||
            e == null ||
            f == null ||
            g == null ||
            h == null)
        ) {
          alert('Please fill all the fields');
        }
         else if (
          current == 2 &&
          (upimg == null ||
            upimg == undefined ||
            webimg == undefined ||
            webimg == null)
        ) {
          alert('Please upload and capture your photo');
        } 
       
        else {
          // setTimeout(() => {
          //   // if (document.getElementById('sign')) {
          //   //   var w = document.getElementById('signf').offsetWidth;
          //   //   var a = document.querySelector('canvas');
          //   //   console.log(w);
          //   //   a.width = w;
          //   //   a.height = 150;
          //   //   a.style.border = '1px dotted #1e5ee5';
          //   // }
          //   if (document.querySelector('video')) {
          //     document.querySelector('video').style.width = '100%';
          //     document.querySelector('video').style.height = '100%';
          //   }
          // }, 1000);
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          //Add Class Active
          $('#progressbar li')
            .eq($('fieldset').index(next_fs))
            .addClass('active');

          //show the next fieldset
          next_fs.show();
          //hide the current fieldset with style
          current_fs.animate(
            { opacity: 0 },
            {
              step: function (now) {
                // for making fielset appear animation
                opacity = 1 - now;

                current_fs.css({
                  display: 'none',
                  position: 'relative',
                });
                next_fs.css({ opacity: opacity });
              },

              duration: 500,
            }
          );

          setProgressBar(++current);
        }
      });

      $('.previous').click(function () {
        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //Remove class active
        $('#progressbar li')
          .eq($('fieldset').index(current_fs))
          .removeClass('active');

        //show the previous fieldset
        previous_fs.show();

        //hide the current fieldset with style
        current_fs.animate(
          { opacity: 0 },
          {
            step: function (now) {
              // for making fielset appear animation
              opacity = 1 - now;

              current_fs.css({
                display: 'none',
                position: 'relative',
              });
              previous_fs.css({ opacity: opacity });
            },
            duration: 500,
          }
        );
        setProgressBar(--current);
      });

      function setProgressBar(curStep) {
        var percent = (100 / steps) * curStep;
        percent = percent;
        $('.progress-bar').css('width', percent + '%');
      }
      $('.submit').click(function () {
        return false;
      });
    });
  }
  reload() {
    localStorage.loadingpage == 'true';
  }
  onChange(event: any) {
    var fileslist = '';
    var files = [].slice.call(event.target.files);

    this.files1 = [].slice.call(event.target.files);
    // console.log(this.files1);
    for (let k = 0; k < this.files1.length; k++) {
      fileslist = this.files1[k].name + ',';
    }
    this.SelectedFiles = fileslist.split(',');
  }
  uimg: any;


  handleFileInput3(event:any) {
    document.getElementById("openpopup").click();
    this.imageChangedEvent= event;
    this.pansig = event.target.files[0];
    this.panimg = event.target.files[0];
  }
  
   handleFileInput4(files:File) {
    this.pansig = files[0];
  }

  
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
   // this.ImageFile = this.croppedImage;
    this.signatureBinary = this.croppedImage;
  }

  // verifyimage() {
  //   const frmData = new FormData();
  //   frmData.append('userId', this.userid);
  //   // frmData.append('fileUpload', this.uimg);
  //   frmData.append("capimg", this.webcamImage.imageAsBase64);

  //   frmData.append("fileUpload", this.panimg);
  //   console.log(this.uimg);
  //   localStorage.uimg = this.uimg;
  //   localStorage.webimg = this.panimg;
  //  // console.log(this.webcamImage.imageAsBase64);
  //   //spinner idre add madu
  //   this.spinner.show();
  //   this.httpService
  //     .post(
  //       'https://blockchainmatrimony.com/jobzoneapi/api/Account/verifyimage/',
  //       frmData
  //     )
  //     .subscribe(
  //       (data) => {
  //         this.getimgverifieddetail(data)
  //         this.spinner.hide();
        
  //         // if (data != 0) {
  //         //   this.httpService
  //         //   .get('https://blockchainmatrimony.com/flaskapp/outputfin/' + data+",jobzone")
  //         //     .subscribe((data) => {
  //         //       this.spinner.hide();
  //         //       console.log(data);
  //         //     },
  //         //     (err: HttpErrorResponse) => {
  //         //       this.getimgverifieddetail(data);
  //         //       this.spinner.hide();
  //         //      // alert('Something went wrong. Please try again..!! ');
  //         //       console.log(err.message); // Show error, if any.
  //         //     });
  //         // } else {
  //         //   this.spinner.hide();
  //         //   alert('Something went wrong. Please try again..!! ');
  //         // }
  //       },
  //       (err: HttpErrorResponse) => {
  //         this.spinner.hide();
  //         alert('Something went wrong. Please try again..!! ');
  //         console.log(err.message); // Show error, if any.
  //       }
  //     );
  // }
  //Original Method (13-12-10-2022)
  verifyimage() {
    const frmData = new FormData();
    frmData.append('userId', this.userid);
    // frmData.append('fileUpload', this.uimg);
    frmData.append("capimg", this.webcamImage.imageAsBase64);

    frmData.append("fileUpload", this.panimg);
    console.log(this.uimg);
    localStorage.uimg = this.uimg;
    localStorage.webimg = this.panimg;
   // console.log(this.webcamImage.imageAsBase64);
    //spinner idre add madu
    this.spinner.show();
    this.httpService
      .post(
        'https://blockchainmatrimony.com/jobzoneapi/api/Account/verifyimage/',
        frmData
      )
      .subscribe(
        (data) => {
         // this.spinner.hide();
        
          if (data != 0) {
            this.httpService
            .get('https://blockchainmatrimony.com/flaskapp/outputfin/' + data+",jobzone")
              .subscribe((data) => {
                this.spinner.hide();
                console.log(data);
              },
              (err: HttpErrorResponse) => {
                this.getimgverifieddetail(data);
                this.spinner.hide();
               // alert('Something went wrong. Please try again..!! ');
                console.log(err.message); // Show error, if any.
              });
          } else {
            this.spinner.hide();
            alert('Something went wrong. Please try again..!! ');
          }
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          alert('Something went wrong. Please try again..!! ');
          console.log(err.message); // Show error, if any.
        }
      );
  }
  getsignverifieddetail(id)
  {
    this.regservice.Getsignverifieddet(id).subscribe((data => {
      this.spinner.hide()
  
      if(data == true)
      {
        alert('Signature Uploaded Successfully');

       // let elm = document.getElementById("nextbtn1");
        this.sigres = data;
       // elm.click();
        this.sigres = "Matched";
        // alert(data);
       // alert("Verified Successfully!!");
        localStorage.setItem("validateval", "true")
        localStorage.setItem("userverification", "true");

      }
      else
      {
        this.sigres = "Not Matched";
        alert("Matched");

        // alert(data);
      }
     
    }));
  }




  getimgverifieddetail(id)
  {
    this.regservice.Getimgverifieddet(id).subscribe((data => {
      this.spinner.hide()
      if(data == true)
      {
        alert('Image Uploaded Successfully');

        let elm = document.getElementById("nextbtn");
        this.imageres = data;
        elm.click();
        this.imageres = "Matched";
        // alert(data);
       // alert("Verified Successfully!!");
        localStorage.setItem("validateval", "true")
        localStorage.setItem("userverification", "true");

      }

      // if(data == true)
      // {
      //   alert('Image Verified Successfully');

      //   let elm = document.getElementById("nextbtn");
      //   this.imageres = data;
      //   elm.click();
      //   this.imageres = "Matched";
      //   // alert(data);
      //  // alert("Verified Successfully!!");
      //   localStorage.setItem("validateval", "true")
      //   localStorage.setItem("userverification", "true");

      // }
      else
      {
       // localStorage.setItem("userverification", "false");
        this.imageres = "Not Matched";
        // alert(data);
        alert("Not Matched");

      }
     
    }));
  }


  onDrop(event: any) {
    var fileslist = '';
    var files = event;

    this.files1 = files;
    for (let k = 0; k < this.files1.length; k++) {
      if (this.files1[k].type == '') {
        alert('Please Upload only Folders');
        this.files1 = '';
      }
      fileslist = this.files1[k].name + ',';
    }
    this.SelectedFiles = fileslist.split(',');
  }
  getPercent(per) {
    return 10;
  }
  Uploaddocs() {
    document.getElementById('cancelUpload').click();

    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = 'Uploading File to jobs-zone...';
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = 'Uploading File to jobs-zone...';
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const frmData = new FormData();

    for (var i = 0; i < this.files1.length; i++) {
      frmData.append('fileUpload', this.files1[i]);
    }

    //frmData.append("fileUpload", this.files1);
    frmData.append('userId', this.userid);
    frmData.append('username', this.userName);
    frmData.append('folderid', this.allSubFolders.parentId);

    this.httpService
      .post(
        'https://blockchainmatrimony.com/jobzoneapi/api/UploadDocument/NewDocumentUpload/',
        frmData
      )
      .subscribe(
        (data) => {
          // SHOW A MESSAGE RECEIVED FROM THE WEB API.
          this.sMsg = data as string;
          // console.log(this.sMsg);
          if (this.sMsg == 0) {
            //document.getElementById("cancelUpload").click();
            this.n = 100;
            this.createdmsg = 'File Uploaded Successfully to jobs-zone';
            this.pid = data;
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);

            setTimeout(
              function () {
                this.folderCreating = false;
                this.foldercreated = false;
                this.generalprogressbar = false;
                this.errormsg = false;
                this.showper = false;
              }.bind(this),
              3000
            );

            this.SelectedFiles = null;
          } else {
          }
        },
        (err: HttpErrorResponse) => {
          alert('Something went wrong. Please try again..!! ');
          // console.log(err.message);    // Show error, if any.
        }
      );
  }
  showImage(data) {
    this.drawsign = true;
    this.choosesign = false;
    this.uploadign = false;

    this.sigimg = data.split(',')[1];
    console.log(this.sigimg);
    this.signatureImage = data;
  }

  handleFileInput1(files: File) {
    this.uimg = files[0];
  }

  handleFileInput2(files: File) {
    this.sgimg = files[0];
  }

  getmaster() {
    this.GetHighQualification();
    this.service.getrole().subscribe((data: any) => {
      this.roles = data;

      console.log(this.roles);
    });
    this.service.getperks().subscribe((data: any) => {
      this.perks = data;
      console.log(this.perks);
    });
    this.service.getgender().subscribe((data: any) => {
      this.genders = data;
      console.log(this.genders);
    });
    this.service.getindustry().subscribe((data: any) => {
      this.industries = data;
      console.log(this.industries);
    });
    this.service.getsalary().subscribe((data: any) => {
      this.salaries = data;
      console.log(this.salaries);
    });
    this.service.getgrowth().subscribe((data: any) => {
      this.growths = data;
      console.log(this.growths);
    });
  }
  onchangeindustry(event) {
    const value = event.value.id;
   this.industry = value;
   if (value == 15) {
     this.otherindustry = true;
   }
   else {
     this.otherindustry = false;
   }
 }

 onchangerole(event) {
   const value = event.target.value;
   this.role = value;
   console.log(value);
   if (value == 1048) {
     this.othersrole = true;
   }
   else {
     this.othersrole = false;
   }
 }

 onchangequalify(data: any) {
  this.sendHQ = data;
   this.HQ = data;
   if (data == 17) {
     this.Otherqualification = true;
   }
   else {
     this.Otherqualification = false;
   }
 }


  onchangesalary(event) {
    const value = event.target.value;
    this.salary = value;
    console.log(value);
  }
  onchangeperk(event) {
    const value = event.target.value;
    this.perk = value;
    console.log(value);
  }
    onchangegender(event) {
    const value = event.target.value;
    this.gender = value;
    console.log(value);
  }
  onchangegrowth(event) {
    const value = event.target.value;
    this.growth = value;
    console.log(value);
  }
  onSubmit() {
    if(this.sendHQ == 17){
      this.sendHQ  = this.otherqualification;
    }
    const inputr: ApplicantDetail = {
      Id: null,

      fullname: this.fullname,
      parentname: this.parentname,
      mobilnumber: this.mobilnumber,
      mailid: this.mailid,
      aadhaarnumber: this.aadhaarnumber,
      fulladdress: this.fulladdress,

      Designation: this.designation,
      Otherindustry:this.otherindustries,
      Otherqualification:this.sendHQ,
      Otherrole:this.otherrole,
      Salarymax: this.HQ,
      Salarymin: this.salary,
      UID: this.userid,
      UserId: null,
      growth: this.growth,
      industry: this.industry,
      loc1: this.loc1,
      loc2: this.loc2,
      loc3: this.loc3,
      perks: this.perk,
      role: this.role,
    gender:this.gender,
    };
    console.log(inputr);
    this.service.registerApplicant(inputr).subscribe(
      (data: any) => {},
      (err: HttpErrorResponse) => {
        alert('Something Went wrong try again!');
      }
    );
  }

  public seconds: number;

  // latest snapshot

  HQ: any;
  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    console.log(webcamImage);
    this.pictureTaken.emit(webcamImage);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  HQlist: any;
  GetHighQualification() {
    this.regservice.GetHighQualification().subscribe((response: any) => {
      this.HQlist = response;
      console.log(this.HQlist);
    });
  }

  // showImage(data) {
  //  this.drawsign = true;
  //  this.choosesign = false;
  //  this.uploadign = false;

  //  this.sigimg = data.split(',')[1];
  //  console.log(this.sigimg);
  //  this.signatureImage = data;

  // }

  uploaddocs() {
    var uid = localStorage.getItem('CusId');

    const frmData = new FormData();

    frmData.append('fileUpload', this.uimg);
    frmData.append('fileUpload', this.sgimg);

    frmData.append('userId', uid);

    frmData.append('phonen', null);
    frmData.append('email', null);

    if (this.webcamImage.imageAsBase64 != null) {
      frmData.append('capimg', this.webcamImage.imageAsBase64);
    }
    frmData.append('sigimg', this.sigimg);

    this.httpService
      .post(
        'https://blockchainmatrimony.com/jobzoneapi/api/Account/Uploaduserdet/',
        frmData
      )
      .subscribe(
        (data) => {
          // SHOW A MESSAGE RECEIVED FROM THE WEB API.

          if (data == '1') {
            //document.getElementById("cancelUpload").click();
            //alert("File Uploaded Successfully to jobs-zone");
            // this.();
          } else {
            console.log('unsuc');
          }
        },
        (err: HttpErrorResponse) => {
          alert('Something went wrong. Please try again..!! ');
          console.log(err.message); // Show error, if any.
        }
      );
  }

  // verifysignature() {
  //   if (this.sgimg != null && this.pansig != null) {
  //     var uid = this.userid;
  //     const frmData = new FormData();
  //     frmData.append('userId', uid);

  //     frmData.append('fileUpload', this.sgimg);
  //     frmData.append("fileUploadTwo", this.signatureBinary);

  //     localStorage.webimgs = this.sgimg;
  //     localStorage.upimgs = this.pansig;

  //     this.spinner.show();
  //     this.httpService
  //       .post(
  //         'https://blockchainmatrimony.com/jobzoneapi/api/Account/verifysignature/',
  //         frmData
  //       )
  //       .subscribe(
  //         (data) => {
  //           this.spinner.hide();
  //           //call flask api just like face recognition
  //           // if (data != 0) {
  //           //   this.httpService
  //           //   .get('https://blockchainmatrimony.com/flaskapp/sigmatchfin/' + data+",jobzone")
  //           //     .subscribe((data) => {
  //           //       console.log(data);
  //           //       alert('Image Verified Successfully');
  //           //       this.spinner.hide();
  //           //     },
  //           //     (err: HttpErrorResponse) => {
  //           //       // Show error, if any.
  //           //    this.getsignverifieddetail(data);
  //           //       this.spinner.hide();
  //           //   });
  //           // } else {
  //           //   alert('Something went wrong. Please try again..!! ');
  //           // }
  //         },
  //         (err: HttpErrorResponse) => {
  //           alert('Something went wrong. Please try again..!! ');
  //           console.log(err.message); // Show error, if any.
  //         }
  //       );
  //   } else {
  //     alert('Select your image and Sign');
  //   }
  // }


  //Original Method(13-10-2022)
  verifysignature() {
    if (this.sgimg != null && this.pansig != null) {
      var uid = this.userid;
      const frmData = new FormData();
      frmData.append('userId', uid);

      frmData.append('fileUpload', this.sgimg);
      frmData.append("fileUploadTwo", this.signatureBinary);

      localStorage.webimgs = this.sgimg;
      localStorage.upimgs = this.pansig;

      this.spinner.show();
      this.httpService
        .post(
          'https://blockchainmatrimony.com/jobzoneapi/api/Account/verifysignature/',
          frmData
        )
        .subscribe(
          (data) => {
            //call flask api just like face recognition
            if (data != 0) {
              this.httpService
              .get('https://blockchainmatrimony.com/flaskapp/sigmatchfin/' + data+",jobzone")
                .subscribe((data) => {
                  console.log(data);
                  alert('Image Verified Successfully');
                  this.spinner.hide();
                },
                (err: HttpErrorResponse) => {
                  // Show error, if any.
               this.getsignverifieddetail(data);
                  this.spinner.hide();
              });
            } else {
              alert('Something went wrong. Please try again..!! ');
            }
          },
          (err: HttpErrorResponse) => {
            alert('Something went wrong. Please try again..!! ');
            console.log(err.message); // Show error, if any.
          }
        );
    } else {
      alert('Select your image and Sign');
    }
  }
  public triggerSnapshot(): void {
    this.showWebcam = !this.showWebcam;

    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}