<br>
<br><br>
<br><br>
<br>
<h1 style="text-align:center">Coding Test</h1>
<br><br>
<div class="col-md-12" style="font-size: 16px;text-align: center;"><h3><b>Question : {{question.question}}</b></h3></div>
<br><br>
<div class="col-md-12 row">
    <div class="col-md-3"></div>
    <div style="width: 50%" class="col-lg-6">

        <div class="dropdown-cover field-cover   has-input">
            <span class="sub-label-true">
                <span class="main-label">
                    <span>Select Language</span>
                </span>
                <span class="sub-label">
                </span>
            </span>
            <div class="col-md-6">
                <!-- <select class="form-control" data-is-required="true" [(ngModel)]="lang" name="academicyear">
                    <option value="" class=" ">Select An Option</option>
                    <option value="cpp" class=" ">C++</option>
                    <option value="c" class=" ">C</option>
                    <option value="java" class=" ">Java</option>
                    <option value="py" class=" ">Python</option>
                </select> -->
                <!-- <select class="form-control" data-is-required="true" [(ngModel)]="lang" name="academicyear">
                    <option value="" class=" ">Select A Language</option>
                    <option value="java" class=" ">JAVA</option>
                    <option value="c" class=" ">C</option>
                    <option value="cpp" class=" ">C++</option>
                    <option value="php" class=" ">PHP</option>
                    <option value="perl" class=" ">Perl</option>
                    <option value="python3" class=" ">Python 3</option>
                    <option value="ruby" class=" ">Ruby</option>
                    <option value="go" class=" ">Go Language</option>
                    <option value="csharp" class=" ">C#</option>
                </select> -->

                <select class="form-control" data-is-required="true" [(ngModel)]="lang" name="academicyear">
                    <option value="cpp" class=" ">C++</option>
                    <option value="c" class=" ">C</option>
                    <option value="py" class=" ">Python</option>
                    <option value="java" class=" ">Java</option>
                </select>

            </div>
        </div>
    </div>
</div>
<br><br>
<br><br>
<div class="col-md-12 row">
    <div class="col-md-3"></div>
    <div class="col-md-2">
        <div class="text-area">
            <textarea rows="30" cols="125" [(ngModel)]="code"></textarea>
        </div>
        <br>
        <br>
       
        <br>
        <br>
        <!-- <p > <b>Output:</b> </p>
        <textarea rows="10" cols="125" [(ngModel)]="output1"></textarea> -->
    </div>
</div>
<div class="col-md-12 row">
<div class="col-md-1"></div>
<div class="col-md-5">
    <p > <b>Inputs:</b> </p>
    <textarea placeholder="Give all the inputs at a time that is one below the other" rows="10" cols="80" [(ngModel)]="inputs"></textarea>
</div>
<div class="col-md-1"></div>
<div class="col-md-5">
    <p > <b>Output:</b>
        <!-- &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        <button class="btn-btn-primary" (click)="clearoutput()"> Clear Output</button> -->
    </p>


    <textarea rows="10" cols="80" [(ngModel)]="output1"></textarea>
</div>


</div>
<br><br><br>
<div class="col-md-12 row">
    <div class="col-md-4"></div>
    <div class="col-md-3">
        <button class="btn btn-primary" (click)="compile()">Compile</button>
    </div>
    <div class="col-md-1"></div>

    <div class="col-md-3">
        <button class="btn btn-primary" (click)="submitResult()">Submit</button>
    </div>
</div>


<!-- <br><br><br> *Note: Please write your code only in Python 3 Language.(Single submission) <br> **Note: Please take the inputs using sys.argv itself. <br> Ex: inputa = sys.argv[1:] <br> Thank you!
<ngx-codemirror [(ngModel)]="content" [options]="{
    lineNumbers: true,
    theme: 'material',
    mode: 'python'
  }"></ngx-codemirror>
<br> -->
<!-- <button class="btn btn-primary" (click)="fetch()">submit</button> -->