import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyRegistrationVM } from '../VIewmodels/CompanyRegistrationVM';
import { BasicRegistration } from '../VIewmodels/BasicRegistration';
import { jobpostVM } from '../VIewmodels/jobpostVM';
import { SavepaymentDetails } from '../VIewmodels/SavepaymentDetails';
import { addresslist } from '../VIewmodels/addresslist';
import { PayU } from '../VIewmodels/Payment';
import { Signup } from '../VIewmodels/Signup';
import { OtpViewModel } from '../VIewmodels/OtpViewModel';
import { JobPostForINsVM } from '../VIewmodels/jobpostforinsvm';
import { InstitutionVm } from '../VIewmodels/instituionvm';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  readonly url = 'https://blockchainmatrimony.com/jobzoneapi/api';
  // readonly url = "https://blockchainmatrimony.com/jobzoneapi/api";
  // readonly url = "https://blockchainmatrimony.com/jobzoneapi/api";
  readonly rooturl1 = 'http://localhost:15000';
  constructor(private http: HttpClient) {}
  getsize(id) {
    return this.http.post(this.url + '/Master/GetSize', id);
  }
  GetIndrustlist() {
    return this.http.get(this.url + '/Master/GetIndrustyMasters');
  }
 

  SaveCompanyDetails(data: CompanyRegistrationVM) {
    return this.http.post(
      this.url + '/CompanyRegistration/PostSaveCompany',
      data
    );
  }
  SavePostRegCompany(data: Signup) {
    return this.http.post(this.url + '/Account/PostSaveRegister', data);
  }
  LoginVerification(data: OtpViewModel) {
    return this.http.post(this.url + '/Account/LoginVerification', data);
  }
  sendotp(data: Signup) {
    return this.http.post(this.url + '/Account/sendotp', data);
  }
  SaveJobDetails(data: jobpostVM) {
    return this.http.post(
      this.url + '/CompanyRegistration/PostSaveNewJob',
      data
    );
  }
  apply(id, uid, name) {
    return this.http.get(
      this.url + '/Account/applyForJob/' + id + ',' + uid + ',' + name
    );
  }
  getallappliedjob(id, uid) {
    return this.http.get(this.url + '/Account/viewapplied/' + id + ',' + uid);
  }
  getjobgroups(id, uid, group) {
    return this.http.get(
      this.url + '/Account/viewappliedgroup/' + id + ',' + uid + ',' + group
    );
  }
  getPayuPlans() {
    return this.http.get(this.url + '/Payment/GetPlanList');
  }
  SaveOrderDetails(aaa: SavepaymentDetails) {
    return this.http.post(this.url + '/Payment/GetOrderPayments', aaa);
  }
  getorderpayment(mm: addresslist) {
    return this.http.post(this.url + '/Payment/GetOrderDetailsforPayment', mm);
  }
  getpaymentstatus(aa: PayU) {
    return this.http.post(this.url + '/Payment/GetPaymentStatus', aa);
  }
  GetJobList(id) {
    return this.http.get(this.url + '/CompanyRegistration/GetallData/' + id);
  }
  GetMatchList(id) {
    return this.http.get(
      this.url + '/JobMatching/GetJobMacthesForCompany/' + id
    );
  }
  getmatchedjoblist(id) {
    return this.http.get(
      this.url + '/JobMatching/GetJobMacthesForJobSeeker/' + id
    );
  }
  getpayscale(id) {
    return this.http.get(
      this.url + '/ApplicantRegister/getpayscalevalue/' + id
    );
  }
  getprofile(id) {
    return this.http.get(this.url + '/ApplicantRegister/FetchData/' + id);
  }
  getprofileforcompany(id) {
    return this.http.get(
      this.url + '/ApplicantRegister/FetchDataForCompany/' + id
    );
  }
  getrole(id) {
    return this.http.get(this.url + '/ApplicantRegister/getrole/' + id);
  }

  getsalary(id) {
    return this.http.get(this.url + '/ApplicantRegister/getsalary/' + id);
  }

  getperks(id) {
    return this.http.get(this.url + '/ApplicantRegister/getperks/' + id);
  }

  getindustry(id) {
    return this.http.get(this.url + '/ApplicantRegister/getindustry/' + id);
  }
  getgrowth(id) {
    return this.http.get(this.url + '/ApplicantRegister/getgrowth/' + id);
  }
  geteducation(id) {
    return this.http.get(this.url + '/ApplicantRegister/geteducation/' + id);
  }
  GetCource: any;
  GetSpecialization: any;
  GetHighQualification() {
    return this.http.get(this.url + '/Master/GetHQMasters');
  }
  GetJobrolelist() {
    return this.http.get(this.url + '/JobMatching/GetJobMacthesForJobSeeker');
  }
  GetSalarylist() {
    return this.http.get(this.url + '/Master/GetsalaryMasters');
  }
  GetEduPrcentage() {
    return this.http.get(this.url + '/Master/GetEduPercentageMasters');
  }
  getSharedDetails(id) {
    return this.http.get(this.url + '/Account/getShare/' + id);
  }

  getjoblistforins(id) {
    return this.http.get(this.url + '/JobMatching/GetJobMacthesForIns/' + id);
  }

  getInternshipforins(id) {
    return this.http.get(
      this.url + '/JobMatching/GetInternshipMacthesForIns/' + id
    );
  }

  getTrainingforins(id) {
    return this.http.get(
      this.url + '/JobMatching/GetTrainingMacthesForIns/' + id
    );
  }

  SaveJobDetailsForIns(data: JobPostForINsVM) {
    return this.http.post(
      this.url + '/InstitutionRegistration/PostSaveNewJobForIns',
      data
    );
  }
  SaveInstitutionDetails(data: InstitutionVm) {
    return this.http.post(
      this.url + '/InstitutionRegistration/PostSaveInstitution',
      data
    );
  }
  getImage(id) {
    return this.http.get(this.url + '/Account/getImg/' + id);
  }
  saveupdatejob(data) {
    return this.http.post(
      this.url + '/CompanyRegistration/PostSaveUpdateJob',
      data
    );
  }
  getgender(id) {
    return this.http.get(this.url + '/ApplicantRegister/getgender/' + id);
  }

  alert(data:any) {
    return this.http.post(
        this.url + '/Master/postalert',data
      );
  }

  Getimgverifieddet(id){
    return this.http.get(this.url + "/ApplicantRegister/Getimageverifieddetails/"+id);
  }
  Getsignverifieddet(id){
    return this.http.get(this.url+ "/ApplicantRegister/Getsignverifieddetails/"+id);
  }

}
