<br/><br/><br/><br/>
<br/><br/>

<div class="col-lg-12 row" style="background: #153157;height: 2px;margin: 0;padding: 0;">

</div>
<br/><br/>
<div class="row col-sm-12">
    <div class="row col-sm-2"></div>
    <div class="row col-sm-8">
        <div class="table-responsive">
            <table class="table table-bordered">
                <tr>
                    <th>File Name</th>
                    <th></th>
                </tr>
                <tr *ngFor="let fo of deletedlist.folders; let i = index">

                    <td><i class="fa fa-folder fa-1x" aria-hidden="true"></i> &nbsp;{{fo.folderName}}</td>
                    <!-- <td>{{fo.orderedDate | date}}</td> -->
                    <td>
                        <button class="btn btn-danger" (click)="Restorefile(fo)">Restore</button>
                    </td>
                </tr>
                <tr *ngFor="let fi of deletedlist.files; let i = index">

                    <td><i class="fa fa-file fa-1x" aria-hidden="true"></i>&nbsp;{{fi.documentName}}</td>
                    <!-- <td>{{fi.orderedDate | date}}</td> -->
                    <td>
                        <button class="btn btn-danger" (click)="Restorefile(fi)">Restore</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row col-sm-2"></div>
</div>
<br/><br/><br/><br/>