<div class="inbox-head">
    <h3>Compose</h3>

</div>
<div class="inbox-body">

    <div class="col-md-10" style="
          padding: 30px 72px 50px 180px;">

        <!-- <label>Ticket Id</label>
                            <select (change)="onSelectTicket($event.target.value)" class="form-control">
    <option value="">Select Ticket Id</option>
    <option *ngFor="let do of TicketsList" value={{do.ticketId}}>
        {{do.ticketId}}
    </option>
</select> -->
        <div class="col-md-12" id="divstyle">
            <label id="labeldiv">To</label>
            <div id="aboveTagInp">
                <input type="text" class="form-control" name="ToMails" [(ngModel)]="ToMails" autofocus>
            </div>
            <!-- <input class="form-control" type="text" id="inputbox" name="to" [(ngModel)]="to" /> -->
        </div><br /><br />
        <!-- <br /> <br />

                            <label> To</label>
                            <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
                <option value=""> Select Friend </option>
                <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
                    {{ I.friendName }}
                </option>
            </select>
                            <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;">
                                {{ mem.friendName }}<br />
                            </div> -->
        <br />
        <label>Subject</label>
        <input type="text" class="form-control" name="mailsubject" [(ngModel)]="mailsubject">
        <br />
        <label>Message</label>
        <textarea type="text" class="form-control" name="message" [(ngModel)]="message" rows="4" cols="50"> </textarea>
        <br /> <br />
     <div style="text-align: right;">
            <button type="submit" id="SendBtn" class="btn btn-primary" (click)="sendmail()">SEND</button>
        </div><br /> <br />
    </div>

</div>


<!-- Upload from computer modal -->
<div class="modal modal-angular fade" id="UploadModalPopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content" id="choosepopup">
            <button type="button" class="close" id="closeinto" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="float: right;">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Attach File</p>
            </div>
            <br />
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <!-- <div id="chosefromcom">
            <br /> <br />
            <img src="./assets/img/Choosefrom.png" style="cursor: pointer;" />
            <br />
            <label id="chosefromcom">
              <p class="temp-upload-icon" for="excel-upload">
                UPLOAD FROM COMPUTER</p>
              <input #fileInput (click)="fileInput.value = null"
type="file" id='excel-upload' style="display: none"
                (change)="onChangeDocumnets($event)" multiple="true" />
                <input type="file" name="avatars" multiple=true
(change)="onChangeDocumnets($event)"> <br /> <br />

            </label>
            <br />
          </div> -->
                </div>
                <div class="col-md-2"></div>
            </div>

            <br />
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitmaindiv"
                        id="chosefromcom">
                        <br /> <br />
                        <img data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitmaindiv"
                            src="./assets/img/Choosefrom.png" style="cursor: pointer;" />
                        <br />
                        <p class="temp-upload-icon" data-dismiss="modal" style="margin-top: 1%;" data-toggle="modal"
                            data-target="#choosefromhaspitmaindiv">
                            UPLOAD FROM BLOCDRIVE
                        </p>
                        <br />
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 row">
                    <div class="col-md-5"></div>
                    <div style="margin-left: -6%;">
                        <button type="button" id="testt" class="close" data-dismiss="modal" aria-label="Close"></button>
                        <button type="submit" id="CloseBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button><br>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- choosefrom main haspit modal -->
<div class="modal modal-angular fade" id="choosefromhaspitmaindiv" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
                    id="closeinto">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Attach From Haspit</p>
            </div>
            <div class="modal-body" style="margin-top: -6%;">
                <div *ngIf="folderdisaply">
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <p id="homediv">Home</p>
                            <p id="namediv">Name</p>
                            <table class="table table-hover" id="tabledivision">
                                <tbody class="items">
                                    <tr id="trdiv">
                                        <td style="cursor: pointer;" data-dismiss="modal" data-toggle="modal"
                                            data-target="#choosefromhaspitacc">
                                            <img width="30px" height="30px" src="./assets/img/folder.png"
                                                style="cursor: pointer;margin-top: -1%;" />
                                            <span id="spandivv">My Personal Files</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- <tbody class="items">
                  <tr id="trdiv">
                    <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
                      data-target="#choosefromshared">
                      <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
                      <span id="spandivv">Shared with Others</span>
                    </td>
                  </tr>
                </tbody> -->
                                <!-- <tbody class="items">
                  <tr id="trdiv">
                    <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
                      data-target="#choosefromdeletd">
                      <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
                      <span id="spandivv">Deleted Files</span>
                    </td>
                  </tr>
                </tbody> -->
                            </table>
                            <br />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <button type="submit" id="uploadBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">UPLOAD</button>
                    <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button>
                </div>

            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>

<!-- choosefromhaspit My personal files modal -->

<div class="modal modal-angular fade" id="choosefromhaspitacc" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
                    id="closeinto">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Upload From Blocdrive</p>
            </div>
            <div class="modal-body">

                <div *ngIf="folderdisaply">
                    <div class="col-md-12 row" id="popupcurrentpagename">
                        <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal"
                            data-target="#choosefromhaspitmaindiv">Home</p>
                        <span (click)="GetAllFolders()" id="Breadcrumbone" style="cursor: pointer;"> &nbsp; <img
                                width="30px" height="30px" src="./assets/img/folder.png" />&nbsp; My Personal
                            Files</span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">
                            &nbsp; <img width="30px" height="30px" src="./assets/img/folder.png" />&nbsp;
                            <span (click)="GetDetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                        </span>
                        <!-- <span id="Breadcrumbone" style="cursor: pointer;">
&nbsp; <img src="./assets/img/Vector122.png" />
              &nbsp;{{currentfoldername}}</span> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files</span></p>               -->
                            <table class="table table-hover table-responsive" id="popuptable">
                                <thead>
                                    <tr id="theadstyle">
                                        <th style="padding-left:6%">Name</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody class="items" *ngFor="let folderview of allFolders">
                                    <tr id="trdiv">
                                        <td (click)="GetDetails(folderview)" style="cursor: pointer;"
                                            id="foldernamedisplay1">

                                            <div class="col-md-12 row">
                                                <div class="col-md-1">
                                                    <img width="30px" height="30px" src="./assets/img/folder.png"
                                                        style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                                    {{folderview.folderName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle">{{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>
                                <tbody class="items" *ngFor="let ret of allFoldersList">
                                    <tr id="hoverlink" id="{{ret.Id}}" style="height: 60px;">
                                        <td style="cursor: pointer;" *ngIf="ret.DocumentType=='image/jpeg'||ret.DocumentType=='image/png'||ret.DocumentType=='png'
||ret.DocumentType=='jpeg'||ret.DocumentType=='jpg'

||ret.DocumentType=='PNG'" id="foldernamedisplay1">

                                            <div class="col-md-12 row" (click)="selectfile(ret)">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle">{{ret.date | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
                <div *ngIf="subfolderdisaply">
                    <div class="col-md-12 row" id="popupcurrentpagename">
                        <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal"
                            data-target="#choosefromhaspitmaindiv">Home</p>
                        <span (click)="GetMypersonalfolders()" id="Breadcrumb" style="cursor: pointer;"> &nbsp; <img
                                src="./assets/img/Vector122.png" />&nbsp; My Personal
                            Files</span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">
                            &nbsp; <img src="./assets/img/Vector122.png" />&nbsp;
                            <span (click)="GetSubfolderdetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                        </span>
                        <span id="Breadcrumbone" style="cursor: pointer;"> &nbsp;
                            <img width="30px" height="30px" src="./assets/img/folder.png" />
                            &nbsp;{{currentfoldername}}</span>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files > {{currentfoldername}}</span></p>
-->
                            <table class="table table-hover table-responsive" *ngIf="allSubFolders" id="popuptable">
                                <thead>
                                    <tr id="theadstyle">
                                        <th style="padding-left:6%">Name</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody class="items" *ngFor="let folderview of
allSubFolders.retData">
                                    <tr id="trdiv">
                                        <td *ngIf="folderview.folderName" style="cursor:
pointer;" (click)="GetSubfolderdetails(folderview)" id="foldernamedisplay1">

                                            <div class="col-md-12 row">
                                                <div class="col-md-1">
                                                    <img width="30px" height="30px" src="./assets/img/folder.png"
                                                        style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay3" style="cursor: pointer;">
                                                    {{folderview.folderName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="!folderview.folderName" id="foldernamedisplay1">
                                            <div class="col-md-12 row">
                                                <input type="checkbox" (click)="onClick(folderview)" style="
                        height: 16px;
                        width: 16px;
                        /* margin-top: 28px; */
                        cursor: pointer;
                      " />
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>

                                                <div class="col-md-10" id="datadisplay3" style="cursor: pointer;"
                                                    (click)="onClick(folderview)">
                                                    {{folderview.documentName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle" *ngIf="folderview.folderName">
                                            {{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                        <td id="datestyle" *ngIf="!folderview.folderName">{{folderview.date | date:
                                            'MM/dd/yy, hh:mm a'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="margin-right: 10%;">
                <button type="button" id="closetwo" class="close" data-dismiss="modal" aria-label="Close"></button>
                <button type="submit" id="Uploadbtnn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal"
                    (click)="uploadmypersonalfiles()">UPLOAD</button>
                <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button>
            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>
<br /> <br /> <br />