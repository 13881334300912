import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { UserService } from 'app/shared/UserService/user.service';
import { JobzoneShare } from 'app/shared/VIewmodels/sharedetails';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-appjob',
  templateUrl: './appjob.component.html',
  styleUrls: ['./appjob.component.scss'],
})
export class AppjobComponent implements OnInit {
  isLoggedIn: boolean;
  userid: string;
  accountid: string;
  joblist: any;
  Jobdescription;
  CompanyName: any;
  roles: any;
  joblistcopy: any;
  constructor(
    private masterService: MasterService,
    private route: ActivatedRoute,
    private userser: UserService,
    private registrationService: RegistrationService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe((data: any) => {
      if (data) {
        this.searchvalue = data['search'];
        this.searching = true;
        //this.onSearchChange(data['search']);
      }
    });
    if (localStorage.loadingpage == 'true') {
      localStorage.loadingpage = 'false';
      location.reload();
    }
    if (localStorage.getItem('IsLoggedIn') == 'true') {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem('userId');
      this.accountid = localStorage.getItem('uid');
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      setTimeout(() => {
        var nav = document.getElementById('nav');
        if (nav) {
          nav.style.display = 'block';
        }
      }, 1000);
      // var coll = document.getElementsByClassName("collapsible");
      // var i;

      // for (i = 0; i < coll.length; i++) {
      //     coll[i].addEventListener("click", function() {
      //         this.classList.toggle("active");
      //         var content = this.nextElementSibling;
      //         if (content.style.maxHeight) {
      //             content.style.maxHeight = null;
      //         } else {
      //             content.style.maxHeight = content.scrollHeight + "px";
      //         }
      //     });
      // }
    }, 5000);

    this.GetJobMatchList();
    this.getcertificates();
  }
  Certi: any;
  getcertificates() {
    var uid = localStorage.getItem('userId');
    this.userser.getCerti(uid).subscribe((respose: any) => {
      this.Certi = respose;
      console.log(respose);
    });
  }
  GetJobMatchList() {
    this.masterService.getrole().subscribe((roles: any) => {
      this.roles = roles;

      this.registrationService
        .getmatchedjoblist(this.accountid)
        .subscribe((response: any) => {
          if (response == 1) {
            this.joblist = 1;
          } else {
            this.joblist = response;

            console.log(this.joblist);
            this.joblist.forEach((element) => {
              this.registrationService
                .getpayscale(element.payScale)
                .subscribe((data: any) => {
                  element.payScale = data;
                });
            });
            this.joblist.forEach((element) => {
              this.roles.forEach((element1) => {
                if (element['jobRole'] == element1['id']) {
                  element['jobRole'] = element1.role;
                }
              });
            });
            this.joblist.forEach((element) => {
              this.registrationService
                .getindustry(element.industry)
                .subscribe((data: any) => {
                  element.IndustryName = data.indrustType;
                });
            });

            setTimeout(() => {
              this.joblistcopy = this.joblist;
              if (this.searching) {
                this.onSearchChange(this.searchvalue);
              }
            }, 2000);
          }
          this.spinner.hide();
        });
    });
  }
  searching;
  searchvalue;
  onSearchChange(val) {
    if (val) {
      this.searching = true;
      this.joblistcopy = this.joblist
        ? this.joblist.filter(
            (item) =>
              item.jobLocation.search(new RegExp(val, 'i')) > -1 ||
              item.jobRole.search(new RegExp(val, 'i')) > -1 ||
              item.IndustryName.search(new RegExp(val, 'i')) > -1
          )
        : [];
    } else {
      this.searching = false;
      this.joblistcopy = this.joblist;
    }
  }
  selectedDetails(job) {
    this.CompanyName = job;
    console.log(this.CompanyName);
  }

  shareDetails() {
    const inputr: JobzoneShare = {
      Id: null,
      ApplicantId: this.userid,
      CompanyId: this.CompanyName.companyId,
      JobBlkId: this.CompanyName.blockId,
    };
    this.userser.sharedetails(inputr).subscribe((data: any) => {
      if (data == 1) {
        alert('Shared your details successfully');
      }
    });
  }
  descriptions(val) {
    this.Jobdescription = val;
  }
}
