import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DepartmentService } from 'app/shared/department.service';
import { RoleService } from 'app/shared/role.service';
import { Department } from 'app/shared/VIewmodels/department';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {
  closeResult: any;
  DepartmentName: any;
  departmentname: any;
  DepartmentDesc: any;
  departmentdesc: any;
  public dismissalert = false;
  notify: string;
  DepartmentList: any;
  p: number = 1;
  editdepartmentName: any;
  editdesc: any;
  editdepartmentid: any;
  udepartmentname: any;
  udepartmentdesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  RoleList: any=[];
  selectedrole: any;
  editrole: any;
  constructor(private modalService: NgbModal,private spinner: NgxSpinnerService,private departmentService: DepartmentService,
    private roleService:RoleService) { }

  ngOnInit(): void {
    this.Departments();
    this.Roles();
    this.DepartmentName = "";
    this.DepartmentDesc = "";
  }
  Roles(){
    this.spinner.show();
    this.roleService.getAllRoles().subscribe((Data: any) => {
      this.RoleList = Data;
      this.spinner.hide();
    });
  }
  onSelectedRole(id){
    this.selectedrole=id;
  }
  Departments() {
    this.spinner.show();
    this.departmentService.getAllDepartments().subscribe((Data: any) => {
      this.DepartmentList = Data;
      this.spinner.hide();
    });
  }
  addDepartment(adddepartment) {
    this.modalService.open(adddepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditDepartment(updatedata, updatedepartment) {
    this.editdepartmentName = updatedata.departmentName;
    this.editdesc = updatedata.departmentDesc;
    this.editdepartmentid = updatedata.id;
    this.editrole=updatedata.roleId;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.modalService.open(updatedepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteDepartment(deletedata, adddepartment) {
    this.DeleteName = deletedata.departmentName;
    this.deleteid = deletedata.id;
    this.modalService.open(adddepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  SaveDepartment() {
    const inputRequest: Department = {
      Id: 0,
      DepartmentName: this.departmentname,
      DepartmentDesc: this.departmentdesc,
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
      RoleId:this.selectedrole
    };
    this.spinner.show();
    this.departmentService
      .saveDepartment(inputRequest)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Department added Successfully";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Departments();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Departments();
        }
      });
  }

  UpdateDepartment() {
    if(this.selectedrole==null || this.selectedrole==""){
      this.selectedrole=this.editrole
    }
    const inputRequest1: Department = {
      Id: this.editdepartmentid,
      DepartmentName: this.editdepartmentName,
      DepartmentDesc: this.editdesc,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
      RoleId:this.selectedrole
    };
    this.spinner.show();
    this.departmentService
      .UpdateDepartment(inputRequest1)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Department Updated Succesfully";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Departments();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Departments();
          this.spinner.hide();
        }
      });
  }
  DeleteDepartmentRow() {
    this.spinner.show();
    this.departmentService
      .deleteDepartment(this.deleteid)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Department Deleted Succesfully";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Departments();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Departments();
          this.spinner.hide();
        }
      });
  }
  closealert(){
    this.dismissalert=false;
  }
  GetRole(rid) {
    for (let i = 0; i < this.RoleList.length; i++) {
      if (rid == this.RoleList[i].id) {
        return this.RoleList[i].roleName;
      }
    }
  }
}
