import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { Jobzonerolemaster } from 'app/shared/VIewmodels/Jobzonerolemaster';

@Component({
  selector: 'app-jobrolemaster',
  templateUrl: './jobrolemaster.component.html',
  styleUrls: ['./jobrolemaster.component.scss'],
})
export class JobrolemasterComponent implements OnInit {
  plantype: string;
  amount: string;
  planlist: any;
  palnid: any;
  plaid: any;

  constructor(private masterservices: MasterService, private router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('save').style.display = 'block';
      document.getElementById('update').style.display = 'none';
    }, 500);
    this.PlansList();
    this.industryname = '';
  }
  ResetData() {
    this.industryname = '';
  }
  industry: any;
  PlansList() {
    this.masterservices.getrole().subscribe((data: any) => {
      this.industry = data;
    });
  }
  industryname: any;
  SavePlanType(form: NgForm) {
    const inputRequest: Jobzonerolemaster = {
      Id: 0,
      Role: this.industryname,
    };
    this.masterservices.postjobrole(inputRequest).subscribe((data: any) => {
      if (data == null) {
        alert('Added Successfully');
        this.router.navigate(['/jobrolemaster']);
        window.location.reload();
      } else {
        alert('Something went Wrong');
        this.router.navigate(['/jobrolemaster']);
        window.location.reload();
      }
    });
  }
  instiid: any;
  editplantype(data: any) {
    document.getElementById('save').style.display = 'none';
    document.getElementById('update').style.display = 'block';
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.instiid = data.id;
    this.industryname = data.role;
  }

  UpdatePlanType(form: NgForm) {
    document.getElementById('save').style.display = 'block';
    document.getElementById('update').style.display = 'none';
    console.log(this.industryname);
    const inputRequest1: Jobzonerolemaster = {
      Id: this.instiid,
      Role: this.industryname,
    };
    this.masterservices.updatejobrole(inputRequest1).subscribe((data: any) => {
      if (data == 1) {
        alert('Role Name Updated Succesfully');
        this.PlansList();
        window.location.reload();
      } else {
        alert('Something Went Wrong. Try again !');
        window.location.reload();
      }
    });
  }
  deleteplantype(data: { id: any }) {
    this.plaid = data.id;
    this.masterservices.deletejobrole(this.plaid).subscribe((data: any) => {
      if (data != null) {
        alert('Role Deleted Succesfully');
        this.PlansList();
        window.location.reload();
      } else {
        alert('Something Went Wrong. Try again !');
        window.location.reload();
      }
    });
  }
}
