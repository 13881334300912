import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'app/shared/MailService/mail.service';
import { MovesService } from 'app/shared/MoveService/moves.service';
import { UserService } from 'app/shared/UserService/user.service';
import { Friend } from 'app/shared/VIewmodels/Friend';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-jobreadmail',
  templateUrl: './jobreadmail.component.html',
  styleUrls: ['./jobreadmail.component.scss']
})
export class JobreadmailComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  mailid: any;
  maildata: any;
  docments: boolean = false;
  Doc: any;
  imgdisplay: any;
  seleteddownload: boolean;
  closeResult: any;
  msg: any; m: string;
  date: any;
  zzz: any;
  current_url: any; displayUrl: any;
  current_url1: any;
  ID: any; pdfUrl: any; pdfUrl1: any; pdfUrl2: any; pdfUrl3: any;
  videoplay: any;
  videodate: any;
  audioplay: any;
  audiodate: any;
  memlist: any;
  tomem: any;
  replytouser: any;
  replayenable: boolean =false;
  mailsubject:any;
  message:any;
  frmDataa: FormData;
  SelectedFiles: any = [];
  selectedFriend: any = [];
  Friends: any;
  frndslist: any;
  Transid: void;
  errorMessages: string;
  email: any;
  serchfind: any;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  frndslistone: any;
  accid: any;
  forwordmaildata: any;
  selectedSelected: any;
  selectedticketid: any;
  friends: boolean;
  friend: any;
  userImage: any;
  forwardimages: boolean = false;
  UserID: string;
  ToUserfor: any = [];

  constructor(private router: ActivatedRoute, private userservice: UserService, private moveService: MovesService,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal, private domSanitizer: DomSanitizer,
    private spinner: NgxSpinnerService, private datepipe: DatePipe, private http: HttpClient,
    private mailservice: MailService) {
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.mailid = params["id"];
      }
    });

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");
      this.UserID=localStorage.getItem("uid");
    }
  }

  ngOnInit(): void {
    this.GetMailDetais();
    this.GetFriendDetails();
    this.GetFriendDetailsonee();
  }
  inbox() {
    this.route.navigate(['jobmail']);
  }
  composemail() {
    this.route.navigate(['jobcompose']);
  }
  deleteitems() {
    this.route.navigate(['jobdelete']);
  }
  sentitems() {
    this.route.navigate(['jobsent']);
  }
  archiveitems() {
    this.route.navigate(['jobarchive']);
  }
  Addnewmembers(data,newmember) {
    this.forwordmaildata = data;
    this.modalService.open(newmember).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  GetMailDetais() {
   // this.spinner.show();
    var info = {
      UID: this.mailid,
      FromId: this.UserID
    }
    this.mailservice.getmaildetailsforjob(info).subscribe((respose: any) => {
      this.maildata = respose;
      this.Transid = this.maildata[0].trnsid;
      this.mailsubject = this.maildata[0].subject;
      if(this.maildata[0].images != null){
        this.forwardimages = true;
        this.message = this.maildata[0].message;
        this.userImage = this.maildata[0].images;
      }
      console.log(respose)
      this.date = this.maildata.date;
      this.memlist = this.maildata.memlist;


      for (let index = 0; index < this.memlist.length; index++) {
        if (index == 0) {
          this.tomem = this.memlist[index].useName;
        }
        else {
          this.tomem = this.tomem + " , " + this.memlist[index].useName;
        }
      }
      if (respose.documents != null) {
        this.Doc = respose.documents;
        console.log(this.Doc);
        this.docments = true;
      }
      console.log(respose);
      this.spinner.hide();
    });
  }
  GetFriendDetailsonee() {
    this.userservice.friendlist(this.GUID).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslistone = this.Friends.friendsList;
      // console.log(this.frndslist);
      var idd = this.accid;

      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }
  GetFriendDetails() {
    this.userservice.GetFriendRequests(this.GUID).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends;
      console.log(this.frndslist);
      // console.log(this.frndslist);
      var idd = this.accid;
      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
    });
  }
  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      if (this.frndslist[i].Email == value.display) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }

  // onItemAdded(value: any) {
  //   if (value.Email == undefined) {
  //     this.email = value.value;
  //   }
  //   else {
  //     this.email = value.Email;
  //   }
  //   this.serchfind = this.regexp.test(this.email);
  //   if (!this.serchfind) {
  //     this.errorMessages = "Please Enter valid Email";
  //   }
  //   if (value.uid != null) {
  //     this.selectedFriend.push(value);
  //   }
  //   else {
  //     this.selectedFriend.push(value.value);
  //   }
  // }
  onItemAdded(value: any) {
    if (value.email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend.push(value);
    }
    else{
      alert("OOPS!! This person not in your Friends List.Let us add him/her Click Friend Request.");
      this.friends = true;   
        this.friend = this.email; 
      this.selectedFriend="";
 // this.savefrienddetails();
    }
    // else {
    //   this.selectedFriend.push(value.email);
    // }
  }
  attachmentmail(){
    var det = {
      TicketId: this.selectedticketid,
      FromId: this.uid,
      MultiToUser: this.ToUserfor,
      ToUserfor:this.ToUserfor,
     
      Blocdrivedoc:this.selectedSelected,
      FromuserGUID: this.GUID,
      MailId: this.forwordmaildata.mailId,
      TransId:this.forwordmaildata.trnsid,
      Message:this.message
      //Externalmail:null,
    }
    //this.frmDataa.append("Drive", this.selectedSelected[i]);
    this.frmDataa.append("MessageData", JSON.stringify(det));
    this.frmDataa.append("fileUpload", "SelectedFiles");
  
   // this.spinner.show();
    this.httpService.post("https://blockchainmatrimony.com/jobzoneapi" + '/api/UploadFile/forwardmailattachment/', this.frmDataa).subscribe(
      data => {
        this.spinner.hide();
        this.selectedFriend= this.selectedSelected;
       // alert("Mail Sent Successfully"); 
        this.mailsubject = "";
        this.message = "";
        window.location.reload();
        // this.route.navigate(['sentmails']);
      
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });

  }
  savefrienddetails() {
   // this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount:this.friend,
      UserId: this.uid,
      Status: 4,
      IsEnabled: true,
     // GUID: this.GUID

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");
        this.friends = false;
      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using blocdrive!!");
        this.friends = false;
      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");
        this.friends = false;
      }
      else if (data == "1") {
        alert("Friend Request Sent Successfully!");
        this.friends = false;
        const audit = {
          TransactionName:"Friend Request",
          TransactionType:"Send",
          TransactionDesc:"Friend Request",

          }
         // this.auditortransactionservice.AuditorTransactionDetails(audit);
      }
      else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";

    });
  }

  forword(){
    this.attachmentmail();
    var aa={
      MultiToUser: this.ToUserfor,
      ToUserfor:this.ToUserfor,
      
      MailId: this.forwordmaildata.mailId,
      TransId:this.forwordmaildata.trnsid,
      Id:this.forwordmaildata.id
    };
    this.mailservice.postaddnewebers(aa).subscribe((respose: any) => {
     if(respose =="Succ"){
      alert("Mail Forwarded Successfully");
      const audit = {
        TransactionName:"Mail",
        TransactionType:"Forward",
        TransactionDesc:"Forwarded Mail",

        }
        //this.auditortransactionservice.AuditorTransactionDetails(audit);
     }
     else{
      alert("Something went worng");
     }

    });
  }
  replay(mail , aa){
    // var qq = document.getElementById(mail.id);
    // qq.setAttribute(visibility,"visible");
    this.replayenable = true;
    this.replytouser = "";
   
    if(aa == 'single'){
      this.replytouser = mail.fromName;
      this.selectedFriend.push(mail.toUser);
    }
    else if(aa == 'all'){
      this.replytouser = mail.fromName;
      this.selectedFriend.push(mail.fromId);
      for (let index = 0; index < mail.memlist.length; index++) {
        if ( mail.memlist[index].uid != this.UserID) {
          this.replytouser = this.replytouser + " , " + mail.memlist[index].useName;
          this.selectedFriend.push(mail.memlist[index].uid);
        }         
      }
    }
 
    window.scrollTo(0,document.body.scrollHeight);

    
  }

  onChangeDocumnets(event: any) {
    this.frmDataa = new FormData();
    let files = [].slice.call(event.target.files);
    for (var i = 0; i < files.length; i++) {
      this.SelectedFiles.push(files[i]);
      this.frmDataa.append("Docs", files[i]);
    }
  }
  sendmail(){
    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var det = {
      TicketId:null,
      FromId: this.UserID,
      ReplyMultiToUser: this.selectedFriend,
      Subject: this.mailsubject,
      Message: this.message,
      FromuserGUID: this.GUID,
      MailId: this.mailid,
      TransId:this.Transid,

    }
    this.frmDataa.append("MessageData", JSON.stringify(det));
    //// this.spinner.show();
    this.httpService.post("https://blockchainmatrimony.com/jobzoneapi" + '/api/JobMailSystem/PostSaveReplayMailforjob/', this.frmDataa).subscribe(
      data => {
        // this.spinner.hide(); 
        alert("Mail Sent Successfully");
        const audit = {
          TransactionName:"Mail",
          TransactionType:"Send",
          TransactionDesc:"Reply Mail",

          }
         // this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.route.navigate(['jobmail']);
        this.mailsubject = "";
        this.message = "";
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }
  ViewImageDoc(aa, content) {
    if (aa.pdfFile != null) {
      // this.ViewDoc(aa)
    }
    else {
      this.imgdisplay = aa;
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
      });
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ViewDocument(aa, pdftesting) {
    if (aa.pdfFile != null) {
      // this.ViewDoc(aa)
    }
    else {

      this.zzz = aa;
      this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
      console.log(this.pdfUrl);
      this.pdfUrl = this.zzz.doclink;
      // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

      this.modalService.open(pdftesting).result.then((result) => {

        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
        console.log(this.m)

      });
    }
  }
  ViewVideo(cc, videocontent) {
    this.videoplay = cc.doclink
    this.videodate = this.date;
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }

  Viewaudio(cc, videocontent) {
    this.audioplay = cc.doclink
    this.audiodate = this.date;
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }
  MailFriend(){
    this.route.navigate(['/friendrequest']).then(()=>{
      window.location.reload();
    })
  }
}