import { Component, OnInit } from '@angular/core';
import { ChatDetail } from 'app/shared/VIewmodels/ChatDetail';
import { UserService } from 'app/shared/UserService/user.service';
import { Router } from '@angular/router';
import { ModalServiceService } from 'app/shared/Modalpopup/modal-service.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserId } from 'app/shared/VIewmodels/UserId';
import { IndividualChatDetails } from 'app/shared/VIewmodels/IndividualChatDetail';
import { NgForm } from '@angular/forms';
import { Friend } from 'app/shared/VIewmodels/Friend';

@Component({
  selector: 'app-studentchat',
  templateUrl: './studentchat.component.html',
  styleUrls: ['./studentchat.component.scss']
})
export class StudentchatComponent implements OnInit {
  b: any[] = [1, 2, 8, 5];

  className: string;
  chatdetail: ChatDetail;
  Users: any = [];
  userid: any;
  isLoggedIn: boolean;
  fullName: string;
  touserid: any;
  chatopen: boolean;
  IndividualChatDetail: any;
  uid: boolean;
  chattoid: any;
  chatvar: any;
  chattouserid: any;
  chaatto: any;
  aaaaa: ChatDetail;
  IndividualChatRecivedDetails: any;
  IndividualChatSentDetails: any;
  UserImage: any;
  BMid: string;
  touser: any;
  currentuser: string;
  data: any;
  chtnm: any;
  cnnTableData: any = [];
  closeResult: string;
  accid: any;
  m: any;
  Friends: any;
  accountid: string;
  constructor(
    private userservice: UserService,
    private router: Router,
    private modalService1: ModalServiceService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {
    window.scrollTo(0,0);

    // this.spinner.hide();
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem("userId");
      this.accountid = localStorage.getItem("uid");
    }
    this.isLoggedIn = true;
    this.userid = Number(localStorage.getItem("userId"));
    this.currentuser = localStorage.getItem("UserName");
    this.BMid = localStorage.getItem("uid");
  }

  scrollFunction() {
    if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 5) {
      document.getElementById("nb").style.top = "0";
    } else {
      document.getElementById("nb").style.top = "-50px";
    }
    this.data.sort(
      (a, b) =>
        new Date(b.CREATE_TS).getTime() - new Date(a.CREATE_TS).getTime()
    );
  }

  ngOnInit() {
  
    this.loadChatDetail();
    this.GetDetails();
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if (footer.style.display == "none") {
        footer.style.display = "block";
      }
    }, 1000);
  }

  OnSelectUser(id) {
    this.touserid = id;
    this.userservice.GetToUser(this.touserid).subscribe((respose: any) => {
      this.touser = respose;
    });
  }
  messa: any;
  SaveUserChat() {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: this.currentuser,
      ChatTo: this.touserid,
      ChatToName: this.touser.userName,
      ChatMessage: this.messa,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.touserid,
    };
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Message Sent Successfully");
      }
    });
  }



  GetCurrentUserImage() {
    this.userservice
      .getCurrentUSerImage(this.userid)
      .subscribe((respose: any) => {
        this.UserImage = respose;
      });
  }

  loadChatDetail() {
    this.userservice
      .GetChatforParticularUser(this.userid)
      .subscribe((chat: any) => {
        this.chatdetail = chat;
      });
  }

  OpenChatWindow(chat) {
    this.chatvar = chat;
    this.chattouserid = chat.chatFrom;
    this.chaatto = chat.userID;
    this.chatopen = true;
    const inputRequest: IndividualChatDetails = {
      Chatid: chat.id,
      ChatFrom: chat.chatFrom,
      ChatTo: chat.userID,
    };
    this.userservice
      .GetIndividualChatRecivedDetails(inputRequest)
      .subscribe((data: any) => {
        // this.IndividualChatRecivedDetails = data.recivedchatdetails;
        this.IndividualChatSentDetails = data;
        console.log(this.IndividualChatSentDetails);
        for (let i = 0; i < this.IndividualChatSentDetails.length; i++) {
          if (
            this.IndividualChatSentDetails[i].chatFromName != this.currentuser
          ) {
            console.log(this.IndividualChatSentDetails);
            this.chtnm = this.IndividualChatSentDetails[i].chatFromName;
            break;
          }
        }

        if (this.userid == chat.chatTo) {
          this.uid = true;
        }
      });
  }
  goto() {
    this.router.navigate(["chat"]);
  }
  SendIndividualMessage(form: NgForm) {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: "",
      ChatTo: this.chaatto,
      ChatToName: "",
      ChatMessage: form.controls["individualchatmessage"].value,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.chattouserid,
    };
    this.aaaaa = inputRequest;
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.OpenChatWindow(this.chatvar);
        (<HTMLInputElement>(
          document.getElementById("individualchatmessage")
        )).value = "";
      }
    });
  }

  CloseChatWindow() {
    this.chatopen = false;
  }
  GetDetails() {
    this.userservice.friendlist(this.userid).subscribe((respose: any) => {
      this.Friends = respose;
      this.spinner.hide();
    });
  }

  addFriend(addfriendcontent) {
    this.modalService.open(addfriendcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.accid;
      }
    );
  }
  savefrienddetails() {
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
    };
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");
        this.GetDetails();
      } else if (data == "alreadyfriends") {
        alert("You are already friends!!");
        this.GetDetails();
      } else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using jobs-zone!");
        this.GetDetails();
      } else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!");
        this.GetDetails();
      } else if (data == "1") {
        alert("Friend Request Sent successfully");
        this.GetDetails();
      } else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
    });
  }
  acceptrequest(f) {
    this.userservice.AcceptReq(f).subscribe((data: any) => {
      alert("Accepted Successfully");
      // this.route.navigate(['/allfolders']);
      this.GetDetails();
    });
  }

  ViewDocument(bb, pdfcontent) {
    this.modalService.open(pdfcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = "yvtfcfu";
      }
    );
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService1.close(id);
  }
}