import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AllservicesService {
  readonly url = 'https://blockchainmatrimony.com/jobzoneapi/api';

  constructor(private http: HttpClient) {}
  getallstudents(id) {
    return this.http.get(this.url + '/Account/GetAllUser/' + id);
  }
}
