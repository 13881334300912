import { Component, OnInit } from '@angular/core';
import * as PIXI from "pixi.js-legacy";
import { TweenMax } from "gsap";

@Component({
  selector: 'app-heroanimation',
  templateUrl: './heroanimation.component.html',
  styleUrls: ['./heroanimation.component.scss']
})
export class HeroanimationComponent implements OnInit {
  width: any;
  height: any;
  constructor() {
    this.width = 800;
    this.height = 800;
  }

  ngOnInit(): void {
    const app = new PIXI.Application({
      width: this.width,
      height: this.height,
      transparent:true,


    });

    const sprite = PIXI.Sprite.from('assets/img/desktop.svg');
    sprite.position.x = -270;
    sprite.position.y = -100;

    app.stage.addChild(sprite);
    document.getElementById("anima").appendChild(app.view);

    // Create a new texture
    const texture = PIXI.Texture.from("assets/img/file.svg");

    // time animation in seconds
    const time = 1.5;


    // const bunny1 = new PIXI.Sprite(texture);
    // app.stage.addChild(bunny1);

    // TweenMax.to(bunny1, time, { x: 500, repeat: -1, yoyo: true });

    // const bunny = new PIXI.Sprite(texture);
    // bunny.y = 600;
    // app.stage.addChild(bunny);

    // TweenMax.to(bunny, time, { alpha: 0.0, repeat: -1, yoyo: true });

    //   const bunny3 = new PIXI.Sprite(texture);
    //   bunny3.y = 200;
    //   app.stage.addChild(bunny3);

    //   TweenMax.to(bunny3.scale, time, {
    //     x: 2.0, y: 2.0, repeat: -1, yoyo: true,
    // });

    // const bunny4 = new PIXI.Sprite(texture);
    // bunny4.y = 350;
    // bunny4.x = 100;
    // bunny4.anchor.set(0.5, 0.5);
    // app.stage.addChild(bunny4);

    // TweenMax.to(bunny4, time, { rotation: 2 * Math.PI, repeat: -1, yoyo: true });




    function resize() {
      // app.view.style.position = 'absolute';
      //app.view.style.left = ((window.innerWidth) >> 1) + 'px';
      
      app.view.style.width = "100%";

    }

    if (window.innerWidth >= 500) {
      resize();
      window.addEventListener("resize", resize);
    } else {
      //  app.view.style.marginLeft= '-51%';
      app.view.style.position = "absolute";
      app.view.style.width = "100%";
    }
  
     //  resize();
     //  window.addEventListener('resize', resize);

    let colorBunny = null;

    setTimeout(() => {
      createNewBunny();
    }, 1000);

    setTimeout(() => {
      createNewBunny1();
    }, 3000);
    setTimeout(() => {
      createNewBunny2();
    }, 5000);

    setTimeout(() => {
      createNewBunny3();
    }, 7000);

    setTimeout(() => {
      createNewBunny4();
    }, 9000);


    setInterval(() => {
      setTimeout(() => {
        createNewBunny();
      }, 1000);

      setTimeout(() => {
        createNewBunny1();
      }, 3000);
      setTimeout(() => {
        createNewBunny2();
      }, 5000);

      setTimeout(() => {
        createNewBunny3();
      }, 7000);

      setTimeout(() => {
        createNewBunny4();
      }, 9000);
    }, 9500);
    function createNewBunny() {
      if (colorBunny) {
        app.stage.removeChild(colorBunny);
      }

      colorBunny = new PIXI.Sprite(texture);
      colorBunny.y = 320;
      colorBunny.x = 320;
      colorBunny.tint = `0x${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      app.stage.addChild(colorBunny);
      TweenMax.to(colorBunny, time, { y: 530, x: 0, alpha: 0.0 });
    }

    let colorBunny1 = null;

    function createNewBunny1() {
      if (colorBunny1) {
        app.stage.removeChild(colorBunny1);
      }

      colorBunny1 = new PIXI.Sprite(texture);
      colorBunny1.y = 550;
      colorBunny1.x = 30;
      colorBunny1.tint = `0x${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      app.stage.addChild(colorBunny1);
      TweenMax.to(colorBunny1, time, { x: 40, y: 200, alpha: 0.0 });
    }

    let colorBunny2 = null;

    function createNewBunny2() {
      if (colorBunny2) {
        app.stage.removeChild(colorBunny2);
      }

      colorBunny2 = new PIXI.Sprite(texture);
      colorBunny2.y = 200;
      colorBunny2.x = 30;
      colorBunny2.tint = `0x${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      app.stage.addChild(colorBunny2);
      TweenMax.to(colorBunny2, time, { x: 380, y: 30, alpha: 0.0 });
    }
    let colorBunny3 = null;

    function createNewBunny3() {
      if (colorBunny2) {
        app.stage.removeChild(colorBunny3);
      }

      colorBunny3 = new PIXI.Sprite(texture);
      colorBunny3.y = 40;
      colorBunny3.x = 380;
      colorBunny3.tint = `0x${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      app.stage.addChild(colorBunny3);
      TweenMax.to(colorBunny3, time, { x: 730, y: 230, alpha: 0.0 });
    }

    let colorBunny4 = null;

    function createNewBunny4() {
      if (colorBunny4) {
        app.stage.removeChild(colorBunny4);
      }

      colorBunny4 = new PIXI.Sprite(texture);
      colorBunny4.y = 230;
      colorBunny4.x = 710;
      colorBunny4.tint = `0x${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      app.stage.addChild(colorBunny4);
      TweenMax.to(colorBunny4, time, { x: 710, y: 550, alpha: 0.0 });
    }

  


  }

}