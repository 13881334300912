<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>

<app-nav-bar></app-nav-bar>

<h2 style="text-align: center;">Role List</h2>
<div>&nbsp;</div>
<div class="row col-sm-12">
    <div class="col-sm-1"></div>
    <div class="col-sm-2">
        <button class="btn btn-primary" style="width: 8vw;" (click)="addRole(addrole)">
            Add
        </button>
    </div>
    <div class="col-sm-9"></div>
</div>
<br />
<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
        <div>
            <div style="overflow-x: visible; height: 300px;">
                <table class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>Role Name</th>
                        <th>Created By</th>
                        <th>Created On</th>
                        <th>Modified By</th>
                        <th>Modified On</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                    <tr *ngFor="
              let aa of RoleList
                | paginate: { itemsPerPage: 5, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{ aa.roleName }}</td>
                        <td>{{ aa.createdBy }}</td>
                        <td>{{ aa.createdOn | date: "short" }}</td>
                        <td>{{ aa.modifiedBy }}</td>
                        <td>{{ aa.modifiedOn | date: "short" }}</td>
                        <td>
                            <button class="btn btn-success" (click)="EditRole(aa, updaterole)">
                                Edit
                            </button>
                        </td>
                        <td>
                            <button class="btn btn-danger" (click)="deleteRole(aa, deleterole)">
                                Delete
                            </button>
                        </td>
                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event" style="float: right;"></pagination-controls>
                <br /><br />
                <br /><br />
            </div>
        </div>
    </div>
</div>
<br /><br />



<ng-template #addrole let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Role</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Role Name:</h5>
        <input type="text" id="rolename" [(ngModel)]="rolename" class="form-control" name="dp" />

    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="SaveRole()" (click)="d('Cross click')" style="color: black; cursor: pointer;">
            Save
        </button>
    </div>
    <br />
</ng-template>
<ng-template #updaterole let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Role</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Role Name:</h5>
        <input type="text" id="editroleName" [(ngModel)]="editroleName" class="form-control" name="dp" />

    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="UpdateRole()" (click)="d('Cross click')" style="color: black; cursor: pointer;">
            Update
        </button>
    </div>
    <br />
</ng-template>
<ng-template #deleterole let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Role</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="DeleteRoleRow()" (click)="d('Cross click')" style="color: black; cursor: pointer;">
            Ok
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="color: black; cursor: pointer;">
            Cancel
        </button>
    </div>
    <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
                <i class="material-icons" style="color: white;">close</i>
            </button>
            <span style="color: white; margin-top: 6px;">
                <b style="text-transform: capitalize; font-size: 14px;">
                    {{ notify }}</b></span>
        </div>
    </div>
</div>