import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlaytypeJobPortalVM } from '../VIewmodels/PlaytypeJobPortalVM';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  // readonly rooturl1 = "https://blockchainmatrimony.com/jobzoneapi/api";
  readonly rooturl1 = 'https://blockchainmatrimony.com/jobzoneapi/api';
  constructor(private http: HttpClient) {}

  savePlanType(data: PlaytypeJobPortalVM) {
    return this.http.post(
      this.rooturl1 + '/Payment/PostSaveSeekerPayUplan',
      data
    );
  }

  getPlanType() {
    return this.http.get(this.rooturl1 + '/Payment/GetListofPayUplans');
  }
  deletePlantype(id: number) {
    return this.http.get(this.rooturl1 + '/Payment/DeletePlan/' + id);
  }
  deleteinstitution(id: number) {
    return this.http.get(this.rooturl1 + '/Master/deleteInstitution/' + id);
  }
  deleterole(id: number) {
    return this.http.get(this.rooturl1 + '/Master/deleteRole/' + id);
  }
  UpdatePlanType(data: PlaytypeJobPortalVM) {
    return this.http.put(
      this.rooturl1 + '/Payment/PutUpdateSeekerPayUplan',
      data
    );
  }
  Updateinstitution(data: any) {
    return this.http.put(this.rooturl1 + '/Master/PutUpdateInstitution', data);
  }
  Updaterole(data: any) {
    return this.http.put(this.rooturl1 + '/Master/PutUpdateRole', data);
  }
  savePlanTypeCompany(data: PlaytypeJobPortalVM) {
    return this.http.post(
      this.rooturl1 + '/Payment/PostSaveCompanyPayUplan',
      data
    );
  }
  getPlanTypeCompany() {
    return this.http.get(this.rooturl1 + '/Payment/GetListofCompanyPayUplans');
  }
  deletePlantypeCompany(id: number) {
    return this.http.get(this.rooturl1 + '/Payment/DeleteCompanyPlan/' + id);
  }

  UpdatePlanTypeCompany(data: PlaytypeJobPortalVM) {
    return this.http.put(
      this.rooturl1 + '/Payment/PutUpdateCompanyPayUplan',
      data
    );
  }

  getindustry() {
    return this.http.get(this.rooturl1 + '/Master/GetIndrustyMasters');
  }
  getsalary() {
    return this.http.get(this.rooturl1 + '/Master/GetsalaryMasters');
  }
  getrole() {
    return this.http.get(this.rooturl1 + '/Master/GetJobRoleMasters');
  }
  getperks() {
    return this.http.get(this.rooturl1 + '/Master/GetPerks');
  }
  getgrowth() {
    return this.http.get(this.rooturl1 + '/Master/GetGrowths');
  }
  registerApplicant(data: any) {
    return this.http.post(
      this.rooturl1 + '/ApplicantRegister/RegisterOne',
      data
    );
  }
  getjobzonerole() {
    return this.http.get(this.rooturl1 + '/Account/getjobzonerole');
  }
  getjobzoneinstitution() {
    return this.http.get(this.rooturl1 + '/Account/getjobzoneinstitution');
  }
  getjobpostsingledetails(id) {
    return this.http.get(
      this.rooturl1 + '/CompanyRegistration/GetallSingleData/' + id
    );
  }

  postinstitution(data: any) {
    return this.http.post(this.rooturl1 + '/Master/postInstitution', data);
  }

  postjobzonerole(data: any) {
    return this.http.post(this.rooturl1 + '/Master/postrole', data);
  }

  postindustry(data) {
    return this.http.post(this.rooturl1 + '/Master/postindustry', data);
  }
  Updateindustry(data: any) {
    return this.http.put(this.rooturl1 + '/Master/Updateindustry', data);
  }
  deleteIndustry(id: number) {
    return this.http.get(this.rooturl1 + '/Master/deleteindustry/' + id);
  }
  postjobrole(data) {
    return this.http.post(this.rooturl1 + '/Master/postjobrole', data);
  }
  updatejobrole(data: any) {
    return this.http.put(this.rooturl1 + '/Master/UpdateJobrole', data);
  }
  deletejobrole(id: number) {
    return this.http.get(this.rooturl1 + '/Master/deletejobrole/' + id);
  }
  getgender() {
    return this.http.get(this.rooturl1 + '/Master/GetGender');
  }


// added
  getlocation() {
    return this.http.get(this.rooturl1 + '/Master/GetLocation');
   }
   postlocation(data) {
    return this.http.post(this.rooturl1 + '/Master/postlocation', data);
   }
   Updatelocation(data: any) {
    return this.http.put(this.rooturl1 + '/Master/Updatelocation', data);
  }
   deletelocation(id: number) {
    return this.http.get(this.rooturl1 + '/Master/deletelocation/' + id);
   }
  getSkill() {
    return this.http.get(this.rooturl1 + '/Master/GetSkillMaster');
   }
   postSkill(data) {
    return this.http.post(this.rooturl1 + '/Master/postSkillMaster', data);
   }
   UpdateSkill(data: any) {
    return this.http.put(this.rooturl1 + '/Master/UpdateSkillMaster', data);
  }
   deleteSkill(id: number) {
    return this.http.get(this.rooturl1 + '/Master/deleteSkillMaster/' + id);
  }
  getYears() {
    return this.http.get(this.rooturl1 + '/Masters/GetYears');
   }
}
