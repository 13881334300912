<!-- <ng4-loading-spinner> </ng4-loading-spinner> -->





<header style="position: fixed;" id="nav">
    <a [routerLink]="['/newhome']" style="z-index: 1000;">
        <img src="assets/img/jobzone.svg" width="150px" height="50px" alt="Jobzone">
    </a>
    <nav>
        <ul>
            <li id="company">
                <a (click)="itsUserorCompany('company')" *ngIf="!isLogged && !admin"
                    style="cursor: pointer;">Company</a>
            </li>
            <li id="PO">
                <a (click)="itsUserorCompany('PLacement Officer')" *ngIf="!isLogged && !admin"
                    style="cursor: pointer;">Placement Officer</a>
            </li>

            <li id="applicant">
                <a (click)="itsUserorCompany('jobseeker')" *ngIf="!isLogged && !admin"
                    style="cursor: pointer;">Applicants
                </a>
            </li>
            <li>
                <a [routerLink]="['/newhome']" *ngIf="!admin" (click)="togglem()">Home</a>
            </li>

            <li class="sub-menu" *ngIf="isLogged && !admin">
                <a>Safe &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                <ul>
                    <li>
                        <a [routerLink]="['/allfolders']" (click)="togglem()">My Objects</a>
                    </li>
                    <li><a [routerLink]="['/shared']" (click)="togglem()">Shared</a></li>
                    <li>
                        <a [routerLink]="['/deleted']" (click)="togglem()">Deleted Object</a>
                    </li>
                    <li>
                        <a [routerLink]="['/studentchat']" (click)="togglem()">Chat</a>
                    </li>
                    <li>
                        <a [routerLink]="['/videointerview']" (click)="togglem()">Video Conferencing</a>
                    </li>
                    <li>
                        <a [routerLink]="['/physicalvideoconference']" (click)="togglem()">Physical Video Recording</a>
                    </li>
                    <li>
                        <a [routerLink]="['/friendrequest']" (click)="togglem()">Friend Request</a>
                    </li>
                </ul>
            </li>
            <li class="sub-menu" *ngIf="isLogged && !admin && role != '2'">
                <a>MailBox&nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                <ul>
                    <li>
                        <a [routerLink]="['/inboxmails']" (click)="togglem()">Inbox</a>
                    </li>
                    <li><a [routerLink]="['/sentmails']" (click)="togglem()">Sent</a></li>
                    <li>
                        <a [routerLink]="['/del']" (click)="togglem()">Deleted</a>
                    </li>
                   
                </ul>
            </li>

            <li class="sub-menu" *ngIf="isLogged && !admin && role == '2'">
                <a>MailBox&nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                <ul>
                    <li>
                        <a [routerLink]="['/inboxmails']" (click)="togglem()">Inbox</a>
                    </li>
                    <li><a [routerLink]="['/sentmails']" (click)="togglem()">Sent</a></li>
                    <li>
                        <a [routerLink]="['/del']" (click)="togglem()">Deleted</a>
                    </li>
                   
                </ul>
            </li>
            <!-- <li class="sub-menu" *ngIf="isLogged && !admin"><a>Safe &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                        <ul>
                            <li><a [routerLink]="['/allfolders']" (click)="togglem()">My Objects</a> </li>
                            <li><a [routerLink]="['/shared']" (click)="togglem()">Shared</a></li>
                            <li><a [routerLink]="['/deleted']" (click)="togglem()">Deleted Object</a></li>
                            <li><a [routerLink]="['/studentchat']" (click)="togglem()">Chat</a></li>
                        </ul>
                    </li> -->
            <li class="sub-menu" *ngIf="isLogged && !admin && role == '4'">
                <a>Invite &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                <ul>
                    <li>
                        <a (click)="Modalpopup(InviteInternship)" style="cursor: pointer;" (click)="togglem()">Invite
                            For Internship</a>
                    </li>
                    <li>
                        <a (click)="Modalpopup(InviteTraining)" style="cursor: pointer;" (click)="togglem()">Invite For
                            Training</a>
                    </li>
                    <li>
                        <a (click)="Modalpopup(InviteJob)" style="cursor: pointer;" (click)="togglem()">Invite For
                            Job</a>
                    </li>
                    <li>
                        <a (click)="Modalpopup(InviteStudents)" style="cursor: pointer;" (click)="togglem()">Invite
                            Students</a>
                    </li>
                </ul>
            </li>

            <li class="sub-menu" *ngIf="isLogged && !admin && role == '4'">
                <a>Postings &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                <ul>
                    <li>
                        <a [routerLink]="['/internview']" (click)="togglem()">Internships</a>
                    </li>
                    <li>
                        <a [routerLink]="['/trainings']" (click)="togglem()">Trainings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/jobviewforins']" (click)="togglem()">Jobs</a>
                    </li>
                </ul>
            </li>

            <!-- <li><a (click)="plans()" *ngIf="isLogged && role =='2'" style="cursor: pointer;">PayU</a></li> -->
            <li>
                <a [routerLink]="['/jobposting']" *ngIf="isLogged && role == '2'" style="cursor: pointer;">Post Job</a>
            </li>

            <li>
                <a [routerLink]="['/joblist']" *ngIf="isLogged && role == '2'" style="cursor: pointer;">Jobs Posted</a>
            </li>
            <!-- <li><a [routerLink]="['/applicantshare']" *ngIf="isLogged && role =='2'" style="cursor: pointer;">Applicants</a></li> -->
            <!-- <li>
                <a [routerLink]="['/alert']" *ngIf="isLogged && role == '3' && !institution"
                    style="cursor: pointer;">Alert</a>
            </li> -->
            <li>
                <a [routerLink]="['/appjobseek']" *ngIf="isLogged && role == '3'"
                    style="cursor: pointer;">Jobs</a>
            </li>
            <!-- <li>
                <a [routerLink]="['/jobviewforins']" *ngIf="isLogged && role == '3' && institution"
                    style="cursor: pointer;">Jobs</a>
            </li> -->

            <li>
                <a [routerLink]="['/newsignin']" (click)="togglem()" *ngIf="!isLogged && !admin">Sign In</a>
            </li>
            <li>
                <a [routerLink]="['/jobposting']" (click)="togglem()" *ngIf="admin">Post Job</a>
            </li>
            <li>
                <a [routerLink]="['/joblist']" (click)="togglem()" *ngIf="admin">Jobs Posted</a>
            </li>
            <li (click)="togglemenu()" id="safe" *ngIf="admin">
                <a>Masters</a>
                <ul id="dropdown-menu">
                    <!-- <li>
                                        <a [routerLink]="['/institutionmaster']" style="cursor: pointer;">Institution Masters</a
            >
          </li>
          <br /> -->
                    <!-- <li>
            <a [routerLink]="['/approlemaster']" style="cursor: pointer;"
              >PerluKerja Applicant Role Master</a
            >
          </li> 
          <br />-->
                    <li>
                        <a [routerLink]="['/jobrolemaster']" style="cursor: pointer;">Jobrole Master</a>
                    </li>
                 
                    <li>
                        <a [routerLink]="['/industrymaster']" style="cursor: pointer;">Industry Master</a>
                    </li>
                    <li><a [routerLink]="['/institutionmaster']" style="cursor: pointer;">Institution Masters</a></li>
                
                    <li><a [routerLink]="['/approlemaster']" style="cursor: pointer;">Jobs-zone Applicant Role Master</a>
                    </li>
                    <li><a [routerLink]="['/paymentchanges']" style="cursor: pointer;">Payment Master</a></li>
                    
                    <li><a [routerLink]="['/skill']" style="cursor: pointer;">Skill Master</a>
                    </li>
                    <li><a [routerLink]="['/location1']" style="cursor: pointer;">Location Master</a>
                    </li>
                    
                    <br>
                    <!-- <li>
            <a [routerLink]="['/paymentchanges']" style="cursor: pointer;"
              >Payment Master</a
            >
          </li> -->
                    <br />
                    <!-- <li><a [routerLink]="['/filerequests']" style="cursor: pointer;">Object Request</a></li><br>
                                    <li><a [routerLink]="['/delete']" style="cursor: pointer;">Deleted Object</a></li><br>
                                    <li><a [routerLink]="['/friends']" style="cursor: pointer;">Chat</a></li><br> -->
                </ul>
            </li>
            <li (click)="togglemenu1()" id="safe" *ngIf="admin">
                <a>Profile</a>
                <ul id="dropdown-menu1">
                    <!-- <li>
                                                            <a [routerLink]="['/institutionmaster']" style="cursor: pointer;">Institution Masters</a
                                >
                              </li>
                              <br /> -->
                    <!-- <li>
                                <a [routerLink]="['/approlemaster']" style="cursor: pointer;"
                                  >PerluKerja Applicant Role Master</a
                                >
                              </li> 
                              <br />-->
                    <li>
                        <a [routerLink]="['/admindashboard']" style="cursor: pointer;">Dashboard</a>
                    </li>
                    <br>
                    <li>
                        <a (click)="logoutadmin()" style="cursor: pointer;">Logout</a>
                    </li>

                    <br />
                </ul>
            </li>
         
            <li>
                <a style="text-transform: capitalize; cursor: pointer; color: #ffffff;" id="profile"
                    *ngIf="isLogged && !admin" (click)="toggleprofile()">{{ FirstName }}</a>
                <ul id="profile-menu">
                    <li>
                        <a [routerLink]="['/appprofile']" style="cursor: pointer;" *ngIf="role == '3'">Profile</a>
                    </li>
                    <li>
                        <a [routerLink]="['/report']" style="cursor: pointer;" *ngIf="role == '4'">Dashboard</a>
                    </li>
                    <li>
                        <a [routerLink]="['/placementprofile']" style="cursor: pointer;" *ngIf="role == '4'">Profile</a>
                    </li>
                    <li>
                        <a [routerLink]="['/companyprofile']" style="cursor: pointer;" *ngIf="role == '2'">Profile</a>
                    </li>
                 
                    <br />
                </ul>
            </li>
            <li><a (click)="logout()"  *ngIf="isLogged && !admin" style="cursor: pointer;">Sign Out</a></li>

        </ul>
    </nav>
    <div class="menu-toggle" id="remove">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
</header>


<router-outlet>
    <ngx-spinner></ngx-spinner>
</router-outlet>
<!-- <div class="back-to-top js-back-to-top">
    <span class="fa fa-angle-double-up back-to-top__icon"></span>
    <span class="back-to-top__text">TOP</span>
</div> -->
<!-- <footer class="footer" id="footerremove" style="background-color: crimson;">
    <div class="container" style="color: white;">
        <div class="row">
            <div class="col-lg-3">
                <div class="footer__widget">
                    <a href="#" class="brand">
                        <h1 style="color: white;">Jobs-Zone </h1>
                    </a>
                    <p>
                        Srichid Blockchain Services <br /> Bangalore, India.
                    </p>
                    <div class="footer__widget-contact">
                        <i class="fa fa-phone"></i>
                        <p>+91 80 2685 2070</p>
                    </div>
                    <div class="footer__widget-contact">
                        <i class="fa fa-envelope"></i>
                        <p>jobzone@srichid.academy</p>
                    </div>
                </div>
                <ul class="footer__widget-network">
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=100068265824948" class="footer__widget-network-link"><i class="fa fa-facebook"></i
            ></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/Jobszone5" class="footer__widget-network-link"><i class="fa fa-twitter"></i
            ></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/jobs-zone-b32ba81ba/" class="footer__widget-network-link"><i class="fa fa-linkedin"></i
            ></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/jobs__zone/" class="footer__widget-network-link"><i class="fa fa-instagram"></i
            ></a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-lg-2 col-sm-3">
            
            </div>
            <div class="col-6 col-lg-2 col-sm-3"></div>
            <div class="col-6 col-lg-2 col-sm-3">
                <div class="footer__widget">
                    <h4 class="footer__widget-title"></h4>
                    <ul class="footer__widget-linklist">
                        <li>
                            <a [routerLink]="['/about']" style="color: white;">About Us</a>
                        </li>
                        
                        <li>
                            <a [routerLink]="['/support']" style="color: white;">Support</a>
                        </li>
                       =
                    </ul>
                </div>
            </div>
      
        </div>
        <div class="row">
            <div class="col">
                <div class="footer__subfooter">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <p>
                                © 2021
                                <a [routerLink]="['']" style="cursor: pointer;" style="color: white;">Srichid Blockchain Services Pvt. Ltd.</a
                >
                <br>
                All Rights Reserved.
              </p>
            </div>
          
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer> -->

<br><br><br><br><br><br><br>
  <!---------footer--------->
  <section class="deneb_cta ">
    <div class="container">
      <div class="cta_wrapper">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="cta_content">
              <h3>Srichid Academy</h3>
              <p>Transforming Lives through Knowledge
                and Learning.</p>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="button_box">
              <a href="https://srichid.academy/srichidacademy/" class="btn btn-warning">Connect Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer class="deneb_footer">
    <div class="widget_wrapper"
      style="background-image: url(http://demo.tortoizthemes.com/deneb-html/deneb-ltr/assets/images/footer_bg.png);">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <div class="widget widegt_about">
              <div class="widget_title">
                <img src="assets/images/logo_1.png" class="img-fluid" alt="">
              </div>
              <p>"S R I C H I D" , # 563, II Cross, II Main, RBI Layout , VII Phase, J P Nagar, NEAR BRIGADE MILLENNIUM TRAFFIC SIGNAL, OPP. RBI LAYOUT WELFARE ASSOCIATION, Bengaluru, Karnataka 560078</p>
              <ul class="social">
                <li><a href="https://www.facebook.com/profile.php?id=100068265824948"><i class="fa fa-facebook"></i></a></li>
                <!-- <li><a href="https://twitter.com/Jobszone5"><i class="fa fa-twitter"></i></a></li>
                <li><a href="https://www.linkedin.com/in/jobs-zone-b32ba81ba/"><i class="fa fa-linkedin"></i></a></li> -->
                <li> <a href="https://www.instagram.com/jobs__zone/"><i class="fa fa-instagram"></i></a>
              </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-1 col-md-6 col-sm-12"></div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="widget widget_link">
              <div class="widget_title">
                <h4 style="margin-left: 10%;">Links</h4>
              </div>
              <ul>
                <li><a [routerLink]="['/about']">About Us</a></li>
                <!-- <li><a href="#">Services</a></li>
                <li><a href="#">PRM</a></li> -->
                <li><a [routerLink]="['/support']">Support</a></li>
             
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="widget widget_contact">
              <div class="widget_title">
                <h4>Contact Us</h4>
              </div>
              <div class="contact_info">
                <div class="single_info">
                  <div class="icon">
                    <i class="fa fa-phone"></i>
                  </div>
                  <div class="info">
                    <p><a href="">+91 80 2685 2070</a></p>
                 
                  </div>
                </div>
                <br>
                <div class="single_info">
                  <div class="icon">
                    <i class="fa fa-envelope"></i>
                  </div>
                  <div class="info">
                    <p><a href="">jobs-zone@srichid.academy</a></p>
              
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="copyright_text">
              <p>Copyright &copy; 2022 All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

<ng-template #InviteStudents let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Invite Students For Registration</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <div class="modal-body" style="font-size: 16px;">


        <br />




        <div>
            <br>
            <div>
                <h4 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                    Select Text File (has to contain only Emails)
                </h4>
                <input type="file" id="file" (change)="handleFileInput2($event.target.files)">
            </div>
            <br />

        </div>
    </div>
    <br />
    <div class="modal-footer">
        <div>

            <button type="button" class="btn btn-outline-dark btn-primary" (click)="SendInvitation1()" style="color: black; color: white;padding: 5px;margin-left: 10px;">
Send Invitation
</button>
        </div>
    </div>
    <br />
</ng-template>

<ng-template #InviteInternship let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Invite Company for Internship</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <div class="modal-body" style="font-size: 16px;">


        <br />




        <div>
            <br>
            <div>
                <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                Email:
            </h5> -->
                <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email" name="dp" />
            </div>
            <br />

        </div>
    </div>
    <br />
    <div class="modal-footer">
        <div>

            <button type="button" class="btn btn-outline-dark btn-primary" (click)="SendInvitation('Internship')" style="color: black; color: white;padding: 5px;margin-left: 10px;">
Send Invitation
</button>
        </div>
    </div>
    <br />
</ng-template>

<ng-template #InviteTraining let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Invite Company for Training</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <div class="modal-body" style="font-size: 16px;">


        <br />




        <div>
            <br>
            <div>
                <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                Email:
            </h5> -->
                <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email" name="dp" />
            </div>
            <br />

        </div>
    </div>
    <br />
    <div class="modal-footer">
        <div>

            <button type="button" class="btn btn-outline-dark btn-primary" (click)="SendInvitation('Training')" style="color: black; color: white;padding: 5px;margin-left: 10px;">
Send Invitation
</button>
        </div>
    </div>
    <br />
</ng-template>



<ng-template #InviteJob let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Invite Company for Job</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <div class="modal-body" style="font-size: 16px;">


        <br />




        <div>
            <br>
            <div>
                <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                Email:
            </h5> -->
                <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email" name="dp" />
            </div>
            <br />

        </div>
    </div>
    <br />
    <div class="modal-footer">
        <div>

            <button type="button" class="btn btn-outline-dark btn-primary" (click)="SendInvitation('Job')" style="color: black; color: white;padding: 5px;margin-left: 10px;">
Send Invitation
</button>
        </div>
    </div>
    <br />
</ng-template>