import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'app/shared/role.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Role } from 'app/shared/VIewmodels/role';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  closeResult: any;
  RoleName: any;
  rolename: any;
  RoleDesc: any;
  roledesc: any;
  public dismissalert = false;
  notify: string;
  RoleList: any;
  p: number = 1;
  editroleName: any;
  editdesc: any;
  editroleid: any;
  urolename: any;
  uroledesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  constructor(private modalService: NgbModal, private spinner: NgxSpinnerService,private roleService: RoleService) { }

  ngOnInit(): void {
    this.Roles();
    this.RoleName = "";
    this.RoleDesc = "";
  }
  Roles() {
    this.spinner.show();
    this.roleService.getAllRoles().subscribe((Data: any) => {
      this.RoleList = Data;
      this.spinner.hide();
    });
  }
  addRole(addrole) {
    this.modalService.open(addrole).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditRole(updatedata, updaterole) {
    this.editroleName = updatedata.roleName;
    this.editdesc = updatedata.roleDesc;
    this.editroleid = updatedata.id;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.modalService.open(updaterole).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteRole(deletedata, deleterole) {
    this.DeleteName = deletedata.roleName;
    this.deleteid = deletedata.id;
    this.modalService.open(deleterole).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  SaveRole() {
    const inputRequest: Role = {
      Id: 0,
      RoleName: this.rolename,
      RoleDesc: this.roledesc,
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
    };
    this.spinner.show();
    this.roleService.saveRole(inputRequest).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Role added Successfully";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Roles();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Roles();
      }
    });
  }

  UpdateRole() {
    const inputRequest1: Role = {
      Id: this.editroleid,
      RoleName: this.editroleName,
      RoleDesc: this.editdesc,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
    };
    this.spinner.show();
    this.roleService.UpdateRole(inputRequest1).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Role Updated Succesfully";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Roles();
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Roles();
        this.spinner.hide();
      }
    });
  }
  DeleteRoleRow() {
    this.spinner.show();
    this.roleService.deleteRole(this.deleteid).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Role Deleted Succesfully";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Roles();
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Roles();
        this.spinner.hide();
      }
    });
  }
  closealert(){
    this.dismissalert=false;
  }
}
