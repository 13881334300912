<!-- <div class="whole-page">
    <br /><br /><br /><br /><br />
    <div class="col-md-12 text-center" style="font-weight: 700;font-size: 22px;color: whitesmoke;" *ngIf="joblist == undefined || joblist == null|| joblist == ''">No Matching Candidate could be found !</div>
    <div class="col-md-12 text-center" style="font-weight: 700;font-size: 22px;color: whitesmoke;" *ngIf="joblist.length > 0">Candidates list</div>
    <br>
    <div *ngFor="let job of joblist">

        <button class="collapsible">{{job.name}}</button>
        <div class="whole-content">
            <div class="content">
                <label>Name :</label>
                <span style="font-size: 15px;"><b>&nbsp;{{ job.name }}</b></span
        ><br />
        <label>Mobile :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.mobie }}</b></span
        ><br />
        <label>key Skils :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.keySkils }}</b></span
        ><br />

        <label>Email :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.email }}</b></span
        ><br />
        <label>Education :</label>
        <span style="font-size: 15px;"
          ><b>&nbsp;{{ job.edudation }}</b></span
        ><br />
      </div>
      <div class="row col-md-12"></div>
      <div class="row col-md-12" id="button">
         <button type="submit" class="btn btn-primary">
          Contact
        </button> 

        <div class="row col-md-12" id="button">
          <style>
            .container {
              position: relative;
              width: 50%;
            }
  
            .image {
              opacity: 1;
              display: block;
              width: 100%;
              height: auto;
              transition: 0.5s ease;
              backface-visibility: hidden;
            }
  
            .middle {
              transition: 0.5s ease;
              opacity: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              text-align: center;
            }
  
            .container:hover .image {
              opacity: 0.3;
            }
  
            .container:hover .middle {
              opacity: 1;
            }
  
            .text {
              background-color: mediumslateblue;
              color: white;
              font-size: 16px;
              padding: 16px 32px;
            }
          </style>
  
          <div class="col-md-12 row">
            <div class="chat-panel panel panel-default" *ngIf="chatopen">
              <div class="panel-heading">
                <i class="fa fa-comments fa-fw"></i> Individual Chat Window
              </div>
  
              <div
                class="col-md-12"
                style="
                  padding-bottom: 10px;
                  background-color: mediumslateblue;
                  color: white;
                  padding-top: 10px;
                  font-family: bold;
                "
              >
                {{ chtnm }}
              </div>
              <div
                class="panel-body"
                style="
                  background-image: url(./assets/img/wwp.jpg);
                  overflow-y: auto;
                  max-height: 300px;
                "
              >
                <div class="col-md-12">
                  <div
                    class="col-md-12"
                    *ngFor="let c of IndividualChatSentDetails"
                  >
                    <div class="col-md-12" *ngIf="c.chatFrom != userid">
                      <div class="chat">
                        <br /><br />
                        <div>
                          <div class="left clearfix">
                            <div class="chat-body clearfix">
                              <span
                                style="
                                  background-color: orange;
                                  color: black;
                                  padding: 8px 8px;
                                  border-radius: 20px;
                                "
                              >
                                {{ c.chat }}
                              </span>
                <p>
                    {{ message }}
                </p>
            </div>
        </div>
    </div>
</div>
</div>

<div class="col-md-12" style="float: right;" *ngIf="c.chatFrom == userid">
    <div>
        <br /><br />
        <div style="float: right;">
            <div style="float: right;">
                <div>
                    <span style="
                                  background-color: mediumspringgreen;
                                  color: black;
                                  padding: 8px 8px;
                                  border-radius: 20px;
                                ">
                                {{ c.chat }}
                              </span>
                    <p>
                        {{ message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
<form #individualchatform="ngForm" (ngSubmit)="SendIndividualMessage(individualchatform)">
    <div class="panel-footer">
        <div class="input-group">
            <input type="text" id="individualchatmessage" name="individualchatmessage" class="form-control" placeholder="Type your message here..." ngModel />
            <span class="input-group-btn">
                      <button class="btn btn-warning btn-sm" id="btn-chat">
                        Send
                      </button>
                    </span>
        </div>
    </div>
</form>
</div>

<div class="col-md-2">
    <h4 class="modal-title" id="modal-basic-title"></h4>

    <button type="button" class="btn btn-primary" (click)="addFriend(job.toid, content3)">
                Contact
              </button>
</div>
-->

<div class="whole-page">
  <br /><br /><br /><br />
  <div
    class="col-md-12 text-center"
    style="font-weight: 700; font-size: 22px; color: whitesmoke"
    *ngIf="joblist == undefined || joblist == null || joblist == ''"
  >
    No Candidate matched to your provided skillset !
  </div>
  <div
    class="col-md-12 text-center"
    *ngIf="joblist.length > 0"
    style="font-weight: 700; font-size: 22px; color: whitesmoke"
  >
    Candidates matching your skillset
  </div>
  <main>
    <section class="container register-form">
      <div style="display: flex" class="col-md-12 row">
        <div class="complete-form col-md-6" *ngFor="let job of joblist">
          <div class="form">
            <div class="row text-center justify-content-center">
              <div class="col-lg-12">
                <div class="col-lg-12">
                  <br />
                  <div class="note">
                    <p style="font-size: x-large">{{ job.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-content">
              <div class="container">
                <div class="row justify-content-center mt--200"></div>
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <label for="fname" style="font-size: 15px; color: #8a061c"
                        >Name :</label
                      >
                      <label for="fname" style="font-size: 15px; color: #8a061c"
                        >key Skils :</label
                      ><br />
                      
                    </div>

                    <div class="col-md-8">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c"
                        >{{ job.name }}</label
                      ><br />
                      <label
                      for="fname"
                      style="font-size: 15px; color: #8a061c"
                      >{{ job.keySkils }}</label
                    ><br /> <br /> 
                    <label
                    for="fname"
                    style="font-size: 15px; color: #8a061c"
                    >% Matching: &nbsp;&nbsp;&nbsp;&nbsp;{{ job.percentage }}</label
                  ><br />


                    
                    </div>

                    <!-- ------------------------------- -->
                    <div class="col-md-12">&nbsp;</div>

                    <div
                      class="col-md-10"
                      style="text-align: center; padding-left: 10%"
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        (click)="addFriend(job.toid, content3)"
                      >
                        Contact
                      </button>
                      &nbsp; &nbsp;
                      <button
                        type="button"
                        class="btn btn-primary"
                        (click)="viewUserProfile(job)"
                      >
                        View Profile
                      </button>

                      &nbsp; &nbsp;
                      <button
                        type="button"
                        class="btn btn-primary"
                         data-target="#exampleModal2" 
                         data-toggle="modal" 
                         (click)="emailstu(job)" 
                         data-original-title="" title=""
                      >
                        Email
                      </button>
                    </div>
                    <div class="col-md-1">&nbsp;</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
<ng-template #friendcontent let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="color: black">
      Add Friend
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title" id="modal-basic-title" style="color: black">
      Email:
    </h5>
    <input
      type="text"
      id="accid"
      [(ngModel)]="accid"
      class="form-control"
      name="dp"
    />
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="savefrienddetails()"
      (click)="d('Cross click')"
      style="color: black; background-color: grey; color: white"
    >
      Send Request
    </button>
  </div>
  <br />
</ng-template>

<ng-template #pdfcontent8 let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Recieved Request</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div>
        <div style="border: solid">
          <div>
            <span
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                font-size: 20x;
              "
              >Friends</span
            >
          </div>
          <div *ngFor="let f of Friends.incomingRequest">
            <span>{{ f.friendName }}</span>
            <button
              type="button"
              (click)="acceptrequest(f)"
              (click)="d('Cross click')"
            >
              <span>Accept</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="modal-footer"></div>
  <br />
</ng-template>

<ng-template #pdfcontent7 let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sent Request</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div
      style="
        font-family: 'Times New Roman', Times, serif;
        text-align: center;
        background-color: #000;
      "
    >
      Friends
    </div>
  </div>
  <br />
  <div class="modal-footer"></div>
  <br />
</ng-template>

<div class="modal fade" id="feedbackModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" title="Close">
          <span class="glyphicon glyphicon-remove"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
          <h4>Details</h4>
        </div>

        <div id="myGallery" class="carousel slide" data-interval="false">
          <div class="carousel-inner">
            <div class="item active" *ngFor="let modalData of cnnTableData">
              <div class="row">
                <div class="col-sm-6">
                  <br />
                  <img
                    src="{{ modalData.Image }}"
                    style="width: 100%; min-height: 211px"
                  />
                </div>
                <div class="col-sm-6">
                  <div>
                    <h6><b>Image Name</b></h6>
                    <small>{{ modalData.Name }}</small>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <a
            class="left carousel-control"
            href="#myGallery"
            role="button"
            data-slide="prev"
            style="margin-left: -84px"
          >
            <span class="glyphicon glyphicon-chevron-left"></span
          ></a>
          <a
            class="right carousel-control"
            href="#myGallery"
            role="button"
            data-slide="next"
            style="margin-right: -84px"
          >
            <span class="glyphicon glyphicon-chevron-right"></span
          ></a>
        </div>
      </div>
      <div class="modal-footer"><br /><br /></div>
    </div>
  </div>
</div>

<ng-template #content let-modal let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h2
      style="
        font-family: 'Times New Roman', Times, serif;
        text-align: center;
        color: black;
      "
    >
      Sent Request
    </h2>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div>
        <table class="table table-bordered" style="border: solid">
          <tr>
            <th
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                background-color: black;
                color: white;
              "
            >
              Friends
            </th>
          </tr>
          <div *ngIf="Friends">
            <tr *ngFor="let f of Friends.sentRequest">
              <td style="border: solid; text-align: center">
                <b style="color: black; font-size: 16px">{{ f.friendName }}</b>
              </td>
            </tr>
          </div>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="d('Cross click')"
      style="background-color: darkgray"
    >
      Close
    </button>
  </div>
</ng-template>

<hr />

<ng-template #content3 let-modal let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h2
      style="
        font-family: 'Times New Roman', Times, serif;
        text-align: center;
        color: black;
      "
    >
      Continue Chat here
    </h2>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="form-horizontal">
            <div class="">
              <h2 style="text-align: center"></h2>
            </div>
            <div
              class="col-md-12"
              style="
                margin-bottom: 50px;
                max-width: 100%;
                background-color: white;
              "
            >
              <div class="form-group">
                <label class="control-label">chat message:</label>
                <input
                  type="text"
                  id="messa"
                  name="messa"
                  class="form-control"
                  placeholder="Enter the message"
                  [(ngModel)]="messa"
                />
              </div>
              <div class="form-group">
                <input
                  type="Submit"
                  class="btn btn-primary btn-round"
                  style="background-color: grey; color: white"
                  (click)="SaveUserChat()"
                  (click)="d('Cross click')"
                  value="Send"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>


  <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div   class="modal-dialog" role="document">

        <div class="modal-content" id="myOverlay" style="width:100%;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">New Message</h5>
                <img  *ngIf="expands" src="assets/img/expand.png" (click)="expand()" alt="" width="18px" style="margin-left: 74%;"/>
                <img  *ngIf="compress" src="assets/img/compres.png" (click)="compres()" alt="" width="26px" style="margin-left: 86%;"/>

                <button type="button" class="close" (click)="cancelmail()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="inbox-body">

                    <div class="col-md-12">


                        <div class="col-md-12" id="divstyle">
                            <label id="labeldiv">To</label>
                              <input type="text" class="form-control" placeholder="" name="mailto" [(ngModel)]="mailto" style="font-size: 20px;" autofocus>
                        </div> <br />

                        <input type="text" class="form-control" placeholder="Subject" name="mailsubject" [(ngModel)]="mailsubject" style="font-size: 20px;" autofocus>
                        <br />

                        <textarea type="text" class="form-control" placeholder="Message" name="message" [(ngModel)]="message"  style="font-size: 20px;" rows="4" cols="50"> </textarea>
                        <br /> <br />
                        <label id="actionfunctions">
                            <!-- <p data-toggle="modal" data-target="#UploadModalPopup">
                              <img src="./assets/img/ellipse182.png"
            style="cursor: pointer;" /> &nbsp; Add Attachment From Blocdrive
                            </p> -->
                          </label><br /> <br />
                        <label>Attach file</label>
                        <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)"> <br /> <br />
                        <!-- <div style="text-align: right;">
                            <button type="submit" id="SendBtn" class="btn btn-primary" (click)="sendmail()">SEND</button></div><br /> <br />
                    -->

                    <div *ngIf="friends">
                        <a  (click)="savefrienddetails()"><u style="font-size: 22px;
                            color: blue;">Send Friend Request</u></a>
    
                    </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="cancelmail()" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="sendmail()">Send</button>
            </div>
        </div>
    </div>
  </div>


