<app-nav-bar></app-nav-bar>
<br /><br> <br /> <br /> <br /> <br />
<div class="col-md-12 row">
    <div class="col-md-2">
        <app-jobmailcompose></app-jobmailcompose>
    </div>
    <div class="col-md-10">
        <aside class="lg-side">

            <div class="inbox-body" *ngFor="let mail of maildata">
                <div class="col-md-10" style="    padding: 30px 1px 50px 70px;">

                    <div class="col-lg-12 row">
                        <div class="col-lg-6">
                            <span>
                                <h5>From: {{ mail.fromName }}</h5>
                            </span>
                            <p>
                                To:
                                <span *ngFor="let mailmem of mail.memlist">
                                    {{ mailmem.useName }},
                                </span>
                            </p>
                        </div>

                        <div class="col-lg-6">
                            <span>
                                <p style="float: right; font-size: smaller">
                                    {{ mail.date }}
                                </p>
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-12 row">
                        <div class="col-lg-12">
                            <h5>Sub: {{ mail.subject }}</h5>
                        </div>
                        <div class="col-lg-12">
                            <p style="text-align: justify;">{{ mail.message }}</p>

                            <div class="col-md-3 row" *ngFor="let ret of mail.documents">
                                <div *ngIf="
                        ret.documentType == 'image/jpeg' ||
                        ret.documentType == 'image/png'
                      " (click)="ViewImageDoc(ret, content)">
                                    <img src="{{ ret.doclink }}" style="cursor: pointer; width: 60%; height: 80px;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>

                                <div *ngIf="
                        ret.documentType == 'application/pdf' ||
                        ret.documentType == 'pdf'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/pdf.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />
                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType == 'audio/mp3' ||
                        ret.documentType == 'mp3'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/video.png" style="cursor: pointer; width: 100%; height: 100%" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        ret.documentType == 'xlsx' ||
                        ret.documentType == 'application/octet-stream'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/Excel.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        ret.documentType == 'pptx' ||
                        ret.documentType == 'ppt'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/ppt.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType == 'video/mp4' ||
                        ret.documentType == 'mp4'
                      " (click)="ViewVideo(ret, videocontent)">
                                    <img src="assets/img/nvideo.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        ret.documentType == 'application/octet-stream' ||
                        ret.documentType == 'doc' ||
                        ret.documentType == 'docx'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/word.jpg" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType == 'text/plain' ||
                        ret.documentType == 'txt'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/textt.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="forwardimages" class="col-md-12">
                            <img src="{{userImage}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;
                border-radius: 7px;" />
                        </div>

                        <br><br>
                        <div class="col-lg-12">
                            <br>
                            <p>Thanks & Regards,<br> {{ mail.fromName }}</p>
                        </div>

                        <div class="col-md-12 row">
                            <div style="    padding-left: 2%;"></div>
                            <div>

                                <br />

                                <button id="SendBtn" (click)="replay(mail, 'single')" type="button" class="btn btn-outline-secondary btn-rounded waves-effect" style="border: solid 1px">
                                    <i class="fa fa-reply pr-2" aria-hidden="true"></i>Reply
                                </button>
                                <br />
                                <br />
                            </div>
                            <div style="
                            padding: 0px 14px 0px 14px;
                        ">

                                <br />
                                <button id="SendBtn" (click)="replay(mail, 'all')" type="button" class="btn btn-outline-secondary btn-rounded waves-effect" style="border: solid 1px">
                                    <i class="fa fa-reply-all pr-2" aria-hidden="true"></i>Reply All
                                </button>
                                <br />
                                <br />
                            </div>
                            <div>

                                <br />
                                <button id="SendBtn" (click)="Addnewmembers(mail, newmember)" type="button" class="btn btn-outline-secondary btn-rounded waves-effect" style="border: solid 1px">
                                    <i class="fas fa-share pr-2" aria-hidden="true"></i>Forward
                                </button>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </aside>
    </div>

</div>

<div class="col-md-12 row" *ngIf="replayenable">
    <div class="col-md-3"></div>

    <div class="col-md-8" style="background-color: white; padding: 30px 36px 50px 43px;box-shadow: 0px 4px 24px 0px rgb(0 0 0 / 20%);">
        <label> To User</label>
        <div class="col-lg-12 row" style="cursor: pointer">
            {{ replytouser }}<br />
        </div>
        <br />
        <label>Message :</label>

        <textarea type="text" class="form-control" name="message" [(ngModel)]="message" rows="4" cols="50">
    </textarea>
        <br />
        <br />

        <label>Upload Documents</label>

        <input type="file" name="avatars" multiple="true" (change)="onChangeDocumnets($event)" />

        <br /><br />
        <div style="text-align: center">
            <button type="submit" id="SendBtn" style="text-align: left" class="btn btn-success" (click)="sendmail()">
                SEND
            </button>
        </div>
    </div>
</div>
<br><br>
<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer">
            {{ imgdisplay.documentName }}
        </h6>
        <h6 style="float: right">Date &nbsp;: &nbsp;{{ date | date: "short" }}</h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{ imgdisplay.doclink }}" class="img-responsive" alt="" style="height: 300px; width: 400px" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="imgdisplay.doclink" target="blank" style="cursor: pointer"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>
<!-- PDF VIEW -->
<ng-template #pdftesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white">
        <div>&nbsp;</div>
        <h6 style="text-align: center">{{ zzz.documentName }} <br /></h6>
        <h6 style="float: left">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh"></ngx-doc-viewer>
        <br /><br />

        <!-- <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button> </a> -->

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #newmember let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
            <b>Forward Message</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
        <div>
            <div id="aboveTagInp">
                <!-- <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                [separatorKeyCodes]="[13,188,186,32]">
                <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                <!-- <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                    <ng-template let-item="item" let-index="index">
                        {{ item.email }}
                    </ng-template>
                </tag-input-dropdown> -->
            <!-- </tag-input> --> 
            <input type="text" class="form-control" placeholder="To User" name="ToUserfor" [(ngModel)]="ToUserfor" style="font-size: 20px;" autofocus>
            </div>
            <textarea type="text" class="form-control" name="message" [(ngModel)]="message" rows="1" cols="40">
            </textarea>
        </div>
        <br />
        <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)">  
    </div>
    <br />
    <div></div>

    <div class="modal-footer">
        <!-- <a class="req" (click)="MailFriend()"><u>Send Friend Request</u></a> -->
        <div *ngIf="friends">
            <a class="req" (click)="savefrienddetails()"><u style="font-size: 22px;
                color: blue;">Send Friend Request</u></a>
        </div>
        <button type="button" class="btn btn-outline-dark btn-primary" (click)="forword()" (click)="d('Cross click')" style="color: black; color: white; padding: 5px; margin-left: 10px">
            Forward
        </button>
    </div>
    <br />
</ng-template>

<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white">
        <div>&nbsp;</div>
        <h6 style="float: left">
            Date &nbsp;: &nbsp;{{ videodate | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white">
        <video controls #videoPlayer style="height: 350px; width: 400px">
            <source src="{{ videoplay }}" type="video/mp4" />
        </video>
    </div>
</ng-template>
<ng-template #audiocontent let-d="dismiss" let-c="dismiss">
    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white">
        <audio controls #videoPlayer style="height: 350px; width: 400px">
            <source src="{{ audioplay }}" type="video/mp4" />
        </audio>
    </div>
</ng-template>