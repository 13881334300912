import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {
  query:string;
  constructor(private router:ActivatedRoute, private route:Router) { 
    this.router.params.subscribe(params => {
    this.query = params["id"];
  });
  }

  ngOnInit(): void {
  }


  inbox() {
    this.route.navigate(['mail/inbox']);
  }
  composemail() {
    this.route.navigate(['mail/compose']);
  }
  sentitems() {
    this.route.navigate(['mail/sent']);
  }
}
