<br /><br /><br /><br />

<div>
    <div class="col-md-12 row">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-5">
            <!-- <b style="font-size: 22px; color: black;"> Details</b> -->
            <img width="100px" height="60px" src="data:image/png;base64,{{ dispinfo.logo }}" />
        </div>
    </div>
    <div class="col-md-12">&nbsp;</div>
    <div class="col-md-12">&nbsp;</div>

    <div class="col-md-12 row">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-8 row" style="
        background-color: crimson;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      ">
            <!-- <div class="col-md-12 text-center"> <input type="file" id="filePicker" (change)="handleFileSelect($event)"></div> -->
            <div class="table-responsive">
                <table class="table table-hover">
                    <tbody style="color: white;">
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Institution Name
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  dispinfo.companyName
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Email
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  dispinfo.email
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                Mobile
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  dispinfo.phoneNumber
                }}</b>
                            </td>
                        </tr>

                        <tr>
                            <td style="color: white; font-weight: 500;">
                                City
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  dispinfo.city
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                pincode
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  dispinfo.pincode
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                State
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  dispinfo.state
                }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: white; font-weight: 500;">
                                country
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <b style="float: right; font-weight: 500;">{{
                  dispinfo.country
                }}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<br /><br /><br />