<br><br>

<section id="container">
    <div id="content-container">
        <h1>Alert</h1>
        <h3>Be the first to hear
        about new jobs.
        </h3>
        <button (click)="actionMethod()">Get job Alerts</button>
    </div>
    <div id="bg-container">
        
<svg width="856" height="692" viewBox="0 0 856 692" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)" filter="url(#filter0_d)">
        <path
            d="M436.104 0L668.69 142.416C664.713 150.156 662.151 158.72 661.155 167.601C660.16 176.481 660.751 185.497 662.894 194.114C665.037 202.731 668.689 210.774 673.632 217.767C678.576 224.759 684.71 230.559 691.673 234.823C698.635 239.086 706.284 241.726 714.166 242.586C722.048 243.447 730.002 242.51 737.557 239.831C745.113 237.153 752.116 232.787 758.152 226.993C764.187 221.199 769.133 214.094 772.695 206.1L852 254.659L646.575 683.386L230.679 428.726L436.104 0Z"
            fill="#F2F2F2" />
        <path
            d="M730.763 333.488L563.142 363.65L576.761 460.368L707.714 436.803L712.994 474.306L723.73 433.922L744.381 430.206L730.763 333.488Z"
            fill="#F39D13" />
        <path opacity="0.2"
            d="M730.763 333.488L563.142 363.65L576.761 460.368L707.714 436.803L712.994 474.306L723.73 433.922L744.381 430.206L730.763 333.488Z"
            fill="black" />
        <path d="M702.312 373.013L601.042 391.236L599.652 381.367L700.923 363.144L702.312 373.013Z" fill="#F2F2F2" />
        <path d="M705.092 392.751L603.821 410.974L602.431 401.105L703.702 382.882L705.092 392.751Z" fill="#F2F2F2" />
        <path d="M707.871 412.489L606.6 430.712L605.211 420.843L706.481 402.62L707.871 412.489Z" fill="#F2F2F2" />
        <path
            d="M742.244 472.337L586.459 396.171L552.069 486.059L673.777 545.564L660.442 580.417L688.661 552.841L707.854 562.224L742.244 472.337Z"
            fill="#F39D13" />
        <path d="M699.481 488.447L605.361 442.431L608.87 433.259L702.99 479.275L699.481 488.447Z" fill="#F2F2F2" />
        <path d="M692.462 506.792L598.342 460.776L601.851 451.604L695.972 497.62L692.462 506.792Z" fill="#F2F2F2" />
        <path d="M685.447 525.136L591.326 479.12L594.836 469.947L688.956 515.964L685.447 525.136Z" fill="#F2F2F2" />
        <path
            d="M718.376 296.454L562.591 220.289L528.201 310.177L649.908 369.681L636.574 404.535L664.792 376.958L683.986 386.342L718.376 296.454Z"
            fill="#F39D13" />
        <path d="M675.612 312.565L581.492 266.549L585.001 257.377L679.121 303.393L675.612 312.565Z" fill="#F2F2F2" />
        <path d="M668.594 330.91L574.474 284.894L577.983 275.721L672.103 321.738L668.594 330.91Z" fill="#F2F2F2" />
        <path d="M661.578 349.254L567.458 303.238L570.967 294.066L665.087 340.082L661.578 349.254Z" fill="#F2F2F2" />
        <path
            d="M706.499 78.9043L83.3486 109.009C83.3486 109.009 -14.2961 460.225 58.4936 610.747L648.8 684C648.8 684 524.525 423.097 706.499 78.9043Z"
            fill="#3F3D56" />
        <path d="M611.517 242.471L86.0115 232.436" stroke="#2F2E41" stroke-width="2" stroke-miterlimit="10" />
        <path d="M597.314 288.631L66.4825 276.589" stroke="#2F2E41" stroke-width="2" stroke-miterlimit="10" />
        <path d="M693.88 103.625L78.356 128.093" stroke="#2F2E41" stroke-width="2" stroke-miterlimit="10" />
        <path opacity="0.2"
            d="M230.82 463.238C230.82 464.727 230.829 466.216 230.864 467.685V467.695C230.917 471.332 231.041 474.91 231.244 478.468C231.456 482.285 231.745 486.052 232.111 489.77C235.691 526.116 246.494 557.785 261.654 579.43C270.247 591.712 280.245 600.776 291.127 605.653C294.45 607.149 297.902 608.25 301.426 608.94C304.162 609.473 306.933 609.741 309.709 609.74C322.731 609.74 335.019 603.884 345.839 593.491C366.746 573.454 382.216 536.559 387.016 492.478V492.468C387.83 485.013 388.342 477.368 388.51 469.554C388.563 467.465 388.59 465.36 388.59 463.238C388.59 382.322 353.274 316.726 309.709 316.726C299.393 316.726 289.536 320.403 280.502 327.099C280.236 327.289 279.971 327.488 279.715 327.698C274.744 331.539 270.19 336.027 266.145 341.069C263.545 344.288 261.113 347.675 258.861 351.213C255.973 355.759 253.339 360.505 250.975 365.423C248.995 369.52 247.13 373.837 245.415 378.344V378.354C243.629 382.991 241.994 387.821 240.509 392.845V392.855C236.027 408.224 233.103 424.118 231.792 440.233C231.147 447.728 230.82 455.413 230.82 463.238Z"
            fill="black" />
        <path
            d="M658.163 90.4409C657.487 93.307 658.298 96.6316 659.972 97.8641C661.642 99.094 663.539 97.7749 664.212 94.9205C664.885 92.0661 664.083 88.7478 662.415 87.5063C660.744 86.2621 658.839 87.5748 658.163 90.4409Z"
            fill="#F39D13" />
        <path
            d="M643.981 90.1899C643.305 93.056 644.116 96.3806 645.79 97.6132C647.46 98.843 649.357 97.524 650.03 94.6695C650.704 91.8151 649.901 88.4967 648.233 87.2553C646.562 86.0111 644.657 87.3238 643.981 90.1899Z"
            fill="#F39D13" />
        <path
            d="M672.344 90.6918C671.668 93.5579 672.48 96.8825 674.153 98.1151C675.823 99.3449 677.72 98.0259 678.394 95.1715C679.067 92.317 678.264 88.9987 676.597 87.7573C674.926 86.5131 673.021 87.8258 672.344 90.6918Z"
            fill="#F39D13" />
        <path
            d="M229.632 378.761L225.044 394.196L220.076 394.629L224.542 356.152C222.883 331.85 231.857 310.41 253.355 308.534H253.355C260.563 307.905 267.697 310.539 273.188 315.857C278.678 321.174 282.075 328.739 282.632 336.887L285.167 374.02C285.436 377.956 284.31 381.852 282.038 384.85C279.766 387.848 276.534 389.703 273.052 390.007L234.509 393.37L229.632 378.761Z"
            fill="#2F2E41" />
        <path
            d="M224.787 466.67C224.787 466.67 220.707 479.19 213.712 481.167C206.717 483.144 154.84 496.981 154.84 496.981C154.84 496.981 109.374 498.299 123.363 509.501C137.353 520.703 160.086 509.501 160.086 509.501C160.086 509.501 226.536 500.276 231.782 490.392C235.235 483.665 238.161 476.61 240.525 469.306L224.787 466.67Z"
            fill="#FBBEBE" />
        <path
            d="M246.937 411.32C246.937 411.32 213.712 472.601 217.792 473.919C221.873 475.237 259.761 489.074 261.51 483.803C263.258 478.531 263.258 454.151 263.258 454.151L246.937 411.32Z"
            fill="#FF6584" />
        <path opacity="0.15"
            d="M246.937 411.32C246.937 411.32 213.712 472.601 217.792 473.919C221.873 475.237 259.761 489.074 261.51 483.803C263.258 478.531 263.258 454.151 263.258 454.151L246.937 411.32Z"
            fill="black" />
        <path
            d="M338.219 391.037L387.535 468.553C387.535 468.553 420.328 501.664 408.038 505.64C395.748 509.616 376.24 477.552 376.24 477.552L319.398 396.407L338.219 391.037Z"
            fill="#FBBEBE" />
        <path
            d="M237.028 362.559C237.028 362.559 251.6 387.598 250.434 393.528C250.434 393.528 251.6 397.482 249.852 400.118C248.103 402.754 248.686 418.568 248.686 418.568L254.515 427.793L262.092 399.459L265.59 369.148C265.59 369.148 257.721 356.958 258.887 351.027C260.052 345.097 237.028 362.559 237.028 362.559Z"
            fill="#FBBEBE" />
        <path
            d="M261.655 579.43C270.247 591.712 280.245 600.776 291.127 605.652C294.45 607.148 297.902 608.25 301.426 608.94C299.561 562.161 297.413 520.439 295.901 516.752C292.4 508.188 283.074 504.89 283.074 504.89C283.074 504.89 282.155 507.678 280.617 512.525C276.63 525.156 268.479 551.778 261.655 579.43Z"
            fill="#FF6584" />
        <path opacity="0.15"
            d="M261.655 579.43C270.247 591.712 280.245 600.776 291.127 605.652C294.45 607.148 297.902 608.25 301.426 608.94C299.561 562.161 297.413 520.439 295.901 516.752C292.4 508.188 283.074 504.89 283.074 504.89C283.074 504.89 282.155 507.678 280.617 512.525C276.63 525.156 268.479 551.778 261.655 579.43Z"
            fill="black" />
        <path
            d="M244.606 366.512C232.694 366.512 223.039 355.597 223.039 342.132C223.039 328.667 232.694 317.751 244.606 317.751C256.517 317.751 266.173 328.667 266.173 342.132C266.173 355.597 256.517 366.512 244.606 366.512Z"
            fill="#FBBEBE" />
        <path
            d="M253.928 454.813C257.959 459.15 260.921 462.348 263.095 464.786C267.515 469.723 268.7 471.492 269.089 473.261C269.672 475.899 272.581 475.899 272.581 475.899L280.617 512.525L289.492 552.988C289.492 552.988 290.067 575.473 291.128 605.652C294.451 607.148 297.902 608.25 301.426 608.94C304.162 609.473 306.933 609.74 309.709 609.74C322.731 609.74 335.019 603.884 345.839 593.49C345.689 554.896 346.034 515.433 346.034 515.433C346.034 515.433 355.36 498.304 323.297 464.697C323.297 464.697 318.24 448.028 313.007 431.289C307.747 414.42 302.31 397.481 301.727 397.481C301.338 397.481 298.553 393.454 295.017 388.088C291.914 383.391 288.228 377.655 285.081 372.688C281.023 366.332 277.832 361.246 277.832 361.246C277.832 361.246 268.258 361.246 264.156 366.772C263.482 367.658 262.976 368.693 262.671 369.81C261.681 373.457 259.418 382.361 257.146 391.395C254.096 403.557 251.02 415.929 251.02 415.929L249.455 401.059C249.455 401.059 244.602 408.684 244.602 414.61C244.602 414.93 244.593 415.289 244.584 415.699C244.328 422.804 242.356 442.341 253.928 454.813Z"
            fill="#FF6584" />
        <path
            d="M270.836 366.512L277.831 361.241C277.831 361.241 306.975 381.009 327.377 382.327C347.778 383.645 347.195 396.823 339.035 406.048C330.874 415.273 298.815 446.902 297.649 438.995C296.483 431.088 270.836 366.512 270.836 366.512Z"
            fill="#FF6584" />
        <path
            d="M232.41 311.702C237.098 309.914 248.439 308.963 250.098 308.818C254.095 308.469 258.113 309.014 261.924 310.421C265.735 311.828 269.263 314.069 272.307 317.018C275.352 319.966 277.852 323.563 279.667 327.604C281.482 331.645 282.574 336.05 282.883 340.569L297.638 387.861L275.335 389.807L269.972 372.234L265.32 390.681L254.093 391.661L252.392 380.968L249.328 392.077L236.822 393.168C244.553 368.394 248.044 346.122 234.818 333.718C226.596 335.722 219.417 333.818 217.927 328.825C216.211 323.072 222.695 315.406 232.41 311.702Z"
            fill="#2F2E41" />
        <path d="M173.73 367.711H3.99969V465.646H136.602V503.621L152.819 465.646H173.73V367.711Z" fill="#F39D13" />
        <path d="M140.138 401.688H37.5925V391.695H140.138V401.688Z" fill="#F2F2F2" />
        <path d="M140.138 421.675H37.5925V411.682H140.138V421.675Z" fill="#F2F2F2" />
        <path d="M140.138 441.662H37.5925V431.669H140.138V441.662Z" fill="#F2F2F2" />
        <path opacity="0.2" d="M314.055 155.675L356.759 142.779L368.166 191.053L325.463 203.949L314.055 155.675Z"
            fill="#F39D13" />
        <path
            d="M329.515 208.484C322.311 208.484 316.471 201.882 316.471 193.738C316.471 185.594 322.311 178.992 329.515 178.992C336.719 178.992 342.56 185.594 342.56 193.738C342.56 201.882 336.719 208.484 329.515 208.484Z"
            stroke="#3F3D56" stroke-width="2" stroke-miterlimit="10" />
    </g>
    <defs>
        <filter id="filter0_d" x="0" y="0" width="856" height="692" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="clip0">
            <rect width="848" height="684" fill="white" transform="matrix(-1 0 0 1 852 0)" />
        </clipPath>
    </defs>
</svg>

    </div>
</section>
<br><br><br>


<div class="text-center mb-5">
 
    <h1 class="font-weight-bold display-4 ">To create a  <span style="color: #ff5722">job alert</span></h1>
</div>
<section id="container">

        
        <div class="col-12 col-md-6 mx-auto">
            <div class="">
                <div class="bg-light position-relative px-3 my-5">
                    <div class="font-weight-bold circle text-white rounded-circle d-flex align-items-center justify-content-center mx-auto position-relative border border-white"
                        style="width: 60px;height: 60px;top: -30px;border-width: 4px !important; background-color: #ff5722">
                        1
                    </div>
                    <div class="px-3 text-center pb-3">
                        <h4>Search jobs that interest you</h4>
                        <p class="font-weight-light my-3">Filter by location, salary, and more.</p>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="bg-light position-relative px-3 my-5">
                    <div class="font-weight-bold circle text-white rounded-circle d-flex align-items-center justify-content-center mx-auto position-relative border border-white"
                        style="width: 60px;height: 60px;top: -30px;border-width: 4px !important; background-color: #ff5722">
                        2
                    </div>
                    <div class="px-3 text-center pb-3">
                        <h4>Turn the job alert toggle on</h4>
                        <p class="font-weight-light my-3">Get weekly or daily updates when new matching jobs open.
</p>
                    </div>
                </div>
            </div>
            
            <div class="">
                <div class="bg-light position-relative px-3 my-5">
                    <div class="font-weight-bold circle text-white rounded-circle d-flex align-items-center justify-content-center mx-auto position-relative border border-white"
                        style="width: 60px;height: 60px;top: -30px;border-width: 4px !important; background-color: #ff5722">
                        3
                    </div>
                    <div class="px-3 text-center pb-3">
                        <h4>Set alert preferences</h4>
                        <p class="font-weight-light my-3">Choose email updates, notifications, or both.</p>
                    </div>
                </div>
            </div>
        </div>

</section>
<br><br>