import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Friend } from 'app/shared/VIewmodels/Friend';
import { ChatDetail } from 'app/shared/VIewmodels/ChatDetail';
import { NgForm } from '@angular/forms';
import { IndividualChatDetails } from 'app/shared/VIewmodels/IndividualChatDetail';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ubio } from 'app/shared/VIewmodels/ubio';
import { skills } from 'app/shared/VIewmodels/Skills';
import { education } from 'app/shared/VIewmodels/Education';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { UserService } from 'app/shared/UserService/user.service';
import { Router } from '@angular/router';
import { ModalServiceService } from 'app/shared/Modalpopup/modal-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { experience } from 'app/shared/experience';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { DomSanitizer } from '@angular/platform-browser';
import { filter } from 'rxjs-compat/operator/filter';

@Component({
  selector: 'app-profile1',
  templateUrl: './profile1.component.html',
  styleUrls: ['./profile1.component.scss'],
})
export class Profile1Component implements OnInit {
    fileupload: File = null; fid: any;
  userDetails: any;
  username: any;
  email: any;
  role: any;
  salaryrange: any;
  perks: any;
  industry: any;
  growth: any;
  loc1: any;
  loc2: any;
  loc3: any;
  b: any[] = [1, 2, 8, 5];

  className: string;
  chatdetail: ChatDetail;
  Users: any = [];
  userid: any;
  isLoggedIn: boolean;
  fullName: string;
  touserid: any;
  chatopen: boolean;
  IndividualChatDetail: any;
  uid: boolean;
  chattoid: any;
  chatvar: any;
  chattouserid: any;
  chaatto: any;
  aaaaa: ChatDetail;
  IndividualChatRecivedDetails: any;
  IndividualChatSentDetails: any;
  UserImage: any;
  BMid: string;
  touser: any;
  currentuser: string;
  data: any;
  chtnm: any;
  cnnTableData: any = [];
  closeResult: string;
  accid: any;
  m: any;
  Friends: any;
  accountid: string;
  profpic: any;
  salary: any;
  city: any;
  period: any;
  resume: any;
  files1: any;
  SelectedFiles: string[];
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  Creatingmsg: string;
  num: NodeJS.Timeout;
  n: number;
  allFolders1: any;
  genders: any;
  gender: any;
  ResumeFile: any;
  zzz: any;
  pdfUrl: any;
  current_url: any;
  msg: any;
  config: {
    displayKey: string; //if objects array passed which key to be displayed defaults to description
    search: boolean; //true/false for the search functionlity defaults to false,
    height: string; //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: string; // text to be displayed when no item is selected defaults to Select,
    customComparator: () => void; // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: number; // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: string; // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: string; // text to be displayed when no items are found while searching
    searchPlaceholder: string; // label thats displayed in search input,
    searchOnKey: string;
  };
  slist: any;

  selectedItemsSkils:any = [];
  dropdownSettingsForSkiltype: {};
  MultipleSkilTypes: any=[];
  allSkilTypes: any;
  skilsselecte: any = "";

  constructor(
    private userService: RegistrationService,
    private userser: UserService,
    private httpService: HttpClient,
    private userservice: UserService,
    private router: Router,
    private modalService1: ModalServiceService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private service: MasterService,
     private domSanitizer: DomSanitizer,
  ) {
    if (localStorage.loadingpage == 'true') {
      localStorage.loadingpage = 'false';
      location.reload();
    }
    this.username = localStorage.getItem('UserName');
    this.email = localStorage.getItem('MailId');
    if (localStorage.getItem('IsLoggedIn') == 'true') {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem('userId');
      this.accountid = localStorage.getItem('uid');
    }
    this.isLoggedIn = true;
    this.userid = Number(localStorage.getItem('userId'));
    this.currentuser = localStorage.getItem('UserName');
    this.BMid = localStorage.getItem('uid');
  }
  image: any;
  ngOnInit(): void {
    this.config = {
      displayKey:"indrustType", //if objects array passed which key to be displayed defaults to description
      search:true, //true/false for the search functionlity defaults to false,
      height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder:'Select Skill', // text to be displayed when no item is selected defaults to Select,
      customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder:'Search', // label thats displayed in search input,
      searchOnKey: 'indrustType', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      }
    var id = localStorage.getItem('uid');
    this.userService.getImage(this.userid).subscribe((data: any) => {
      this.image = data;
      this.image = 'data:image/png;base64,' + this.image;
      // console.log(this.image);
    });
    this.getskilllist();
    this.dropdownSettingsForSkiltype = {
      singleSelection: false,
      idField: 'id',
      textField: 'skill',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.userService.getprofile(id).subscribe((data: any) => {
      this.userDetails = data;
      this.userService.getrole(this.userDetails.role).subscribe((data: any) => {
        this.role = data;
      });
      this.userService
        .getsalary(this.userDetails.salarymin)
        .subscribe((data: any) => {
          this.salaryrange = data;
        });
      this.userService
        .getperks(this.userDetails.perks)
        .subscribe((data: any) => {
          this.perks = data;
        });
      this.userService
        .getindustry(this.userDetails.industry)
        .subscribe((data: any) => {
          this.industry = data;
        });
      this.userService
        .getgrowth(this.userDetails.growth)
        .subscribe((data: any) => {
          this.growth = data;
        });
       this.userService
        .getgender(this.userDetails.gender)
        .subscribe((data: any) => {
          this.gender = data;
        });
       
      this.userService
        .geteducation(this.userDetails.salarymax)
        .subscribe((data: any) => {
          this.education = data;
        });
      console.log(this.userDetails);
    });
    this.service.getindustry().subscribe((data: any) => {
      this.industries = data;
      console.log(this.industries);
    });

    this.getExp();
    this.getbio();
    this.getUserImage();
    this.getEdu();
    this.getSkills();
    this.getcertificates();
    // this.getmarks();
    this.loadChatDetail();
    this.GetDetails();
    setTimeout(() => {
      var footer = document.getElementById('footer');
      if (footer) {
        footer.style.display = 'block';
      }
    }, 1000);


    
  }

  getskilllist() { 
    this.userser.getallskils().subscribe((respose: any) => {
      this.slist = respose;
       
      console.log(respose);
    });
  }
  selectedskils(value:any){
    let dd = this.slist.filter(c => c.id == value);
    this.MultipleSkilTypes.push(dd[0]); 
    this.skilsselecte = this.skilsselecte + ", "+ dd[0].skill;
  }
  // onItemSelectskilType(item: any) {
  //   this.MultipleSkilTypes.push(item);
  // }
  // onSelectAllskilsTypes(items: any) {
  //   this.allSkilTypes = items;
  // }
  getbio() {
    var uid = localStorage.getItem('userId');
    this.userser.getbio(uid).subscribe((respose: any) => {
      this.bio = respose;
      console.log(respose);
    });
  }
  education: any;
  onchangeindustry(event) {
    const value = event.value.id;
    this.industry = value;
    console.log(value);
  }
  getmarks() {
    var uid = localStorage.getItem('userId');
    this.userser.getMarks(uid).subscribe((respose: any) => {
      this.marks = respose;
      console.log(respose);
    });
  }

  imgp = false;
  usrimage: any;
  getcertificates() {
    var uid = localStorage.getItem('userId');
    this.userser.getCerti(uid).subscribe((respose: any) => {
      this.Certi = respose;
      console.log('Hello');
      console.log(respose);
    });
  }
  getUserImage() {
    var uid = localStorage.getItem('userId');
    this.userser.getUserImage(uid).subscribe((respose: any) => {
      if (respose != '0') {
        this.imgp = true;
        this.usrimage = respose;
      } else {
        this.imgp = false;
      }
    });
  }

  edrad = false;
  Cname: any;
  Course: any;
  date: any;
  addedct() {
    this.edrad = true;
    this.Cname = '';
    this.Course = '';
    this.date = '';
  }
  Edudet: any;

  getEdu() {
    var uid = localStorage.getItem('userId');
    this.userser.getEduDet(uid).subscribe((respose: any) => {
      this.Edudet = respose;
      console.log(respose);
    });
  }
  Skilldet: any;
  getSkills() {
    var uid = localStorage.getItem('userId');
    this.userser.getSkillDet(uid).subscribe((respose: any) => {
      this.Skilldet = respose;
      console.log(respose);
    });
  }
  requestingfor: any;
  edid: any;
  editpp(a) {
    this.edrad = false;
    this.Cname = a.college;
    this.Course = a.course;
    this.date = a.date;
    this.edid = a.id;
  }

  deleteedu(data) {
    this.userser.deleteedu(data.id).subscribe((respose: any) => {
      if ((respose = '1')) {
        alert('Deleted successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again! ');
        location.reload();
      }
    });
  }

  deleteskl(data) {
    this.userser.deleteskl(data.id).subscribe((respose: any) => {
      if ((respose = '1')) {
        alert('Deleted successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again! ');
        location.reload();
      }
    });
  }
  // accountid = localStorage.getItem("uid");
  AddEdu() {
    var uid = localStorage.getItem('userId');
    const inp: education = {
      Id: 0,
      College: this.Cname,
      Course: this.Course,
      date: this.date,
      UserId: Number(uid),
      UID: this.accountid,
    };

    this.userser.saveoreditedu(inp).subscribe((respose: any) => {
      if (respose == '1') {
        alert('Education details added successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again! ');
        location.reload();
      }
    });
  }

  updatedu() {
    var uid = localStorage.getItem('userId');
    const inp: education = {
      Id: this.edid,
      College: this.Cname,
      Course: this.Course,
      date: this.date,
      UserId: Number(uid),
      UID: this.accountid,
    };

    this.userser.saveoreditedu(inp).subscribe((respose: any) => {
      if (respose == '1') {
        alert('Education details updated successfully.');
        location.reload();
      } else {
        alert('Something went wrong. Please try again! ');
        location.reload();
      }
    });
  }
  Skill: any;
  addskl() {
    this.edrad = true;
    this.Skill = '';
  }
  skid = null;
  editskl(a) {
    this.edrad = false;
    this.Skill = a.skill1;
    this.skid = a.id;
  }

  Addskill() {
   var aa = this.MultipleSkilTypes;
   for (let index = 0; index < this.MultipleSkilTypes.length; index++) {    
    if(index == 0)
    this.Skill =  this.MultipleSkilTypes[index].skill;
    else
    this.Skill = this.Skill +","+this.MultipleSkilTypes[index].skill;     
   }
    var uid = localStorage.getItem('userId');
    const inp: skills = {
      Id: 0,
      UID: this.accountid,
      UserId: Number(uid),
      Skill1: this.Skill,
    };

    this.userser.saveoreditskill(inp).subscribe((respose: any) => {
      if (respose == '1') {
        alert('Skill details added successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again! ');
        location.reload();
      }
    });
  }

  updateskill() {
    var aa = this.MultipleSkilTypes;
    for (let index = 0; index < this.MultipleSkilTypes.length; index++) {
     
     if(index == 0)
     this.Skill =  this.MultipleSkilTypes[index].skill;
     else
     this.Skill = this.Skill +","+this.MultipleSkilTypes[index].skill;
      
    }
    var uid = localStorage.getItem('userId');
    const inp: skills = {
      Id: this.skid,
      UID: this.accountid,
      UserId: Number(uid),
      Skill1: this.Skill,
    };

    this.userser.saveoreditskill(inp).subscribe((respose: any) => {
      if (respose == '1') {
        alert('Skill details updated successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again! ');
        location.reload();
      }
    });
  }

  deletedoc(data) {
    this.userser.deletedoc(data.blockId).subscribe((respose: any) => {
      if ((respose = '1')) {
        alert('Deleted successfully.');
        location.reload();
      } else {
        alert('Something went wrong. Please try again! ');
        location.reload();
      }
    });
  }
  getprofilepic() {
    this.userService.getImage(this.userid).subscribe((data: any) => {
      this.image = data;
      this.image = 'data:image/png;base64,' + this.image;
      console.log(this.image);
    });
  }
  saveprofilepic() {
    var uid = localStorage.getItem('userId');
    const frmData = new FormData();
    frmData.append('fileUpload', this.profpic);

    frmData.append('userId', uid);

    this.httpService
      .post('https://blockchainmatrimony.com/jobzoneapi/api/Account/updateprofilepic/', frmData)
      .subscribe(
        (data) => {
          // SHOW A MESSAGE RECEIVED FROM THE WEB API.

          if (data == '1') {
            //document.getElementById("cancelUpload").click();

            alert('File uploaded successfully.');
            this.getprofilepic();
            // this.();
          } else {
            alert('Something went wrong. Please try again!');
          }
        },
        (err: HttpErrorResponse) => {
          alert('Something went wrong. Please try again!');
          console.log(err.message); // Show error, if any.
        }
      );
  }

  Addbio() {
    var uid = localStorage.getItem('userId');
    const inp: ubio = {
      uid: uid,
      bio: this.bio,
    };

    this.userser.savebio(inp).subscribe((respose: any) => {
      if (respose == '1') {
        alert('Bio added successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again!');
        location.reload();
      }
    });
  }

  fileToUploaddoc: FileList;
  Certi: any;
  marks: any;

  ubio: any;
  bio: any;
  base64: string;
  uploaddocs(flname) {
    var uid = localStorage.getItem('userId');

    const frmData = new FormData();
    for (let i = 0; i < this.fileToUploaddoc.length; i++) {
      frmData.append('fileUpload', this.fileToUploaddoc[i]);
    }

    frmData.append('userId', uid);

    frmData.append('foldername', flname);

    this.httpService
      .post('https://localhost:8541/api/Account/UploadCertiandMkc/', frmData)
      .subscribe(
        (data) => {
          // SHOW A MESSAGE RECEIVED FROM THE WEB API.

          if (data == '1') {
            //document.getElementById("cancelUpload").click();

            alert('File uploaded successfully');
            location.reload();
            // this.();
          } else {
            alert('Something went wrong. Please try again! ');
            location.reload();
          }
        },
        (err: HttpErrorResponse) => {
          alert('Something went wrong. Please try again! ');
          console.log(err.message); // Show error, if any.
        }
      );
  }

  fileToUpload;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  handleFileInput1(files: FileList) {
    this.fileToUploaddoc = files;
  }
  handleFileInput2(files: File) {
    this.profpic = files[0];
  }
    handleFileInput3(files: File) {
    this.resume = files[0];
    }
  
  
  // ---------------------------------------------------
  OnSelectUser(id) {
    this.touserid = id;
    this.userservice.GetToUser(this.touserid).subscribe((respose: any) => {
      this.touser = respose;
    });
  }
  messa: any;
  SaveUserChat() {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: this.currentuser,
      ChatTo: this.touserid,
      ChatToName: this.touser.userName,
      ChatMessage: this.messa,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.touserid,
    };
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert('Message sent successfully');
      }
    });
  }

  GetCurrentUserImage() {
    this.userservice
      .getCurrentUSerImage(this.userid)
      .subscribe((respose: any) => {
        this.UserImage = respose;
      });
  }

  loadChatDetail() {
    this.userservice
      .GetChatforParticularUser(this.userid)
      .subscribe((chat: any) => {
        this.chatdetail = chat;
      });
  }

  OpenChatWindow(chat) {
    this.chatvar = chat;
    this.chattouserid = chat.chatFrom;
    this.chaatto = chat.userID;
    this.chatopen = true;
    const inputRequest: IndividualChatDetails = {
      Chatid: chat.id,
      ChatFrom: chat.chatFrom,
      ChatTo: chat.userID,
    };
    this.userservice
      .GetIndividualChatRecivedDetails(inputRequest)
      .subscribe((data: any) => {
        // this.IndividualChatRecivedDetails = data.recivedchatdetails;
        this.IndividualChatSentDetails = data;
        console.log(this.IndividualChatSentDetails);
        for (let i = 0; i < this.IndividualChatSentDetails.length; i++) {
          if (
            this.IndividualChatSentDetails[i].chatFromName != this.currentuser
          ) {
            console.log(this.IndividualChatSentDetails);
            this.chtnm = this.IndividualChatSentDetails[i].chatFromName;
            break;
          }
        }

        if (this.userid == chat.chatTo) {
          this.uid = true;
        }
      });
  }
  goto() {
    this.router.navigate(['chat']);
  }
  SendIndividualMessage(form: NgForm) {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: '',
      ChatTo: this.chaatto,
      ChatToName: '',
      ChatMessage: form.controls['individualchatmessage'].value,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.chattouserid,
    };
    this.aaaaa = inputRequest;
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.OpenChatWindow(this.chatvar);
        (<HTMLInputElement>(
          document.getElementById('individualchatmessage')
        )).value = '';
      }
    });
  }

  CloseChatWindow() {
    this.chatopen = false;
  }
  GetDetails() {
    this.userservice.friendlist(this.userid).subscribe((respose: any) => {
      this.Friends = respose;
      this.spinner.hide();
    });
  }

  addFriend(addfriendcontent) {
    this.modalService.open(addfriendcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.accid;
      }
    );
  }
  savefrienddetails() {
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
    };
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == 'SameUser') {
        alert('Please enter your friend mailId!');
        this.GetDetails();
      } else if (data == 'alreadyfriends') {
        alert('You are already friends!');
        this.GetDetails();
      } else if (data == 'NoUserFound') {
        alert('We are sorry ' + this.accid + ' is not using jobs-zone!');
        this.GetDetails();
      } else if (data == 'SentRequest') {
        alert('You have already sent or recived request from this user!');
        this.GetDetails();
      } else if (data == '1') {
        alert('Friend request sent successfully');
        this.GetDetails();
      } else {
        alert('Something went wrong!');
        // this.route.navigate(['/allfolders']);
      }
    });
  }
  acceptrequest(f) {
    this.userservice.AcceptReq(f).subscribe((data: any) => {
      alert('Accepted successfully');
      // this.route.navigate(['/allfolders']);
      this.GetDetails();
    });
  }

  // ViewDocument(bb, pdfcontent) {
  //   this.modalService.open(pdfcontent).result.then(
  //     (result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     },
  //     (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       this.m = 'yvtfcfu';
  //     }
  //   );
  // }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService1.close(id);
  }

  Edudet1: any;

  edrad1: boolean;
  Cname1: string;
  Course1: string;

  edid1: any;
  addedct1() {
    this.edrad1 = true;
    this.Cname1 = '';
    this.Course1 = '';
    this.salary = '';
    this.period = '';
     this.city = '';
    this.date = '';
  }

  editpp1(b) {
    this.edrad1 = false;
    this.Cname1 = b.oragnisation;
    this.Course1 = b.designation;
    this.salary = b.salary;
    this.period = b.period;
    this.city = b.city;

    this.date = b.date;
    this.edid1 = b.id;
  }

  getExp() {
    var uid = localStorage.getItem('userId');
    this.userser.getExpDet(uid).subscribe((respose: any) => {
      this.Edudet1 = respose;
      console.log(respose);
    });
  }
  deleteexp(data) {
    this.userser.deleteexp(data.id).subscribe((respose: any) => {
      if ((respose = '1')) {
        alert('Deleted successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again ! ');
        location.reload();
      }
    });
  }

  updatexp() {
    var uid = localStorage.getItem('userId');
    const inp: experience = {
      Id: this.edid1,
      Oragnisation: this.Cname1,
      Designation: this.Course1,
      date: this.date,
      Salary: this.salary,
      City: this.city,
      Period:this.period,
      UserId: Number(uid),
      UID: this.accountid,
    };

    this.userser.saveoreditexp(inp).subscribe((respose: any) => {
      if (respose == '1') {
        alert('Experience details updated successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again!');
        location.reload();
      }
    });
  }

  AddExp() {
    var uid = localStorage.getItem('userId');
    const inp: experience = {
      Id: 0,
      Oragnisation: this.Cname1,
      Designation: this.Course1,
      date: this.date,
      Salary:this.salary,
      City: this.city,
      Period: this.period,
  
   
      UserId: Number(uid),
      UID: this.accountid,
    };

    this.userser.saveoreditexp(inp).subscribe((respose: any) => {
      if (respose == '1') {
        alert('Experience details added successfully');
        location.reload();
      } else {
        alert('Something went wrong. Please try again!');
        location.reload();
      }
    });
  }
  roles: any;
  industries: any;
  salaries: any;
  growths: any;
  HQlist: any;
  getmaster() {
    this.GetHighQualification();
    this.service.getrole().subscribe((data: any) => {
      this.roles = data;

      console.log(this.roles);
    });
    this.service.getperks().subscribe((data: any) => {
      this.perks = data;

      console.log(this.perks);
    });
    this.service.getindustry().subscribe((data: any) => {
      this.industries = data;

      console.log(this.industries);
    });
    this.service.getsalary().subscribe((data: any) => {
      this.salaries = data;

      console.log(this.salaries);
    });
    this.service.getgrowth().subscribe((data: any) => {
      this.growths = data;

      console.log(this.growths);
    });
      this.service.getgender().subscribe((data: any) => {
      this.genders = data;
      console.log(this.genders);
    });
    
  }
  GetHighQualification() {
    this.userService.GetHighQualification().subscribe((response: any) => {
      this.HQlist = response;
      console.log(this.HQlist);
    });
  }
    onchangegender(event) {
    const value = event.target.value;
    this.gender = value;
    console.log(value);
  }
  onChange(event: any) {
    var fileslist = "";
    var files = [].slice.call(event.target.files);

    this.files1 = [].slice.call(event.target.files);
    // console.log(this.files1);
    for (let k = 0; k < this.files1.length; k++) {
      fileslist = this.files1[0].name + ",";
    }
    this.SelectedFiles = fileslist.split(',');
  }
  
  onDrop(event: any) {

    var fileslist = "";
    var files = event;

    this.files1 = files;
    for (let k = 0; k < this.files1.length; k++) {
     
      fileslist = this.files1[0].name + ",";
    }
    this.SelectedFiles = fileslist.split(',');
  
  }
  sMsg: any;
  createdmsg: string;
  foldercreated: boolean;
  FileFoldID: any;
    pid: any;
  Uploaddocs1() {
    document.getElementById("cancelUpload").click();

    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Uploading resume...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Uploading resume...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const frmData = new FormData();

    
    for (var i = 0; i < this.files1.length; i++) {
      frmData.append("fileUpload", this.files1[i]);
    }

    //frmData.append("fileUpload", this.files1);
    frmData.append("userId", this.userid);
    frmData.append("username", this.username);
    // frmData.append("folderid", this.allSubFolders.parentId);
      this.showper = true;
    this.httpService.post('https://blockchainmatrimony.com/jobzoneapi/api/UploadDocument/NewDocumentUpload1/', frmData).subscribe(
      data => {
        // SHOW A MESSAGE RECEIVED FROM THE WEB API.
        this.sMsg = data as string;
        console.log(this.sMsg);
        if (this.sMsg == 0) {
          //document.getElementById("cancelUpload").click();
          this.n = 100;
          this.createdmsg = "Resume uploaded successfully";
          // alert("Resume Uploaded Successfully");
          this.pid = data;
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);

          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          this.files1 = [];
          this.SelectedFiles = null;
          this.SelectedFiles = [];

          // this.GetDetails(this.FileFoldID);
          // this.GetResume();

        }
        else {
        }
      },
      (err: HttpErrorResponse) => {
        alert("Something went wrong. Please try again! ")
        // console.log(err.message);    // Show error, if any.
      });
  }

  getPercent(per) {
    return 10;
  }

  GetResume() {
    this.spinner.show();
    this.userservice.getresumefile(this.userid).subscribe((respose: any) => {
      this.ResumeFile = respose;
      this.zzz = this.ResumeFile;
      this.spinner.hide();
      console.log(this.ResumeFile.file);
      // this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
      this.current_url = this.ResumeFile.file;
      this.pdfUrl = this.current_url;
       console.log(this.ResumeFile.file);
   
    });
  }
  clicked(){
  document.getElementById("ppup12").click();
  }
    ViewDocument(aa) {
 
    this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
  //  console.log(this.pdfUrl);
      this.pdfUrl = this.current_url;
      console.log("view pdf link = " + this.pdfUrl);
      
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    // this.modalService.open(pdftesting).result.then((result) => {
     
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //   this.m = this.msg;
    //   console.log(this.m)

    // });
  }

}