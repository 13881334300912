import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'app/shared/MailService/mail.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-delete-mails',
  templateUrl: './delete-mails.component.html',
  styleUrls: ['./delete-mails.component.scss']
})
export class DeleteMailsComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  DateTime: Date;
  deleteList: any;

  
  constructor(private router: ActivatedRoute,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal,
    private spinner: NgxSpinnerService, private datepipe: DatePipe, private http: HttpClient, 
    private mailservice: MailService) {
       
    
      if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");
    }
  }


  ngOnInit(): void {
    this.GetDeleteMailList();
  }

  inbox() {
    this.route.navigate(['inboxmails']);
  }
  composemail() {
    this.route.navigate(['composemsg']);
  }
  deleteitems() {
    this.route.navigate(['del']);
  }
  sentitems() {
    this.route.navigate(['sentmails']);
  }
  archiveitems() {
    this.route.navigate(['archive']);
  }

  GetDeleteMailList() {
    this.spinner.show();
    var info ={
      UID  :this.uid
    }
    this.mailservice.getdeletelist(info).subscribe((respose: any) => {
      this.deleteList = respose;
      console.log(respose);
      this.spinner.hide(); 
    });
  }

  ReadMessage(data) {
    localStorage.setItem('fromuser', data.From);
    localStorage.setItem('touser', data.To);
    localStorage.setItem('attachments', data.Attachments);
    localStorage.setItem('mailid', data.MasId);
    localStorage.setItem('transid', data.TransId);
    localStorage.setItem('maildate', data.DateforRM);
    localStorage.setItem('profilepic', data.UserProfilePic);
    localStorage.setItem('firstletter', data.FirstLetter);
    localStorage.setItem('docname', data.DocumentName);
    localStorage.setItem('emailbody', data.EmailBody);
    localStorage.setItem('sub', data.Subject); 
    this.route.navigate(['readmsg/'+ data.mailId]);
  }
  sort = "1";

  sortby() {
    if (this.sort == "1") {
      this.deleteList.sort((a, b) => a.fromName.toLowerCase() < b.fromName.toLowerCase() ? -1 : a.fromName.toLowerCase() > b.fromName.toLowerCase() ? 1 : 0)
      this.sort = "2";
    }
    else if (this.sort == "2") {
      this.deleteList.sort((a, b) => a.fromName.toLowerCase() > b.fromName.toLowerCase() ? -1 : a.fromName.toLowerCase() < b.fromName.toLowerCase() ? 1 : 0)
      this.sort = "1";

    }

  }
  sortname = "1";
  sortbydate() {
    if (this.sortname == "1") {
      this.deleteList.sort((a, b) => a.Sortdate < b.Sortdate ? -1 : a.Sortdate > b.Sortdate ? 1 : 0)
      this.sortname = "2";
    }
    else if (this.sortname == "2") {
      this.deleteList.sort((a, b) => a.Sortdate > b.Sortdate ? -1 : a.Sortdate < b.Sortdate ? 1 : 0)
      this.sortname = "1";

    }

  }
}