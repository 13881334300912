import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { onlyid } from 'app/shared/VIewmodels/onlyid';
import { streamstore } from './storestream';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  // readonly rootUrl = "https://blockchainmatrimony.com/jobzoneapi/api/";
  // readonly rooturl = "https://blockchainmatrimony.com/jobzoneapi/api/";
  readonly rooturl = 'https://blockchainmatrimony.com/jobzoneapi/api/';

  constructor(private http: HttpClient) {}

  getAllquestions(blkid: onlyid) {
    return this.http.post(this.rooturl + 'Question/questions', blkid);
  }

  getcoursequestions(id) {
    return this.http.get(
      this.rooturl + 'QuestionMaster1/GettblQuestionBankMasters/' + id
    );
  }

  savequestion(data: any) {
    return this.http.post(
      this.rooturl + 'QuestionMaster/PosttblQuestionBankMaster',
      data
    );
  }

  UpdatequestionRow(data: any) {
    return this.http.post(
      this.rooturl + 'QuestionMaster1/PuttblQuestionBankMaster',
      data
    );
  }

  deletequestiondata(id: any) {
    return this.http.get(
      this.rooturl + 'QuestionBankMasters/DeletetblQuestionBankMaster/' + id
    );
  }
  getQuestions(id: any) {
    return this.http.post(this.rooturl + 'Question/getQuestions', id);
  }
  storeMarks(id: any) {
    return this.http.post(this.rooturl + 'Question/storeMarks', id);
  }
  storevideo(video) {
    return this.http.post(this.rooturl + 'Question/upload', video);
  }
  getoptions(id: any) {
    return this.http.get(this.rooturl + 'questionMaster1/getoptions/' + id);
  }
  postoptions(dat: any) {
    return this.http.post(this.rooturl + 'questionMaster1/postoptions', dat);
  }

  getcodeanswers(id: any) {
    return this.http.get(this.rooturl + 'questionMaster1/getcodeanswers/' + id);
  }
  postcodeoptions(dat: any) {
    return this.http.post(
      this.rooturl + 'questionMaster1/postcodeoptions',
      dat
    );
  }
  savecodequestion(data: any) {
    return this.http.post(
      this.rooturl + 'QuestionMaster/PostCodequestion',
      data
    );
  }
  UpdatecodequestionRow(data: any) {
    return this.http.post(
      this.rooturl + 'QuestionMaster1/putcodequestion',
      data
    );
  }
  deletecodequestiondata(id: any) {
    return this.http.get(
      this.rooturl + 'QuestionBankMasters/deletecodequestion/' + id
    );
  }
  getAllcodequestions(blkid: any) {
    return this.http.post(this.rooturl + 'Question/codequestions', blkid);
  }
  getcodequestion(id) {
    return this.http.post(this.rooturl + 'Question/getcodequestion', id);
  }
  checktest(id) {
    return this.http.post(this.rooturl + 'Question/checktest', id);
  }

  storepeerid(uid, qid, peerid) {
    const data: streamstore = {
      peerid: peerid,
      qid: qid,
      uid: uid,
    };
    return this.http.post(this.rooturl + 'Question/storepeerdatahost', data);
  }
  storepeeridstudent(uid, qid, peerid) {
    const data: streamstore = {
      peerid: peerid,
      qid: qid,
      uid: uid,
    };
    return this.http.post(this.rooturl + 'Question/storepeerdatastudent', data);
  }
}
