import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/UserService/user.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sharefile',
  templateUrl: './sharefile.component.html',
  styleUrls: ['./sharefile.component.scss']
})
export class SharefileComponent implements OnInit {
  selectedSelected: any[];
  isLoggedIn: boolean;
  uid: string;
  username: string;
  roleid: string;
  gridvalue: string;
  allFolders: any;
  SharedFiles: any;
  subf: any;
  allSubFolders: any;
  videoplay: any;
  zzz: any;
  current_url: any;
  domSanitizer: any;
  pdfUrl: any;
  closeResult: string;
  txtUrl: any;
  m: any;
  msg: any;
  current_url1: any;
  videodate: any;
  doc: string;
  allFolders1: any;
  FileFoldID: any;
  allSubFolders1: any;
  fid: any;
ppopup:any;

mobile=false;
  zzzz: any;
  constructor(private userservice: UserService, private router: Router,private modalService: NgbModal,private spinner:NgxSpinnerService) {
    this.ppopup=true;
    window.scrollTo(0,0);

    this.spinner.show();
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.username = localStorage.getItem("UserName");
      this.gridvalue = localStorage.getItem("gridvalue");
    }
    window.scrollTo(0,0);

   }
  ngOnInit() {
    this.GetAllSharingFolders();
    this.GetAllFolders1();
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if(footer.style.display=="none"){
        footer.style.display = "block";
      }
    }, 1000);
    if(window.innerWidth<500){
      setTimeout(() => {
        var tree = document.getElementById("treeview");
          
          if(tree){
            tree.style.display = "none";
          }
          var upload = document.getElementById("view");
          
          if(upload){
            upload.style.display = "none";
          }
      }, 100);

        this.mobile=true;
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 6000);
  } 
  
  treeviewba(){
    var tree = document.getElementById("treeview");
    if(tree){
      if(tree.style.display == "none"){
        tree.style.display = "block";
      }
      else{
        tree.style.display = "none";
      }
    }
  }
  GetAllFolders1() {
    this.userservice.getAllSharingfolders(this.uid).subscribe((respose: any) => {
      this.allFolders1 = respose;
      console.log(this.allFolders1);
      if (this.allFolders.length > 0 && this.allFolders[0].sharedFiles.length != 0 && this.allFolders[0].TotalMembers == null) {
        this.SharedFiles = this.allFolders[0].sharedFiles;
      }
      else if (this.allFolders[0].TotalMembers != null) {
        this.allFolders = respose;
        console.log(respose)
        this.SharedFiles = this.allFolders[0].sharedFiles;
      }
      this.spinner.hide();
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  GetAllSharingFolders() {
    //this.GetallFilesForUplodtoCompare();
    this.selectedSelected = [];
    this.userservice.getAllSharingfolders(this.uid).subscribe((respose: any) => {
      this.allFolders = respose;
      if (this.allFolders.length > 0 && this.allFolders[0].sharedFiles.length != 0 && this.allFolders[0].TotalMembers == null) {
        this.SharedFiles = this.allFolders[0].sharedFiles;
      }
      else if(this.allFolders == null||this.allFolders == undefined || this.allFolders == ""){
        this.spinner.hide();
      }
      else if (this.allFolders[0].TotalMembers != null) {
        this.allFolders = respose;
        this.SharedFiles = this.allFolders[0].sharedFiles;
        
      }
      // this.spinner.hide();
    });
  }
  GetSubfolder(data) {
    this.subf = data.folderName;
    this.userservice.getsubfolders(data.folderId).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.folderName;
      var Pid = this.allSubFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));
      console.log("user", this.allSubFolders);
      this.router.navigate(['allsubfolders/' + data.folderID]);
    });
  }

  GetSubfolder1(data) {
    this.subf = data.folderName;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.folderName;
      var Pid = this.allSubFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));
      console.log("user", this.allSubFolders);
      this.router.navigate(['allsubfolders/' + data.folderID]);
    });
  }

  getfolders1(id1) {
    let id = id1.folderID;
   
      let elem = document.getElementById(id);
      if(elem.childNodes.length<2)
      {

   var icn = document.getElementById(id1.id);
   if(icn!=null)
   {
    icn.setAttribute("class","fa fa-chevron-down");

   }

    this.FileFoldID = id;
    this.userservice.getsubfolders(this.FileFoldID).subscribe((respose: any) => {
      this.allSubFolders1 = respose;
      this.subf = this.allSubFolders1.fileName;
      this.fid = this.allSubFolders1.parentId;
      console.log("user", this.allSubFolders1);
     // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

      var mdv1 = document.createElement("div");
for(let i=0;i<this.allSubFolders1.subFolders.length;i++)
{
    var mdv = document.createElement("div");
mdv.setAttribute("id",this.allSubFolders1.subFolders[i].folderID);
    mdv.setAttribute("style","padding-left:20px");
    var elm =document.createElement("span");
    var img = document.createElement("img");
    img.setAttribute("src","./assets/img/folder.png");
    img.setAttribute("width","30px");
    img.setAttribute("height","30px");
    var elm1 =document.createElement("span");
    elm1.innerHTML = this.allSubFolders1.subFolders[i].folderName;

    var elm12 =document.createElement("i");

    elm12.setAttribute("class","fa fa-chevron-right");
    elm12.setAttribute("id",this.allSubFolders1.subFolders[i].id);

    elm12.addEventListener('click', this.getfolders1.bind(this,this.allSubFolders1.subFolders[i]));



    elm.appendChild(elm12);
    elm.appendChild(img);
    elm.appendChild(elm1);

    mdv.appendChild(elm);

    img.addEventListener('click', this.GetSubfolder.bind(this,this.allSubFolders1.subFolders[i]));
    elm1.addEventListener('click', this.GetSubfolder.bind(this,this.allSubFolders1.subFolders[i]));


    mdv1.appendChild(mdv);
}



// for(let i=0;i<this.allSubFolders.retData.length;i++)
// {
//     var mdv = document.createElement("div");
// mdv.setAttribute("id",this.allSubFolders.retData[i].blockId);
//     mdv.setAttribute("style","padding-left:20px");
//     var elm =document.createElement("span");
//     var img = document.createElement("img");

// if(this.allSubFolders.retData[i].documentType=="image/gif")
// {
//     img.setAttribute("src",this.allSubFolders.retData[i].file);
// }
// else if(this.allSubFolders.retData[i].documentType=='image/jpeg'||this.allSubFolders.retData[i].documentType=='image/png')
// {
//     img.setAttribute("src",this.allSubFolders.retData[i].file);
// }
// else if(this.allSubFolders.retData[i].documentType=='application/pdf'||this.allSubFolders.retData[i].documentType=='pdf')
// {
//     img.setAttribute("src","./assets/img/pdficon.png");
// }
// else if(this.allSubFolders.retData[i].documentType=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'||this.allSubFolders.retData[i].documentType=='xlsx')
// {
//     img.setAttribute("src","./assets/img/Excel.png");
// }
// else if(this.allSubFolders.retData[i].documentType=='application/vnd.openxmlformats-officedocument.presentationml.presentation'||this.allSubFolders.retData[i].documentType=='pptx'||this.allSubFolders.retData[i].documentType=='ppt')
// {
//     img.setAttribute("src","./assets/img/ppt.png");
// }
// else if(this.allSubFolders.retData[i].documentType=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'||this.allSubFolders.retData[i].documentType=='application/octet-stream' || this.allSubFolders.retData[i].documentType=='doc' ||this.allSubFolders.retData[i].documentType=='docx')
// {
//     img.setAttribute("src","./assets/img/word.jpg");
// }
// else if((this.allSubFolders.retData[i].documentType=='text/plain') || (this.allSubFolders.retData[i].documentType=='txt'))
// {
//     img.setAttribute("src","./assets/img/textt.png");
// }
// else if(this.allSubFolders.retData[i].documentType=='video/mp4'||this.allSubFolders.retData[i].documentType=='mp4')
// {
//     img.setAttribute("src","./assets/img/nvideo.png");
// }
// else
// {
//     img.setAttribute("src","./assets/img/document.png");
// }
  
//     img.setAttribute("width","30px");
//     img.setAttribute("height","30px");
//     var elm1 =document.createElement("span");
//     elm1.innerHTML = this.allSubFolders.retData[i].documentName;
//     elm.appendChild(img);
//     elm.appendChild(elm1);

//     mdv.appendChild(elm);

//    // mdv.addEventListener('click', this.getfolders.bind(this,this.allSubFolders.retData[i].BLK2020022800001));


//     mdv1.appendChild(mdv);
// }


//mdv1.appendChild(mdv);

elem.appendChild(mdv1);


    });


  }
  else{

    document.getElementById(id1.id).setAttribute("class","fa fa-chevron-right");
    let elem = document.getElementById(id);
     while(elem.childNodes.length>1)
     {
         elem.removeChild(elem.lastChild);
     }
  }
}
  
Delete(data) {
  var res;
  if (data.folderName == null) {
    res = confirm("Do You Want to Delete " + data.documentName + "!!!");
  }
  else {
    res = confirm("Do You Want to Delete " + data.folderName + "!!!");
  }
  if (res == true) {
    if (data.hash) {
      var info = {
        Id: data.id,
        FolderID: data.folderId,
        FolderName: data.folderName,
        UserId: this.uid,
        Code: "Document",
        ParentID: data.folderId,
        RoleId: this.roleid,
        Click: null
      };
    }
    else {
      info = data;
    }
  }
  this.userservice.deletefolder(info).subscribe((data: any) => {
    this.GetAllSharingFolders();
    this.GetAllFolders1();
  });
}

  ViewDocument(aa, pdftesting) {
    this.zzz = aa;
    this.current_url = (this.zzz.file)
    this.pdfUrl = this.zzz.file;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  
  ViewDoc(aa, content) {
    this.zzz = aa;
    this.current_url = (this.zzz.file)
    this.pdfUrl = this.zzz.file;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ViewDocumentword(aa, wordcontent) {
    this.zzz = aa;
    this.current_url = (this.zzz.file)
    this.pdfUrl = this.zzz.file;
    this.doc = "http://docs.google.com/gview?" + this.zzz.file + "&embedded=true";

    this.modalService.open(wordcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ViewDocumenttext(aa, textfileview) {
    this.zzz = aa;
    this.current_url = (this.zzz.file)
    this.txtUrl = this.zzz.file;
    // this.doc = "http://docs.google.com/gview?" + this.zzz.file + "&embedded=true";

    this.modalService.open(textfileview).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ViewxlsDoc(aa, xlscontent) {
    this.zzz = aa;
    this.current_url = (this.zzz.file)
    this.pdfUrl = this.zzz.file;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(xlscontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ViewPpt(aa, pptcontent) {
    this.zzz = aa;
    this.current_url1 = (this.zzz.file)
    this.pdfUrl = this.zzz.file;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pptcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ViewVideo(cc, videocontent) {
    this.videoplay = cc.file
    this.videodate = cc.date
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }
}
