import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from './VIewmodels/role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  readonly url="https://blockchainmatrimony.com/jobzoneapi/api/";
  constructor(private http:HttpClient) { }
  saveRole(data:Role){
    return this.http.post(this.url +"Role/PostSaveRole",data);
  }

  getAllRoles(){
    return this.http.get(this.url +"Role/GetAllRoles");
  }

  deleteRole(id: number){
    return this.http.delete(this.url +"Role/DeleteRoleData/"+ id);
  }

  UpdateRole(data:Role){
    return this.http.put(this.url +"Role/PutRole",data);
  }
}