import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { UserService } from 'app/shared/UserService/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-allstudentlist',
  templateUrl: './allstudentlist.component.html',
  styleUrls: ['./allstudentlist.component.scss'],
})
export class AllstudentlistComponent implements OnInit {
  appcount;
  constructor(
    private registrationService: RegistrationService,
    private route: Router,
    private router: ActivatedRoute,
    private service: MasterService,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.getallApplicants();
  }
  getallApplicants() {
    this.userService.GetallUsersApplicant().subscribe((data: any) => {
      this.appcount = data;
    });
  }
  deletestudent(uid) {
    this.userService.deletecom(uid).subscribe((data: any) => {
      if (data === null) {
        alert("Deleted successfully");
        location.reload();
      } else {
        alert("Something went wrong!");
      }
    });
  }
}
