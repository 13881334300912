<br /><br /><br /><br />
<div class="col-md-12 row">
  <div class="col-md-1"></div>
  <div class="col-md-8">
    <div class="img" style="
        background-image: linear-gradient(
            150deg,
            rgba(63, 174, 255, 0.3) 15%,
            rgba(63, 174, 255, 0.3) 70%,
            rgba(63, 174, 255, 0.3) 94%
          ),
          url(../assets/img/blockc.jpg);

        height: 350px;
        background-size: cover;
      "></div>

    <div class="card social-prof">
      <div class="card-body">
        <div class="wrapper" style="padding-left: 10%">
          <img [src]="image" alt="" class="user-profile" style="
              width: 150px;
              height: 150px;
              border-radius: 50%;
              margin-bottom: 20px;
              border: 5px solid #fff;
            " />
          <div id="edit" data-toggle="modal" data-target="#EditProfilePhoto"
            style="padding-left: 7%; padding-bottom: 2%">
            EDIT
          </div>
          <div class="col-md-12 row">
            <div class="col-md-9">
              <h3>
                <div class="top-section-profile-name" style="    text-transform: capitalize;">{{ username }}</div>
              </h3>
              <br />
              <p>
                {{ email }}
              </p>
              <!-- <p>
                {{ role.role }}
              </p> -->
              <p style="text-align: justify;">{{ bio }}</p>
            </div>
            <div class="col-md-3">
              <div class="col-md-1">
                <div>
                  <!-- <img
                    data-toggle="modal"
                    data-target="#addbio"
                    src="/assets/img/edit.png"
                    width="30vw"
                    height="30vw"
                    style="z-index: 10;"
                  /> -->
                  <button data-toggle="modal" data-target="#addbio" style="
                      border-radius: 10px;
                      background-color: #ff5722;
                      color: white;
                      padding: 5px 30px 5px 10px;
                      z-index: 10;
                      width: 100px;
                    " (click)="addskl()">
                    &nbsp;&nbsp;&nbsp;Bio
                  </button>
                </div>
              </div>
            </div>

            <!-- ----------------- -->
          </div>
        </div>

        <div class="col-md-12 row">
          <div class="col-md-1"></div>
          <div class="col-md-6">
            <hr class="new4" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ------------------------------------------ -->
  <div class="modal fade" id="addbio" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Bio</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container fileUploadWrapper">
            <div class="col-md-12">
              <ul style="list-style: none">
                <div class="modal-body">
                  <form>
                    <h4 class="modal-title" id="modal-basic-title">
                      Highlight Bio
                    </h4>
                    <br />
                    <textarea rows="5" type="text" id="bio" [(ngModel)]="bio" class="form-control"
                      name="bio"></textarea><br />
                  </form>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal"
            style="cursor: pointer">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" (click)="Addbio()" style="cursor: pointer">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- ----------------------------------chat----------------------------- -->
  <div class="col-md-3">
    <div class="card social-timeline-card">
      <div class="card-body" style="height: 640px">
        <h3 style="padding-left: 35%"><b>CHAT</b></h3>

        <div id="nb" style="overflow: auto">
          <div style="margin-bottom: 50px; background-color: white">
            <div class="chat-panel panel panel-default">
              <div class="panel-heading"></div>
              <br />
              <div class="panel-body">
                <div class="chat" *ngFor="let chat of chatdetail">
                  <div class="left clearfix" (click)="OpenChatWindow(chat)" style="cursor: pointer">
                    <span class="chat-img pull-left">
                      <!-- <img src="./assets/img/chat1.png" style="width: 20px;height: 20px;" class="img-circle"
                                        /> -->
                      <!-- <i class="fa fa-comments" aria-hidden="true"></i> -->
                    </span>
                    <a href="studentchat">
                      <div class="chat-body clearfix">
                        <div>
                          <strong class="primary-font" *ngIf="chat.toName != currentuser">
                            {{ chat.toName }}</strong>
                          <strong class="primary-font" *ngIf="chat.chatFromName != currentuser">
                            {{ chat.chatFromName }}</strong>

                          <small class="pull-right text-muted">
                            <i class="fa fa-clock-o fa-fw"></i>
                            {{ chat.date | date: "short" }}
                            <!-- <div class="container" (click)="OpenChatWindow(chat)">
                                                <img src="./assets/img/msg.png" style="width:30px;height: 30px;" class="image" alt="Los Angeles"
                                                   >
                                                <div class="middle" style="font-size: 15x;color:darkmediumslateblue;margin-left: 18px;">
                                                    Click here to Chat</div>
                                            </div> -->
                          </small>
                        </div>
                        <p>
                          {{ chat.chat }}
                        </p>
                        <p>
                          {{ message }}
                        </p>
                      </div>
                    </a>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ------------------------------------education-------------------------------- -->
<div class="col-md-12 row">
  <div class="col-md-1"></div>
  <div class="col-md-8">
    <div class="card social-timeline-card">
      <div class="card-body">
        <h4 class="card-title" style="padding-left: 10px">Qualification</h4>
        <br />
        <!-- <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2">
              <img
                class="rounded-circle"
                width="45"
                src="/assets/img/college.png"
                alt=""
              />
            </div>
            <!-- <div class="ml-2">
              <div class="h5 m-0 text-black">
                1 education.highestQualification }}
              </div>
            </div> -->
        <!-- </div>
        </div>  -->

        <div class="bottom-section-details" style="z-index: 2">
          <div class="bottom-section-right">
            <div *ngFor="let a of Edudet">
              <div class="col-md-12 row" style="margin-top: -20px">
                <div class="mr-2">
                  <img class="rounded-circle" width="45" src="/assets/img/college.png" alt="" />
                </div>
                <div class="col-md-6" style="padding-left: 10px">
                  <span style="font-size: 20px">{{ a.college }}</span><br />
                  <span style="font-size: 18px">{{ a.course }}</span><br />
                  <span style="font-size: 18px">{{ a.date }}</span>
                </div>
                <div class="col-md-3">&nbsp;</div>
                <div class="col-md-1">
                  <div>
                    <img data-toggle="modal" data-target="#exampleModal1" (click)="editpp(a)" src="/assets/img/edit.svg"
                      width="30vw" height="30vw" style="z-index: 10" />
                  </div>
                </div>
                <div class="col-md-1">
                  <div>
                    <img src="/assets/img/delete.svg" (click)="deleteedu(a)" width="30vw" height="30vw"
                      style="z-index: 10" />
                  </div>
                </div>
              </div>
              <br /><br />
            </div>
            <span style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
              ">
              <!-- <button
                data-toggle="modal"
                data-target="#exampleModal1"
                style="
                  border-radius: 10px;
                  background-color: blue;
                  color: white;
                  padding: 5px 10px 5px 10px;
                "
                (click)="addedct()"
              >
                &nbsp;&nbsp;Add
              </button> -->
              <img src="/assets/img/add.svg" (click)="addedct()" width="50vw" height="50vw" style="z-index: 10"
                data-toggle="modal" data-target="#exampleModal1" />
            </span>
          </div>
          <br /><br /><br /><br />
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card social-timeline-card">
      <div class="card-body" style="height: auto">
        <br /><br />
        <div style="color: #ff5722">Preferred Role</div>
        <div class="bottom-section-right">{{ role.role }}</div>
        <br />
        <div class="bottom-section-details">
          <div class="bottom-section-right" style="color: #ff5722">
            Preferred Salary Range
          </div>
          <div class="bottom-section-right">{{ salaryrange.salary }}</div>
        </div>

        <br />
        <div class="bottom-section-details">
          <div class="bottom-section-right" style="color: #ff5722">
            Preferred Industry
          </div>
          <div class="bottom-section-right">{{ industry.indrustType }}</div>
        </div>
        <br />
        <div class="bottom-section-details">
          <div class="bottom-section-right" style="color: #ff5722">
            Preferred Growth
          </div>
          <div class="bottom-section-right">{{ growth.growth1 }}</div>
        </div>
        <br />
      </div>
    </div>
  </div>
</div>
<!-- -----------------------------------------Skills-------------------------------------------------------------------- -->

<div class="col-md-12 row">
  <div class="col-md-1"></div>
  <div class="col-md-8">
    <div class="card social-timeline-card">
      <div class="card-body">
        <h4 class="card-title" style="padding-left: 10px">Skills</h4>
        <br />
        <div class="bottom-section-details" style="z-index: 2">
          <div class="bottom-section-right">
            <div *ngFor="let a of Skilldet">
              <div class="col-md-12 row" style="margin-top: -20px">
                <div class="col-md-6" style="padding-left: 10px">
                  <span style="width: max-content; font-size: 20px">
                    {{ a.skill1 }}</span>
                </div>
                <div class="col-md-3">&nbsp;</div>
                <div class="col-md-1">
                  <div>
                    <img data-toggle="modal" data-target="#Addskill" (click)="editskl(a)" src="/assets/img/edit.svg"
                      width="30vw" height="30vw" style="z-index: 10" />
                  </div>
                </div>
                <div class="col-md-1">
                  <div>
                    <img src="/assets/img/delete.svg" (click)="deleteskl(a)" width="30vw" height="30vw"
                      style="z-index: 10" />
                  </div>
                </div>
              </div>
              <br /><br />
            </div>
            <span style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
              ">
              <!-- <button
                data-toggle="modal"
                data-target="#Addskill"
                style="
                  border-radius: 10px;
                  background-color: blue;
                  color: white;
                  padding: 5px 10px 5px 10px;
                "
                (click)="addskl()"
              >
                &nbsp;&nbsp;Add
              </button> -->
              <img src="/assets/img/add.svg" (click)="addskl()" width="50vw" height="50vw" style="z-index: 10"
                data-toggle="modal" data-target="#Addskill" />
            </span>
          </div>
          <br /><br /><br /><br />
        </div>
      </div>
    </div>
  </div>
  <!-- -----resume---- -->
  <div class="col-md-3">
    <div class="card social-timeline-card">
      <div class="card-body" style="height: auto">
        <!-- <br />
          <h3   style="    text-align: center;">Resume&nbsp;&nbsp; <img src="/assets/img/add.svg" (click)="addskl()" width="50vw" height="50vw" style="z-index: 10" data-toggle="modal"
            data-target="#Addskill1" /></h3>
         
          <br /><br />
            <div style="    text-align: center;">
            <button data-toggle="modal" data-target="#resume" style="
                                                                  border-radius: 10px;
                                                                  background-color: #ff5722;
                                                                  color: white;
                                                                  padding: 5px 30px 5px 10px;
                                                                  z-index: 10;
                                                                  width: 120px;
                                                                ">
              &nbsp;&nbsp;&nbsp;View
            </button>
    </div><br /><br />
          <div style="    text-align: center;">
            <button data-toggle="modal" style="
                                                                border-radius: 10px;
                                                                background-color: #ff5722;
                                                                color: white;
                                                                padding: 5px 30px 5px 10px;
                                                                z-index: 10;
                                                                width: 120px;"
                                                               
                      data-target="#Addskill1"
                     
                                                              >
              &nbsp;&nbsp;&nbsp;Edit
            </button></div>
          <br /> -->
        <h3 style="    text-align: center;">Resume <span>
            <h6>(pdf only*)</h6>
          </span>
        </h3><br><br>
        <div class="col-md-12">
          <div class="fileupload" appDragDropFileUpload (click)="fileField.click()" (fileDropped)="onDrop($event)">
            <span class="ddinfo">Choose an object or drag here</span>
          </div>
          <input type="file" name="avatars" #fileField (change)="onChange($event)" />
        </div>
        <br><br>
        <div style="    text-align: center;">

          <button (click)="Uploaddocs1()" style="
                                                                    border-radius: 10px;
                                                                    background-color: #ff5722;
                                                                    color: white;
                                                                    padding: 5px 30px 5px 10px;
                                                                    z-index: 10;
                                                                    width: 120px;
                                                                  ">
            &nbsp;&nbsp;&nbsp;Upload
          </button>
        </div><br><br>
        <div style="    text-align: center;">
          <button data-toggle="modal" data-target="#resume1" (click)="GetResume()" style="
                                                                  border-radius: 10px;
                                                                  background-color: #ff5722;
                                                                  color: white;
                                                                  padding: 5px 30px 5px 10px;
                                                                  z-index: 10;
                                                                  width: 120px;
                                                                ">
            &nbsp;&nbsp;&nbsp;View
          </button>
        </div><br /><br />

      </div>
    </div>

  </div>
</div>
<div class="modal fade" id="Addskill" tabindex="-1 " role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Skill Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container fileUploadWrapper">
          <div class="col-md-12">
            <ul style="list-style: none">
              <div class="modal-body">
                <h5 class="modal-title" id="modal-basic-title">Skill</h5>
                <br />
                <!-- <input *ngIf="!edrad" type="text" class="form-control" id="Skill" [(ngModel)]="Skill" name="Skill" /> -->
                <div  *ngFor="let mem of MultipleSkilTypes" style="cursor: pointer;">
                  {{mem.skill }}<br />
              </div>
<!-- {{skilsselecte}}<br/>
 <br/> -->
                <select (change)='selectedskils($event.target.value)' class='select-option'
                  style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-top: 10px;text-decoration: none;">
                  <option class='option' value="">Select</option>
                  <option class='option' *ngFor="let ss of slist" value="{{ss.id}}"  [selected]="ss.skill == Skill">{{ss.skill}}</option>
                </select>

                <!-- <ng-multiselect-dropdown *ngIf="edrad" [placeholder]="'Select Skils'" name="skiltype" [data]="slist"
                  [(ngModel)]="selectedItemsSkils " [settings]="dropdownSettingsForSkiltype"
                  (onSelect)="onItemSelectskilType($event)" (onSelectAll)="onSelectAllskilsTypes($event)">
                </ng-multiselect-dropdown> -->


                <!-- <div class="col-md-12 col-lg12 inputbox" style="display: flex;">
                  <span id="no">3.&nbsp;</span>
                  <span style="width: 100%">
                      <ngx-select-dropdown id="industry" [config]="config" [options]="skills"
                          [multiple]="true" (change)="onchangeindustry($event)">
                      </ngx-select-dropdown>
                  </span>
              </div> -->
                <br />
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer">
          Cancel
        </button>

        <button type="button" class="btn btn-primary" (click)="Addskill()" style="cursor: pointer" *ngIf="edrad">
          Add
        </button>
        <button type="button" class="btn btn-primary" (click)="updateskill()" style="cursor: pointer" *ngIf="!edrad">
          Update
        </button>
      </div>
    </div>
  </div>
</div>
<!------------------------------------------- Modal HTML delete --------------------------->
<div id="myModal" class="modal fade">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="icon-box">
          <i class="material-icons">&#xE5CD;</i>
        </div>
        <h4 class="modal-title">Are you sure?</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <p>
          Do you really want to delete these records? This process cannot be
          undone.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" data-dismiss="modal">
          Cancel
        </button>
        <button type="button" class="btn btn-danger">Delete</button>
      </div>
    </div>
  </div>
</div>

<!-- <------------------------------------------popup---------------------------------->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container fileUploadWrapper">
          <div class="col-md-12">
            <ul style="list-style: none">
              <div class="modal-body">
                <form>
                  <h5 class="modal-title" id="modal-basic-title">
                    College Name
                  </h5>
                  <br />
                  <input type="text" id="requestingfor" [(ngModel)]="requestingfor" class="form-control"
                    name="requestingfor" /><br />

                  <h5 class="modal-title" id="modal-basic-title">Course</h5>
                  <br />
                  <input type="text" id="requestingfor" [(ngModel)]="requestingfor" class="form-control"
                    name="requestingfor" /><br />
                </form>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" style="cursor: pointer">
          Upload
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Education Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container fileUploadWrapper">
          <div class="col-md-12">
            <ul style="list-style: none">
              <div class="modal-body">
                <form>
                  <h5 class="modal-title" id="modal-basic-title">
                    College Name
                  </h5>
                  <br />
                  <input type="text" id="Cname" [(ngModel)]="Cname" class="form-control" name="Cname" /><br />

                  <h5 class="modal-title" id="modal-basic-title">Course</h5>
                  <br />
                  <input type="text" id="Course" [(ngModel)]="Course" class="form-control" name="Course" /><br />
                  <h5 class="modal-title" id="modal-basic-title">Date</h5>
                  <br />
                  <input type="text" id="date" [(ngModel)]="date" class="form-control" name="date" /><br />
                </form>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="AddEdu()" style="cursor: pointer" *ngIf="edrad">
          Add
        </button>
        <button type="button" class="btn btn-primary" (click)="updatedu()" style="cursor: pointer" *ngIf="!edrad">
          update
        </button>
      </div>
    </div>
  </div>
</div>
<!-- --------------------------pop------------------------------ -->
<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Experience Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container fileUploadWrapper">
          <div class="col-md-12">
            <ul style="list-style: none">
              <div class="modal-body">
                <form>
                  <h5 class="modal-title" id="modal-basic-title">
                    Organization
                  </h5>

                  <input type="text" id="Cname1" [(ngModel)]="Cname1" class="form-control" name="Cname1" />
                  <br>
                  <h5 class="modal-title" id="modal-basic-title">
                    Designation
                  </h5>

                  <input type="text" id="Course1" [(ngModel)]="Course1" class="form-control" name="Course1" /><br>
                  <h5 class="modal-title" id="modal-basic-title">Annual Salary</h5>

                  <input type="text" placeholder="Rupees" id="salary" [(ngModel)]="salary" class="form-control"
                    name="salary" /><br />
                  <br>
                  <h5 class="modal-title" id="modal-basic-title">Location</h5>

                  <input type="text" id="city" [(ngModel)]="city" class="form-control" name="city" /><br />
                  <br>
                  <h5 class="modal-title" id="modal-basic-title">Notice period</h5>

                  <input type="text" id="period" placeholder="Days" [(ngModel)]="period" class="form-control"
                    name="period" /><br />
                  <br>
                  <h5 class="modal-title" id="modal-basic-title">Date Of Probably Joining</h5>

                  <input type="text" id="date" [(ngModel)]="date" class="form-control" name="date" />
                </form>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="AddExp()" style="cursor: pointer" *ngIf="edrad1">
          Add
        </button>
        <button type="button" class="btn btn-primary" (click)="updatexp()" style="cursor: pointer" *ngIf="!edrad1">
          update
        </button>
      </div>
    </div>
  </div>
</div>
<!-- ------------Exp----------------- -->
<div class="col-md-12 row">
  <div class="col-md-1"></div>
  <div class="col-md-8">
    <div class="card social-timeline-card">
      <div class="card-body">
        <h4 class="card-title" style="padding-left: 10px">Experience</h4>
        <br />
        <div class="bottom-section-details" style="z-index: 2">
          <div class="bottom-section-right">
            <div *ngFor="let b of Edudet1">
              <div class="col-md-12 row" style="margin-top: -20px">
                <div class="mr-2">
                  <img class="rounded-circle" width="45" src="/assets/img/org.png" alt="" />
                </div>
                <div class="col-md-6" style="padding-left: 10px">
                  <p style="font-size: 20px">Organization:<span style="font-size: 20px">&nbsp;{{ b.oragnisation
                      }}</span></p>

                  <p style="font-size: 20px">Designation: <span style="font-size: 18px">&nbsp;{{ b.designation }}</span>
                  </p>
                  <p style="font-size: 20px">Annual Salary:<span style="font-size: 18px">&nbsp;{{ b.salary
                      }}</span></p>

                  <p style="font-size: 20px">Location: <span style="font-size: 18px">&nbsp;{{ b.city}}</span></p>

                  <p style="font-size: 20px">Notice period: <span style="font-size: 18px">&nbsp;{{ b.period
                      }}&nbsp;Days</span></p>

                  <p style="font-size: 20px">Date of Probably Joining: <span style="font-size: 18px">&nbsp;{{ b.date }}</span></p>

                </div>
                <div class="col-md-3">&nbsp;</div>
                <div class="col-md-1">
                  <div>
                    <img data-toggle="modal" data-target="#exampleModal2" (click)="editpp1(b)"
                      src="/assets/img/edit.svg" width="30vw" height="30vw" style="z-index: 10" />
                  </div>
                </div>
                <div class="col-md-1">
                  <div>
                    <img src="/assets/img/delete.svg" (click)="deleteexp(b)" width="30vw" height="30vw"
                      style="z-index: 10" />
                  </div>
                </div>
              </div>
              <br /><br />
            </div>
            <span style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
              ">
              <!-- <button
                data-toggle="modal"
                data-target="#exampleModal2"
                style="
                  border-radius: 10px;
                  background-color: blue;
                  color: white;
                  padding: 5px 10px 5px 10px;
                "
                (click)="addedct1()"
              >
                &nbsp;&nbsp;Add
              </button> -->
              <img src="/assets/img/add.svg" (click)="addedct1()" width="50vw" height="50vw" style="z-index: 10"
                data-toggle="modal" data-target="#exampleModal2" />
            </span>
          </div>
          <br /><br /><br /><br />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- -------------internship------------------ -->
<div class="col-md-12 row">
  <div class="col-md-1"></div>
  <div class="col-md-8">
    <div class="card social-timeline-card">
      <div class="card-body">
        <h4 class="card-title">
          <b> We can help you!</b>
        </h4>

        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2"></div>
            <div class="ml-2">
              <div class="h5 m-0 text-black">
                <a href="https://srichid.academy">
                  <b style="color: #ff5722; font-size: 20px"> Internship</b></a>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2"></div>
            <div class="ml-2">
              <div class="h5 m-0 text-black">
                Srichid Academy and Srichid technologies is approved for
                academic internship under the AICTE
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2"></div>
            <div class="ml-2">
              <div class="h5 m-0 text-black">
                <a href="https://srichid.academy">
                  <b style="color: #ff5722; font-size: 20px">
                    Training through Srichid Academy</b></a>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2"></div>
            <div class="ml-2">
              <div class="h5 m-0 text-black">
                Srichid academy is the authorised preferred training institution
                by the professional International managers association PRMIA
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2"></div>
            <div class="ml-2">
              <div class="h5 m-0 text-black">
                <a href="https://srichid.academy">
                  <b style="color: #ff5722; font-size: 20px">
                    Consultation and counselling
                  </b></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- -------------------edit profile pic-------------- -->

<div class="modal fade" id="EditProfilePhoto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Profile Photo</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container fileUploadWrapper">
          <div class="col-md-12">
            <ul style="list-style: none">
              <div class="modal-body">
                <label for="file">Choose Profile Photo</label> <br />
                <input type="file" id="file" (change)="handleFileInput2($event.target.files)" />
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="saveprofilepic()" style="cursor: pointer">
          Add
        </button>
      </div>
    </div>
  </div>
</div>

<!-- OTHER PROGRESS BAR Ansuha-->
<div class="col-lg-12 row" *ngIf="showper">
  <div class="col-lg-3"></div>
  <div class="col-lg-7" style="position: fixed; top: 86%; left: 20%;">
    <p *ngIf="errormsg">
      <b style="color: black;">{{ notify }} </b>
    </p>
    <p class="alert alert-info" style="box-shadow: white; background-color: white;" *ngIf="generalprogressbar">
      <b *ngIf="folderCreating && !foldercreated" style="color: black;">{{ Creatingmsg }} :{{ n }}%</b>

      <img src="./assets/img/tick.png" height="20px;" width="20px;"
        *ngIf="foldercreated && !folderCreating" />&nbsp;&nbsp;
      <b style="color: black;" *ngIf="foldercreated && !folderCreating">&nbsp;&nbsp; {{ createdmsg }}
      </b>
      <br />
      <progress value="{{ n }}" max="100" style="width: 100%; height: 7px;">
      </progress>&nbsp;&nbsp;{{ n }}%
    </p>
  </div>
</div>

<!-- ---resume popup -->

<div class="modal fade" id="resume1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="
  width: 758px;
  max-width: 750px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Resume View</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container fileUploadWrapper">
          <div class="col-md-12">
            <ul style="list-style: none">
              <div class="modal-body">

                <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 95vh; height: 70vh;"></ngx-doc-viewer>

              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer">
          Cancel
        </button>


      </div>
    </div>
  </div>
</div>