import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/shared/Login/login.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'app/shared/UserService/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newhome',
  templateUrl: './newhome.component.html',
  styleUrls: ['./newhome.component.scss'],
})
export class NewhomeComponent implements OnInit {
  mobile = false;
  constructor(
    private SocialloginService: LoginService,
    private user: UserService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: Router,
  ) {
    this.spinner.show();
    window.scrollTo(0, 0);

    if (localStorage.loadingpage == 'true') {
      localStorage.loadingpage = 'false';
      location.reload();
    }
  }
  searchvalue;
  ngOnInit() {
    this.spinner.hide();

    if (window.innerWidth <= 800) {
      this.mobile = true;
    }
    var anima = document.getElementById('anima');
    if (anima) {
      anima.style.display = 'block';
    }
  }
  downpath: any;
  seleteddownload: any;
  DownloadFile() {
    this.SocialloginService.downloadFile().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
    });
  }

  search() {
    //[routerLink]="['/gotojobposting']" [queryParams]="{ redirect: 'appjobseek',search:'Jabodebabek'}"
    //this.router.navigate(['/gotojobposting',{ queryParams: { redirect: 'appjobseek',search:this.searchvalue } }])
    document.getElementById('searchbar').click();
  }
  gotoLogin(){
    this.route.navigate(["/newsignin"]);

  }
}
