import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'app/shared/MailService/mail.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-composemail',
  templateUrl: './composemail.component.html',
  styleUrls: ['./composemail.component.scss']
})
export class ComposemailComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  useridformail: any;
  selectedticketid: any;
  TicketsList: any;
  ToUsersForMail: any;
  mailsubject:any;
  message:any;
  frmDataa: FormData;
  SelectedFiles: any = [];
  selected = []; CurrentFiles: any;
  Friends: any;
  frndslist: any = [];
  accid: any;
  folderdisaply: boolean;
  subfolderdisaply: boolean;
  allfoldersdisplay: boolean;
  subfolderdetails: any;
  currentfoldername: any;
  FileFoldID: any;
  allSubFolders: any;
  isvisible: boolean;
  selectedSelected: any = [];
  AttachmnetsList: any;
  HaspitFiles: string;
  SubFoldersList: any;
  foldernamelist: any;
  allFolders: any;
  sharedfolderdisaply: boolean;
  morefiles: boolean;
  allFoldersList: any;
  fileslist: any;
  fid: any;
  allFolders1: any;
  subf: any;
  remove: boolean;
  blocdriveFiles: any;
  email: any;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  serchfind: boolean;
  errorMessages: string;
  ToMails:any;


  constructor(private router: ActivatedRoute,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal, private spinner: NgxSpinnerService,
     private datepipe: DatePipe, private http: HttpClient, private mailservice: MailService,private domSanitizer: DomSanitizer,) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentID");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");

    }
    this.folderdisaply = true;
  }

  ngOnInit(): void {
    // this.GetFriendDetails();
    // this.GetAllFolders();
    // this.GetAllFolders1();
  }
  onSelectTicket(data) {
    this.selectedticketid = data;
  }
  GetUsersforMail() {
    this.spinner.show()
    this.mailservice.getUsersformail(this.uid).subscribe((data: any) => {
      this.ToUsersForMail = data;
      console.log(this.ToUsersForMail);
      this.spinner.hide()
    })
  }
  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      if (this.frndslist[i].Email == value.display) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }

  onItemAdded(value: any) {
    if (value.Email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.Email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend.push(value);
    }
    else {
      this.selectedFriend.push(value.value);
    }
  }
  // GetFriendDetails() {
  //   this.userservice.friendlist(this.GUID).subscribe((respose: any) => {
  //     this.Friends = respose;
  //     this.frndslist = this.Friends.friendsList;
  //     // console.log(this.frndslist);
  //     var idd = this.accid;

  //     for (let i = 0; i < this.frndslist.length; i++) {
  //       if (idd != undefined) {
  //         if (idd.includes('@')) {
  //           if (this.frndslist[i]['email'] == this.accid) {
  //             for (i = 0; i < 100; i++) {
  //               window.clearInterval(i);
  //             }
  //             alert(this.accid + " has accepted your friend request");
  //             this.accid = "";
  //           }
  //         }
  //       }
  //       if (this.frndslist[i]['friendId'] == this.uid) {
  //         this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
  //       }
  //     }
  //     // console.log(this.frndslist);

  //   });
  // }
  selectedFriend: any = [];
  ShareFileMembers(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].friendGUID == data) {
        this.selectedFriend.push(this.frndslist[index])
        break;
      }
    }
  }
  onSelectUserformail(data) {
    this.useridformail = data;
  }
  onChangeDocumnets(event: any) {
    this.frmDataa = new FormData();
    let files = [].slice.call(event.target.files);
    for (var i = 0; i < files.length; i++) {
      this.SelectedFiles.push(files[i]);
      this.frmDataa.append("Docs", files[i]);
    }
  }

  sendmail(){

  
    var det = {
      From: this.usermail,
      To: this.ToMails,
      Subject: this.mailsubject,
      BodyText: this.message,
      reply:"0"
    
    }
    this.spinner.show();

    this.mailservice.sendOrReplyMail(det).subscribe(data=>
      {
        alert(data);
        this.spinner.hide();
        this.route.navigate(['mail/inbox']);
      });
  }



// haspit section

onClick(data) {
  this.CurrentFiles = data;
  if (data != null) {
    for (var i = 0; i < this.selectedSelected.length; i++) {
      if (this.selectedSelected[i].Id == data.Id) {
        var index = this.selected.indexOf(data);
        this.selectedSelected.splice(index, 1);
        this.remove = true;
      }
    }
    this.selectedSelected.push(data);
  }

}
uploadmypersonalfiles() {
  this.isvisible = true;
  this.frmDataa = new FormData();
  for (var i = 0; i < this.selectedSelected.length; i++) {
    // this.frmData = new FormData();
    this.AttachmnetsList.push(this.selectedSelected[i].documentName);
    this.frmDataa.append("Drive", this.selectedSelected[i]);
    this.blocdriveFiles = this.selectedSelected[i].documentName;

  }
}

// GetSubfolderdetails(data) {
//   this.folderdisaply = false;
//   this.subfolderdisaply = true;
//   this.allfoldersdisplay = false;
//   this.subfolderdetails = data;
//   this.currentfoldername = data.folderName;
//   this.FileFoldID = data.folderId;
//   this.GetPFolderlist(data.folderId);
//   this.spinner.show();
//   const dataaa: ShareIdShow = {
//     EID: "",
//     Fid: data.folderId,
//     LoginUID: this.uid
//   }
//   this.mailservice.getsubfolders(dataaa).subscribe((respose: any) => {
//     this.allSubFolders = respose;
//     this.spinner.hide();
//     for (let i = 0; i < this.allSubFolders.retData.length; i++) {
//       if (this.allSubFolders.retData[i].file != null) {
//         this.allSubFolders.retData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.retData[i].file)
//         if (this.allSubFolders.retData[i].DocumentType == 'image/jpeg' || this.allSubFolders.retData[i].DocumentType == 'image/png'
//           || this.allSubFolders.retData[i].DocumentType == 'png' || this.allSubFolders.retData[i].DocumentType == 'jpeg' || this.allSubFolders.retData[i].DocumentType == 'jpg'
//           || this.allSubFolders.retData[i].DocumentType == 'PNG') {
//           this.SubFoldersList.push(this.allSubFolders.retData[i]);
//         }
//       }
//     }

//   });
// }
GetPFolderlist(id){
  // this.spinner.show();
  this.mailservice.getpfoldernames(id).subscribe((respose: any) => {
    // this.spinner.hide();
    this.foldernamelist = respose;  
    console.log(this.foldernamelist);
  });
}

GetMypersonalfolders(){
  this.foldernamelist = null;
  this.currentfoldername = null;
  this.mailservice.getfolders(this.uid).subscribe((respose: any) => {
    this.allFolders = respose;
    this.fileslist = this.allFolders.retData;
    for (var i = 0; i < this.fileslist.length; i++) {
      if (this.fileslist[i].DocumentType == 'image/jpeg' || this.fileslist[i].DocumentType == 'image/png'
        || this.fileslist[i].DocumentType == 'png' || this.fileslist[i].DocumentType == 'jpeg' || this.fileslist[i].DocumentType == 'jpg'
        || this.fileslist[i].DocumentType == 'PNG') {
        var dataa = this.fileslist[i];
        this.allFoldersList.push(dataa);
      }
    }
    this.folderdisaply = true;
    this.subfolderdisaply = false;
    this.spinner.hide();
  });
  this.folderdisaply = true;
  this.sharedfolderdisaply = true;
}

closecurrentpopup(){
  var a = document.getElementById("closetwo").click();
  var a = document.getElementById("closeshare").click();
  var a = document.getElementById("closedelete").click();
  this.folderdisaply = true;
  this.subfolderdisaply = false;
  this.sharedfolderdisaply = true;
  this.sharedfolderdisaply = false;
  //this.GetAllSharingFolders();
}

selectfile(data) {
  if (data != null) {
    if (this.selectedSelected.length == 0) {
      this.morefiles = false;
      document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
      this.selectedSelected.push(data);
    }
    else {
      const index = this.selectedSelected.indexOf(data, 0);
      if (index > -1) {
        this.selectedSelected.splice(index, 1);
        document.getElementById(data.Id.toString()).style.backgroundColor = "";
      }
      else {
        this.selectedSelected.push(data);
        document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
      }
    }
    if (this.selectedSelected.length == 0) {
      this.morefiles = false;
    }
  }
  if (this.selectedSelected.length <= 1) {
    this.morefiles = false;
  }
  else if (this.selectedSelected.length >= 2) {
    this.morefiles = true;
  }
}
// GetAllFolders() {
//   this.spinner.show();
//   this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
//     this.allFolders = respose;
//     // console.log("user", this.allFolders)
//   });
// }
// GetAllFolders1() {
//   this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
//     this.allFolders1 = respose;
//     // console.log("user", this.allFolders);
//     this.spinner.hide();
//   });
// }

// GetDetails(details) {
//   this.folderdisaply = false;
//   this.subfolderdisaply = true;
//   this.FileFoldID = details.FolderID;
//   this.currentfoldername = details.FolderName;
//   this.spinner.show();
//   this.allfoldersdisplay = false;
//   this.GetPFolderlist(details.FolderID);
//   const dataaa: ShareIdShow = {
//     EID: "",
//     Fid: details.FolderID,
//     LoginUID: this.uid
//   }
//   this.mailservice.getsubfolders(dataaa).subscribe((respose: any) => {
//     this.allSubFolders = respose;
//     this.currentfoldername = this.allSubFolders.FileName;
//     this.fid = this.allSubFolders.ParentId;
//     this.spinner.hide();
//     for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
//       if (this.allSubFolders.RetData[i].file != null) {
//         this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file);
//         if (this.allSubFolders.RetData[i].DocumentType == 'image/jpeg' || this.allSubFolders.RetData[i].DocumentType == 'image/png'
//           || this.allSubFolders.RetData[i].DocumentType == 'png' || this.allSubFolders.RetData[i].DocumentType == 'jpeg' || this.allSubFolders.RetData[i].DocumentType == 'jpg'
//           || this.allSubFolders.RetData[i].DocumentType == 'PNG') {
//           this.SubFoldersList.push(this.allSubFolders.RetData[i]);
//         }
//       }
//     }
//   });
// }
}