import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { JobPostForINsVM } from 'app/shared/VIewmodels/jobpostforinsvm';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { UserService } from 'app/shared/UserService/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-jobpostins',
  templateUrl: './jobpostins.component.html',
  styleUrls: ['./jobpostins.component.scss']
})
export class JobpostinsComponent implements OnInit {
  Industry: any;
  Industrytype: any;
  isLoggedIn: boolean;
  accountid: string;
  userid: string;
  focus2: boolean;
  HQlist: any;
  HQ: any;
  courcelist: any;
  course: any;
  spllist: any;
  splization: any;
  jobrole: any;
  sallist: any;
  salary: any;
  jobrolelist: any;
  eduperlist: any;
  eduper: any;
  transport: any;
  hra: any;
  sabbatical: any;
  FirstName: any;
  alldata: any;
  insid: any;
  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: MasterService,
    private userservice: UserService,
    private modalService: NgbModal
  ) {
    window.scrollTo(0, 0);

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem("userId");
      this.accountid = localStorage.getItem("uid");
      this.insid = localStorage.getItem("invinsuid");

    }
    this.FirstName = localStorage.getItem("UserName");
  }
  perks: any;
  roles: any;
  role: any;
  growth: any;
  growths: any;
  onchangerole(event) {
    const value = event.target.value;
    this.role = value;
    console.log(value);
  }
  onchangegrowth(event) {
    const value = event.target.value;
    this.growth = value;
    console.log(value);
  }
  ngOnInit() {
    this.transport = false;
    this.hra = false;
    this.sabbatical = false;
    this.GetIndustry();
    this.GetHighQualification();
    this.GetJobRole();
    this.GetSalary();
    this.GetEduPrcentage();
    this.GetProfileDetails();
    this.service.getperks().subscribe((data: any) => {
      this.perks = data;
      console.log(this.perks);
    });
    this.service.getrole().subscribe((data: any) => {
      this.roles = data;

      console.log(this.roles);
    });

    this.service.getgrowth().subscribe((data: any) => {
      this.growths = data;
      console.log(this.growths);
    });
  }

  GetIndustry() {
    this.registrationService.GetIndrustlist().subscribe((response: any) => {
      this.Industry = response;
    });
  }
  GetHighQualification() {
    this.registrationService
      .GetHighQualification()
      .subscribe((response: any) => {
        this.HQlist = response;
      });
  }
  onSelectIndrust(id) {
    this.Industrytype = id;
  }
  onchangequalify(id) {
    this.HQ = id.target.value;
    console.log(this.HQ);
  }
  onSelectHQ(id) {
    this.HQ = id;
    this.registrationService.GetCource(this.HQ).subscribe((response: any) => {
      this.courcelist = response;
    });
  }
  onSelectCourse(id) {
    this.course = id;
    this.registrationService
      .GetSpecialization(this.course)
      .subscribe((response: any) => {
        this.spllist = response;
      });
  }
  onSelectspl(id) {
    this.splization = id;
  }
  GetJobRole() {
    this.registrationService.GetJobrolelist().subscribe((response: any) => {
      this.jobrolelist = response;
    });
  }
  onSelectJobRole(id) {
    this.jobrole = id;
  }
  perk: any;
  onchangeperk(event) {
    const value = event.target.value;
    this.perk = value;
    console.log(value);
  }
  GetSalary() {
    this.registrationService.GetSalarylist().subscribe((response: any) => {
      this.sallist = response;
    });
  }
  GetEduPrcentage() {
    this.registrationService.GetEduPrcentage().subscribe((response: any) => {
      this.eduperlist = response;
    });
  }
  onSelectEduPer(id) {
    this.eduper = id;
  }
  onSelectsalary(id) {
    this.salary = id;
  }
  tansport(e) {
    this.transport = e.target.checked;
  }
  HRA(e) {
    this.hra = e.target.checked;
  }
  Sabbatical(e) {
    this.sabbatical = e.target.checked;
  }
  OnSubmitJobPost(form: NgForm) {
    // var IndustryType = document.getElementById("cname");
   var insid = localStorage.getItem("invinsuid");
    const inputRequest: JobPostForINsVM = {
      Id: null,
      InstitutionId:this.insid 
      //this.userid,
      ,
      type: "Job",
      CompanyName: this.FirstName,
      CompanyId: this.accountid,
      JobCategory: form.controls["jobcategory"].value,
      Industry: this.Industrytype,
      HighestQualification: this.HQ,
      Course: this.course,
      Specialization: this.splization,
      CasualLeaves: form.controls["calleaves"].value,
      EarnedLeaves: form.controls["eleaves"].value,
      EduPercentage: this.eduper,
      MinimumExp: form.controls["miniExp"].value,
      MaximumExp: form.controls["MaxExp"].value,
      JobDescription: form.controls["JobDescription"].value,
      JobposterName: form.controls["JobposterName"].value,
      JobLocation: form.controls["JobLocation"].value,
      JobposterEmail: null,
      JobposterPhone: form.controls["JobposterPhone"].value,
      KeySkils: form.controls["KeySkils"].value,
      PayScale: this.salary,
      LastDate: form.controls["LastDate"].value,
      FixedPayPer: this.perk,
      VariablePayPer: this.growth,
      JobRole: this.role,
      HRA: null,
      Sabbatical: null,
      Transportela: null,
    };
    if (inputRequest.LastDate == null) {
      alert("Select Lastdate");
    } else if (inputRequest.KeySkils == null) {
      alert("Enter Key Skills");
    } else if (inputRequest.JobposterPhone == null) {
      alert("Enter Job Poster Phone");
    } else if (inputRequest.JobLocation == null) {
      alert("Enter Job Poster Phone");
    } else if (inputRequest.JobposterName == null) {
      alert("Enter Job Poster Name");
    } else if (inputRequest.JobDescription == null) {
      alert("Enter Job Description");
    } else if (inputRequest.MaximumExp == null) {
      alert("Enter Maximum Expirience");
    } else if (inputRequest.MinimumExp == null) {
      alert("Enter Minimum Expirience");
    } else if (inputRequest.HighestQualification == null) {
      alert("Select Education");
    } else if (inputRequest.JobCategory == null) {
      alert("Select Job Category");
    } else {
      this.registrationService
        .SaveJobDetailsForIns(inputRequest)
        .subscribe((data: any) => {
          if (data != null) {
            localStorage.removeItem("invinsuid");
            alert("Registered Your Details Successfully");
            this.router.navigate(["/newhome"]);
            this.spinner.hide();
          } else {
            alert("Please Fill Details Properly");
            this.spinner.hide();
          }
        });
    }
  }
  GetProfileDetails() {
    this.userservice
      .getcompanyprofile(this.accountid)
      .subscribe((data: any) => {
        this.alldata = data[0];

        this.service.getindustry().subscribe((data: any) => {
          this.Industry = data;
          this.Industry.forEach((element) => {
            if (element["id"] == this.alldata.industryType) {
              this.alldata.industry = element["indrustType"];
            }
          });
        });

        this.spinner.hide();
      });
  }
  accid(accid: any) {
    throw new Error("Method not implemented.");
  }
}
