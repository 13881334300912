import { Component, OnInit } from '@angular/core';
import { AllservicesService } from 'app/shared/PlacementService/allservices.service';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
id:any;
alldata:any;
  constructor(private service:AllservicesService,private ser:RegistrationService) {
    if(        localStorage.loadingpage == "true"    ){
      localStorage.loadingpage = "false";
      location.reload();
    }
   }

  ngOnInit(): void {

    this.allmaster();
  }
  allmaster(){
    this.getstudentslist();

  }
  completedata;
  getstudentslist(){
    var userid =   localStorage.getItem("userId");

    this.service.getallstudents(userid).subscribe((data:any)=>{
      this.completedata = data;
      this.alldata = data;
      if(data.length == 0){
        alert("No students under your institutions has registered");
      }
      else {
        this.alldata.forEach(element => {
          if(element['branch']==1){
            element['branchN']="Computer Science";
          }
          else if(element['branch']==2){
            element['branchN']="Information Science";
          }
          else if(element['branch']==3){
            element['branchN']="Electronics";
          }
          else if(element['branch']==4){
            element['branchN']="Electricals";
          }
          else if(element['branch']==5){
            element['branchN']="Civil";
          }
          else if(element['branch']==6){
            element['branchN']="Mechanical";
          }

        });
      }
    });
  }
  sort = "1";

  sortby() {
    if (this.sort == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.alldata.sort((a, b) => a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : 0)
      this.sort = "2";
    }
    else if (this.sort == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.alldata.sort((a, b) => a.userName.toLowerCase() > b.userName.toLowerCase() ? -1 : a.userName.toLowerCase() < b.userName.toLowerCase() ? 1 : 0)

      this.sort = "1";

    }

  }

  sortusn = "1";

  sortbyusn() {
    if (this.sortusn == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.alldata.sort((a, b) => a.usn.toLowerCase() < b.usn.toLowerCase() ? -1 : a.usn.toLowerCase() > b.usn.toLowerCase() ? 1 : 0)
      this.sortusn = "2";
    }
    else if (this.sortusn == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.alldata.sort((a, b) => a.usn.toLowerCase() > b.usn.toLowerCase() ? -1 : a.usn.toLowerCase() < b.usn.toLowerCase() ? 1 : 0)

      this.sortusn = "1";

    }

  }
  getgroups(id){
    var uid = localStorage.getItem("userId");
  if(id == '100'){
    location.reload();
  }
  else{
    this.alldata=[]
    this.completedata.forEach(element => {
      if(element["branch"]==id){
        if(element['branch']==1){
          element['branchN']="Computer Science";
        }
        else if(element['branch']==2){
          element['branchN']="Information Science";
        }
        else if(element['branch']==3){
          element['branchN']="Electronics";
        }
        else if(element['branch']==4){
          element['branchN']="Electricals";
        }
        else if(element['branch']==5){
          element['branchN']="Civil";
        }
        else if(element['branch']==6){
          element['branchN']="Mechanical";
        }

        this.alldata.push(element)
      }
    });

  }}
}
