import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserDetails } from './VIewmodels/userdetails';

@Injectable({
  providedIn: 'root'
})
export class UserdetailsService {

  readonly url="https://blockchainmatrimony.com/jobzoneapi/api/";
  constructor(private http:HttpClient) { }
  saveUser(data:UserDetails){
    return this.http.post(this.url +"User/PostSaveUser",data);
  }

  getAllUsers(){
    return this.http.get(this.url +"User/GetAllUsers");
  }

  deleteUser(id: number){
    return this.http.delete(this.url +"User/DeleteUserData/"+ id);
  }

  UpdateUser(data:UserDetails){
    return this.http.put(this.url +"User/PutUser",data);
  }
 
}
