<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>

<div class="container borrow-outer double" style="background: whitesmoke">
    <div class="col-md-12">&nbsp;</div>
    <form #SavePlanTypeForm="ngForm">
        <h2 style="text-align:center; color: rgb(65, 24, 104);"><u>Institution Master</u></h2>
        <div class="col-md-12 row">
            <div class="col-md-12 row">
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-6">
                    <label for="instiname" style="font-size: 18px;color: #8a061c">Institution Name</label>
                    <input class="form-control" type="text" name="instiname" id="instiname" placeholder="Enter Name of the institution" [(ngModel)]="instiname" />
                </div>


            </div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12 row" style="text-align: center">
                <div class="col-md-4">
                    <button class="signin-submit _demo" id="save" (click)=SavePlanType(SavePlanTypeForm) type="submit" style="background-color: #18ce0f">
                        <div class="">Save</div>
                    </button>
                </div>
                <div class="col-md-4"><button class="signin-submit _demo" id="update" type="submit" style="background-color: #ffb236" (click)=UpdatePlanType(SavePlanTypeForm)>
                        <div class="">Update</div>
                    </button></div>
                <div class="col-md-4"><button class="signin-submit _demo" type="submit" (click)=ResetData()>
                        <div class="">Reset</div>
                    </button></div>

            </div>
        </div>
    </form>

    <div class="col-md-12">&nbsp;</div>
    <div class="col-md-12">&nbsp;</div>

</div>

<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="container">

    <table class="table table-bordered" style="border: solid;">
        <tr style="font-size:17px;text-align: center;border-bottom: solid;">
            <th>Sl No</th>
            <th>Institution Name</th>
            <!-- <th>Created By</th>
          <th>Created On</th> -->
            <th>Edit</th>
            <th>Delete</th>
        </tr>
        <tr *ngFor="let insti of institution;index as i" style="font-size:16px;font-family: sans-serif" style="border-bottom: solid;text-align: center">
            <td>{{i+1}}</td>
            <td>{{insti.institutionName}}</td>
            <!-- <td>{{aa.CreatedBy}}</td>
          <td>{{aa.CreatedOn|date}}</td> -->
            <td>
                <button class="signin-submit _demo" (click)="editplantype(insti)" type="submit" style="background-color: #2587e5; padding: 3px 13px 4px;">
                    <div class="">Edit</div>
                </button>
            </td>
            <td>
                <button class="signin-submit _demo" (click)="deleteplantype(insti)" type="submit" style="background-color: #b42121; padding: 3px 13px 4px;">
                    <div class="">Delete</div>
                </button>
            </td>
        </tr>
    </table>
</div>

<style>
    .borrow-outer,
    .emudhra-outer {
        background: white;
        box-shadow: 3px 3px 9px 13px #7a6f922e;
    }
    
    div.double {
        border-style: solid;
        color: maroon
    }
    
    #rcorners1 {
        border-radius: 90px;
        background: #e75e1d;
        padding: 24px;
        width: 50px;
        height: 5px;
    }
    
    ._demo {
        min-height: 35px;
        padding: 13px 30px 15px;
        font-weight: inherit;
        font-style: inherit;
        letter-spacing: 0;
        font-family: "Montserrat,-apple-system,BlinkMacSystemFont,Segoe UI,Fira Sans,Helvetica Neue,Arial,sans-serif";
        font-size: 20px;
        line-height: 18px;
        font-weight: 700;
        margin-top: auto;
        margin-bottom: auto;
        white-space: nowrap;
        outline: 0;
        border: none;
        border-radius: 6px;
        display: inline-block;
        cursor: pointer;
        line-height: 1em;
        transition: all .2s ease-in-out;
        background-color: #f73859;
        color: #fff;
    }
    
    .status-buttons {
        color: black;
        display: inline-block;
        font-size: 18px;
        margin-right: 12px;
        text-align: center;
        text-transform: uppercase;
    }
    
    .status-buttons span {
        background: white;
        display: block;
        height: 30px;
        margin: 0 auto 13px;
        padding-top: 5px;
        width: 30px;
        border-radius: 50%;
    }
    
    .status-buttons a {
        color: black;
        display: inline-block;
        font-size: 18px;
        margin-right: 13px;
        text-align: center;
        text-transform: uppercase;
    }
    
    #status-buttons a.active span {
        background: #00adef;
    }
</style>