import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'app/shared/MailService/mail.service';
import { MovesService } from 'app/shared/MoveService/moves.service';
import { UserService } from 'app/shared/UserService/user.service';
import { MailboxVM } from 'app/shared/VIewmodels/MailboxVM';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-jobinboxmail',
  templateUrl: './jobinboxmail.component.html',
  styleUrls: ['./jobinboxmail.component.scss']
})

export class JobinboxmailComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  DateTime: Date;
  InboxList: any;
  UsersList: any;
  notify: string;
  public n: any = 20; num: any; 
  selectedSelected: any = [];
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  q: number = 1;
  p: number = 1;
  searchText: any;
  CurrentFiles: any;
  createdmsg: string;
  userid: string;
  UserID: string;
  id: any;
  mailsubject: string;
  Touser: string;
  message: string;
  selectedFriend: any;
  frmDataa: any;
  mailto: any;
  accountid: string;
  constructor(private router: ActivatedRoute, private userservice: UserService, private moveService: MovesService,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal,
    private spinner: NgxSpinnerService, private datepipe: DatePipe, private http: HttpClient, private mailservice: MailService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem("userId");
      this.accountid = localStorage.getItem("uid");
      this.usermail = localStorage.getItem("MailId");
      this.userid = localStorage.getItem("userId");
      this.router.params.subscribe((data:any)=>{
        if(data){
          this.id = data['id'];
        }
        
      })
    }
  }

  ngOnInit(): void {
    this.GetInboxList();
    this.GetUsers();
  }
  GetInboxList() {
   // this.spinner.show()
    var info ={
      UID  :this.accountid,
      MailID:this.usermail
      
    }
    this.mailservice.getInboxlistforjob(info).subscribe((respose: any) => {
      this.InboxList = respose;
      console.log(respose);
      this.spinner.hide(); 
    });
  }

  GetUsers() {
   // this.spinner.show()
    this.mailservice.getUsers(this.UserID).subscribe((respose: any) => {
      this.UsersList = respose;
      this.spinner.hide();
    });
  }
  inbox() {
    this.route.navigate(['jobmail']);
  }
  composemail() {
    this.route.navigate(['jobcompose']);
  }
  deleteitems() {
    this.route.navigate(['jobdelete']);
  }
  sentitems() {
    this.route.navigate(['jobsent']);
  }
  archiveitems() {
    this.route.navigate(['jobarchive']);
  }
  ReadMessage(data) {

    localStorage.setItem('fromuser', data.From);
    localStorage.setItem('touser', data.To);
    localStorage.setItem('attachments', data.Attachments);
    localStorage.setItem('mailid', data.MasId);
    localStorage.setItem('transid', data.TransId);
    localStorage.setItem('maildate', data.DateforRM);
    localStorage.setItem('profilepic', data.UserProfilePic);
    localStorage.setItem('firstletter', data.FirstLetter);
    localStorage.setItem('docname', data.DocumentName);
    localStorage.setItem('emailbody', data.EmailBody);
    localStorage.setItem('sub', data.Subject); 
   // this.spinner.show()
    var info ={
      UID  :data.mailId
    }
    this.mailservice.postupdatemailreadforjob(info).subscribe((respose: any) => {
      var aa = respose;
      this.spinner.hide();
      this.route.navigate(['readmsgjob/'+ data.mailId]);
    });
   
  }
  sendmail(id){
    localStorage.profileUserName = id.name;
  localStorage.profileEmail = id.email;
  this.mailto=id.email;

    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var det = {
      
      //TicketId: this.selectedticketid,
    FromId: this.UserID,
    //MultiToUser: this.Touser,
    Jobzonedoc:this.selectedSelected,
      Touser:this.mailto,
      Subject: this.mailsubject,
      Message: this.message,
      //FromuserGUID: this.GUID
    }
    //this.frmDataa.append("Drive", this.selectedSelected[i]);
    this.frmDataa.append("MessageData", JSON.stringify(det));
    this.frmDataa.append("Internal", "Internal");
   // this.spinner.show()
    this.httpService.post("https://blockchainmatrimony.com/jobzoneapi/" + '/api/JobMailSystem/PostSaveNewMailforjob/', this.frmDataa).subscribe(
      data => {
        this.spinner.hide();
        this.selectedFriend= this.selectedSelected;
        alert("Mail Sent Successfully");
        const audit = {
          TransactionName:"Mail",
          TransactionType:"Send",
          TransactionDesc:"Compose Mail",

          }
          //this.auditortransactionservice.AuditorTransactionDetails(audit);
          this.Touser="";

        this.mailsubject = "";
        this.message = "";
        window.location.reload();
        // this.route.navigate(['sentmails']);
      
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });

  }
  reply(data) {
    localStorage.setItem('fromuser', data.From);
    localStorage.setItem('touser', data.To);
    localStorage.setItem('attachments', data.Attachments);
    localStorage.setItem('mailid', data.MasId);
    localStorage.setItem('transid', data.TransId);
    localStorage.setItem('maildate', data.DateforRM);
    localStorage.setItem('profilepic', data.UserProfilePic);
    localStorage.setItem('firstletter', data.FirstLetter);
    localStorage.setItem('docname', data.DocumentName);
    localStorage.setItem('emailbody', data.EmailBody);
    localStorage.setItem('sub', data.Subject);
    localStorage.setItem('rep', "replay");
    this.route.navigate(['readmsgjob']);
  }


  replyall(data) {
    localStorage.setItem('fromuser', data.From);
    localStorage.setItem('touser', data.To);
    localStorage.setItem('attachments', data.Attachments);
    localStorage.setItem('mailid', data.MasId);
    localStorage.setItem('transid', data.TransId);
    localStorage.setItem('maildate', data.DateforRM);
    localStorage.setItem('profilepic', data.UserProfilePic);
    localStorage.setItem('firstletter', data.FirstLetter);
    localStorage.setItem('docname', data.DocumentName);
    localStorage.setItem('emailbody', data.EmailBody);
    localStorage.setItem('sub', data.Subject);
    localStorage.setItem('rep', "replyall");
    this.route.navigate(['readmsgjob']);
  }
  singlearchive(data){
    this.selectedSelected.push(data);
    this.multiarchive();
    var info = {
      MailId : data.mailId,
      Id: data.id,

    }
   // this.spinner.show()
    this.mailservice.postmakeitArchiveforjob(info).subscribe((data: any) => {
     // this.UsersList = respose;
     if (data == "success") {
        this.notify = 'Archived Successfully';

      this.spinner.hide();
      this.GetInboxList();
      }
    });
  }
  
  singleDelete(data){
    this.selectedSelected.push(data);
    this.multidelete();
  }
  singleread(data){
    // this.selectedSelected.push(data);
    // this.multiread();
  }
  
  singleunread(data){
    // this.selectedSelected.push(data);
    // this.multiunread();
  }
  multiread() {
    // this.DateTime = new Date();
    // let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');

    // this.showper = true;
    // this.generalprogressbar = true;
    // this.folderCreating = true;
    // this.getPercent(this.n);
    // this.Creatingmsg = "Reading Messages...";
    // this.num = setInterval(() => {
    //   if (this.n <= 90) {
    //     this.Creatingmsg = "Reading Messages...";
    //     this.n = this.n + this.getPercent(this.n);
    //     if (this.n == 90) {
    //       this.n = 90;
    //       clearInterval(this.num);
    //     }
    //   }
    // }, 3000);

    // const inputRequest1: MailboxVM = {
    //   Fromuser:this.uid,
    //   MailsList:this.selectedSelected,
    //   CurrentDate:latest_date
    // }
    // this.mailboxservice.multireadmessages(inputRequest1).subscribe((respose: any) => {
    //   if(respose == "1"){
    //       this.n=100;
    //       this.createdmsg = "Read Successfully...!!"
    //       this.folderCreating = false;
    //       this.foldercreated = true;
    //       this.selectedSelected = [];
    //       this.morefiles = false;
    //       clearInterval(this.num);

    //       setTimeout(function () {
    //         this.folderCreating = false;
    //         this.foldercreated = false;
    //         this.generalprogressbar = false;
    //         this.errormsg = false;
    //         this.showper = false;
    //         this.n=10;
    //       }.bind(this), 3000);
    //       this.GetInboxList();
    //   }
    //   else{
    //     this.selectedSelected = [];
    //     this.GetInboxList();
    //     this.morefiles = false;
    //   }
    // });
  }

  multiunread() {
    // this.DateTime = new Date();
    // let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');


    // this.showper = true;
    // this.generalprogressbar = true;
    // this.folderCreating = true;
    // this.getPercent(this.n);
    // this.Creatingmsg = "Unreading Messages...";
    // this.num = setInterval(() => {
    //   if (this.n <= 90) {
    //     this.Creatingmsg = "Unreading Messages...";
    //     this.n = this.n + this.getPercent(this.n);
    //     if (this.n == 90) {
    //       this.n = 90;
    //       clearInterval(this.num);
    //     }
    //   }
    // }, 3000);

    // const inputRequest1: MailboxVM = {
    //   Fromuser:this.uid,
    //   MailsList:this.selectedSelected,
    //   CurrentDate:latest_date
    // }
    // this.mailboxservice.multiunreadmessages(inputRequest1).subscribe((respose: any) => {
    //   if(respose == "1"){
    //     this.n=100;
    //     this.createdmsg = "UnRead Successfully...!"
    //     this.folderCreating = false;
    //     this.foldercreated = true;
    //     this.selectedSelected = [];
    //     this.morefiles = false;
    //     clearInterval(this.num);

    //     setTimeout(function () {
    //       this.folderCreating = false;
    //       this.foldercreated = false;
    //       this.generalprogressbar = false;
    //       this.errormsg = false;
    //       this.showper = false;
    //       this.n=10;
    //     }.bind(this), 3000);
    //     this.GetInboxList();
    // }
    // else{
    //   this.selectedSelected = [];
    //   this.GetInboxList();
    //   this.morefiles = false;
    // }
    // });
  }

  multiarchive() {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');


    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest1: MailboxVM = {
      Fromuser:this.uid,
      MailsList:this.selectedSelected,
      CurrentDate:latest_date
    }
    this.mailservice.postmakeitArchiveforjob(inputRequest1).subscribe((respose: any) => {
      if(respose == "1"){
        this.n=100;
        this.createdmsg = "Archived Successfully...!"
        this.folderCreating = false;
        this.selectedSelected = [];
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n=10;
        }.bind(this), 3000);
        this.GetInboxList();
    }
    else{
      this.selectedSelected = [];
      this.GetInboxList();
    }
    });
  }
  getPercent(per) {
    return 10;
  }
  toggleSelect(data) {
    this.CurrentFiles = data;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
        }
        else {
          this.selectedSelected.push(data); 
        }
      }
    }

    // for (var i = 0; i < this.selectedSelected.length; i++) {
    //   if (this.selectedSelected[i].folderName != null) {
    //     this.sfolder = this.sfolder + 1;
    //   }
    //   else {
    //     this.sfiles = this.sfiles + 1;
    //   }
    // }


  
  }
  multidelete() {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');

    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.notify = "Deleting Messages...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.notify = "Deleting Messages...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest1: MailboxVM = {
      Fromuser:this.uid,
      MailsList:this.selectedSelected,
      CurrentDate:latest_date
    }
    this.mailservice.multideletemessagesforjob(inputRequest1).subscribe((respose: any) => {
      if(respose == "1"){
        this.n=100;
        this.notify = "Deleted Successfully...!"
        this.GetInboxList();
        this.folderCreating = false;
        this.selectedSelected = [];
    
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n=10;
        }.bind(this), 3000);
        this.GetInboxList();
    }
    else{
      this.selectedSelected = [];
      this.GetInboxList();
 
    }
    });
  }

  sort = "1";

  sortby() {
    if (this.sort == "1") {
      this.InboxList.sort((a, b) => a.fromName.toLowerCase() < b.fromName.toLowerCase() ? -1 : a.fromName.toLowerCase() > b.fromName.toLowerCase() ? 1 : 0)
      this.sort = "2";
    }
    else if (this.sort == "2") {
      this.InboxList.sort((a, b) => a.fromName.toLowerCase() > b.fromName.toLowerCase() ? -1 : a.fromName.toLowerCase() < b.fromName.toLowerCase() ? 1 : 0)
      this.sort = "1";

    }

  }
  sortname = "1";
  sortbydate() {
    if (this.sortname == "1") {
      this.InboxList.sort((a, b) => a.Sortdate < b.Sortdate ? -1 : a.Sortdate > b.Sortdate ? 1 : 0)
      this.sortname = "2";
    }
    else if (this.sortname == "2") {
      this.InboxList.sort((a, b) => a.Sortdate > b.Sortdate ? -1 : a.Sortdate < b.Sortdate ? 1 : 0)
      this.sortname = "1";

    }

  }

}

