<app-nav-bar></app-nav-bar>
<br> <br /> <br /> <br /> <br />
<div class="col-md-12 row">
    <div class="col-md-2">
        <app-mailcomposee></app-mailcomposee>
    </div>
    <div class="col-md-10">
        <aside class="lg-side">
            <div class="inbox-head">
                <div class="col-md-8">
                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Here " />
                    <br />
                </div>

            </div>
            <div class="inbox-body">
                <table class="table table-hover">
                    <thead>
                        <!-- <tr id="theadstyle">
                                    <th id="textdisplay" (click)="sortby()">From</th>
                                    <th id="textdisplay">Subject</th>
                                    <th id="textdisplay" (click)="sortbydate()">Sent on</th>
                                </tr> -->
                    </thead>
                    <tbody style="background: #ecf0f1">
                        <tr class="unread" id="{{ ret.Id }}" *ngFor="let ret of archiveList;index as i" style="cursor: pointer">
                            <td >
                                <input type="checkbox" style="height: 16px;" class="mail-checkbox" (click)="toggleSelect(ret)" >
                            </td>

                            <td class="view-message dont-show" (click)="ReadMessage(ret)">
                                {{ ret.fromName }}
                            </td>

                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.subject }}
                            </td>
                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.date }}
                            </td>
                            <td (click)="restore(ret)">
                                <img src="assets/images/restore.png" data-toggle="tooltip" title="Restore" alt="" width="25px" />
                            </td>
                            <td (click)="singleDelete(ret)">
                                <img src="assets/images/permanentdel.png"  data-toggle="tooltip" title="permanently delete" alt="" width="25px" />
                            </td>
                            <td>
                                <div>
                                    <a class="nav-link dropdown" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="margin-top: 12px">
                                        <span style="
                    font-family: Thanom regular;
                    color: white;
                    font-size: 15px;
                  ">
                  <!-- <i   class="fas fa-ellipsis-h" style="color: #4F44C5"></i> -->
                </span>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br /><br />
               
                <br /><br />
                <br /><br />
            </div>
        </aside>
    </div>
</div>