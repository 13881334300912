import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { Location } from 'app/shared/VIewmodels/Location';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
  
})
export class LocationComponent implements OnInit {
plantype: string;
  amount: string;
  planlist: any;
  palnid: any;
  plaid: any;
  Id: any;
  Location: any;
  constructor(private masterservices: MasterService, private router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('save').style.display = 'block';
      document.getElementById('update').style.display = 'none';
    }, 500);
    this.PlansList();
    this.LocationName = '';
  }
  ResetData() {
    this.LocationName = '';
  }
  LocationName: any;
  PlansList() {
    this.masterservices.getlocation().subscribe((data: any) => {
      this.Location = data;
      console.log(this.Location);
    });
  }

  SavePlanType(form: NgForm) {
    const inputRequest: Location = {
      Id: 0,
      location1: this.LocationName,
    };
    this.masterservices.postlocation(inputRequest).subscribe((data: any) => {
      if (data == null) {
        alert('Added Successfully');
        this.router.navigate(['/Locationmaster']);
        window.location.reload();
      } else {
        alert('Something went Wrong');
        this.router.navigate(['/Locationmaster']);
        window.location.reload();
      }
    });
  }
  instiid: any;
  editplantype(data: any) {
    document.getElementById('save').style.display = 'none';
    document.getElementById('update').style.display = 'block';
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.instiid = data.id;
    this.LocationName = data.location1;
  }

  UpdatePlanType(form: NgForm) {
    document.getElementById('save').style.display = 'block';
    document.getElementById('update').style.display = 'none';
    console.log(this.LocationName);
    const inputRequest1: Location = {
      Id: this.instiid,
      location1: this.LocationName,
    };
    this.masterservices.Updatelocation(inputRequest1).subscribe((data: any) => {
      if (data == 1) {
        alert('Location Name Updated Succesfully');
        this.PlansList();
        window.location.reload();
      } else {
        alert('Something Went Wrong. Try again !');
        window.location.reload();
      }
    });
  }
  deleteplantype(data: { id: any }) {
    this.plaid = data.id;
    this.masterservices.deletelocation(this.plaid).subscribe((data: any) => {
      if (data != null) {
        alert('Location Deleted Succesfully');
        this.PlansList();
        window.location.reload();
      } else {
        alert('Something Went Wrong. Try again !');
        window.location.reload();
      }
    });
  }
}