<br /><br /><br /><br />
<main>
    <section class="container register-form">
        <div class="form">
            <div class="row text-center justify-content-center">
                <div class="col-lg-12">
                    <br />
                    <div class="note">
                        <p style="font-size: x-large;">Training Details</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-content">
            <!-- SVG separator -->
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
        </div> -->
            <div class="container">
                <div class="row justify-content-center mt--300"></div>
                <form #JobPost="ngForm" (ngSubmit)="OnSubmitJobPost(JobPost)" ngNativeValidate>
                    <div class="row">
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="secondemail" style="font-size: 15px; color: #8a061c;">About Institue
              </label>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea required rows="8" class="form-control" #JobDescription type="text" [ngModel] id="JobDescription" name="JobDescription" (focus)="focus2 = true" (blur)="focus2 = false"></textarea>
                            </div>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="fname" style="font-size: 15px; color: #8a061c;">Institute Name</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #jobcategory
                  type="text"
                  [ngModel]
                  id="jobcategory"
                  name="jobcategory"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >Industry Type</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <select
                  (change)="onSelectIndrust($event.target.value)"
                  class="form-control"
                  required
                >
                  <option value="">Select Industry</option>
                  <option *ngFor="let do of Industry" value="{{ do.id }}">
                    {{ do.indrustType }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>

            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >Training
              </label>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <select (change)="onchangerole($event)" class="form-control" required>
                  <option value="">Select Training Role</option>
                  <option *ngFor="let role of roles" value="{{ role.id }}">
                    {{ role.role }}
                  </option>
                </select>
                            </div>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="fname" style="font-size: 15px; color: #8a061c;">Qualification</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #qualification
                  type="numofemp"
                  [ngModel]
                  id="qualification"
                  name="qualification"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="addressone" style="font-size: 15px; color: #8a061c;"
                >Courses</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #KeySkils
                  type="text"
                  [ngModel]
                  id="KeySkils"
                  name="KeySkils"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >Tool you will Learn</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #contactpersonphone
                  type="text"
                  [ngModel]
                  id="contactpersonphone"
                  name="contactpersonphone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="secondemail" style="font-size: 15px; color: #8a061c;"
                >Fee
              </label>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" #MaxExp type="number" [ngModel] id="MaxExp" name="MaxExp" (focus)="focus2 = true" (blur)="focus2 = false" placeholder="Enter in Rs" required />
                            </div>
                        </div>

                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div class="col-md-3">
                            <label for="email" style="font-size: 15px; color: #8a061c;">Start Date</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #miniExp
                  type="date"
                  [ngModel]
                  id="miniExp"
                  name="miniExp"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >End Date</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #contactpersonphone
                  type="date"
                  [ngModel]
                  id="contactpersonphone"
                  name="contactpersonphone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="email" style="font-size: 15px; color: #8a061c;"
                >Training Location</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #JobLocation
                  type="RegAddress"
                  [ngModel]
                  id="JobLocation"
                  name="JobLocation"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="city" style="font-size: 15px; color: #8a061c;"
                >Application Deadline</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #LastDate
                  type="date"
                  [ngModel]
                  id="LastDate"
                  name="LastDate"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <h3>Contact Details</h3>
            </div>
            <div class="col-md-6">
              <div class="form-group"></div>
            </div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="secondemail" style="font-size: 15px; color: #8a061c;">
                Name</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #JobposterName
                  type="text"
                  ngModel
                  id="JobposterName"
                  name="JobposterName"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="addressone" style="font-size: 15px; color: #8a061c;">
                Email</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #JobposterEmail
                  type="text"
                  [ngModel]
                  id="JobposterEmail"
                  name="JobposterEmail"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="landmark" style="font-size: 15px; color: #8a061c;">
                Phone</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #JobposterPhone
                  type="number"
                  [ngModel]
                  id="JobposterPhone"
                  name="JobposterPhone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  required
                />
              </div>
            </div>

            <!-- <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="landmark" style="font-size: 15px; color: #8a061c;"
                >PayScale</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <select
                  class="form-control"
                  (change)="onSelectsalary($event.target.value)"
                >
                  <option value="">Salary Range</option>
                  <option *ngFor="let role of sallist" [value]="role.id">{{
                    role.salary
                  }}</option>
                </select>
              </div>
            </div> -->

            <!-- <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >Contact Person Phone</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #contactpersonphone
                  type="number"
                  [ngModel]
                  id="contactpersonphone"
                  name="contactpersonphone"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div> -->
            <!-- <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >Growth</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <select (change)="onchangegrowth($event)" class="form-control">
                  <option value="">Select Growth</option>
                  <option *ngFor="let do of growths" value="{{ do.id }}">
                    {{ do.growth1 }}
                  </option>
                </select>
              </div>
            </div> -->
            <!-- 
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div> -->

            <!-- --------------------------- -->
            <!-- <div class="col-md-12">&nbsp;</div>

            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <h3>Leaves</h3>
            </div>
            <div class="col-md-6">
              <div class="form-group"></div>
            </div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="city" style="font-size: 15px; color: #8a061c;"
                >Casual Leaves</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="number"
                  class="form-control"
                  #calleaves
                  id="calleaves"
                  name="calleaves"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  [ngModel]
                />

                <!-- <input
                  class="form-control"
                  placeholder="Enter Count"
                  #calleaves
                  type="number"
                  [ngModel]
                  id="calleaves"
                  name="calleaves"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                  style="text-transform: capitalize;"
                /> -->
            <!-- </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="city" style="font-size: 15px; color: #8a061c;"
                >Earned Leaves</label
              >
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  class="form-control"
                  #eleaves
                  type="number"
                  [ngModel]
                  id="eleaves"
                  name="eleaves"
                  (focus)="focus2 = true"
                  (blur)="focus2 = false"
                />
              </div>
            </div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-3">
              <label for="state" style="font-size: 15px; color: #8a061c;"
                >Perks</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <select class="form-control" (change)="onchangeperk($event)">
                  <option value="">Perks</option>
                  <option *ngFor="let role of perks" [value]="role.id">{{
                    role.perks
                  }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">&nbsp;</div> -->

            <!-- ------------------- -->
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-10" style="text-align: center;">
              <button type="submit" class="btnSubmit">
                Get In!
              </button>
            </div>
            <div class="col-md-1">&nbsp;</div>
          </div>
        </form>
      </div>
    </div>
  </section>
</main>