import { Component, OnInit } from '@angular/core';
import { JobzoneShare } from 'app/shared/VIewmodels/sharedetails';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { UserService } from 'app/shared/UserService/user.service';
import { MasterService } from 'app/shared/MasrerService/master.service';

@Component({
  selector: 'app-jobviewforins',
  templateUrl: './jobviewforins.component.html',
  styleUrls: ['./jobviewforins.component.scss']
})
export class JobviewforinsComponent implements OnInit {

  isLoggedIn: boolean;
  userid: string;
  accountid: string;
  joblist: any;
role:any;
  CompanyName:any;
  constructor(private userser:UserService,private registrationService: RegistrationService,private service:MasterService) {
    if (localStorage.loadingpage == "true") {
      localStorage.loadingpage = "false";
      location.reload();
    }
    this.role = localStorage.getItem("role");

    window.scrollTo(0,0);

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem("userId");
      this.accountid = localStorage.getItem("uid")
    }
   }


   ngOnInit(): void {
  
   setTimeout(() => {
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }
   }, 5000);
   
    this.GetJobMatchList();
    this.getcertificates();
  }
  Certi:any;
  getcertificates()
{
  var uid = localStorage.getItem("userId");
  this.userser.getCerti(uid).subscribe((respose: any) => {

this.Certi = respose;
console.log(respose);

  });
}
roles:any;
  GetJobMatchList() {
    this.registrationService.getjoblistforins(this.accountid).subscribe((response: any) => {
      if(response == 1){
        this.joblist = null;
      }
      else{
      this.joblist = response;
      this.registrationService.GetHighQualification().subscribe((data:any)=>{
        this.joblist.forEach(element => {
          data.forEach(element1 => {
            if(element['highestQualification']==element1['id']){
              element['highestQualification']=element1.highestQualification
            }
          });
            
         
        });
      })
      this.service.getsalary().subscribe((data:any)=>{
        this.joblist.forEach(element => {
          data.forEach(element1 => {
              if(element1['id']==element['payScale']){
                element['payScale'] = element1['salary']
              }
          });
        });
      })
     
      }
      console.log(this.joblist);
    });
  }

  selectedDetails(job){
    this.CompanyName = job;
    console.log(this.CompanyName);
  }
 
  apply(id,name){
    var uid = localStorage.getItem("userId");
    this.registrationService.apply(id,uid,name).subscribe((data:any)=>{
      if(data==1){
        alert("Application sent successfully");
      }
      else if(data == 0){
        alert("You have already applied to this job");
            }
    })
  }
  shareDetails(){
    const inputr:JobzoneShare={
      Id:null,
      ApplicantId:this.userid,
      CompanyId:this.CompanyName.companyId,
      JobBlkId:this.CompanyName.blockId
    }
    this.userser.sharedetails(inputr).subscribe((data:any)=>{
      if(data==1){
        alert("Shared your details successfully");
      }
    })
  }
}