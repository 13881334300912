<br /><br /><br /><br />
<div class="col-md-12 text-center" style="min-height: 100vh">
  <div class="col-md-12 text-center row">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-center">
      <h2><strong> All Companies</strong></h2>
      <table class="table" style="width: 100%">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Job Role</th>
            <th>Location</th>

            <th>Salary</th>
            <th>Posted Date</th>
            <th>Description</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let data of joblist; index as i">
            <td>{{ i + 1 }}</td>

            <td>{{ data.jobRole }}</td>
            <td>{{ data.jobLocation }}</td>
            <td>{{ data.payScale }}</td>
            <td>{{ data.specialization | date: "mediumDate" }}</td>
            <td>
              <button
                type="button"
                class="btn btn-primary"
                (click)="descriptions(data.jobDescription)"
                data-target="#basicExample"
                data-toggle="modal"
              >
                Description
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="basicExample"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="margin-top: 20%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          {{ Jobdescription }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          style="cursor: pointer"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
