import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnterPriseRegister } from './VIewmodels/enterpriseregister';
import { EmailValidation } from './VIewmodels/emailvalidation';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {
  readonly url="https://blockchainmatrimony.com/jobzoneapi/api/";
  constructor(private http:HttpClient) { }
  EnterpriseRegister(data:EnterPriseRegister){
    return this.http.post(this.url +"EnterPriseRegistration/PostEnterPriseRegistration",data);
  }
  validateemail(mail:EmailValidation){
    return this.http.post(this.url + "EnterPriseRegistration/PostValidMail", mail);
  }
  validateusername(mail:EmailValidation){
    return this.http.post(this.url + "EnterPriseRegistration/PostValidUserName", mail);
  }
  GetallDetails(id) {
    return this.http.get(
      this.url + "EnterPriseRegistration/GetallDetails/" + id
    );
  }
}