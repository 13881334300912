import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ChapterService {
  // readonly rooturl = "https://blockchainmatrimony.com/jobzoneapi/api/";
  // private readonly rootUrl = "https://blockchainmatrimony.com/jobzoneapi/api/";
  readonly rooturl = 'https://blockchainmatrimony.com/jobzoneapi/api/';

  readonly rootUrl = 'https://blockchainmatrimony.com/jobzoneapi/api/';

  constructor(private http: HttpClient) {}

  getAllChapteres() {
    return this.http.get(this.rooturl + 'ChapterMasters/ChapterMasters');
  }
  getallmat() {
    return this.http.get(this.rooturl + 'UploadDoc/GetUploadStudentDocuments');
  }
  getcourseChapteres(id) {
    return this.http.get(this.rooturl + 'CourseChapter/GetCourseChapter/' + id);
  }

  saveChapter(data: any) {
    return this.http.post(
      this.rooturl + 'ChapterMasters/PosttblChapterMaster',
      data
    );
  }

  UpdateChapterRow(data: any) {
    return this.http.put(
      this.rooturl + 'ChapterMasters/PuttblChapterMaster',
      data
    );
  }

  deleteChapterdata(id: any) {
    return this.http.delete(
      this.rooturl + 'ChapterMasters/DeletetblChapterMaster/' + id
    );
  }

  getChapters(id: any) {
    return this.http.post(this.rootUrl + 'Question/getChapters', id);
  }
  getOneChapter(id: any) {
    return this.http.post(this.rootUrl + 'Question/getOneChapter', id);
  }
  getStatusTest(id: any) {
    return this.http.post(this.rootUrl + 'Question/getStatusTest', id);
  }
  getMaterials(id: any) {
    return this.http.post(this.rootUrl + 'Question/getMaterials', id);
  }
  getOneChapterName(id: any) {
    return this.http.post(this.rootUrl + 'Question/getOneChapterName', id);
  }
}
