import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'app/shared/JobPortalServices/registration.service';
import { MasterService } from 'app/shared/MasrerService/master.service';
import { UserService } from 'app/shared/UserService/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-allcompanylist',
  templateUrl: './allcompanylist.component.html',
  styleUrls: ['./allcompanylist.component.scss'],
})
export class AllcompanylistComponent implements OnInit {
  companies;
  constructor(
    private registrationService: RegistrationService,
    private route: Router,
    private router: ActivatedRoute,
    private service: MasterService,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.getallcompanies();
  }
  getallcompanies() {
    this.userService.GetallcompApplicant().subscribe((data: any) => {
      this.companies = data;
      this.companies.forEach((element) => {
        this.userService
          .GetCompStatus(element.uid)
          .subscribe((response: any) => {
            element['Status'] = response;
          });
      });
      console.log(this.companies);
    });
  }
  deletecompany(uid) {
    this.userService.deletecom(uid).subscribe((data: any) => {
      if (data === null) {
        alert("Deleted successfully");
        location.reload();
      } else {
        alert("Something went wrong!");
      }
    });
  }
}
