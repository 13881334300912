import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'app/shared/MailService/mail.service';
import { MovesService } from 'app/shared/MoveService/moves.service';
import { UserService } from 'app/shared/UserService/user.service';
import { Friend } from 'app/shared/VIewmodels/Friend';
import { MailboxVM } from 'app/shared/VIewmodels/MailboxVM';
import { ShareIdShow } from 'app/shared/VIewmodels/shareidshow';
import { NgxSpinnerService } from 'ngx-spinner';
import { TagInputModule } from 'ngx-chips';
@Component({
  selector: 'app-mailcomposee',
  templateUrl: './mailcomposee.component.html',
  styleUrls: ['./mailcomposee.component.scss']
})
export class MailcomposeeComponent implements OnInit  {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  useridformail: any;
  selectedticketid: any;
  TicketsList: any;
  ToUsersForMail: any;
  mailsubject:any;
  ToUser:any;
  message:any;
  frmDataa: FormData;
  SelectedFiles: any = [];
  selected = []; CurrentFiles: any;
  Friends: any;
  frndslist: any = [];
  accid: any;
  folderdisaply: boolean;
  subfolderdisaply: boolean;
  allfoldersdisplay: boolean;
  subfolderdetails: any;
  currentfoldername: any;
  FileFoldID: any;
  allSubFolders: any;
  isvisible: boolean;
  selectedSelected: any = [];
  AttachmnetsList: any;
  HaspitFiles: string;
  SubFoldersList: any;
  foldernamelist: any;
  allFolders: any;
  sharedfolderdisaply: boolean;
  morefiles: boolean;
  allFoldersList: any;
  fileslist: any;
  fid: any;
  allFolders1: any;
  subf: any;
  remove: boolean;
  blocdriveFiles: any;
  email: any;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  serchfind: boolean;
  errorMessages: string;
  install: boolean;
  friends: boolean=false;
  friend: any;
  
  expands: boolean = true;
  compress: boolean = false;
  UserID: string;


  constructor(private router: ActivatedRoute, private userservice: UserService, private moveService: MovesService,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal, private spinner: NgxSpinnerService,
     private datepipe: DatePipe, private http: HttpClient, private mailservice: MailService,
     private domSanitizer: DomSanitizer,) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentID");
      this.usermail = localStorage.getItem("MailId");
      this.UserID=localStorage.getItem("uid");
      //this.gridvalue = localStorage.getItem("gridvalue");
      //this.GUID = localStorage.getItem("GUID");

    }
    if(window.innerWidth <= 1200){
      setTimeout(() => {
        this.expands = false;
        this.compress = false;   
      }, 10);
    }
    this.folderdisaply = true;
   TagInputModule.withDefaults({
      tagInput: {
       placeholder: 'Add email address',
       secondaryPlaceholder: 'Add email address',
      },

   });
  }

  ngOnInit(): void {
   // this.GetUserTickets();
    this.GetFriendDetails();
    this.GetAllFolders();
    this.GetAllFolders1();
    this.GetMailStatus();
  }
  expand(){
    document.getElementById("myOverlay").style.width = "200%";
    document.getElementById("myOverlay").style.marginLeft = "-42%";
    document.getElementById("myOverlay").style.height = "600px;";
  this.expands = false;
  this.compress = true;
  if(window.innerWidth <= 1000){
    setTimeout(() => {
      this.expands = false;
      this.compress = false;
      document.getElementById("myOverlay").style.width = "100%";
      document.getElementById("myOverlay").style.marginLeft = "0";
    }, 10);
  }
  }
  compres(){
    document.getElementById("myOverlay").style.width = "100%";
     document.getElementById("myOverlay").style.marginLeft = "0";
    // document.getElementById("myOverlay").style.height = "0";
    this.expands = true;
  this.compress = false;
  }
  GetUserTickets() {
    //this.spinner.show()
    this.mailservice.getUserTickets(this.uid).subscribe((data: any) => {
      this.TicketsList = data;
      this.spinner.hide()
    })
  }
  onSelectTicket(data) {
    this.selectedticketid = data;
  }
  GetUsersforMail() {
    //this.spinner.show()
    this.mailservice.getUsersformail(this.uid).subscribe((data: any) => {
      this.ToUsersForMail = data;
      console.log(this.ToUsersForMail);
      this.spinner.hide()
    })
  }
  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      if (this.frndslist[i].Email == value.display) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }

  onItemAdded(value: any) {
    if (value.email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend.push(value);
    }
    else{
      alert("OOPS!! This person not in your Friends List.Let us add him/her Click Friend Request.");
      this.friends = true;   
        this.friend = this.email; 
      this.selectedFriend="";
 // this.savefrienddetails();
    }
    // else {
    //   this.selectedFriend.push(value.email);
    // }
  }
  GetFriendDetails() {
    this.userservice.GetFriendRequests(this.UserID).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends;
      console.log(this.frndslist);
      // console.log(this.frndslist);
      var idd = this.accid;
      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }
  selectedFriend: any = [];
  ShareFileMembers(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].friendGUID == data) {
        this.selectedFriend.push(this.frndslist[index])
        break;
      }
    }
  }
  onSelectUserformail(data) {
    this.useridformail = data;
  }
  onChangeDocumnets(event: any) {
    this.frmDataa = new FormData();
    let files = [].slice.call(event.target.files);
    for (var i = 0; i < files.length; i++) {
      this.SelectedFiles.push(files[i]);
      this.frmDataa.append("Docs", files[i]);
    }
  }
  inbox() {
    this.route.navigate(['inboxmails']);
  }
  composemail() {
    this.route.navigate(['mcompose']);
  }
  deleteitems() {
    this.route.navigate(['del']);
  }
  sentitems() {
    this.route.navigate(['sentmails']);
  }
  archiveitems() {
    this.route.navigate(['archive']);
  }

  cancelmail(){
    this.route.navigate(['inboxmails']);

  }

  savefrienddetails() {
  //this.spinner.show()
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount:this.friend,
      UserId: this.UserID,
      Status: 4,
      IsEnabled: true,
      //GUID: this.GUID

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using blocdrive!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "1") {
        alert("Friend Request Sent Successfully!");
        const audit = {
          TransactionName:"Friend Request",
          TransactionType:"Send",
          TransactionDesc:"Friend Request",

          }
         // this.auditortransactionservice.AuditorTransactionDetails(audit);
      }
      else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";
    
    });
  }
  sendmail(){

    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var det = {
     // TicketId: this.selectedticketid,
      FromId: this.UserID,
      MultiToUser: this.selectedFriend,
      Jobzonedoc:this.selectedSelected,
      Subject: this.mailsubject,
      Message: this.message,
      //FromuserGUID: this.GUID
    }
    //this.frmDataa.append("Drive", this.selectedSelected[i]);
    this.frmDataa.append("MessageData", JSON.stringify(det));
    this.frmDataa.append("Internal", "Internal");
    // //this.spinner.show()
    this.httpService.post("https://blockchainmatrimony.com/jobzoneapi" + '/api/MailSystem/PostSaveNewMail/', this.frmDataa).subscribe(
      data => {
        this.spinner.hide();
        this.selectedFriend= this.selectedSelected;
        alert("Mail Sent Successfully");
        const audit = {
          TransactionName:"Mail",
          TransactionType:"Send",
          TransactionDesc:"Compose Mail",

          }
          //this.auditortransactionservice.AuditorTransactionDetails(audit);
          this.ToUser="";

        this.mailsubject = "";
        this.message = "";
        window.location.reload();
        // this.route.navigate(['sentmails']);
      
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });

  }


  GetMailStatus(){
  
    this.mailservice.getmailstatus(this.uid).subscribe((response:any) => {
      this.install = true;
      if(this.install == response){
       this.install=true;
      }
      else if(false == response){
       this.install=false;
      }
    })
  
}


  MailFriend(){
    this.route.navigate(['/friendrequest']).then(()=>{
      window.location.reload();
    })
  }
// haspit section

onClick(data) {
  this.CurrentFiles = data;
  if (data != null) {
    for (var i = 0; i < this.selectedSelected.length; i++) {
      if (this.selectedSelected[i].Id == data.Id) {
        var index = this.selected.indexOf(data);
        this.selectedSelected.splice(index, 1);
        this.remove = true;
      }
    }
    this.selectedSelected.push(data);
  }

}
uploadmypersonalfiles() {
  this.isvisible = true;
  this.frmDataa = new FormData();
  for (var i = 0; i < this.selectedSelected.length; i++) {
    // this.frmData = new FormData();
    this.AttachmnetsList.push(this.selectedSelected[i].documentName);
    this.frmDataa.append("Drive", this.selectedSelected[i]);
    this.blocdriveFiles = this.selectedSelected[i].documentName;

  }
}

GetSubfolderdetails(data) {
  this.folderdisaply = false;
  this.subfolderdisaply = true;
  this.allfoldersdisplay = false;
  this.subfolderdetails = data;
  this.currentfoldername = data.folderName;
  this.FileFoldID = data.folderId;
  this.GetPFolderlist(data.folderId);
  //this.spinner.show()
  const dataaa: ShareIdShow = {
    EID: "",
    Fid: data.folderId,
    LoginUID: this.uid
  }
  this.mailservice.getsubfolders(dataaa).subscribe((respose: any) => {
    this.allSubFolders = respose;
    this.spinner.hide();
    for (let i = 0; i < this.allSubFolders.retData.length; i++) {
      if (this.allSubFolders.retData[i].file != null) {
        this.allSubFolders.retData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.retData[i].file)
        if (this.allSubFolders.retData[i].DocumentType == 'image/jpeg' || this.allSubFolders.retData[i].DocumentType == 'image/png'
          || this.allSubFolders.retData[i].DocumentType == 'png' || this.allSubFolders.retData[i].DocumentType == 'jpeg' || this.allSubFolders.retData[i].DocumentType == 'jpg'
          || this.allSubFolders.retData[i].DocumentType == 'PNG') {
          this.SubFoldersList.push(this.allSubFolders.retData[i]);
        }
      }
    }

  });
}
GetPFolderlist(id){
  //this.spinner.show()
  this.mailservice.getpfoldernames(id).subscribe((respose: any) => {
    // this.spinner.hide();
    this.foldernamelist = respose;  
    console.log(this.foldernamelist);
  });
}

GetMypersonalfolders(){
  this.foldernamelist = null;
  this.currentfoldername = null;
  this.mailservice.getfolders(this.uid).subscribe((respose: any) => {
    this.allFolders = respose;
    this.fileslist = this.allFolders.retData;
    for (var i = 0; i < this.fileslist.length; i++) {
      if (this.fileslist[i].DocumentType == 'image/jpeg' || this.fileslist[i].DocumentType == 'image/png'
        || this.fileslist[i].DocumentType == 'png' || this.fileslist[i].DocumentType == 'jpeg' || this.fileslist[i].DocumentType == 'jpg'
        || this.fileslist[i].DocumentType == 'PNG') {
        var dataa = this.fileslist[i];
        this.allFoldersList.push(dataa);
      }
    }
    this.folderdisaply = true;
    this.subfolderdisaply = false;
    this.spinner.hide();
  });
  this.folderdisaply = true;
  this.sharedfolderdisaply = true;
}

closecurrentpopup(){
  var a = document.getElementById("closetwo").click();
  var a = document.getElementById("closeshare").click();
  var a = document.getElementById("closedelete").click();
  this.folderdisaply = true;
  this.subfolderdisaply = false;
  this.sharedfolderdisaply = true;
  this.sharedfolderdisaply = false;
  //this.GetAllSharingFolders();
}

selectfile(data) {
  if (data != null) {
    if (this.selectedSelected.length == 0) {
      this.morefiles = false;
      document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
      this.selectedSelected.push(data);
    }
    else {
      const index = this.selectedSelected.indexOf(data, 0);
      if (index > -1) {
        this.selectedSelected.splice(index, 1);
        document.getElementById(data.Id.toString()).style.backgroundColor = "";
      }
      else {
        this.selectedSelected.push(data);
        document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
      }
    }
    if (this.selectedSelected.length == 0) {
      this.morefiles = false;
    }
  }
  if (this.selectedSelected.length <= 1) {
    this.morefiles = false;
  }
  else if (this.selectedSelected.length >= 2) {
    this.morefiles = true;  
  }
}
GetAllFolders() {
  //this.spinner.show()
  this.userservice.getfolders( this.uid).subscribe((respose: any) => {
    this.allFolders = respose;
    // console.log("user", this.allFolders)
  });
}
GetAllFolders1() {
  this.userservice.getfolders( this.uid).subscribe((respose: any) => {
    this.allFolders1 = respose;
    // console.log("user", this.allFolders);
    this.spinner.hide();
  });
}

GetDetails(details) {
  this.folderdisaply = false;
  this.subfolderdisaply = true;
  this.FileFoldID = details.FolderID;
  this.currentfoldername = details.FolderName;
  // //this.spinner.show()
  this.allfoldersdisplay = false;
  this.GetPFolderlist(details.FolderID);
  const dataaa: ShareIdShow = {
    EID: "",
    Fid: details.FolderID,
    LoginUID: this.uid
  }
  this.mailservice.getsubfolders(dataaa).subscribe((respose: any) => {
    this.allSubFolders = respose;
    this.currentfoldername = this.allSubFolders.FileName;
    this.fid = this.allSubFolders.ParentId;
    this.spinner.hide();
    for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
      if (this.allSubFolders.RetData[i].file != null) {
        this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file);
        if (this.allSubFolders.RetData[i].DocumentType == 'image/jpeg' || this.allSubFolders.RetData[i].DocumentType == 'image/png'
          || this.allSubFolders.RetData[i].DocumentType == 'png' || this.allSubFolders.RetData[i].DocumentType == 'jpeg' || this.allSubFolders.RetData[i].DocumentType == 'jpg'
          || this.allSubFolders.RetData[i].DocumentType == 'PNG') {
          this.SubFoldersList.push(this.allSubFolders.RetData[i]);
        }
      }
    }
  });
}
}

