import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'app/shared/MailService/mail.service';
import { MovesService } from 'app/shared/MoveService/moves.service';
import { UserService } from 'app/shared/UserService/user.service';
import { MailboxVM } from 'app/shared/VIewmodels/MailboxVM';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sentmails',
  templateUrl: './sentmails.component.html',
  styleUrls: ['./sentmails.component.scss']
})
export class SentmailsComponent implements OnInit {

  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  DateTime: Date;
  sentList: any;
  q: number = 1;
  p: number = 1;
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  searchText: any;
  selectedSelected: any = [];
  notify: string;
  n: number;
 
  num: any;
  CurrentFiles: any;
  createdmsg: string;
  UserID: string;
  constructor(private router: ActivatedRoute, private userservice: UserService, private moveService: MovesService,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal,
    private spinner: NgxSpinnerService, private datepipe: DatePipe, private http: HttpClient, 
    private mailservice: MailService) {
       
    
      if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");
      this.UserID=localStorage.getItem("uid");
    }
  }


  ngOnInit(): void {
    this.GetSentMailList();
  }

  inbox() {
    this.route.navigate(['inboxmails']);
  }
  composemail() {
    this.route.navigate(['mcompose']);
  }
  deleteitems() {
    this.route.navigate(['del']);
  }
  sentitems() {
    this.route.navigate(['sentmails']);
  }
  archiveitems() {
    this.route.navigate(['archive']);
  }
  GetSentMailList() {
  this.spinner.show()
    var info ={
      UID  :this.UserID
    }
    this.mailservice.getsentlist(info).subscribe((respose: any) => {
      this.sentList = respose;
      console.log(respose);
      this.spinner.hide(); 
    });
  }

  toggleSelect(data) {
    this.CurrentFiles = data;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
        }
        else {
          this.selectedSelected.push(data); 
        }
      }
    }

   

  
  }
  // singlearchive(data){
  //   this.selectedSelected.push(data);
  //   this.multiarchive();
  //   var info = {
  //     MailId : data.mailId,
  //     Id: data.id,
  //     Fromuser:data.fromId,
  //     MailsList:this.selectedSelected,
  //     CurrentDate:data.date

  //   }
  //   this.spinner.show()
  //   this.mailservice.postmakeitArchive(info).subscribe((data: any) => {
  //    // this.UsersList = respose;
  //    if (data == "success") {
  //       this.notify = 'Archived Successfully';

  //     this.spinner.hide();
  //     this.GetSentMailList();
  //     }
  //   });
  // }
  singlearchive(data){
    this.selectedSelected.push(data);
    this.multiarchive();
    // var info = {
    //   MailId : data.mailId,
    //   Id: data.id,

    // }
    // this.spinner.show();
    // this.mailservice.postmakeitArchive(info).subscribe((data: any) => {
    //  // this.UsersList = respose;
    //  if (data == "success") {
    //     this.notify = 'Archived Successfully';

    //   this.spinner.hide();
    //  this.GetSentMailList();
    //   }
    // });
  }
  // multiarchive() {
  //   this.DateTime = new Date();
  //   let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');


  //   this.showper = true;
  //   this.generalprogressbar = true;
  //   this.folderCreating = true;
  //   this.getPercent(this.n);
  //   this.num = setInterval(() => {
  //     if (this.n <= 90) {
  //       this.n = this.n + this.getPercent(this.n);
  //       if (this.n == 90) {
  //         this.n = 90;
  //         clearInterval(this.num);
  //       }
  //     }
  //   }, 3000);

  //   const inputRequest1: MailboxVM = {
  //     Fromuser:this.uid,
  //     MailsList:this.selectedSelected,
  //     CurrentDate:latest_date
  //   }
  //   this.mailservice.postmakeitArchiveforjob(inputRequest1).subscribe((respose: any) => {
  //     if(respose == "1"){
  //       this.n=100;
  //       this.createdmsg = "Archived Successfully...!"
  //       this.folderCreating = false;
  //       this.selectedSelected = [];
  //       clearInterval(this.num);

  //       setTimeout(function () {
  //         this.folderCreating = false;
  //         this.foldercreated = false;
  //         this.generalprogressbar = false;
  //         this.errormsg = false;
  //         this.showper = false;
  //         this.n=10;
  //       }.bind(this), 3000);
  //       this.GetSentMailList();
  //   }
  //   else{
  //     this.selectedSelected = [];
  //     this.GetSentMailList();
  //   }
  //   });
  // }
  // singlearchive(data){
  //   // this.selectedSelected.push(data);
  //   // this.multiarchive();
  //   var info = {
  //     MailId : data.mailId,
  //     Id: data.id,

  //   }
  //  //////// this.spinner.show()
  //   this.mailservice.postmakeitArchive(info).subscribe((data: any) => {
  //    // this.UsersList = respose;
  //    if (data == "success") {
  //       this.notify = 'Archived Successfully';

  //     this.spinner.hide();
  //     this.GetSentMailList();
  //     }
  //   });
  // }
  multiarchive() {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');


    this.folderCreating = true;
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest1: MailboxVM = {
      Fromuser:this.UserID,
      MailsList:this.selectedSelected,
      CurrentDate:latest_date
    }
    this.mailservice.postmakeitsentArchive(inputRequest1).subscribe((respose: any) => {
      if(respose == "1"){
        this.n=100;
        this.createdmsg = "Archived Successfully...!"
        this.folderCreating = false;
        this.selectedSelected = [];
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n=10;
        }.bind(this), 3000);
       this.GetSentMailList();
     
    }
    else{
      this.selectedSelected = [];
     this.GetSentMailList();
   
    }
    });
  }
  // singleDelete(data){
  //   this.selectedSelected.push(data);
  //   this.multidelete();
  // }
  singleDelete(data){
    this.selectedSelected.push(data);
    this.multidelete();
  }
  getPercent(per) {
    return 10;
  }

  // multidelete() {
  //   this.DateTime = new Date();
  //   let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');

  //   this.showper = true;
  //   this.generalprogressbar = true;
  //   this.folderCreating = true;
  //   this.getPercent(this.n);
  //   this.notify = "Deleting Messages...";
  //   this.num = setInterval(() => {
  //     if (this.n <= 90) {
  //       this.notify = "Deleting Messages...";
  //       this.n = this.n + this.getPercent(this.n);
  //       if (this.n == 90) {
  //         this.n = 90;
  //         clearInterval(this.num);
  //       }
  //     }
  //   }, 3000);

  //   const inputRequest1: MailboxVM = {
  //     Fromuser:this.UserID,
  //     MailsList:this.selectedSelected,
  //     CurrentDate:latest_date
  //   }
  //   this.mailservice.multideletemessages(inputRequest1).subscribe((respose: any) => {
  //     if(respose == "1"){
  //       this.n=100;
  //       this.notify = "Deleted Successfully...!"
  //       this.GetSentMailList();
  //       this.folderCreating = false;
  //       this.selectedSelected = [];
    
  //       clearInterval(this.num);

  //       setTimeout(function () {
  //         this.folderCreating = false;
  //         this.foldercreated = false;
  //         this.generalprogressbar = false;
  //         this.errormsg = false;
  //         this.showper = false;
  //         this.n=10;
  //       }.bind(this), 3000);
  //       this.GetSentMailList();
  //   }
  //   else{
  //     this.selectedSelected = [];
  //     this.GetSentMailList();
 
  //   }
  //   });
  // }
  multidelete() {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
    // this.showper = true;
    // this.generalprogressbar = true;
    this.folderCreating = true;
    const inputRequest1: MailboxVM = {
      Fromuser:this.UserID,
      MailsList:this.selectedSelected,
      CurrentDate:latest_date
    }
    this.mailservice.multideletesentmessages(inputRequest1).subscribe((respose: any) => {
      if(respose == "1"){
        this.n=100;
        this.notify = "Deleted Successfully...!"
        this.GetSentMailList();
        this.folderCreating = false;
        this.selectedSelected = [];
    
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n=10;
        }.bind(this), 3000);
        this.GetSentMailList();
    }
    else{
      this.selectedSelected = [];
      this.GetSentMailList();
 
    }
    });
  }
  ReadMessage(data) {
    localStorage.setItem('fromuser', data.From);
    localStorage.setItem('touser', data.To);
    localStorage.setItem('attachments', data.Attachments);
    localStorage.setItem('mailid', data.MasId);
    localStorage.setItem('transid', data.TransId);
    localStorage.setItem('maildate', data.DateforRM);
    localStorage.setItem('profilepic', data.UserProfilePic);
    localStorage.setItem('firstletter', data.FirstLetter);
    localStorage.setItem('docname', data.DocumentName);
    localStorage.setItem('emailbody', data.EmailBody);
    localStorage.setItem('sub', data.Subject); 
    this.route.navigate(['readmsg/'+ data.mailId]);
  }
  sort = "1";

  sortby() {
    if (this.sort == "1") {
      this.sentList.sort((a, b) => a.fromName.toLowerCase() < b.fromName.toLowerCase() ? -1 : a.fromName.toLowerCase() > b.fromName.toLowerCase() ? 1 : 0)
      this.sort = "2";
    }
    else if (this.sort == "2") {
      this.sentList.sort((a, b) => a.fromName.toLowerCase() > b.fromName.toLowerCase() ? -1 : a.fromName.toLowerCase() < b.fromName.toLowerCase() ? 1 : 0)
      this.sort = "1";

    }

  }
  sortname = "1";
  sortbydate() {
    if (this.sortname == "1") {
      this.sentList.sort((a, b) => a.Sortdate < b.Sortdate ? -1 : a.Sortdate > b.Sortdate ? 1 : 0)
      this.sortname = "2";
    }
    else if (this.sortname == "2") {
      this.sentList.sort((a, b) => a.Sortdate > b.Sortdate ? -1 : a.Sortdate < b.Sortdate ? 1 : 0)
      this.sortname = "1";

    }

  }
}
