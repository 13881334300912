<br />

<!-- <div class="whole-page">
    <br><br><br><br><br>
    <div class="col-md-12 text-center" style="font-weight: 500;font-size: 20px;" *ngIf="jonlist == undefined || jonlist == null|| jonlist == ''" style="font-weight: 700;font-size: 22px;color: whitesmoke;">No Jobs have been posted yet!!!</div>
    <div class="col-md-12 text-center" style="font-weight: 500;font-size: 20px;" *ngIf="jonlist.length > 0" style="font-weight: 700;font-size: 22px;color: whitesmoke;">Company Details</div>
    <br>
    <div *ngFor="let job of jonlist">

        <button class="collapsible"><span>{{job.companyName}}</span></button>
        <div class="whole-content">
            <div class="content">
                Posted By : <b>{{job.jobposterName}} </b><br> Job Description : <b>{{job.jobDescription}}</b> <br> Job Category : <b>{{job.jobCategory}} </b><br> Experience :<b> {{job.minimumExp}} - {{job.maximumExp}}</b> <br> Key Skills : <b>{{job.keySkills}}</b>                <br> Pay Scale : <b>{{job.payScale}}</b>
                <br> Job Location : <b>{{job.jobLocation}}</b> <br> Job Poster Email : <b>{{job.jobposterEmail}}</b>
            </div>

        </div>
    </div>
</div> -->
<div class="whole-page">
    <br /><br /><br /><br />
    <div class="col-md-12 text-center" style="font-weight: 700; font-size: 22px; color: whitesmoke;" *ngIf="joblist == undefined || joblist == null || joblist == ''">
        No Jobs have been posted yet !
    </div>
    <div class="col-md-12 text-center" *ngIf="joblist.length > 0" style="font-weight: 700; font-size: 22px; color: whitesmoke;">
        Jobs
    </div>
    <main>
        <section class="container register-form">
            <div style="display: flex;">
                <div class="complete-form" *ngFor="let job of joblist">
                    <div class="form">
                        <div class="row text-center justify-content-center">
                            <div class="col-lg-12">
                                <div class="col-lg-12">
                                    <br />
                                    <div class="note">
                                        <p style="font-size: x-large;">{{ job.companyName }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-content">
                            <div class="container">
                                <div class="row justify-content-center mt--200"></div>
                                <form>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label for="fname" style="font-size: 15px; color: #8a061c;">Posted By :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.jobposterName }}</label
                      >
                    </div>

                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="
                          font-size: 15px;
                          color: #8a061c;
                          word-break: break-all;
                        "
                        >Job Description :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.jobDescription }}</label
                      >
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Key Skills :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                      >
                        {{ job.keySkils }}
                      </label>
                                        </div>
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label for="fname" style="font-size: 15px; color: #8a061c;">PayScale :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.payScale }}</label
                      >
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Experience :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.minimumExp }} - {{ job.maximumExp }}</label
                      >
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Highest Qualification :</label
                      >
                    </div>
                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.highestQualification }}
                      </label>
                                        </div>
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label for="fname" style="font-size: 15px; color: #8a061c;">Location :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.jobLocation }}</label
                      >
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >Job Poster Email :</label
                      >
                    </div>

                    <div class="col-md-6">
                      <label
                        for="fname"
                        style="font-size: 15px; color: #8a061c;"
                        >{{ job.jobposterEmail }}</label
                      >
                    </div>

                    <!-- ------------------------------- -->
                    <div class="col-md-12">&nbsp;</div>

                    <div
                      class="col-md-10"
                      style="text-align: center; padding-left: 10%;"
                    >
                      <button
                        *ngIf="role == '4'"
                        type="submit"
                        class="btnSubmit"
                        [routerLink]="['/applied', job.blockId]"
                      >
                        View Applied Students List
                      </button>
                      <button
                        *ngIf="role == '3'"
                        type="submit"
                        class="btnSubmit"
                        (click)="apply(job.blockId, job.companyName)"
                      >
                        Apply
                      </button>
                    </div>
                    <div class="col-md-1">&nbsp;</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>

<div
  class="modal fade"
  id="exampleModal1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          Are you sure to share your details with
          <span *ngIf="CompanyName">{{ CompanyName.companyName }}</span> ?
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          id="cancelUpload"
          class="btn btn-secondary"
          data-dismiss="modal"
          style="cursor: pointer;"
        >
          Cancel
        </button>
        <button type="submit" class="btn btn-primary" (click)="shareDetails()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>