import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {  ReactiveFormsModule, FormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
// import { LandingComponent } from './landing/landing.component';
// import { ProfileComponent } from './profile/profile.component';
// import { HomeComponent } from './home/home.component';
// import { FooterComponent } from './shared/footer/footer.component';
// import { HomeModule } from './home/home.module';
// import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
// import { MysafeModule } from './mysafe/mysafe.module';
import { MessageService } from './shared/MessageService';
// import { EdituserComponent } from './USRM/User/edituser/edituser.component';
// import { UserlistComponent } from './USRM/User/userlist/userlist.component';
// import { AdduserComponent } from './USRM/User/adduser/adduser.component';
// import { AddmenumasterComponent } from './USRM/MenuMaster/addmenumaster/addmenumaster.component';
// import { EditmenumasterComponent } from './USRM/MenuMaster/editmenumaster/editmenumaster.component';
// import { MenumasterlistComponent } from './USRM/MenuMaster/menumasterlist/menumasterlist.component';
// import { AddnewfolderComponent } from './USRM/addnewfolder/addnewfolder.component';
import { ModalServiceService } from './shared/Modalpopup/modal-service.service';
// import { ImageviewpopupComponent } from './USRM/imageviewpopup/imageviewpopup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { BiometricsComponent } from './Biometrics/biometrics/biometrics.component';
// import { FriendsComponent } from './USRM/friends/friends.component';
// import { TransactionComponent } from './USRM/transaction/transaction.component';
// import { AllTransactionsComponent } from './USRM/all-transactions/all-transactions.component';
// import { PayuComponent } from './USRM/payu/payu.component';
// import { PayuPlansComponent } from './USRM/payu-plans/payu-plans.component';
// import { PlanComponent } from './USRM/plan/plan.component';
// import { MailInboxComponent } from './USRM/mail-inbox/mail-inbox.component';
// import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
// import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
// import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
// import { HomeoneComponent } from './homeone/homeone.component';
// import { PaymentComponent } from './payment/payment.component';
// import { SubplansComponent } from './subplans/subplans.component';
// import { MyPlansComponent } from './my-plans/my-plans.component';
// import { BillComponent } from './bill/bill.component';
// import { DashComponent } from './dash/dash.component';
import { NgxLinkedinModule } from 'ngx-linkedin';



import { SocialLoginModule, AuthServiceConfig, AuthService } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropFileUploadDirective } from './drag-drop-file-upload.directive';
import { NewhomeComponent } from './newComponents/newhome/newhome.component';
// import { NewfooterComponent } from './newComponents/newfooter/newfooter.component';

import { HeroanimationComponent } from './newComponents/heroanimation/heroanimation.component';

import { UserService } from './shared/UserService/user.service';
import { NgxSpinnerModule } from "ngx-spinner";

import { SignaturePadModule } from '@ng-plus/signature-pad';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ForgotpwdComponent } from './newcomponents/forgotpwd/forgotpwd.component';
import { ProfileregComponent } from './newcomponents/profilereg/profilereg.component';
import { AppjobComponent } from './newComponents/appjob/appjob.component';
import { ProfilepagenewComponent } from './newComponents/profilepagenew/profilepagenew.component';
import { SharedEmployerComponent } from './newComponents/shared-employer/shared-employer.component';
import { AllfilesComponent } from './USRM/allfiles/allfiles.component';
import { AllfoldersComponent } from './USRM/allfolders/allfolders.component';
import { NewbothsigninComponent } from './newComponents/newbothsignin/newbothsignin.component';
import { SharefileComponent } from './newComponents/sharefile/sharefile.component';
import { DeletedfilesComponent } from './newComponents/deletedfiles/deletedfiles.component';
import { StudentchatComponent } from './newComponents/studentchat/studentchat.component';
import { WebcamModule } from 'ngx-webcam';
import { InstituteregisterComponent } from './newComponents/instituteregister/instituteregister.component';
import { InternshippostingComponent } from './newComponents/internshipposting/internshipposting.component';
import { InternshipviewforinsComponent } from './newComponents/internshipviewforins/internshipviewforins.component';
import { JobpostinsComponent } from './newComponents/jobpostins/jobpostins.component';
import { JobviewforinsComponent } from './newComponents/jobviewforins/jobviewforins.component';
import { TrainingpostComponent } from './newComponents/trainingpost/trainingpost.component';
import { TrainingviewComponent } from './newComponents/trainingview/trainingview.component';
import { Profile1Component } from './newComponents/profile1/profile1.component';
import { CompanyprofileComponent } from './newComponents/companyprofile/companyprofile.component';
import { CompanyMatchListComponent } from './company-match-list/company-match-list.component';
import { ProfileviewusersComponent } from './newcomponents/profileviewusers/profileviewusers.component';
import { UserComponent } from './newcomponents/user/user.component';
import { DesignationComponent } from './newcomponents/designation/designation.component';
import { RoleComponent } from './newcomponents/role/role.component';
import { DepartmentComponent } from './newcomponents/department/department.component';
import { CountryComponent } from './newcomponents/country/country.component';
import { EregistrationComponent } from './newcomponents/eregistration/eregistration.component';
import { DatePipe } from '@angular/common';
import { PlacedComponent } from './newcomponents/placed/placed.component';
import { CompletedComponent } from './newcomponents/completed/completed.component';
import { AppliedComponent } from './newcomponents/applied/applied.component';
import { ReportComponent } from './newcomponents/report/report.component';
import { TableComponent } from './newcomponents/table/table.component';
import { DashboardComponent } from './newcomponents/dashboard/dashboard.component';
import { InstitutionComponent } from './newcomponents/masters/institution/institution.component';
import { ApproleComponent } from './newcomponents/masters/approle/approle.component';
import { AddoptionsComponent } from './newcomponents/mcq/addoptions/addoptions.component';
import { AddquestionsComponent } from './newcomponents/mcq/addquestions/addquestions.component';
import { TestComponent } from './newcomponents/mcq/test/test.component';
import { CodemirrorComponent } from './newComponents/mcq/codemirror/codemirror.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { McqreportComponent } from './newComponents/mcq/mcqreport/mcqreport.component';
import { PlacementprofileComponent } from './newComponents/placementprofile/placementprofile.component';
import { VideostreamingComponent } from './newComponents/mcq/videostreaming/videostreaming.component';
import { VideoreceiverComponent } from './newComponents/mcq/videoreceiver/videoreceiver.component';
import { HosteventComponent } from './newComponents/VideoInterview/hostevent/hostevent.component';
import { JoinmeetingComponent } from './newComponents/VideoInterview/joinmeeting/joinmeeting.component';
import { HostjoinComponent } from './newComponents/VideoInterview/hostjoin/hostjoin.component';
import { AlljoblistComponent } from './alljoblist/alljoblist.component';
import { AllcompanylistComponent } from './allcompanylist/allcompanylist.component';
import { AllstudentlistComponent } from './allstudentlist/allstudentlist.component';
import { AdmindashboardComponent } from './admindashboard/admindashboard.component';
import { JobrolemasterComponent } from './jobrolemaster/jobrolemaster.component';
import { IndustrymasterComponent } from './industrymaster/industrymaster.component';
import { GotojobpostingComponent } from './gotojobposting/gotojobposting.component';
import { BackgroundverificationComponent } from './backgroundverification/backgroundverification.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { SkillComponent } from './Masters/skill/skill.component';
import { LocationComponent } from './Masters/location/location.component';
import { AlertComponent } from './alert/alert.component';
import { JobSkillCategoryMasterComponent } from './Masters/job-skill-category-master/job-skill-category-master.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { MsviewComponent } from './msview/msview.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SocialComponent } from './social/social.component';



import { JobinboxmailComponent } from './jobmail/jobinboxmail/jobinboxmail/jobinboxmail.component';

import { JobarchivemailComponent } from './jobmail/jobarchivemail/jobarchivemail/jobarchivemail.component';

import { JobreadmailComponent } from './jobmail/jobreadmail/jobreadmail/jobreadmail.component';
import { JobsentmailComponent } from './jobmail/jobsentmail/jobsentmail/jobsentmail.component';
import { TagInputModule } from 'ngx-chips';
import { MailComponent } from './Mail/mail/mail/mail.component';
import { ArchiveMailsComponent } from './Mail/archive-mails/archive-mails/archive-mails.component';
import { ComposemailComponent } from './Mail/composemail/composemail/composemail.component';
import { DeleteMailsComponent } from './Mail/delete-mails/delete-mails/delete-mails.component';
import { InboxMailsComponent } from './newComponents/Mails/inboxmails/inbox-mails/inbox-mails.component';
import { ReadMailComponent } from './Mail/read-mail/read-mail/read-mail.component';
import { SentMailsComponent } from './Mail/sent-mails/sent-mails/sent-mails.component';
import { ReadmailsComponent } from './newComponents/Mails/readmails/readmails/readmails.component';
import { ArchivemailsComponent } from './newComponents/Mails/archivemails/archivemails/archivemails.component';
import { SentmailsComponent } from './newComponents/Mails/sentmails/sentmails/sentmails.component';
import { DeletedMailsComponent } from './newComponents/Mails/deletedmails/deleted-mails/deleted-mails.component';
import { FriendrequestComponent } from './newComponents/friendrequest/friendrequest/friendrequest.component';
import { MailcomposeeComponent } from './newComponents/Mails/mailcomposee/mailcomposee/mailcomposee.component';
import { JobmailcomposeComponent } from './jobmail/jobmailcomposee/jobmailcomposee/jobmailcomposee.component';
import { JobdeletedmailComponent } from './jobmail/jondeletedmail/jobdeletedmail/jobdeletedmail.component';
import { OnlinecodecompilerComponent } from './newComponents/onlinecodecompiler/onlinecodecompiler.component';
import { RecordedphysicalvideosComponent } from './newComponents/VideoInterview/recordedphysicalvideos/recordedphysicalvideos.component';
import { DocumentgenerationComponent } from './documentgeneration/documentgeneration.component';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    // provider: new GoogleLoginProvider("1028668106160-kq7b3ilia7kc17rvjjl9tdasqibf6ts4.apps.googleusercontent.com")
    provider: new GoogleLoginProvider("763454507797-0evl0bdl0ljqm3ksrn3gnietn1efidr2.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("490414135990233")
  }
]);
export function provideConfig() {
  return config;
}
 

@NgModule({
  
  imports: [
    BrowserModule,
    NgbModule,
    SelectDropDownModule,
    RouterModule,
    AppRoutingModule,
    // HomeModule,
    HttpClientModule,
    TagInputModule,
    // MysafeModule,
   
    ReactiveFormsModule,
    PdfViewerModule,
    SignaturePadModule,
    NgxDocViewerModule,
    SocialLoginModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxLinkedinModule.forRoot({
      clientId: '81fsghpn98dxwx'
  }),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    ReactiveFormsModule,
    WebcamModule,
    CodemirrorModule,
    ImageCropperModule,
  ],
  providers: [
    UserService,MessageService,ModalServiceService, 
    AuthService,
    {  
      provide: AuthServiceConfig,  
      useFactory: provideConfig  
    },DatePipe ],
  bootstrap: [AppComponent],
  declarations: [

    AppComponent,
    DragDropFileUploadDirective,
    NewhomeComponent,
    HeroanimationComponent,
    ForgotpwdComponent,
    AppjobComponent,
    OnlinecodecompilerComponent,
    ProfilepagenewComponent,
    SharedEmployerComponent,
    ProfileregComponent,
    AllfilesComponent,
    AllfoldersComponent,
    NewbothsigninComponent,
    SharefileComponent,
    DeletedfilesComponent,
    StudentchatComponent,
    InstituteregisterComponent,
    InternshippostingComponent,
    InternshipviewforinsComponent,
    JobpostinsComponent,
    JobviewforinsComponent,
    TrainingpostComponent,
    TrainingviewComponent,
    Profile1Component,
    CompanyprofileComponent,
    CompanyMatchListComponent,
    ProfileviewusersComponent,
    UserComponent,
    DesignationComponent,
    RoleComponent,
    DepartmentComponent,
    CountryComponent,
    EregistrationComponent,
    PlacedComponent,
    RecordedphysicalvideosComponent,
    CompletedComponent,
    AppliedComponent,
    ReportComponent,
    TableComponent,
    DashboardComponent,
    InstitutionComponent,
    ApproleComponent,
    AddoptionsComponent,
    AddquestionsComponent,
    TestComponent,
    CodemirrorComponent,
    McqreportComponent,
    PlacementprofileComponent,
    VideostreamingComponent,
    VideoreceiverComponent,
    HosteventComponent,
    JoinmeetingComponent,
    HostjoinComponent,
    AlljoblistComponent,
    AllcompanylistComponent,
    AllstudentlistComponent,
    AdmindashboardComponent,
    JobrolemasterComponent,
    IndustrymasterComponent,
    GotojobpostingComponent,
    BackgroundverificationComponent,
    SkillComponent,
    LocationComponent,
    AlertComponent,
    JobSkillCategoryMasterComponent,
    AboutusComponent,
    MsviewComponent,
    SocialComponent,
    MailComponent,
    ArchiveMailsComponent,
    ComposemailComponent,
    DeleteMailsComponent,
    InboxMailsComponent,
    ReadMailComponent,
    SentMailsComponent,
    ArchivemailsComponent,
    ReadmailsComponent,
    SentmailsComponent,
    DeletedMailsComponent,
    InboxMailsComponent,
    FriendrequestComponent,
   MailcomposeeComponent,
   JobinboxmailComponent,
   JobmailcomposeComponent,
   JobarchivemailComponent,
   JobdeletedmailComponent,
   JobreadmailComponent,
   JobsentmailComponent,
   DocumentgenerationComponent,
   
    ],

    schemas: [
      //  NO_ERRORS_SCHEMA,
      CUSTOM_ELEMENTS_SCHEMA
      
    ],
     exports:[
       
     ]
})
export class AppModule { }
