<br><br><br><br><br><br>
<div class="col-md-12 row text-center">
    <div class="col-md-3"></div>
    <div class="col-md-6" id="inputs">
        <input type="email" class="form-control" id="email1" name="email1" placeholder="Please type the invitee email" [required]="true">
    </div>

</div>
<div class="col-md-12 text-center">
    <br>
    <button class="btn btn-primary" (click)="add()">Add</button>&nbsp;
    <button class="btn btn-primary" (click)="submit()">Invite</button>
</div>